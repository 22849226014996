import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { CompetitorPlanComparison } from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison";
import styled from "styled-components";

export const StyledOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${rhSpacingPx(3)};
  justify-content: center;
  padding: 0 ${rhSpacingPx(2)};
  position: relative;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(5)};
  }
`;

export const StyledCompetitorPlanChart = styled(CompetitorPlanComparison)`
  margin-top: ${rhSpacingPx(4)};
`;
