import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { minBy } from "lodash";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

export interface RepPriceComparisonChartStateType {
  comparisonChartOfferSnapshot: OfferSnapshot | null;
  comparisonChartTermMonths: number | null;
}

interface RepPriceComparisonChartProviderProps
  extends RepPriceComparisonChartStateType {
  setComparisonChartOfferSnapshot: (
    offerSnapshot: OfferSnapshot | null
  ) => void;
  setComparisonChartTermMonths: (termMonths: number | null) => void;
}

export const RepPriceComparisonChartContext = createContext(
  {} as RepPriceComparisonChartProviderProps
);

const lowestPriceOfferSnapshot = (offerSnapshots: OfferSnapshotType[]) => {
  if (!offerSnapshots) {
    return null;
  }

  const lowestPricedOfferSnapshot = minBy(offerSnapshots, "price");

  return lowestPricedOfferSnapshot
    ? new OfferSnapshot(lowestPricedOfferSnapshot)
    : null;
};

interface RepPriceComparisonChartProps {
  offerSnapshots: OfferSnapshotType[];
}

export const RepPriceComparisonChartProvider = ({
  children,
  offerSnapshots,
}: PropsWithChildren<RepPriceComparisonChartProps>) => {
  const [comparisonChartTermMonths, setComparisonChartTermMonths] = useState<
    number | null
  >(null);
  const [comparisonChartOfferSnapshot, setComparisonChartOfferSnapshot] =
    useState<OfferSnapshot | null>(lowestPriceOfferSnapshot(offerSnapshots));

  const repPriceComparisonChart = useMemo(
    () => ({
      comparisonChartOfferSnapshot,
      comparisonChartTermMonths,
      setComparisonChartOfferSnapshot,
      setComparisonChartTermMonths,
    }),
    [comparisonChartOfferSnapshot, comparisonChartTermMonths]
  );

  return (
    <RepPriceComparisonChartContext.Provider value={repPriceComparisonChart}>
      {children}
    </RepPriceComparisonChartContext.Provider>
  );
};

export const useRepPriceComparisonChart = () =>
  useContext(RepPriceComparisonChartContext);
