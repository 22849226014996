import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled, { css } from "styled-components";

export const TabList = styled.div`
  align-items: center;
  border-bottom: 1px solid ${grey[100]};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface TabProps {
  $active: boolean;
}

export const TabButton = styled.button<TabProps>`
  align-items: center;
  appearance: none;
  background: ${trueWhite};
  border: 0;
  border-bottom: 2px solid transparent;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-size: 0.8rem;
  height: 100%;
  padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};
  position: relative;
  transition: 0.6s;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${grey[50]};
    cursor: pointer;
  }

  ${({ $active }) =>
    $active &&
    css`
      border-bottom-color: ${darkPurple[500]};
      color: ${darkPurple[500]};
    `}

  @media screen and (min-width: 600px) {
    font-size: 0.9rem;
    padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(3)};
  }
`;

export const TabPanel = styled.div`
  padding-top: 1rem;
  width: 100%;
`;
