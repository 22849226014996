import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatToCents } from "@common/utils/dataFormatters";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

export const OfferSnapshotRadioGroupContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export const OfferSnapshotRadio = styled.div`
  align-items: center;
  display: flex;
  gap: 0.1rem;

  label {
    text-wrap: balance;
  }
`;

export const OfferSnapshotPrice = styled(RhTypography).attrs({
  component: "span",
})`
  &.MuiTypography-root {
    font-size: 1rem;
    font-weight: ${FontWeight.Bold};
  }
`;

interface OfferSnapshotRadioGroupProps {
  comparisonChartOfferSnapshot: OfferSnapshot;
  handleOfferSnapshotChange: (event: ChangeEvent<HTMLInputElement>) => void;
  offerSnapshots: OfferSnapshot[];
}

export const OfferSnapshotRadioGroup: FC<OfferSnapshotRadioGroupProps> = ({
  comparisonChartOfferSnapshot,
  handleOfferSnapshotChange,
  offerSnapshots,
}) => {
  const { t } = useRhIntl();

  return (
    <OfferSnapshotRadioGroupContainer>
      {offerSnapshots.map((offerSnapshot) => {
        const id = `OfferRadio-${offerSnapshot.id}`;

        return (
          <OfferSnapshotRadio key={offerSnapshot.id}>
            <RhRadioInput
              id={id}
              value={offerSnapshot.id}
              name={offerSnapshot.title}
              onChange={handleOfferSnapshotChange}
              checked={
                comparisonChartOfferSnapshot
                  ? offerSnapshot.id === comparisonChartOfferSnapshot.id
                  : false
              }
            />
            <label htmlFor={id}>
              {offerSnapshot.title}
              <br />
              <OfferSnapshotPrice>
                {formatToCents(offerSnapshot.displayPrice())}
              </OfferSnapshotPrice>{" "}
              {t("RepPriceComparisonFilters.perKwh")}
            </label>
          </OfferSnapshotRadio>
        );
      })}
    </OfferSnapshotRadioGroupContainer>
  );
};
