import { grey } from "@design-system/theme/palette.colors";

// This structure does not fit with MuiTheme borders which is
// why it's not wrapped into the theme
export const borders = {
  100: `1px solid ${grey[100]}`,
  200: `1px solid ${grey[200]}`,
  400: `1px solid ${grey[400]}`,
  50: `1px solid ${grey[50]}`,
};
