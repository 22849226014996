import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotLearnMoreRateAndEstimateTranslations } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreRateAndEstimate/OfferSnapshotLearnMoreRateAndEstimate.en.i18n";
import { RateAndEstimateDisplayedPrice } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreRateAndEstimate/OfferSnapshotLearnMoreRateAndEstimate.styled";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface OfferSnapshotLearnMoreRateAndEstimateProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreRateAndEstimate = (
  props: OfferSnapshotLearnMoreRateAndEstimateProps
) => {
  const { offerSnapshot } = props;
  const { translate, translateJsx } = useTranslations();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalSolarPlanShowAveragePrice } = featureFlagClient.getFlags([
    ["portalSolarPlanShowAveragePrice", false],
  ]);

  const {
    energyRate,
    formattedCurrencyBaseCharge,
    price2000Kwh,
    solarCreditKwhRate,
    solarEligible,
  } = offerSnapshot;

  const { tOfferSnapshotLearnMoreRateAndEstimatePerKwh } = translate(
    offerSnapshotLearnMoreRateAndEstimateTranslations
  );
  const {
    tOfferSnapshotLearnMoreRateAndEstimateBasedOnSolar,
    tOfferSnapshotLearnMoreRateAndEstimateBasedOn,
  } = translateJsx({
    tOfferSnapshotLearnMoreRateAndEstimateBasedOn: {
      amount: DEFAULT_AVERAGE_MONTHLY_USAGE,
    },
    tOfferSnapshotLearnMoreRateAndEstimateBasedOnSolar: {
      baseCharge: formattedCurrencyBaseCharge,
      kwh: DEFAULT_AVERAGE_MONTHLY_USAGE,
      solarBuybackRate: formatDollarsToCents(solarCreditKwhRate),
      totalPerKwh: formatDollarsToCents(price2000Kwh),
    },
  });

  const showEnergyRate =
    solarEligible &&
    (offerSnapshot.isShowEnergyRateOffer ||
      !portalSolarPlanShowAveragePrice.value);

  const displayedPrice = showEnergyRate
    ? formatDollarsToCents(energyRate())
    : formatDollarsToCents(price2000Kwh);

  return (
    <div>
      <RateAndEstimateDisplayedPrice variant="h1" component="span">
        {`${displayedPrice} ${tOfferSnapshotLearnMoreRateAndEstimatePerKwh}`}
      </RateAndEstimateDisplayedPrice>

      <RhTypography color="textSecondary" variant="body1">
        {showEnergyRate
          ? tOfferSnapshotLearnMoreRateAndEstimateBasedOnSolar
          : tOfferSnapshotLearnMoreRateAndEstimateBasedOn}
      </RhTypography>
    </div>
  );
};
