import { CookieName, getCookieByKey } from "@common/utils/getCookies";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { useEffect, useState } from "react";

export const useFullStoryUserId = (): string | null => {
  const [fullStoryUserId, setFullStoryUserId] = useState<string | null>(null);
  const [tracked, setTracked] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const fsInitialized = window._fs_initialized;

    if (fsInitialized) {
      const fullStoryUserIdCookie = getCookieByKey(CookieName.FullStoryUserId);

      if (!tracked && fullStoryUserIdCookie) {
        track({
          action: ActionType.userIdentified,
          category: CategoryType.FullStory,
          event: EventType.identify,
          label: LabelType.FullStoryUserIdentified,
          value: fullStoryUserIdCookie,
        });

        setTracked(true);
      }

      setFullStoryUserId(fullStoryUserIdCookie);
    }
  }, [tracked]);

  return fullStoryUserId;
};
