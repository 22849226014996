import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { findClosest } from "@common/utils/arrayUtils";
import { RhButtonToggle } from "@design-system/components/RhButtonToggle/RhButtonToggle";
import { SliderOnChangeFunction } from "@design-system/types/domEventTypes";
import {
  StyledCostEstimatorHeader,
  StyledCostEstimatorRoot,
  StyledCostEstimatorSubtitle,
  StyledCostEstimatorTitle,
  StyledCostEstimatorToggleContainer,
  StyledMonthlyUsageInputContainer,
} from "@portal/components/CostEstimator/CostEstimator.styled";
import { MonthlyUsageInput } from "@portal/components/MonthlyUsageInput/MonthlyUsageInput";
import {
  MonthlyUsageSlider,
  monthlyUsageSliderMarksValues,
} from "@portal/components/MonthlyUsageSlider/MonthlyUsageSlider";
import { OfferMiniChooser } from "@portal/components/OfferMiniChooser/OfferMiniChooser";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface CostEstimatorProps {
  offerSnapshots: OfferSnapshotType[];
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshotType) => void;
}

export const COST_ESTIMATOR_SCROLL_TO_POINT = "cost-estimator";

export const CostEstimator = ({
  offerSnapshots,
  onSelectOfferSnapshot,
}: CostEstimatorProps) => {
  const { t } = useRhIntl();
  const [defaultValue, setDefaultValue] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const { trackEvent } = useSignUpFlow();

  const title = t("CostEstimator.howMuchWillItCost");
  const subtitle = t("CostEstimator.greatQuestion");
  const methodHelpMeEstimate = t("CostEstimator.helpMeEstimate");
  const methodKnowMyUsage = t("CostEstimator.knowMyUsage");
  const [method, setMethod] = useState<string>(methodHelpMeEstimate);

  const onSliderChange: SliderOnChangeFunction = (_, value) => {
    if (Array.isArray(value)) {
      return;
    }

    dispatch(setSignUpInfo({ estimatedMonthlyUsage: value }));
  };
  const onInputChange = (value: number | null) => {
    if (value !== null) {
      dispatch(setSignUpInfo({ estimatedMonthlyUsage: value }));
    }
  };
  const handleMethodToggle = (newMethod: string) => {
    setMethod(newMethod);
    let label: string;

    if (newMethod === methodHelpMeEstimate) {
      // set estimated usage to something that is on the slider
      dispatch(
        setSignUpInfo({
          estimatedMonthlyUsage: findClosest(
            monthlyUsageSliderMarksValues,
            estimatedMonthlyUsage
          ),
        })
      );
      label = "toggleHelpMeEstimate";
    } else {
      label = "toggleKnowMyUsage";
    }

    trackEvent({
      action: ActionType.click,
      label,
    });
  };

  useEffect(() => {
    // Setup the initial estimated monthly usage if it hasn't been set or
    // it it's already in redux
    if (estimatedMonthlyUsage) {
      setDefaultValue(estimatedMonthlyUsage);
    } else {
      setDefaultValue(DEFAULT_AVERAGE_MONTHLY_USAGE);
    }
  }, [estimatedMonthlyUsage]);

  if (!defaultValue) {
    return null;
  }

  return (
    <StyledCostEstimatorRoot id={COST_ESTIMATOR_SCROLL_TO_POINT}>
      <StyledCostEstimatorHeader>
        <StyledCostEstimatorTitle variant="h2">
          {title}
        </StyledCostEstimatorTitle>
        <StyledCostEstimatorSubtitle variant="subtitle2">
          {subtitle}
        </StyledCostEstimatorSubtitle>
      </StyledCostEstimatorHeader>
      <StyledCostEstimatorToggleContainer>
        <RhButtonToggle
          fullWidth
          labels={{ left: methodHelpMeEstimate, right: methodKnowMyUsage }}
          onChange={handleMethodToggle}
          defaultValue={methodHelpMeEstimate}
        />
      </StyledCostEstimatorToggleContainer>
      {method === methodHelpMeEstimate ? (
        <MonthlyUsageSlider
          onChange={onSliderChange}
          initialValue={estimatedMonthlyUsage}
        />
      ) : (
        <StyledMonthlyUsageInputContainer>
          <MonthlyUsageInput
            onChange={onInputChange}
            initialValue={estimatedMonthlyUsage}
          />
        </StyledMonthlyUsageInputContainer>
      )}
      <OfferMiniChooser
        offerSnapshots={offerSnapshots}
        onSelectOfferSnapshot={onSelectOfferSnapshot}
      />
    </StyledCostEstimatorRoot>
  );
};
