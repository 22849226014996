import { FeatureFlagValues } from "@common/context/featureFlagClient.context";
import { SignUpPlansPage } from "@portal/pages/SignUpPlansPage/SignUpPlansPage";
import React, { ReactNode } from "react";

interface FeatureFlagRouteShape {
  component: ReactNode;
  featureFlag: keyof FeatureFlagValues;
  path: string;
}

export const featureFlagRoutes: FeatureFlagRouteShape[] = [
  {
    component: <SignUpPlansPage />,
    featureFlag: "portalNewPlansPage",
    path: "/plans-v2",
  },
];
