import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { rewardsHistoryTableTranslations } from "@portal/components/RewardsHistoryTable/RewardsHistoryTable.en.i18n";
import { rewardsHistoryTableRewardTranslations } from "@portal/components/RewardsHistoryTable/RewardsHistoryTable.rewards.en.i18n";
import {
  StyledDate,
  StyledDescription,
  StyledPointsAmount,
  StyledPointsValue,
  StyledRewardsHistoryRow,
  StyledRewardsHistoryTable,
} from "@portal/components/RewardsHistoryTable/RewardsHistoryTable.styled";
import { useRewardNames } from "@portal/components/RewardsHistoryTable/useRewardNames";
import { useGetCustomerRewards } from "@portal/hooks/rewardsHooks";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

export const RewardsHistoryTable = () => {
  const { translate, translateJsx } = useTranslations();
  const rewardNames = useRewardNames();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { rewards } = useGetCustomerRewards();

  if (!rewards?.history.length) {
    return null;
  }

  const { tRewardsHistoryTableDefaultRewardName } = translate(
    rewardsHistoryTableRewardTranslations
  );

  const { tRewardsHistoryTableTitle } = translate(
    rewardsHistoryTableTranslations
  );

  return (
    <RhCard title={tRewardsHistoryTableTitle}>
      <StyledRewardsHistoryTable>
        {rewards.history.map(({ name, points, createdAt, value }) => {
          const date = formatMonthDayShortYear(createdAt, {
            leadingZeros: false,
          });
          const defaultDescription = tRewardsHistoryTableDefaultRewardName;

          const description = name ? rewardNames[name] : defaultDescription;

          const {
            tRewardsHistoryTableMobilePoints,
            tRewardsHistoryTablePoints,
          } = translateJsx({
            tRewardsHistoryTableMobilePoints: {
              points,
            },
            tRewardsHistoryTablePoints: {
              points,
            },
          });

          const pointsAmount = isMobile
            ? tRewardsHistoryTableMobilePoints
            : tRewardsHistoryTablePoints;

          const pointsValue = formatCurrency(value);

          return (
            <StyledRewardsHistoryRow
              key={`${name}-${points}-${createdAt}`}
              data-testid="rewardsHistoryTable"
            >
              <StyledDate component="div" variant="body1">
                {date}
              </StyledDate>
              <StyledDescription component="div" variant="body1">
                {description}
              </StyledDescription>
              <StyledPointsAmount component="div" variant="body1">
                {pointsAmount}
              </StyledPointsAmount>
              <StyledPointsValue component="div" variant="body1">
                {pointsValue}
              </StyledPointsValue>
            </StyledRewardsHistoryRow>
          );
        })}
      </StyledRewardsHistoryTable>
    </RhCard>
  );
};
