import { UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { QuickPayQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseQuickPayTokenProps {
  token: UUIDType;
}

export const useQuickPayAccountBalance = (props: UseQuickPayTokenProps) => {
  const { token } = props;

  const query = useQuery({
    queryFn: () => portalApi.quickPay.getAccountBalance(token),
    queryKey: QuickPayQueryKeys.accountBalance(token),
    // We don't want to refetch on error as this is considered isPending on refetch
    retry: false,
  });

  return query;
};
