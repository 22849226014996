import { ReactComponent as DirectEnergyLogo } from "@common/images/DirectEnergyLogo.svg";
import { ReactComponent as GexaLogo } from "@common/images/GexaLogo.svg";
import { ReactComponent as GreenMountainLogo } from "@common/images/GreenMountainLogo.svg";
import { ReactComponent as ReliantLogo } from "@common/images/ReliantLogo.svg";
import { ReactComponent as RhythmLogo } from "@common/images/RhythmLogoDarkPurple.svg";
import { ReactComponent as ShellEnergy } from "@common/images/ShellEnergy.svg";
import { ReactComponent as SparkEnergyLogo } from "@common/images/SparkEnergyLogo.svg";
import { ReactComponent as TxuLogo } from "@common/images/TxuLogo.svg";
import { RHYTHM } from "@portal/constants/misc.constant";
import { CompetitorNames } from "@portal/types/competitorTypes";
import React from "react";
import { StringOrNumberOrCallback } from "victory";

export const renderLogo = (text?: string[] | StringOrNumberOrCallback) => {
  switch (text) {
    case CompetitorNames.DirectEnergy: {
      return <DirectEnergyLogo />;
    }
    case CompetitorNames.GreenMountain: {
      return <GreenMountainLogo />;
    }
    case CompetitorNames.Reliant: {
      return <ReliantLogo />;
    }
    case CompetitorNames.SparkEnergy: {
      return <SparkEnergyLogo />;
    }
    case CompetitorNames.TXU: {
      return <TxuLogo />;
    }
    case CompetitorNames.GexaEnergy: {
      return <GexaLogo />;
    }
    case CompetitorNames.ShellEnergy: {
      return <ShellEnergy />;
    }
    case RHYTHM: {
      return <RhythmLogo />;
    }
    default: {
      return null;
    }
  }
};
