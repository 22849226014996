import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  StyledBaseErrorPageContainer,
  StyledBodyContainer,
  StyledErrorCode,
  StyledErrorMessage,
  StyledErrorTitle,
  StyledIllustrationContainer,
  StyledReturnHomeLink,
} from "@portal/pages/BaseErrorPage/BaseErrorPage.styled";
import { HOME } from "@portal/routes/routePaths";
import React from "react";

interface BaseErrorPageProps {
  code?: string;
  header: string;
  illustration?: JSX.Element;
  message: string;
  showLinkToHome: boolean;
}
export const BaseErrorPage = ({
  code,
  header,
  message,
  illustration,
  showLinkToHome,
}: BaseErrorPageProps) => {
  const withIllustration = !!illustration;
  const { t } = useRhIntl();
  const returnHome = t("BaseErrorPage.returnHome");

  return (
    <StyledBaseErrorPageContainer>
      {withIllustration && (
        <StyledIllustrationContainer $withIllustration={withIllustration}>
          {illustration}
        </StyledIllustrationContainer>
      )}
      <StyledBodyContainer $withIllustration={withIllustration}>
        {Boolean(code) && (
          <StyledErrorCode color="inherit" variant="h1">
            {code}
          </StyledErrorCode>
        )}
        <StyledErrorTitle variant="h1">{header}</StyledErrorTitle>
        <StyledErrorMessage variant="subtitle1">{message}</StyledErrorMessage>
        {showLinkToHome && (
          <StyledReturnHomeLink href={`/${HOME}`}>
            {returnHome}
          </StyledReturnHomeLink>
        )}
      </StyledBodyContainer>
    </StyledBaseErrorPageContainer>
  );
};
