import { blankAddress } from "@common/constants/blankAddress.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { CampaignPromoType } from "@common/types/campaignTypes";
import {
  AddressFormType,
  LanguagePreferenceType,
} from "@common/types/customerTypes";
import { ProductPriceForeignKeyType } from "@common/types/productTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { OfferFilterCategories } from "@portal/components/OfferFilterTabs/OfferFilterTabs";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { SignUpCreatePasswordPageValues } from "@portal/pages/CreatePasswordPage/CreatePasswordPage";
import { ExpiredPasswordTokenFormValues } from "@portal/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage";
import { SignUpContactFormValues } from "@portal/pages/SignUpContactPage/SignUpContactPage";
import { SignUpEnrollmentFormValues } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";
import { SignUpNameFormValues } from "@portal/pages/SignUpNamePage/SignUpNamePage";
import { SignUpOfferFormValues } from "@portal/pages/SignUpOfferPage/SignUpOfferPage";
import { SignUpPaymentFormValues } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { SignUpStepType } from "@portal/routes/routePaths";
import {
  userLoggedIn,
  userLoggedOut,
} from "@portal/slices/authenticationSlice";
import { customerPreferencesLanguageUpdated } from "@portal/slices/customerPreferencesSlice";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import merge from "lodash/merge";

export interface SignUpStepValues {
  visitedSteps: SignUpStepType[];
}

export interface AcquisitionData {
  acquisitionCampaign: null | string;
  acquisitionContent: null | string;
  acquisitionMedium: null | string;
  acquisitionSource: null | string;
  acquisitionTfn: null | string;
}

// Types
export interface SignUpStateType
  extends SignUpNameFormValues,
    SignUpContactFormValues,
    SignUpPremiseFormValues,
    SignUpOfferFormValues,
    SignUpEnrollmentFormValues,
    SignUpPaymentFormValues,
    SignUpCreatePasswordPageValues,
    ExpiredPasswordTokenFormValues,
    SignUpStepValues {
  acquisition: AcquisitionData;
  additionalInformationRequired: boolean;
  appSource: string | null;
  areaNotCovered: boolean | null;
  autopay: boolean;
  campaignPromo: CampaignPromoType | null;
  campaignSlug: string | null;
  estimatedMonthlyUsage: number;
  featureFlagUserId: string | null;
  fingerprintId: string | null;
  googleClientId: string | null;
  invoiceByPrint: boolean;
  isPaperless: boolean;
  languagePreference: LanguagePreferenceType | null;
  nosRequired: boolean;
  offerSnapshotUuid: UUIDType | null;
  productPrices: ProductPriceForeignKeyType[];
  prospectId: IdType | null;
  prospectUuid: UUIDType | null;
  referralFromFriendCode: string | null;
  segmentAnonId: string | null;
  selectedTermMonthsTab: OfferFilterCategories | string | null;
}

export type SetSignUpInfoAction = PayloadAction<Partial<SignUpStateType>>;
export type ManuallySetSignUpIdAction = PayloadAction<IdType | UUIDType>;

// Initial state
export const initialSignUpState = Object.freeze<SignUpStateType>({
  acquisition: {
    acquisitionCampaign: null,
    acquisitionContent: null,
    acquisitionMedium: null,
    acquisitionSource: null,
    acquisitionTfn: null,
  },
  activationToken: "",
  additionalInformationRequired: false,
  appSource: null,
  areaNotCovered: null,
  autopay: false,
  billingPaymentMethodId: null,
  campaignPromo: null,
  campaignSlug: null,
  confirmEmail: "",
  creditEvaluation: null,
  dateOfBirth: "",
  depositAlternativeAmount: null,
  depositAlternativeElected: false,
  depositAmount: null,
  dunsNumber: "",
  email: "",
  emailConflictsWithExistingAccount: undefined,
  enrollmentType: null,
  esiId: "",
  estimatedMonthlyUsage: DEFAULT_AVERAGE_MONTHLY_USAGE,
  featureFlagUserId: null,
  fingerprintId: null,
  firstName: "",
  googleClientId: null,
  id: "",
  invoiceByPrint: true,
  isPaperless: false,
  languagePreference: null,
  lastName: "",
  nosRequired: false,
  offerSnapshotUuid: null,
  offersnapshotId: "",
  phone: "",
  productPrices: [],
  prospectId: null,
  prospectUuid: null,
  referralFromFriendCode: null,
  segmentAnonId: null,
  selectedTermMonthsTab: null,
  sendMarketingPromos: true,
  serviceAddress: {
    ...blankAddress,
  },
  serviceStartDate: "",
  sessionToken: "",
  ssnRequired: false,
  stateToken: "",
  visitedSteps: ["availability"],
});

// Handlers
export const setSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  SetSignUpInfoAction
> = (state, action) => {
  return merge({}, state, action.payload);
};

export const setSignUpInfoLanguagePreferenceHandler = (
  state: SignUpStateType,
  action: PayloadAction<LanguagePreferenceType>
) => {
  return {
    ...state,
    languagePreference: action.payload,
  };
};

export const prospectReceivedHandler: CaseReducer<
  SignUpStateType,
  PayloadAction<Partial<ProspectType>>
> = (state, { payload }) => {
  return merge({}, state, {
    areaNotCovered: payload.areaNotCovered,
    autopay: payload.autopay,
    billingPaymentMethodId: payload.billingPaymentMethodId,
    campaignSlug: payload.rcid,
    dateOfBirth: payload.dateOfBirth
      ? dayjs(payload.dateOfBirth, ISO8601_DATE_FORMAT).format(DOB_DATE_FORMAT)
      : state.dateOfBirth,
    email: payload.email,
    enrollmentType: payload.enrollmentType,
    featureFlagUserId: payload.featureFlagUserId,
    fingerprintId: payload.fingerprintId,
    firstName: payload.firstName,
    googleClientId: payload.googleClientId,
    invoiceByPrint: !payload.eBillOnly,
    isPaperless: payload.isPaperless,
    lastName: payload.lastName,
    marketingEmailOptIn: payload.marketingEmailOptIn,
    offerSnapshotUuid: payload.offerSnapshotUuid,
    offersnapshotId: payload.offersnapshotId,
    phone: payload.phone,
    productPrices: payload.productPrices,
    prospectId: payload.id,
    prospectUuid: payload.uuid,
    segmentAnonId: payload.segmentAnonId,
    serviceAddress: {
      addressLine1: payload.addressLine1,
      city: payload.city,
      state: payload.state,
      unitNumber: payload.unitNumber,
      zipCode: payload.zipCode,
    } as Partial<AddressFormType>,
    serviceStartDate: payload.serviceStartDate,
  });
};

export const clearSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  PayloadAction
> = () => ({ ...initialSignUpState });

export const completedSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  SetSignUpInfoAction
> = (state, action) => {
  const {
    payload: { activationToken, stateToken },
  } = action;
  const { firstName } = state;

  // clear out everything except what's needed for `CreatePassword`
  // this ensures a customer can't back into the sign up flow after creating an account
  return merge({}, initialSignUpState, {
    activationToken,
    firstName,
    stateToken,
  });
};

// Reducer

export const manuallySetSignUpIdHandler: CaseReducer<
  SignUpStateType,
  ManuallySetSignUpIdAction
> = (state, action) => {
  if (action.payload === state.prospectId) {
    return state;
  }

  return { ...initialSignUpState, prospectId: action.payload };
};

export const addProductPriceHandler = (
  state: SignUpStateType,
  action: PayloadAction<UUIDType>
) => {
  state.productPrices.push(action.payload);

  return state;
};

export const removeProductPriceHandler = (
  state: SignUpStateType,
  action: PayloadAction<UUIDType>
) => {
  const newPrices = state.productPrices.filter(
    (price) => price !== action.payload
  );

  return {
    ...state,
    productPrices: newPrices,
  };
};

export const clearProductPricesHandler = (state: SignUpStateType) => {
  return {
    ...state,
    productPrices: [],
  };
};

export const setSignUpAcquisitionTfn = (
  state: SignUpStateType,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    acquisition: {
      ...state.acquisition,
      acquisitionTfn: action.payload,
    },
  };
};

const reducers = {
  addProductPrice: addProductPriceHandler,
  clearProductPrices: clearProductPricesHandler,
  completed: completedSignUpInfoHandler,
  prospectIdChanged: setSignUpInfoHandler,
  prospectManuallySet: manuallySetSignUpIdHandler,
  prospectReceived: prospectReceivedHandler,
  removeProductPrice: removeProductPriceHandler,
  setAcquisitionTfn: setSignUpAcquisitionTfn,
  updated: setSignUpInfoHandler,
};

export const signUpSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(userLoggedIn, clearSignUpInfoHandler);
    builder.addCase(userLoggedOut, clearSignUpInfoHandler);
    builder.addCase(
      customerPreferencesLanguageUpdated,
      setSignUpInfoLanguagePreferenceHandler
    );
  },

  initialState: initialSignUpState,
  name: "signUp",
  reducers,
});

export const signUpSliceName = signUpSlice.name;
export const signUpReducer = signUpSlice.reducer;
export const setSignUpInfo = signUpSlice.actions.updated;
export const completedSignUp = signUpSlice.actions.completed;
export const prospectFetch = signUpSlice.actions.prospectManuallySet;

export const prospectRefetch = createAction(`${signUpSlice.name}/refetch`);
export const createProspect = createAction<Partial<SignUpStateType>>(
  `${signUpSlice.name}/createProspect`
);

export const { setAcquisitionTfn, prospectReceived } = signUpSlice.actions;
