import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsContractTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsContract/OfferSnapshotFAQsContract.en.i18n";
import { MultiAnswerContainer } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsContract/OfferSnapshotFAQsContract.styled";
import { PortalAccordion } from "@portal/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface OfferSnapshotFAQsContractProps {
  etfAmount: number;
}

export const OfferSnapshotFAQsContract = (
  props: OfferSnapshotFAQsContractProps
) => {
  const { etfAmount } = props;
  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFAQsContractCancelEarlyAnswer,
    tOfferSnapshotFAQsContractCancelEarlyQuestion,
    tOfferSnapshotFAQsContractWhenContractEndsAnswer,
    tOfferSnapshotFAQsContractWhenContractEndsAnswerPart2,
    tOfferSnapshotFAQsContractWhenContractEndsQuestion,
    tOfferSnapshotFAQsContractWhatFeesQuestion,
    tOfferSnapshotFAQsContractWhatFeesAnswer,
  } = translate(offerSnapshotFAQsContractTranslations);
  const { tOfferSnapshotFAQsContractCancelEarlyAnswerPart2 } = translateJsx({
    tOfferSnapshotFAQsContractCancelEarlyAnswerPart2: {
      etfAmount: formatCurrency(etfAmount),
      sixMonthEtfAmount: formatCurrency(etfAmount * 6),
    },
  });

  return (
    <>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsContractWhatFeesQuestion}</summary>
        <RhTypography>{tOfferSnapshotFAQsContractWhatFeesAnswer}</RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsContractCancelEarlyQuestion}</summary>
        <MultiAnswerContainer>
          <RhTypography>
            {tOfferSnapshotFAQsContractCancelEarlyAnswer}
          </RhTypography>
          <RhTypography>
            {tOfferSnapshotFAQsContractCancelEarlyAnswerPart2}
          </RhTypography>
        </MultiAnswerContainer>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsContractWhenContractEndsQuestion}</summary>
        <MultiAnswerContainer>
          <RhTypography>
            {tOfferSnapshotFAQsContractWhenContractEndsAnswer}
          </RhTypography>
          <RhTypography>
            {tOfferSnapshotFAQsContractWhenContractEndsAnswerPart2}
          </RhTypography>
        </MultiAnswerContainer>
      </PortalAccordion>
    </>
  );
};
