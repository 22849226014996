import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { MaterialDesignContent } from "notistack";
import styled from "styled-components";

export const StyledToast = styled(MaterialDesignContent)`
  &.notistack-MuiContent-success {
    background-color: ${muiTheme.palette.success.main};
    font-size: 16px;
    font-weight: ${muiTheme.typography.fontWeightMedium};
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  &.notistack-MuiContent-error {
    background-color: ${muiTheme.palette.error.main};
    font-size: 16px;
    font-weight: ${muiTheme.typography.fontWeightMedium};
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;
