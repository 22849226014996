import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Grid } from "@mui/material";
import { MyAccountLegalDocuments } from "@portal/components/MyAccountYourPlanCard/MyAccountLegalDocuments";
import { MyAccountOfferCard } from "@portal/components/MyAccountYourPlanCard/MyAccountOfferCard";
import { NewContractModalSubSection } from "@portal/components/NewContractModal/NewContractModal.styled";
import { usePremise } from "@portal/hooks/usePremise";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

interface NewContractModalProps {
  onClose(): void;
}

export const NewContractModal = ({ onClose }: NewContractModalProps) => {
  const { t } = useRhIntl();
  const { premise } = usePremise();
  const serviceAddress = t("NewContractModal.serviceAddress");
  const notAvailable = t("NewContractModal.notAvailable");
  const esiId = t("NewContractModal.esiId");

  if (!premise || !premise.futureOrder) {
    return null;
  }

  return (
    <RhModal
      ariaLabel={t("NewContractModal.ariaLabel")}
      ariaCloseLabel={t("NewContractModal.ariaCloseLabel")}
      handleClose={onClose}
      modalId="rhNewContractModal"
      size={ModalWidths.md}
    >
      <RhFlexBox marginBottom={3}>
        <NewContractModalSubSection>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {serviceAddress}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.serviceAddress?.deliveryLine1}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.serviceAddress?.lastLine}
          </RhTypography>
        </NewContractModalSubSection>
        <NewContractModalSubSection>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {esiId}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.meter?.esiId || notAvailable}
          </RhTypography>
        </NewContractModalSubSection>
      </RhFlexBox>
      <RhFlexBox>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Box marginBottom={1}>
              <RhTypography variant="subtitle2" fontWeight="Bold">
                {serviceAddress}
              </RhTypography>
            </Box>
            <MyAccountOfferCard order={premise.futureOrder} />
          </Grid>

          <Grid item sm={12} md={6}>
            <MyAccountLegalDocuments
              premiseId={premise.id}
              orderId={premise.futureOrder.id}
            />
          </Grid>
        </Grid>
      </RhFlexBox>
    </RhModal>
  );
};
