import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { oneClickRenewalSummaryFormTranslations } from "@portal/components/OneClickRenewalSummaryForm/OneClickRenewalSummaryForm.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

export const AutoPayFinePrint = ({
  offerSnapshot,
  selectedAutoPay,
  paymentMethod,
  isPastDue,
}: {
  isPastDue: boolean | undefined;
  offerSnapshot: OfferSnapshot;
  paymentMethod: PaymentMethod | null | undefined;
  selectedAutoPay: boolean;
}) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tOneClickRenewalSummaryFormAutoPayDescription,
    tOneClickRenewalSummaryFormNoPaymentMethod,
    tOneClickRenewalSummaryFormPastDueWarning,
  } = translate(oneClickRenewalSummaryFormTranslations);

  const {
    tOneClickRenewalSummaryFormAutoPayDisabledWarning,
    tOneClickRenewalSummaryFormAutoPayPaymentMethod,
  } = translateJsx({
    tOneClickRenewalSummaryFormAutoPayDisabledWarning: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? "",
        2
      ),
    },
    tOneClickRenewalSummaryFormAutoPayPaymentMethod: {
      maskedNumber: paymentMethod?.trimmedMaskedNumber ?? "",
    },
  });
  const isAutoPayDiscount = offerSnapshot.autopayPaperlessMonthlyDiscount;

  if (isAutoPayDiscount && !selectedAutoPay && paymentMethod) {
    return (
      <>
        <RhTypography color="error" variant="body2">
          {tOneClickRenewalSummaryFormAutoPayDisabledWarning}
        </RhTypography>

        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormAutoPayPaymentMethod}
        </RhTypography>
      </>
    );
  }

  if (isAutoPayDiscount && !selectedAutoPay && !paymentMethod) {
    return (
      <>
        <RhTypography color="error" variant="body2">
          {tOneClickRenewalSummaryFormAutoPayDisabledWarning}
        </RhTypography>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormNoPaymentMethod}
        </RhTypography>
      </>
    );
  }

  if (!selectedAutoPay && paymentMethod) {
    return (
      <>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormAutoPayDescription}
        </RhTypography>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormAutoPayPaymentMethod}
        </RhTypography>
      </>
    );
  }

  if (selectedAutoPay && paymentMethod) {
    return (
      <>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormAutoPayPaymentMethod}
        </RhTypography>
        {isPastDue && (
          <RhTypography variant="body2" color="error">
            {tOneClickRenewalSummaryFormPastDueWarning}
          </RhTypography>
        )}
      </>
    );
  }

  if (!selectedAutoPay && !paymentMethod) {
    return (
      <>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormAutoPayDescription}
        </RhTypography>
        <RhTypography variant="body2" color="textSecondary">
          {tOneClickRenewalSummaryFormNoPaymentMethod}
        </RhTypography>
      </>
    );
  }

  return (
    <RhTypography variant="body2" color="textSecondary">
      {tOneClickRenewalSummaryFormAutoPayDescription}
    </RhTypography>
  );
};
