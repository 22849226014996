import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import {
  ModalWidths,
  RhModalTitle,
} from "@design-system/components/RhModal/RhModal.styled";
import { GroupedCompetitorPlans } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { competitorPlanComparisonPlansModalTranslations } from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.en.i18n";
import {
  EFLCell,
  PlanList,
  PlanListHeader,
  PlanListWrapper,
  PlanNameWrapper,
  PlansComparisonContainer,
  PlansComparisonTitleContainer,
  PriceCell,
  ProviderLogoCell,
} from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.styled";
import { CompetitorPlanRow } from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanRow";
import { SelectedPlan } from "@portal/components/CompetitorPlanComparisonPlansModal/SelectedPlan";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useEffect } from "react";

interface CompetitorPlanComparisonPlansModalProps {
  competitorPlans: GroupedCompetitorPlans[];
  handleClose: () => void;
  offerSnapshot: OfferSnapshot;
  onSelectPlanClick: (OfferSnapshot: OfferSnapshot) => void;
}

export const CompetitorPlanComparisonPlansModal = (
  props: CompetitorPlanComparisonPlansModalProps
) => {
  const { offerSnapshot, handleClose, competitorPlans, onSelectPlanClick } =
    props;

  const { translate } = useTranslations();

  useEffect(() => {
    document.body.classList.add(CSSClasses.OverflowHidden);

    return () => {
      document.body.classList.remove(CSSClasses.OverflowHidden);
    };
  }, []);

  const {
    tCompetitorPlanComparisonPlansModalTitle,
    tCompetitorPlanComparisonPlansModalProvider,
    tCompetitorPlanComparisonPlansModalPrice,
    tCompetitorPlanComparisonPlansModalPlanName,
    tCompetitorPlanComparisonPlansModalEFL,
  } = translate(competitorPlanComparisonPlansModalTranslations);

  const plans = competitorPlans
    .flatMap((_plans) => _plans.plans)
    .sort((a, b) => Number(a.price2000Kwh) - Number(b.price2000Kwh));

  return (
    <RhModal
      ariaCloseLabel="Close Modal"
      ariaLabel="Competitor Plans Modal"
      handleClose={handleClose}
      withGradientBorder
      size={ModalWidths.md}
      showPortalHeader
    >
      <PlansComparisonContainer>
        <PlansComparisonTitleContainer>
          <RhModalTitle>
            {tCompetitorPlanComparisonPlansModalTitle}
          </RhModalTitle>
        </PlansComparisonTitleContainer>

        <SelectedPlan
          offerSnapshot={offerSnapshot}
          onSelectPlanClick={onSelectPlanClick}
        />

        <PlanListWrapper>
          <PlanListHeader>
            <ProviderLogoCell>
              {tCompetitorPlanComparisonPlansModalProvider}
            </ProviderLogoCell>
            <PlanNameWrapper>
              {tCompetitorPlanComparisonPlansModalPlanName}
            </PlanNameWrapper>
            <PriceCell>{tCompetitorPlanComparisonPlansModalPrice}</PriceCell>
            <EFLCell>{tCompetitorPlanComparisonPlansModalEFL}</EFLCell>
          </PlanListHeader>
          <PlanList className={plans ? "plansLoaded" : "plansLoading"}>
            {plans.map((plan) => (
              <CompetitorPlanRow key={plan.id} plan={plan} />
            ))}
          </PlanList>
        </PlanListWrapper>
      </PlansComparisonContainer>
    </RhModal>
  );
};
