import { deviceSelectorTranslations } from "@portal/components/DeviceSelector/DeviceSelector.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import { DeviceStatus } from "@portal/types/devicesTypes";

export const useDeviceStatus = () => {
  const { translate } = useTranslations();

  const {
    tDeviceSelectorOffline,
    tDeviceSelectorOnline,
    tDeviceSelectorUnavailable,
  } = translate(deviceSelectorTranslations);

  return {
    [DeviceStatus.Offline]: tDeviceSelectorOffline,
    [DeviceStatus.Online]: tDeviceSelectorOnline,
    [DeviceStatus.Unavailable]: tDeviceSelectorUnavailable,
  };
};
