import { api } from "@common/api/api";
import { prospectApi } from "@common/api/prospectApi";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isRequired,
  isValidConfirmationEmail,
  isValidEmail,
  isValidPhoneNumber,
} from "@common/forms/validators";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhApiError } from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  LOGGED_OUT_FORM_WIDTH,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpContactFields } from "@portal/components/SignUpContactFields/SignUpContactFields";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpContactPageTranslations } from "@portal/pages/SignUpContactPage/SignUpContactPage.en.i18n";
import { selectSignUpContactFormValues } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  track,
} from "@portal/services/segment.service";
import { merge } from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export interface SignUpContactFormValues {
  confirmEmail: string;
  email: string;
  emailConflictsWithExistingAccount?: boolean;
  phone: string;
  sendMarketingPromos: boolean;
}
const initialValues = Object.freeze<SignUpContactFormValues>({
  confirmEmail: "",
  email: "",
  emailConflictsWithExistingAccount: false,
  phone: "",
  sendMarketingPromos: true,
});

const signUpContactFormValidator =
  generateValidationErrorCollector<SignUpContactFormValues>({
    confirmEmail: [
      isRequired,
      isValidEmail,
      (value, { email }) => isValidConfirmationEmail(value, email),
    ],
    email: [isRequired, isValidEmail],
    phone: [
      isRequired,
      isValidPhoneNumber,
      ({ phone }) => isValidPhoneNumber(phone),
    ],
  });

export const SignUpContactPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  const { firstName, ...currentValues } = useSelector(
    selectSignUpContactFormValues
  );
  const { translate, translateJsx } = useTranslations();

  const {
    tSignUpContactPageInvalidPhoneNumber,
    tSignUpContactPageCustomerExists,
    tSignUpContactPageErrorSendingContact,
    tSignUpContactPageCreditCheckExplainer,
    tSignUpContactPageSubmitCta,
  } = translate(signUpContactPageTranslations);

  const { tSignUpContactPageLetsKeepInTouch } = translateJsx({
    tSignUpContactPageLetsKeepInTouch: { firstName },
  });

  const flash = useRhFlash();
  const { featureFlagClient } = useFeatureFlagClient();
  const {
    opsAlloyValidationReport: { value: validationReportFlag },
  } = featureFlagClient.getFlags([["opsAlloyValidationReport", false]]);

  const onSubmit = async (values: SignUpContactFormValues) => {
    const validatePhoneNumber = prospectApi
      .validatePhoneNumber(values.phone)
      .then((_apiSuccessResponse) => {
        return false;
      })
      .catch((error: RhApiError) => {
        if (error.data.errorCode === "rhythm.prospect.phone_number.invalid") {
          return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error;
      })
      .then((invalidPhoneNumber) => {
        if (invalidPhoneNumber) {
          return true;
        }

        return false;
      });

    const validateEmail = api.customers
      .available(values.email)
      .then((_apiSuccessResponse) => {
        // This means the email didn't conflict with an existing account
        return false;
      })
      .catch((error: RhApiError) => {
        if (error.data.errorCode === "rhythm.customer.email.conflict") {
          return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error;
      })
      .then((didEmailConflict) => {
        if (didEmailConflict) {
          return true;
        }

        return false;
      });

    try {
      const isInvalidPhoneNumber = await validatePhoneNumber;
      const emailAlreadyExists = await validateEmail;

      // Check if opsAlloyValidationReport is on, if so precede to next step even if email exists
      if (!isInvalidPhoneNumber && emailAlreadyExists && validationReportFlag) {
        return signUpClickNextStepHandler({
          nextStep: "address",
          signUpData: {
            ...values,
            emailConflictsWithExistingAccount: true,
          },
          track: true,
        });
      }

      // Handle any errors our validation functions returns and merge them for RFF
      if (isInvalidPhoneNumber || emailAlreadyExists) {
        const phoneNumberError = isInvalidPhoneNumber
          ? { phone: tSignUpContactPageInvalidPhoneNumber }
          : {};

        const emailConflictError = emailAlreadyExists
          ? { email: tSignUpContactPageCustomerExists }
          : {};

        return merge({}, phoneNumberError, emailConflictError);
      }

      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalProspectEmailSubmitted
      );

      if (values.sendMarketingPromos) {
        track({
          action: ActionType.emailSubmitted,
          category: "contact.marketingPromoOptIn",
          event: EventType.enrollmentClick,
          label: values.email,
          value: true,
        });
      }

      track({
        action: ActionType.contactSubmitted,
        category: CategoryType.Contact,
        email: values.email,
        event: EventType.enrollmentClick,
        label: "Contact Information",
        phone: values.phone,
      });

      signUpClickNextStepHandler({
        nextStep: "address",
        signUpData: {
          ...values,
          emailConflictsWithExistingAccount: false,
        },
        track: true,
      });
    } catch (_e) {
      flash.error(tSignUpContactPageErrorSendingContact);
    }
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerText={tSignUpContactPageLetsKeepInTouch} />
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING}>
        <Form<SignUpContactFormValues>
          initialValues={{ ...initialValues, ...currentValues }}
          onSubmit={onSubmit}
          validate={signUpContactFormValidator}
          render={({ handleSubmit }) => (
            <LoggedOutForm
              onSubmit={handleSubmit}
              submitButtonText={tSignUpContactPageSubmitCta}
            >
              <SignUpContactFields trackEvent={trackEvent} />
            </LoggedOutForm>
          )}
        />
      </Box>
      <Box width={isMobile ? "100%" : LOGGED_OUT_FORM_WIDTH}>
        <RhTypography variant="body2" color="textSecondary">
          {tSignUpContactPageCreditCheckExplainer}
        </RhTypography>
      </Box>
    </SignUpPageLayout>
  );
};
