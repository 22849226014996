import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OfferSnapshotFAQsContract } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsContract/OfferSnapshotFAQsContract";
import { offerSnapshotFAQsGeneralTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsGeneral/OfferSnapshotFAQsGeneral.en.i18n";
import { OfferSnapshotFAQsMonthToMonth } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsMonthToMonth/OfferSnapshotFAQsMonthToMonth";
import { OfferSnapshotFAQsTitleWrapper } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface OfferSnapshotFAQsGeneralProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotFAQsGeneral = (
  props: OfferSnapshotFAQsGeneralProps
) => {
  const { offerSnapshot } = props;

  const { translate } = useTranslations();
  const {
    tOfferSnapshotFAQsGeneralAccountProblemAnswer,
    tOfferSnapshotFAQsGeneralAccountProblemQuestion,
    tOfferSnapshotFAQsGeneralTitle,
  } = translate(offerSnapshotFAQsGeneralTranslations);

  const { etfAmount, isMonthToMonth } = offerSnapshot;

  return (
    <OfferSnapshotFAQsTitleWrapper title={tOfferSnapshotFAQsGeneralTitle}>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsGeneralAccountProblemQuestion}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsGeneralAccountProblemAnswer}
        </RhTypography>
      </PortalAccordion>

      {isMonthToMonth ? (
        <OfferSnapshotFAQsMonthToMonth />
      ) : (
        <OfferSnapshotFAQsContract etfAmount={Number(etfAmount)} />
      )}
    </OfferSnapshotFAQsTitleWrapper>
  );
};
