import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { additionalServicesDevicesTranslations } from "@portal/components/AdditionalServicesDevices/AdditionalServicesDevices.en.i18n";
import {
  StyledContainer,
  StyledLinkButton,
  StyledSVGWrapper,
  StyledTitle,
} from "@portal/components/AdditionalServicesDevices/AdditionalServicesDevices.styled";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as Thermostat } from "@portal/images/Thermostat.svg";
import { devicesEnrollStepPath } from "@portal/routes/routePaths";
import {
  ActionType,
  DeviceEnrollmentEvents,
} from "@portal/services/segment.service";
import React from "react";

interface AdditionalServicesDevicesProps {
  credit: string | undefined;
  thermostatRequired: boolean;
}

export const AdditionalServicesDevices = (
  props: AdditionalServicesDevicesProps
) => {
  const { credit, thermostatRequired } = props;
  const track = useTrackMyAccountEvents();
  const { translate, translateJsx } = useTranslations();
  const {
    tAdditionalServicesDevicesButtonText,
    tAdditionalServicesDevicesTitle,
    tAdditionalServicesDevicesTitleRequired,
  } = translate(additionalServicesDevicesTranslations);

  const { tAdditionalServicesDevicesSubtitle } = translateJsx({
    tAdditionalServicesDevicesSubtitle: {
      credit,
    },
  });

  const handleTrack = () => {
    track({
      action: ActionType.click,
      category: "home.carousel.devices",
      event: DeviceEnrollmentEvents.DevicesClicked,
    });
  };

  return (
    <StyledContainer>
      <StyledSVGWrapper>
        <Thermostat aria-hidden />
      </StyledSVGWrapper>
      <StyledTitle $thermostatRequired={thermostatRequired}>
        {thermostatRequired
          ? tAdditionalServicesDevicesTitleRequired
          : tAdditionalServicesDevicesTitle}
      </StyledTitle>
      <RhTypography variant="body1" color="textSecondary">
        {tAdditionalServicesDevicesSubtitle}
      </RhTypography>
      <StyledLinkButton
        data-tracking-click={{
          event: "Customer going to choose your thermostat device brand page",
        }}
        href={devicesEnrollStepPath("choose-your-device-brand")}
        color="primary"
        onClick={handleTrack}
      >
        {tAdditionalServicesDevicesButtonText}
      </StyledLinkButton>
    </StyledContainer>
  );
};
