import { useOktaAuth } from "@okta/okta-react";
import { useCustomerQuery } from "@portal/hooks/queries/useCustomer.query";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremise } from "@portal/hooks/usePremise";
import {
  customerHomePath,
  devicesBasePath,
  devicesEnrollPath,
  devicesEnrollStepPath,
  myAccountPath,
  notificationsPath,
  payBillPath,
  renewalsPath,
  rewardsPath,
} from "@portal/routes/routePaths";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const pagesWithLoader = [
  customerHomePath(),
  payBillPath(),
  rewardsPath(),
  myAccountPath(),
  notificationsPath(),
  renewalsPath(),
  devicesBasePath(),
  devicesEnrollPath(),
  devicesEnrollStepPath("choose-your-device-brand"),
  devicesEnrollStepPath("terms-and-conditions"),
  devicesEnrollStepPath("select-devices"),
  devicesEnrollStepPath("summary"),
];

export const useLoader = () => {
  const { pathname } = useLocation();
  const [isPageWithLoader, setIsPageWithLoader] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const { requestMonitor: premiseRequestMonitor, premise } = usePremise();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    enabled: premise !== null,
    premiseId: premise?.id || "",
  });
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;

  const customerQuery = useCustomerQuery({
    queryOptions: { enabled: isAuthenticated },
  });

  useEffect(() => {
    setIsPageWithLoader(pagesWithLoader.includes(pathname));
  }, [pathname]);

  if (!isPageWithLoader || !isEnabled) {
    return { isLoading: false };
  }

  if (
    customerQuery.isError ||
    accountSummaryQuery.isError ||
    premiseRequestMonitor.didFail
  ) {
    setIsEnabled(false);
  }

  if (
    customerQuery.isSuccess &&
    accountSummaryQuery.isSuccess &&
    premiseRequestMonitor.didSucceed
  ) {
    setIsEnabled(false);
  }

  return { isLoading: isEnabled };
};
