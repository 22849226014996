import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { OfferCard } from "@portal/components/OfferCard/OfferCard";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { useCompetitorPlans } from "@portal/hooks/queries/useCompetitorPlans";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { OfferPageScrollToSections } from "@portal/pages/SignUpOfferPage/useOfferPageScrollTo";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType, AnalyticsEvent } from "@portal/services/segment.service";
import React from "react";
import { useSelector } from "react-redux";

interface SignUpOfferCoordinatorProps {
  allOfferSnapshots: OfferSnapshotType[];
  hideCostEstimatorButton: boolean;
  index: number;
  offerSnapshot: OfferSnapshotType;
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshotType) => void;
}

export const SignUpOfferCoordinator = (props: SignUpOfferCoordinatorProps) => {
  const {
    index,
    allOfferSnapshots,
    offerSnapshot: offerData,
    onSelectOfferSnapshot,
    hideCostEstimatorButton,
  } = props;

  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const { t } = useRhIntl();
  const selectPlan = t("SignUpOfferCoordinator.selectCTA");
  const { trackEvent } = useSignUpFlow();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const { featureFlagClient } = useFeatureFlagClient();
  const competitorPlansQuery = useCompetitorPlans();
  const { setComparisonChartOfferSnapshot, setComparisonChartTermMonths } =
    useRepPriceComparisonChart();

  const { addModal } = useModals();

  const offerSnapshot = new OfferSnapshot(offerData);

  const { portalRepPriceComparison } = featureFlagClient.getFlags([
    ["portalRepPriceComparison", false],
  ]);

  const trackDetailsEvent = (event: Partial<AnalyticsEvent>) => {
    trackEvent({ ...event, category: "onboarding.planDetails" });
  };

  const handleSelect = () => {
    if (offerSnapshot.isThermostatOffer) {
      return addModal({
        offerSnapshotThermostatRequired: {
          offerSnapshot,
          onEnrollClick: () => onSelectOfferSnapshot(offerData),
        },
      });
    }

    return onSelectOfferSnapshot(offerData);
  };

  const averageOfferSnapshotPrice = (): number | undefined => {
    if (!allOfferSnapshots) {
      return undefined;
    }

    const mappedOfferSnapshots = allOfferSnapshots.map(
      (offer) => new OfferSnapshot(offer)
    );

    const totalPrice = mappedOfferSnapshots
      .map((offer) => offer.price)
      .reduce((previousPrice, currentPrice) => {
        return Number(previousPrice) + Number(currentPrice);
      }, 0);

    return totalPrice / mappedOfferSnapshots.length;
  };

  const selectButtonProps = ({ label }: { label: string }) => ({
    onClick: () => {
      trackEvent({
        action: ActionType.click,
        averagePriceOfAllOffersShown: averageOfferSnapshotPrice(),
        baseCharge: Number(offerSnapshot.baseChargeAmount),
        energyRate: Number(offerSnapshot.rhythmKwhRate),
        label,
        planDate: offerSnapshot.startDate,
        planLength: offerSnapshot.termMonths,
        price: Number(offerSnapshot.price),
        tdspRate: Number(offerSnapshot.tdspKwhRate),
        title: offerSnapshot.title,
        value: `offerId${offerSnapshot.id}`,
      });

      return handleSelect();
    },
    text: selectPlan,
  });

  const moreButtonProps = {
    onClick: () => {
      trackEvent({
        action: ActionType.click,
        label: `card${index + 1}LearnMore`,
        value: `offerId${offerSnapshot.id}`,
      });

      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalProspectOfferCardLearnMoreClicked
      );

      trackDetailsEvent({
        action: ActionType.click,
        label: `card${index + 1}Close`,
        value: `offerId${offerSnapshot.id}`,
      });

      addModal({
        offerSnapshotLearnMore: {
          offerSnapshotUUID: offerSnapshot.uuid,
          onEnrollClick: () => {
            handleEnrollNowClick({
              estimatedMonthlyUsage,
              offerSnapshot,
            });
          },
          selectPlanCtaText: selectPlan,
        },
      });
    },
    text: t("SignUpOfferCoordinator.moreCTA"),
  };

  const selectPlanCardProps = selectButtonProps({
    label: `card${index + 1}SelectPlan`,
  });

  const compareButtonProps = {
    onClick: () => {
      trackEvent({
        action: ActionType.click,
        label: `card${index + 1}Compare`,
        value: `offerId${offerSnapshot.id}`,
      });

      setComparisonChartOfferSnapshot(offerSnapshot);
      setComparisonChartTermMonths(offerSnapshot.termMonths);

      document
        ?.querySelector(`#${OfferPageScrollToSections.RepPriceComparisonChart}`)
        ?.scrollIntoView({
          behavior: "smooth",
        });
    },
    showCompareButton: Boolean(
      portalRepPriceComparison.value &&
        !offerData.solarEligible &&
        competitorPlansQuery?.data?.results?.length
    ),
    text: t("SignUpOfferCoordinator.compareCTA"),
  };

  return (
    <OfferCard
      offerSnapshot={offerSnapshot}
      moreButtonProps={moreButtonProps}
      selectButtonProps={selectPlanCardProps}
      compareButtonProps={compareButtonProps}
      hideCostEstimatorButton={hideCostEstimatorButton}
    />
  );
};
