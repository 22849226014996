import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { grey } from "@design-system/theme/palette.colors";
import { priceTierSnapshotsSummaryTranslations } from "@portal/components/PriceTierSnapshotsSummary/PriceTierSnapshotsSummary.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";
import styled from "styled-components";

interface PriceTierSnapshotsSummaryProps {
  offerSnapshot: OfferSnapshot;
}

const StyledPriceTierSnapshotsSummary = styled.details`
  font-size: 14px;
  line-height: 1.5;
  width: 100%;

  summary:hover {
    cursor: pointer;
  }
`;

const StyledPriceTierSnapshotList = styled.ul`
  color: ${grey[800]};
  list-style: none;
  padding: 0 0 0 1.5rem;
`;

export const PriceTierSnapshotsSummary = ({
  offerSnapshot,
}: PriceTierSnapshotsSummaryProps) => {
  const { translate } = useTranslations();

  const {
    tPriceTierSnapshotsSummaryEnergyChargeTitle,
    tPriceTierSnapshotsSummaryGenerationTitle,
    tPriceTierSnapshotsSummaryPerKwh,
  } = translate(priceTierSnapshotsSummaryTranslations);

  const {
    priceTierSnapshots,
    generationPriceTierSnapshots,
    isTimeOfUseGeneration,
  } = offerSnapshot;

  if (!priceTierSnapshots) {
    return null;
  }

  return (
    <>
      <StyledPriceTierSnapshotsSummary>
        <summary>{tPriceTierSnapshotsSummaryEnergyChargeTitle}</summary>
        <StyledPriceTierSnapshotList>
          {[...priceTierSnapshots]
            ?.sort(
              (a, b) =>
                Number(a.consumptionPricePerKwh) -
                Number(b.consumptionPricePerKwh)
            )
            ?.map((snapshot) => (
              <li key={snapshot.name}>
                {`${snapshot.name}: ${formatDollarsToCents(
                  snapshot.consumptionPricePerKwh
                )} ${tPriceTierSnapshotsSummaryPerKwh}`}
              </li>
            ))}
        </StyledPriceTierSnapshotList>
      </StyledPriceTierSnapshotsSummary>
      {isTimeOfUseGeneration ? (
        <StyledPriceTierSnapshotsSummary>
          <summary>{tPriceTierSnapshotsSummaryGenerationTitle}</summary>
          <StyledPriceTierSnapshotList>
            {[...(generationPriceTierSnapshots ?? [])]
              ?.sort(
                (a, b) =>
                  Number(a.generationPricePerKwh) -
                  Number(b.generationPricePerKwh)
              )
              ?.map((snapshot) => (
                <li key={snapshot.name}>
                  {`${snapshot.name}: ${formatDollarsToCents(
                    snapshot.generationPricePerKwh
                  )} ${tPriceTierSnapshotsSummaryPerKwh}`}
                </li>
              ))}
          </StyledPriceTierSnapshotList>
        </StyledPriceTierSnapshotsSummary>
      ) : null}
    </>
  );
};
