import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const ReasonsToBelieveTable = styled.table`
  border: 1px solid ${grey["200"]};
  border-collapse: collapse;
  height: min-content;
`;

export const ReasonsToBelieveRow = styled.tr`
  display: grid;
  grid-template-columns: minmax(120px, 1fr) 3fr;
  min-height: 64px;

  &:not(:last-child) {
    border-bottom: 1px solid ${grey["200"]};
  }
`;

export const ReasonToBelieveRowTitle = styled.td`
  align-items: center;
  background-color: ${grey["100"]};
  border-right: 1px solid ${grey["200"]};
  display: inline-flex;
  padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};
`;

export const ReasonToBelieveDescription = styled.td`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(2)};
    text-align: left;
  }
`;

export const OfferSnapshotReasonsToBelieveTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(1)};
    margin-top: ${rhSpacingPx(2)};
    text-align: left;
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      display: none;
      margin-top: 0;
    }
  }
`;

export const ReasonsToBelieveLinkButton = styled.button`
  background: transparent;
  border: 0;
  color: ${darkPurple.main};
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;
