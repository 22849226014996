import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsDevicesTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDevices/OfferSnapshotFAQsDevices.en.i18n";
import { useOfferSnapshotDevicesFaqs } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDevices/useOfferSnapshotDevicesFAQs";
import { OfferSnapshotFAQsTitleWrapper } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface OfferSnapshotFAQsDevicesProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotFAQsDevices = (
  props: OfferSnapshotFAQsDevicesProps
) => {
  const { translate } = useTranslations();
  const { offerSnapshot } = props;
  const deviceFaqs = useOfferSnapshotDevicesFaqs({ offerSnapshot });

  const { tOfferSnapshotFAQsDevicesDemandResponseTitle } = translate(
    offerSnapshotFAQsDevicesTranslations
  );

  return (
    <OfferSnapshotFAQsTitleWrapper
      title={tOfferSnapshotFAQsDevicesDemandResponseTitle}
    >
      {deviceFaqs.map(({ faqTitle, faqBody, jsxElementKey }) => (
        <PortalAccordion key={jsxElementKey}>
          <summary>
            <RhTypography variant="body1" align="left">
              {faqTitle}
            </RhTypography>
          </summary>
          {faqBody.map(({ text, jsxElementKey: bodyKey }) => (
            <RhTypography
              key={`${jsxElementKey}-${bodyKey}`}
              component="p"
              variant="body1"
              align="left"
            >
              {text}
            </RhTypography>
          ))}
        </PortalAccordion>
      ))}
    </OfferSnapshotFAQsTitleWrapper>
  );
};
