import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const COMPARISON_TABLE_HEADER_WIDTH_DESKTOP = 170;

export const StyledBillComparisonTable = styled.table<{
  $numberOfColumns: number;
}>`
  border-collapse: collapse;
  font-size: 9px;
  table-layout: fixed;
  text-align: center;
  width: 100%;

  th,
  td {
    border: 1px solid ${grey[100]};
    padding: ${rhSpacingPx(1)};
    width: ${({ $numberOfColumns }) => `calc(100% / ${$numberOfColumns})`};
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    font-size: 14px;

    th {
      width: ${COMPARISON_TABLE_HEADER_WIDTH_DESKTOP}px;
    }

    td {
      width: ${({ $numberOfColumns }) =>
        `calc((100% - ${COMPARISON_TABLE_HEADER_WIDTH_DESKTOP}px) / ${$numberOfColumns - 1})`};
    }
  }
`;
