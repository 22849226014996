import { COOKIE_DOMAIN } from "@portal/settings/config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

export interface PortalCookies {
  rhRenewalReminderModal?: "show" | "hide";
  rhSwitchPrintToPaperlessModal?: "show" | "hide";
}

const defaultPortalCookiesValues: Required<PortalCookies> = {
  rhRenewalReminderModal: "show",
  rhSwitchPrintToPaperlessModal: "show",
};

// Only used for any cookie use in th logged in experience
export const usePortalCookies = () => {
  const [documentCookies, documentSetCookie, documentRemoveCookie] =
    useCookies();

  const [portalCookies, setPortalCookies] = useState<PortalCookies>({});

  useEffect(() => {
    // Get only the cookies from PortalCookies, ignore any others that happen to be in document.cookie
    const newPortalCookies = Object.entries(
      defaultPortalCookiesValues
    ).reduce<PortalCookies>((acc, current) => {
      const [cookieName, defaultPortalCookieValue] = current;

      const cookieValue =
        documentCookies[cookieName as keyof typeof documentCookies];

      return {
        ...acc,
        [cookieName]: cookieValue ?? defaultPortalCookieValue,
      };
    }, {});

    setPortalCookies(newPortalCookies);
  }, [documentCookies]);

  type SetCookieType = typeof documentSetCookie;

  const setPortalCookie = useCallback(
    <K extends keyof PortalCookies>(
      cookieName: K,
      cookieValue: PortalCookies[K],
      options?: Parameters<SetCookieType>[2]
    ) => {
      documentSetCookie(cookieName, cookieValue, {
        ...options,
        domain: COOKIE_DOMAIN,
        path: "/",
        sameSite: "strict", // https://web.dev/articles/samesite-cookies-explained
        secure: true,
      });
    },
    [documentSetCookie]
  );

  return useMemo(
    () => ({
      cookies: portalCookies,
      removeCookie: documentRemoveCookie,
      setCookie: setPortalCookie,
    }),
    [documentRemoveCookie, portalCookies, setPortalCookie]
  );
};
