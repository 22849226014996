import { orange, purple, trueWhite } from "@design-system/theme/palette.colors";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, Dialog } from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiDialog-paperFullScreen {
    background: linear-gradient(90deg, ${purple[500]}, ${orange[400]});
    border-radius: 10px 10px 0 0;
    margin-top: ${muiTheme.spacing(10)};
    max-width: 70rem;
    overflow: visible;
    padding: 3px;
  }
`;

export const StyledContent = styled(Box)`
  background: ${trueWhite};
  border-radius: 7px 7px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding: ${muiTheme.spacing(8)};
  scroll-behavior: smooth;

  @media (max-width: ${RhythmBreakpoints.SM}px) {
    padding: ${muiTheme.spacing(4)};
  }
`;
