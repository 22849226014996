import { RhCard } from "@design-system/components/RhCard/RhCard";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const PlansPageEmptyStateIconContainer = styled(RhCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &.MuiCard-root {
    margin: 2rem auto 0;
    padding: 3rem 1rem 2rem;
  }

  & svg {
    max-height: 50px;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    & svg {
      max-height: 90px;
    }
  }
`;

export const PlansPageEmptyStateText = styled.h2`
  color: ${grey[800]};
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: ${FontWeight.Light};
  line-height: 150%;
  max-width: 400px;
  padding: 0 ${rhSpacingPx(2)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    font-size: 20px;
    font-weight: ${FontWeight.Regular};
  }
`;
