import { rhSpacingPx } from "@design-system/theme/spacing";
import { PAGE_GUTTER } from "@design-system/theme/style.constant";
import styled from "styled-components";

interface Props {
  $boxMaxWidth: number;
  $isMobile: boolean;
}

export const StyledBox = styled.div<Props>`
  margin-left: -${PAGE_GUTTER}px;
  margin-right: -${PAGE_GUTTER}px;
  width: ${({ $isMobile }) => ($isMobile ? "100vw" : "100%")};
`;

export const StyledInnerBox = styled.div<Props>`
  margin: 0 auto;
  max-width: ${({ $boxMaxWidth }) => $boxMaxWidth}px;
  width: 100%;
`;

export const StyledTandemBike = styled.div`
  position: absolute;
  top: 451px;
  width: 195px;
  z-index: 0;
`;

export const StyledTandemBikeLeft = styled(StyledTandemBike)`
  left: -${rhSpacingPx(2)};
`;

export const StyledTandemBikeRight = styled(StyledTandemBike)`
  right: -${rhSpacingPx(2)};
`;
