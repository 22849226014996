import { ReactComponent as RhythmLogo } from "@common/images/RhythmLogoDarkPurple.svg";
import { portalHeaderLogoTranslations } from "@portal/components/PortalHeaderLogo/PortalHeaderLogo.en.i18n";
import { StyledLogoLink } from "@portal/components/PortalHeaderLogo/PortalHeaderLogo.styled";
import { MARKETING_RHYTHM_URL } from "@portal/constants/urls.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import { customerHomePath } from "@portal/routes/routePaths";
import React from "react";

interface PortalHeaderLogoProps {
  isAuthenticated?: boolean;
}

export const PortalHeaderLogo = (props: PortalHeaderLogoProps) => {
  const { isAuthenticated } = props;
  const { translate } = useTranslations();
  const { tPortalHeaderHome } = translate(portalHeaderLogoTranslations);

  const marketingUrl = MARKETING_RHYTHM_URL;

  return (
    <StyledLogoLink
      to={isAuthenticated ? customerHomePath() : marketingUrl}
      external={!isAuthenticated}
      target="_self"
      aria-label={tPortalHeaderHome}
    >
      <RhythmLogo />
    </StyledLogoLink>
  );
};
