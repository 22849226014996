import { SignUpStepType } from "@portal/routes/routePaths";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const hideCartSignUpSteps: SignUpStepType[] = [
  "availability",
  "choose-utility",
  "plans",
  "summary",
];

const hideCartEnrollSteps = ["offers"];

export const useIsCartSignUpStep = (): boolean => {
  const { pathname } = useLocation();
  const [isCartStep, setIsCartStep] = useState<boolean>(true);

  useEffect(() => {
    if (
      hideCartSignUpSteps.some((path) => pathname.includes(path)) ||
      hideCartEnrollSteps.some((path) => pathname.includes(path))
    ) {
      setIsCartStep(false);
    } else {
      setIsCartStep(true);
    }
  }, [pathname]);

  return isCartStep;
};
