export const signUpOfferPageTranslations = {
  tSignUpOfferPageBillingTransparency: "Bill Transparency",
  tSignUpOfferPageChooseThePlan: "Choose the plan that's right for you.",
  tSignUpOfferPageChooseYourPlan: "Choose your plan",
  tSignUpOfferPageEnergyTexasDisclaimer:
    "Since Energy Texas is no longer serving customers, check out the plans below from Rhythm.",
  tSignUpOfferPageEvGoCampaignDisclaimer:
    "*Your $100 credit will be delivered via email with instructions for claiming your EV Charging Credits. You must claim your credits within 12 months from the date these instructions are sent via email. Your right to the EV Charging Credits expire after that time. Use your EV Charging Credits at any EVgo or ChargePoint station. Charges may vary based on location. Offer eligible on Simply Drive 12 Plan. You'll get your $100 EV Charging Credit after 30 days of your Rhythm electricity service starting.",
  tSignUpOfferPageEvGoCampaignHeader:
    "Our EV plans put you on the road to savings.",
  tSignUpOfferPageFixedRate: "Fixed-Rate Plans",
  tSignUpOfferPageNoHiddenFees: "No Hidden Fees",
  tSignUpOfferPageRadioCampaignDisclaimer:
    "* $50 bill credit offerSnapshot for new customers only, excluding Solar Buyback and Month-to-Month Plans. One per customer. OfferSnapshot valid March 14 - May 15, 2022.",
  tSignUpOfferPageUpdate: "Update",
  tSignUpOfferPageWhyRhythmIsBetter:
    "Why is Rhythm better? <Linkable>See for yourself.</Linkable>",
  tSignUpOfferPageYourZipcode: "Your zipcode:",
};
