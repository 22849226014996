import { ReactComponent as ReasonToBelieveCheck } from "@design-system/icons/ReasonToBelieveCheck.svg";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { MARKETING_RHYTHM_URL } from "@portal/constants/urls.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpOfferPageTranslations } from "@portal/pages/SignUpOfferPage/SignUpOfferPage.en.i18n";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";

interface SignUpOfferPageHeaderProps {
  isMobile: boolean;
}

export const SignUpOfferPageHeader = ({
  isMobile,
}: SignUpOfferPageHeaderProps) => {
  const { campaignSlug } = useSelector(selectSignUpState);
  const { translate, translateJsx } = useTranslations();
  const {
    tSignUpOfferPageChooseYourPlan,
    tSignUpOfferPageChooseThePlan,
    tSignUpOfferPageEvGoCampaignHeader,
  } = translate(signUpOfferPageTranslations);

  const { tSignUpOfferPageWhyRhythmIsBetter } = translateJsx({
    tSignUpOfferPageWhyRhythmIsBetter: {
      Linkable: (linkText: string) => (
        <a
          href={`${MARKETING_RHYTHM_URL}/about`}
          target="_blank"
          rel="noreferrer"
        >
          {linkText}
        </a>
      ),
    },
  });

  let headerText = isMobile
    ? tSignUpOfferPageChooseYourPlan
    : tSignUpOfferPageChooseThePlan;

  const evGoCampaign = campaignSlug && campaignSlug === "electric-vehicle";

  if (evGoCampaign) {
    headerText = tSignUpOfferPageEvGoCampaignHeader;
  }

  return (
    <LoggedOutPageHeader
      headerText={headerText}
      subHeaderText={tSignUpOfferPageWhyRhythmIsBetter}
      subheader={{
        href: `${MARKETING_RHYTHM_URL}/about`,
        icon: <ReasonToBelieveCheck />,
        target: "_blank",
      }}
    />
  );
};
