import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as ChangingLightBulb } from "@portal/images/ChangingLightBulb.svg";
import { BaseErrorPage } from "@portal/pages/BaseErrorPage/BaseErrorPage";
import { error500PageTranslations } from "@portal/pages/Error500Page/Error500Page.en.i18n";
import React from "react";

export const Error500Page = () => {
  const { translate } = useTranslations();

  const { tError500PageOops, tError500PageTechnicalDifficulties } = translate(
    error500PageTranslations
  );

  return (
    <BaseErrorPage
      header={tError500PageOops}
      message={tError500PageTechnicalDifficulties}
      illustration={<ChangingLightBulb />}
      showLinkToHome={false}
    />
  );
};
