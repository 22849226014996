import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

export const ErrorLoading = () => {
  const { t } = useRhIntl();

  return (
    <div>
      <RhTypography variant="body2">
        {t("ProductAddOnsCard.errorLoading")}
      </RhTypography>
    </div>
  );
};
