import { portalApi } from "@portal/api/api";
import { useMutation } from "@tanstack/react-query";

export const useGuestPayCreatePayment = () => {
  const mutation = useMutation({
    mutationFn: portalApi.guestPay.createPayment,
  });

  return mutation;
};
