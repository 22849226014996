import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 1000px;
    font-family: ${fontFamily};
    font-size: 16px;
    font-weight: ${FontWeight.Semibold};
    line-height: 14px;
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
    text-transform: none;
    white-space: nowrap;

    &.Mui-disabled {
      border-color: ${grey[50]};

      &.MuiButton-contained {
        background-color: ${grey[50]};
        color: ${grey[500]};
        cursor: not-allowed;
      }

      &.MuiButton-text {
        color: ${grey[500]};
      }
    }

    &.MuiButton-colorInherit {
      &.MuiButton-contained {
        background-color: ${grey[50]};
        color: ${grey[500]};
        cursor: not-allowed;
      }

      &.MuiButton-text {
        color: ${grey[400]};
      }
    }
  }

  &.MuiButtonBase-root {
    border-radius: 1000px;
  }

  &.MuiButton-contained,
  &.MuiButton-outlined {
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
  }

  &.MuiButton-contained {
    background-color: ${grey[50]};
    color: ${grey[500]};

    &:hover {
      background-color: ${grey[100]};
    }

    &.Mui-disabled {
      background-color: ${grey[50]};
    }
  }

  &.MuiButton-containedPrimary {
    background-color: ${muiTheme.palette.primary.main};
    border-color: ${muiTheme.palette.primary.main};
    color: ${trueWhite};

    &:hover {
      background-color: ${muiTheme.palette.primary.light};
      border-color: ${muiTheme.palette.primary.light};
    }

    &:active {
      background-color: ${muiTheme.palette.primary.dark};
      border-color: ${muiTheme.palette.primary.dark};
    }
  }

  &.MuiButton-outlined {
    background-color: ${trueWhite};

    &:hover {
      background-color: ${darkPurple[50]};
    }

    &:active {
      background-color: ${darkPurple[100]};
    }
  }

  &.MuiButton-outlinedPrimary {
    border: 1px solid ${darkPurple[500]};
    color: ${darkPurple.main};

    &:hover {
      border-color: ${darkPurple[50]};
    }

    &:active {
      border-color: ${darkPurple[500]};
    }
  }

  &.MuiButton-sizeLarge {
    font-size: 18px;
    line-height: 18px;
    padding: ${rhSpacingPx(2.5)} ${rhSpacingPx(4)};
  }

  &.MuiButton-sizeSmall {
    font-size: 14px;
    line-height: 10px;
    padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};

    &.MuiButton-text {
      padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};
    }
  }

  &.MuiButton-text {
    border: 0;
    color: ${darkPurple.main};
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};

    &:hover {
      background-color: ${grey[50]};
    }

    &:active {
      background-color: ${grey[100]};
    }
  }
`;
