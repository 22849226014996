import { darkPurple, trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { OFFER_CONTAINER_WIDTH_3CARD } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-bottom: ${rhSpacingPx(5)};
  text-align: center;
`;

export const StyledPageBackground = styled.div`
  background: linear-gradient(to bottom, ${darkPurple[100]}, ${trueWhite});
  height: 400px;
  left: 0;
  position: absolute;
  right: 0;
  top: 570px;
  width: 100%;
  z-index: -1;
`;

export const StyledOffersLoading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 450px;
  text-align: center;

  > * {
    margin: auto;
  }
`;

export const StyledOfferPageSectionContainer = styled.div<{
  $isMobile: boolean;
}>`
  max-width: ${OFFER_CONTAINER_WIDTH_3CARD}px;
  padding: 2rem ${({ $isMobile }) => ($isMobile ? "0.5rem" : "2rem")};
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const StyleRepPriceRef = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledChangeZip = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.9rem;
  gap: 0.5rem;
  justify-content: center;
`;
