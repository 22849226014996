import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  StyledMyAccountLinkContainer,
  StyledNavContainer,
} from "@portal/components/MyAccountNav/MyAccountNav.styled";
import { NavHashLink } from "@portal/components/NavHashLink/NavHashLink";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import { signOutPath } from "@portal/routes/routePaths";
import React from "react";
import { Link } from "react-router-dom";

interface MyAccountNavItemValue {
  anchorTag: string;
  translationId: TranslationKey;
}

export enum AccountNavAnchorTag {
  billingPreferences = "billingPreferences",
  changePassword = "changePassword",
  communicationPreferences = "communicationPreferences",
  contactInfo = "contactInfo",
  languagePreference = "languagePreference",
  paymentMethods = "paymentMethods",
  productAddOns = "productAddOns",
  serviceInfo = "serviceInfo",
  yourPlan = "yourPlan",
}

const myAccountNavItemValues: MyAccountNavItemValue[] = [
  {
    anchorTag: `#${AccountNavAnchorTag.serviceInfo}`,
    translationId: "MyAccountNav.serviceInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.yourPlan}`,
    translationId: "MyAccountNav.yourPlan",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.productAddOns}`,
    translationId: "MyAccountNav.productAddOns",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.contactInfo}`,
    translationId: "MyAccountNav.contactInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.languagePreference}`,
    translationId: "MyAccountNav.languagePreference",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.changePassword}`,
    translationId: "MyAccountNav.changePassword",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.communicationPreferences}`,
    translationId: "MyAccountNav.communicationPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.billingPreferences}`,
    translationId: "MyAccountNav.billingPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.paymentMethods}`,
    translationId: "MyAccountNav.paymentMethods",
  },
];

export const MyAccountNav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { t } = useRhIntl();

  return (
    <div role="navigation">
      <StyledNavContainer data-testid="myAccount__nav">
        {myAccountNavItemValues.map(({ anchorTag, translationId }) => {
          const title = t(translationId);

          return (
            <StyledMyAccountLinkContainer key={anchorTag}>
              <NavHashLink
                smooth
                to={anchorTag}
                scroll={scrollToOffsetPosition}
              >
                {title}
                {isMobile && <RightChevron title={title} />}
              </NavHashLink>
            </StyledMyAccountLinkContainer>
          );
        })}
        <StyledMyAccountLinkContainer>
          <Link to={signOutPath()}>
            {t("MyAccountNav.logout")}
            {isMobile && <RightChevron title={t("MyAccountNav.logout")} />}
          </Link>
        </StyledMyAccountLinkContainer>
      </StyledNavContainer>
    </div>
  );
};
