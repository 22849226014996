import { darkPurple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

const StyledTooltip = styled(Tooltip)`
  line-height: 0;
`;

const StyledChildrenWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: 0;
  margin: 0;
  padding: 0;
`;

export interface RhTooltipProps {
  ariaLabel: string;
  content: JSX.Element;
}

const useMobileTapOutsideTooltip = ({
  childrenRef,
  isMobile,
  isOpen,
  setIsOpen,
}: {
  childrenRef: RefObject<HTMLButtonElement>;
  isMobile: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const handleMobileTapOutsideTooltip = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltip = target.classList.contains("MuiTooltip-tooltip");

      if (isOpen && !isTooltip) {
        setIsOpen(false);
      }
      if (!isOpen && childrenRef.current?.contains(target)) {
        setIsOpen(true);
      }
    },
    [childrenRef, isOpen, setIsOpen]
  );

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    document.addEventListener("mousedown", handleMobileTapOutsideTooltip);
    return () => {
      document.removeEventListener("mousedown", handleMobileTapOutsideTooltip);
    };
  }, [handleMobileTapOutsideTooltip, isMobile]);
};

export const RhTooltip: FC<PropsWithChildren<RhTooltipProps>> = (props) => {
  const { ariaLabel, content, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM), {
    noSsr: true,
  });
  const childrenRef = useRef<HTMLButtonElement>(null);

  useMobileTapOutsideTooltip({
    childrenRef,
    isMobile,
    isOpen,
    setIsOpen,
  });

  // Rather than handling ourselves when to open the tooltip in desktop,
  // default to use the native way in desktop and add the props only if it is a mobil device
  const mobileTooltipProp = isMobile ? { open: isOpen } : {};

  return (
    <StyledTooltip
      aria-label={ariaLabel}
      enterDelay={0}
      title={content}
      arrow
      placement="top"
      {...mobileTooltipProp}
      // styling via styled components does not work combined with the ClickAwayListener.
      // It gives the error "Function components cannot be given refs" and clicking away stops working
      // Changing it to using componentsProps solves the issue
      componentsProps={{
        tooltip: {
          sx: {
            "& .MuiTooltip-arrow": {
              color: darkPurple[500],
            },
            backgroundColor: darkPurple[500],
            fontFamily,
            fontSize: "14px",
            lineHeight: rhSpacingPx(2.5),
            padding: rhSpacingPx(2.5),
          },
        },
      }}
    >
      <StyledChildrenWrapper type="button" ref={childrenRef}>
        {children}
      </StyledChildrenWrapper>
    </StyledTooltip>
  );
};
