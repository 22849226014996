import { ComparisonDataType } from "@common/components/BillComparisonChart/types";
import { darkPurple, purple } from "@design-system/theme/palette.colors";
import { ChartData } from "chart.js";

interface GetChartDataArgs {
  comparisionData: ComparisonDataType;
}

export const getBillComparisonChartData = ({
  comparisionData,
}: GetChartDataArgs) => {
  const data: number[] = [
    Number(comparisionData.previousPeriod?.consumption || 0),
    Number(comparisionData.currentPeriod.consumption),
  ];

  const backgroundColors = [darkPurple[200], purple.dark];

  if (comparisionData.previousYearPeriod) {
    data.unshift(Number(comparisionData.previousYearPeriod.consumption));
    backgroundColors.unshift(darkPurple[200]);
  }

  return {
    datasets: [
      {
        backgroundColor: backgroundColors,
        borderRadius: {
          topLeft: 6,
          topRight: 6,
        },
        data,
      },
    ],
  } as ChartData<"bar">;
};
