import { premiseApi } from "@common/api/premiseApi";
import { RedeemRewardsResponseType } from "@common/types/apiResponseTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { StyledLoadingDialogBody } from "@portal/components/RedeemRewards/RedeemRewards.styled";
import { RewardRedemptionCTA } from "@portal/components/RedeemRewards/RewardRedemptionCTA";
import { RewardRedemptionSuccess } from "@portal/components/RedeemRewards/RewardRedemptionSuccess";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremise } from "@portal/hooks/usePremise";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { selectRewards } from "@portal/selectors/rewardsSelectors";
import { ActionType, MyAccountEvents } from "@portal/services/segment.service";
import { rewardsRedeemed } from "@portal/slices/rewardsSlice";
import React, { FC, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

export interface RedeemRewardsDialogContentProps {
  currentAccountBalance: number;
  onApplyCredit: () => void;
  onCancel: () => void;
  originalAccountBalance: number;
  rewardPointBalance: number;
  value: number;
}

const RewardLoadingComponent = () => {
  return <StyledLoadingDialogBody />;
};

type RewardsDialogContentComponent =
  | "RewardLoadingComponent"
  | "RewardRedemptionCTA"
  | "RewardRedemptionSuccess";

const rewardsLifecycleComponents: Record<
  RewardsDialogContentComponent,
  FC<React.PropsWithChildren<RedeemRewardsDialogContentProps>>
> = {
  RewardLoadingComponent,
  RewardRedemptionCTA,
  RewardRedemptionSuccess,
};

export const RedeemRewards = () => {
  const { t } = useRhIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<
    "RewardLoadingComponent" | "RewardRedemptionCTA" | "RewardRedemptionSuccess"
  >("RewardRedemptionCTA");
  const dispatch = useDispatch();
  const { rewards } = useSelector(selectRewards);
  const { premise, requestMonitor: premiseRequestMonitor } = usePremise();
  const { data: accountSummary, isPending: isAccountSummaryLoading } =
    usePremiseAccountSummaryQuery({ premiseId: premise?.id || "" });
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  const currentPointsBalance = Number(rewards?.balance ?? 0);
  const currentPointsValue = rewards?.value ?? 0;
  const currentAccountBalance = Number(accountSummary?.totalBalance ?? 0);

  const [originalValues, setOriginalValues] = useState({
    accountBalance: currentAccountBalance,
    pointsBalance: currentPointsBalance,
    pointsValue: currentPointsValue,
  });

  if (
    !rewards ||
    !premise ||
    isAccountSummaryLoading ||
    premiseRequestMonitor.isWaiting
  ) {
    return <RhCircularProgress />;
  }

  // NOTE: in the future, customers will be able to select the points to redeem
  // for now they can only redeem their full quantity of points
  const selectedPointsToRedeem = originalValues.pointsBalance;
  const selectedPointValueToRedeem = originalValues.pointsValue;

  const onSuccessfulRedemption = ({
    balance: newRewardsBalance,
  }: RedeemRewardsResponseType) => {
    batch(() => {
      dispatch(
        rewardsRedeemed({
          amountUsed: selectedPointValueToRedeem,
          newRewardsBalance,
        })
      );
      setActiveComponent("RewardRedemptionSuccess");
    });
  };

  const applyCredit = () => {
    setActiveComponent("RewardLoadingComponent");

    track({
      action: ActionType.click,
      credit: originalValues.pointsValue,
      event: MyAccountEvents.applyCredit,
      label: "Clicked Apply credit in Redeem rewards",
    });

    premiseApi
      .redeemRewards(premise.id, selectedPointsToRedeem)
      .then(onSuccessfulRedemption)
      .catch((error: RhApiError) => {
        setActiveComponent("RewardRedemptionCTA");
        flash.error(t("RedeemRewards.issueRedeemingRewards"));
      });
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    batch(() => {
      setActiveComponent("RewardRedemptionCTA");
      setIsDialogOpen(true);
      setOriginalValues({
        accountBalance: currentAccountBalance,
        pointsBalance: rewards.balance ?? 0,
        pointsValue: rewards.value ?? 0,
      });
    });
  };

  const RewardsDialogContent = rewardsLifecycleComponents[activeComponent];

  const redeemRewardsText = t("RedeemRewards.buttonCTA");

  return (
    <>
      <RhButton
        data-tracking-click={{ event: "customer opening rewards modal" }}
        color="primary"
        disabled={currentPointsBalance === 0 || !accountSummary}
        onClick={openDialog}
        type="button"
      >
        {redeemRewardsText}
      </RhButton>
      <RhDialog
        open={isDialogOpen}
        onClose={() =>
          !(activeComponent === "RewardLoadingComponent") && closeDialog()
        }
      >
        <RewardsDialogContent
          currentAccountBalance={currentAccountBalance}
          originalAccountBalance={originalValues.accountBalance}
          rewardPointBalance={originalValues.pointsBalance}
          value={originalValues.pointsValue}
          onCancel={closeDialog}
          onApplyCredit={applyCredit}
        />
      </RhDialog>
    </>
  );
};
