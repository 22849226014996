import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { darkPurple } from "@design-system/theme/palette.colors";
import { fontFamily } from "@design-system/theme/typography";
import { FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root > span {
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: ${FontWeight.Regular};
  }

  svg.MuiSvgIcon-root {
    font-size: 1.71rem;
  }

  &.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked svg.MuiSvgIcon-root {
    color: ${darkPurple[500]};
  }
`;
