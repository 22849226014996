import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { darkPurple, trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { Button, ButtonGroup } from "@mui/material";
import styled from "styled-components";

interface StyledRhButtonToggleContainerProps {
  fullWidth: boolean;
}

export const StyledRhButtonToggleContainer = styled(
  ButtonGroup
)<StyledRhButtonToggleContainerProps>`
  &.MuiButtonGroup-root {
    padding: ${rhSpacingPx(1)};
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};

    & .MuiButton-root:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

interface StyledRhButtonToggleProps {
  $isActive: boolean;
}

export const StyledRhButtonToggle = styled(Button)<StyledRhButtonToggleProps>`
  &.MuiButton-root {
    background-color: ${({ $isActive }) =>
      $isActive ? trueWhite : darkPurple[50]};
    border: 1px solid ${darkPurple[200]};
    border-radius: 1000px;
    color: ${({ $isActive }) =>
      $isActive ? darkPurple.main : darkPurple[300]};
    flex-basis: 50%;
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: ${FontWeight.Regular};
    letter-spacing: normal;
    line-height: 10px;
    padding: 12px 16px;
    text-transform: none;

    &:hover {
      border-color: ${darkPurple.main};
      z-index: 1;
    }
  }
`;
