import { DUE_DATE_FORMAT } from "@common/constants/date.constant";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { red } from "@design-system/theme/palette.colors";
import { balanceDetailsTranslations } from "@portal/components/BalanceDetails/BalanceDetails.en.i18n";
import {
  StyledBalanceDetailsContainer,
  StyledBalanceDisconnectionNotice,
} from "@portal/components/BalanceDetails/BalanceDetails.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { BillingStatus } from "@portal/models/BillingStatus.model";
import { billBreakdownPath } from "@portal/routes/routePaths";
import { PaymentExtensionType } from "@portal/types/paymentExtensionTypes";
import dayjs from "dayjs";
import React from "react";

interface BalanceDetailsProps {
  activePaymentExtension?: PaymentExtensionType | null;
  billingStatus: BillingStatus;
  showBillBreakdownLink?: boolean;
}

export const BalanceDetails = ({
  activePaymentExtension,
  billingStatus,
  showBillBreakdownLink = true,
}: BalanceDetailsProps) => {
  const { featureFlagClient } = useFeatureFlagClient();

  const { portalBillBreakdown } = featureFlagClient.getFlags([
    ["portalBillBreakdown", false],
  ]);

  const { translate, translateJsx } = useTranslations();

  const {
    billingSummary: { latestInvoice, pastDueBalance },
    currentDueBalance,
    disconnectionDate,
    formattedAccountDueDate,
    formattedDisconnectionDate,
    formattedNextDueDate,
    hasPastDueBalance,
    hasPaymentExtension,
    noBalanceDue,
  } = billingStatus;

  const {
    tBalanceDetailsPastDue,
    tBalanceDetailsNoBalanceDue,
    tBalanceDetailsPaymentExtension,
    tBalanceDetailsSeeWhatFactoredIntoMyBill,
  } = translate(balanceDetailsTranslations);

  const {
    tBalanceDetailsBalanceDue,
    tBalanceDetailsDisconnectionNotice,
    tBalanceDetailsNextBillExplainer,
    tBalanceDetailsPaymentExtensionDueDate,
  } = translateJsx({
    tBalanceDetailsBalanceDue: {
      date: formattedAccountDueDate,
    },
    tBalanceDetailsDisconnectionNotice: {
      date: () => <span>{formattedDisconnectionDate}</span>,
    },
    tBalanceDetailsNextBillExplainer: {
      amount: formatCurrency(currentDueBalance),
      date: formattedNextDueDate,
    },
    tBalanceDetailsPaymentExtensionDueDate: {
      date: (
        <strong>
          {dayjs(activePaymentExtension?.extendToDate).format(DUE_DATE_FORMAT)}
        </strong>
      ),
    },
  });

  let balanceDueColor = "primary";
  let balanceDueDate = tBalanceDetailsBalanceDue;
  let displayBalance = currentDueBalance;

  if (hasPastDueBalance) {
    balanceDueColor = red.main;
    balanceDueDate = <>{tBalanceDetailsPastDue}</>;
    displayBalance = Number(pastDueBalance);
  }

  if (noBalanceDue) {
    balanceDueColor = "textSecondary";
    balanceDueDate = <>{tBalanceDetailsNoBalanceDue}</>;
  }

  if (activePaymentExtension) {
    balanceDueColor = "primary";
    balanceDueDate = (
      <>
        <RhTypography fontWeight="Bold">
          {tBalanceDetailsPaymentExtension}
        </RhTypography>
        <RhTypography>{tBalanceDetailsPaymentExtensionDueDate}</RhTypography>{" "}
      </>
    );
    displayBalance = Number(activePaymentExtension.amountExtended);
  }

  return (
    <StyledBalanceDetailsContainer>
      <RhTypography variant="subtitle2">{balanceDueDate}</RhTypography>
      {hasPastDueBalance && disconnectionDate && !hasPaymentExtension ? (
        <StyledBalanceDisconnectionNotice>
          {tBalanceDetailsDisconnectionNotice}
        </StyledBalanceDisconnectionNotice>
      ) : null}
      <RhTypography variant="h1" color={balanceDueColor}>
        {formatCurrency(displayBalance)}
      </RhTypography>
      {hasPastDueBalance && currentDueBalance > 0 && (
        <RhTypography>{tBalanceDetailsNextBillExplainer}</RhTypography>
      )}
      {Boolean(portalBillBreakdown.value) &&
      latestInvoice !== null &&
      showBillBreakdownLink ? (
        <RhRouterLink
          title={tBalanceDetailsSeeWhatFactoredIntoMyBill}
          to={billBreakdownPath(latestInvoice.id)}
        >
          <RhTypography variant="subtitle2">
            {tBalanceDetailsSeeWhatFactoredIntoMyBill}
          </RhTypography>
        </RhRouterLink>
      ) : null}
    </StyledBalanceDetailsContainer>
  );
};
