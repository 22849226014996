import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

const TOGGLE_WIDTH = 320;

export const StyledCostEstimatorRoot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rhSpacingPx(8)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    min-width: 500;
    width: auto;
  }
`;

export const StyledCostEstimatorHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StyledCostEstimatorTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const StyledCostEstimatorSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey["600"]};
    margin-bottom: ${rhSpacingPx(3)};
  }
`;

export const StyledCostEstimatorToggleContainer = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
  width: ${TOGGLE_WIDTH}px;
`;

export const StyledMonthlyUsageInputContainer = styled.div`
  margin-bottom: ${rhSpacingPx(5)};
  width: ${TOGGLE_WIDTH}px;
`;
