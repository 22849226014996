import { api } from "@common/api/api";
import { BASE_URL_REGEX } from "@common/constants/regex.constant";
import { CUSTOMER_NOT_FOUND_ERROR } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useOktaAuth } from "@okta/okta-react";
import { StyledLoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm.styled";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ForgotPasswordFields } from "@portal/pages/ForgotPasswordPage/ForgotPasswordFields";
import { forgotPasswordPageTranslations } from "@portal/pages/ForgotPasswordPage/ForgotPasswordPage.en.i18n";
import { forgotPasswordFormValidator } from "@portal/pages/ForgotPasswordPage/ForgotPasswordPageFormValidator";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";

interface ForgotPasswordFormValues {
  email: string;
}

export const ForgotPasswordPage = () => {
  const { oktaAuth } = useOktaAuth();

  const uri = oktaAuth.getOriginalUri();

  const referrer =
    typeof uri === "string" ? uri.replace(BASE_URL_REGEX, "") : undefined;

  const flash = useRhFlash();

  const { translate } = useTranslations();

  const { tForgotPasswordPageErrorSendingInfo, tForgotPasswordPageTitle } =
    translate(forgotPasswordPageTranslations);

  const sendResetEmail = async ({ email }: ForgotPasswordFormValues) => {
    const [error] = await handleAjaxCall(
      api.customers.password.sendResetEmail(email, referrer)
    );

    if (error) {
      if (error.data.errorCode === CUSTOMER_NOT_FOUND_ERROR) {
        // Do not surface any errors to indicate customer was not found.
        return;
      }

      flash.error(tForgotPasswordPageErrorSendingInfo);

      return { [FORM_ERROR]: [tForgotPasswordPageErrorSendingInfo] };
    }
  };

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerText={tForgotPasswordPageTitle} />
      <Form<ForgotPasswordFormValues>
        onSubmit={sendResetEmail}
        validate={forgotPasswordFormValidator}
        render={({ handleSubmit }) => (
          <StyledLoggedOutForm onSubmit={handleSubmit} noValidate>
            <ForgotPasswordFields />
          </StyledLoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};
