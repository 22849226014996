import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { IdType } from "@common/types/apiTypes";
import { formatCurrency, formatDateRange } from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CalendarIcon } from "@design-system/icons/CalendarIcon.svg";
import { ReactComponent as FlashyDollar } from "@design-system/icons/FlashyDollar.svg";
import { Grid } from "@mui/material";
import { MyAccountAddOnsLegalDocuments } from "@portal/components/MyAccountAddOnsLegalDocuments/MyAccountAddOnsLegalDocuments";
import {
  StyledContainer,
  StyledListItemText,
  StyledOptOut,
} from "@portal/components/MyAccountAddOnsOfferCard/MyAccountAddOnsOfferCard.styled";
import { ProductContractOptOut } from "@portal/components/MyAccountAddOnsOfferCard/ProductContractOptOut/ProductContractOptOut";
import {
  StyledList,
  StyledListItem,
  StyledListItemIcon,
} from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.styled";
import { ProductContractClaimManagement } from "@portal/components/ProductContractClaimManagement/ProductContractClaimManagement";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ProductContractType } from "@portal/types/productContractTypes";
import React from "react";

interface MyAccountAddOnsSingleOfferCardProps {
  premiseId: IdType;
  productContract: ProductContractType;
}

const MyAccountAddOnsSingleOfferCard = ({
  productContract: contract,
  premiseId,
}: MyAccountAddOnsSingleOfferCardProps) => {
  const { featureFlagClient } = useFeatureFlagClient();

  const { portalOptOutProductContract, portalProductContractClaimsManagement } =
    featureFlagClient.getFlags([
      ["portalOptOutProductContract", false],
      ["portalProductContractClaimsManagement", false],
    ]);

  const { t } = useRhIntl();

  const formattedRate = formatCurrency(contract.rate);
  const termPeriodSingular = t(
    `MyAccountAddOnsSingleOfferCard.single${
      contract.termPeriod === "MONTHS" ? "Month" : "Year"
    }`
  );
  const rateText = `${formattedRate}/${termPeriodSingular}`;

  const servicePeriodText = formatDateRange(
    contract.startDate,
    contract.endDate
  );

  const { tosUrl } = contract;

  // TODO: Replace with useID() in React 18
  const id = contract.productName.toLowerCase().replaceAll(" ", "-");

  return (
    <>
      <Grid item sm={12} md={5} role="article" aria-labelledby={id}>
        <StyledContainer>
          <RhCard
            noShadow
            title={
              <>
                <RhTypography variant="h3" id={id}>
                  {contract.productName}
                </RhTypography>
                <RhTypography variant="body1" color="textSecondary">
                  {`${
                    contract.termLength
                  } ${contract.termPeriod.toLowerCase()}`}
                </RhTypography>
              </>
            }
          >
            <StyledList dense>
              <StyledListItem>
                <StyledListItemIcon>
                  <FlashyDollar title="Rate" />
                </StyledListItemIcon>
                <StyledListItemText>{rateText}</StyledListItemText>
              </StyledListItem>
              <StyledListItem>
                <StyledListItemIcon>
                  <CalendarIcon title="Service period" />
                </StyledListItemIcon>
                <StyledListItemText>{servicePeriodText}</StyledListItemText>
              </StyledListItem>
              {portalOptOutProductContract.value ? (
                <StyledOptOut>
                  <ProductContractOptOut
                    productContract={contract}
                    premiseId={premiseId}
                  />
                </StyledOptOut>
              ) : null}
            </StyledList>
          </RhCard>
        </StyledContainer>
      </Grid>
      <Grid item sm={12} md={7}>
        {portalProductContractClaimsManagement.value ? (
          <ProductContractClaimManagement
            productContract={contract}
            premiseId={premiseId}
          />
        ) : null}

        <MyAccountAddOnsLegalDocuments tosUrl={tosUrl} />
      </Grid>
    </>
  );
};

interface MyAccountAddOnsOfferCardProps {
  premiseId: IdType;
  productContracts: ProductContractType[];
}

export const MyAccountAddOnsOfferCard = ({
  productContracts,
  premiseId,
}: MyAccountAddOnsOfferCardProps) => {
  return (
    <Grid container>
      {productContracts.map((contract) => {
        return (
          <MyAccountAddOnsSingleOfferCard
            premiseId={premiseId}
            productContract={contract}
            key={contract.id}
          />
        );
      })}
    </Grid>
  );
};
