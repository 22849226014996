import { LanguagePreferenceType } from "@common/types/customerTypes";

export const convert24HourTo12Hour = (time: number) => {
  if (time === 0) {
    return "12am";
  }

  if (time < 12) {
    return `${time}am`;
  }

  if (time === 12) {
    return "12pm";
  }

  if (time > 24) {
    return "";
  }

  return `${time - 12}pm`;
};

export const convertTimeToHoursAndMinutes = (
  time: string,
  locale: LanguagePreferenceType = "en"
) => {
  const parsedTime = Number(time);
  const hours = Math.floor(parsedTime / 60);
  const minutes = parsedTime % 60;
  let hourText = hours > 1 ? "hours" : "hour";

  if (locale === "es") {
    hourText = hours > 1 ? "horas" : "hora";
  }

  if (hours === 0) {
    return `${minutes} min`;
  }

  if (hours === 1 && minutes === 0) {
    return `1 ${hourText}`;
  }

  return `${hours} ${hourText} ${minutes} min`;
};
