import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  RhTypography,
  RhTypographyVariant,
} from "@design-system/components/RhTypography/RhTypography";
import { AddBankAccount } from "@portal/components/AddBankAccount/AddBankAccount";
import { AddPaymentCard } from "@portal/components/AddPaymentCard/AddPaymentCard";
import { PaymentMethodOptionsType } from "@portal/components/PaymentMethodOptions/PaymentMethodOptions";
import { PaymentMethodRadioField } from "@portal/components/PaymentMethodRadioField/PaymentMethodRadioField";
import { PaymentMethodsTranslations } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection.en.i18n";
import { StyledPaymentMethodsSectionTitle } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection.styled";
import { PaymentMethodView } from "@portal/components/PaymentMethodView/PaymentMethodView";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { usePaymentMethods } from "@portal/hooks/billingHooks";
import { useFetchSinglePaymentMethodQuery } from "@portal/hooks/queries/useFetchSinglePaymentMethod.query";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  billingDetailsRefetch,
  paymentMethodSetAsDefaultRequest,
} from "@portal/slices/billingDetailsSlice";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

export interface PaymentMethodsSectionProps {
  allowMethodChange?: boolean;
  onPaymentMethodAdded?: (paymentMethodId: string) => void;
  oneTimePaymentMethodId?: string;
  sectionTitleText?: string;
  setOneTimePaymentMethodId?: (oneTimePaymentMethodId?: string) => void;
  showPaymentOptions?: boolean;
  variant?: RhTypographyVariant;
}

export const PaymentMethodsSection = ({
  sectionTitleText,
  variant = "h3",
  allowMethodChange = false,
  showPaymentOptions,
  onPaymentMethodAdded,
  oneTimePaymentMethodId,
  setOneTimePaymentMethodId,
}: PaymentMethodsSectionProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { premise } = usePremise();
  const { translate } = useTranslations();
  const { tPaymentMethodsSectionError } = translate(PaymentMethodsTranslations);

  const { paymentMethods, requestMonitor } = usePaymentMethods();

  const { data: oneTimePaymentMethod, refetch: refetchPaymentMethod } =
    useFetchSinglePaymentMethodQuery({
      enabled: false,
      paymentMethodId: oneTimePaymentMethodId || "",
      premiseId: premise?.id || "",
    });

  useEffect(() => {
    if (oneTimePaymentMethodId) {
      refetchPaymentMethod();
    }
  }, [oneTimePaymentMethodId, refetchPaymentMethod]);

  const onAchOrCardAdded = (
    achOrCardAddedResponse: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ) => {
    if (paymentMethodOptions?.isOneTimePayment) {
      setOneTimePaymentMethodId?.(achOrCardAddedResponse.refId);
      onPaymentMethodAdded?.(achOrCardAddedResponse.refId);
      return;
    }
    if (paymentMethods?.length === 0) {
      dispatch(
        paymentMethodSetAsDefaultRequest({ id: achOrCardAddedResponse.refId })
      );
    } else {
      dispatch(billingDetailsRefetch());
    }

    queryClient.invalidateQueries({
      queryKey: PremiseQueryKeys.retrieve(premise?.id ?? ""),
    });

    onPaymentMethodAdded?.(achOrCardAddedResponse.refId);
  };

  if (requestMonitor.didFail) {
    return (
      <RhTypography variant="body1">{tPaymentMethodsSectionError}</RhTypography>
    );
  }

  if (requestMonitor.isIdle) {
    return <RhCircularProgress />;
  }

  const showZuoraAch = !premise?.isNoCheckOrAch;

  const showZuoraCC = !premise?.isNoCreditOrDebitCard;

  return (
    <div>
      {sectionTitleText && (
        <StyledPaymentMethodsSectionTitle variant={variant}>
          {sectionTitleText}
        </StyledPaymentMethodsSectionTitle>
      )}
      {paymentMethods?.map((paymentMethod) =>
        allowMethodChange ? (
          <PaymentMethodRadioField
            key={paymentMethod.id}
            name="selectedPaymentMethodId"
            paymentMethod={paymentMethod}
          />
        ) : (
          <PaymentMethodView
            paymentMethod={paymentMethod}
            key={paymentMethod.id}
            displayOptions
            allowDelete={
              !paymentMethod.defaultPaymentMethod || paymentMethods.length === 1
            }
          />
        )
      )}
      {oneTimePaymentMethod && (
        <PaymentMethodRadioField
          key={oneTimePaymentMethod.id}
          name="selectedPaymentMethodId"
          paymentMethod={oneTimePaymentMethod}
          isOneTimePayment
        />
      )}
      {requestMonitor.isPending && <RhCircularProgress />}
      {showZuoraCC ? (
        <AddPaymentCard
          onCardAdded={onAchOrCardAdded}
          showPaymentOptions={showPaymentOptions}
        />
      ) : null}
      {showZuoraAch ? (
        <AddBankAccount
          showPaymentOptions={showPaymentOptions}
          onBankAccountAdded={onAchOrCardAdded}
        />
      ) : null}
    </div>
  );
};
