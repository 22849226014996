import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const BillCostEstimatorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rhSpacingPx(8)};
  width: 100%;
`;

export const BillCostEstimatorHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const BillCostEstimatorTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(3)};
  }
`;

export const BillCostEstimatorSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey["600"]};
    margin-bottom: ${rhSpacingPx(5)};
  }
`;
