import {
  fontBold,
  fontBoldItalic,
  fontLight,
  fontLightItalic,
  fontMedium,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
} from "@design-system/theme/avertaFont";
import {
  darkPurple,
  green,
  grey,
  lightBlue,
  red,
  trueBlack,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { UNIT_OF_SPACING } from "@design-system/theme/style.constant";
import { typography } from "@design-system/theme/typography";
import { ThemeOptions, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const mui5Typography = typography as TypographyOptions;

export const theme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [
          fontLight,
          fontLightItalic,
          fontRegular,
          fontRegularItalic,
          fontMedium,
          fontMediumItalic,
          fontBold,
          fontBoldItalic,
        ],
        a: {
          "&:not(.MuiButton-root):active": {
            color: darkPurple[700],
            textDecoration: "underline",
          },
          "&:not(.MuiButton-root):hover": {
            textDecoration: "underline",
          },
          color: darkPurple.main,
          cursor: "pointer",
          textDecoration: "none",
        },
        html: {
          scrollBehavior: "smooth",
        },
        ul: {
          "list-style": "none",
          margin: 0,
          padding: 0,
        },
      },
    },
  },
  palette: {
    common: {
      black: trueBlack,
      white: trueWhite,
    },
    error: red,
    grey,
    info: lightBlue,
    primary: darkPurple,
    success: green,
    text: {
      disabled: grey[200],
      primary: grey[900],
      secondary: grey[600],
    },
  },
  spacing: UNIT_OF_SPACING,
  typography: mui5Typography,
};

export const muiTheme = createTheme(theme);
