import { CIRCULAR_PROGRESS_DEFAULT_SIZE } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { darkPurple } from "@design-system/theme/palette.colors";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const StyledCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${darkPurple.main};
  }
  height: ${(props) => props.size ?? CIRCULAR_PROGRESS_DEFAULT_SIZE};
  width: ${(props) => props.size ?? CIRCULAR_PROGRESS_DEFAULT_SIZE};
`;
