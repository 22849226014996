import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { ReactComponent as Magnifier } from "@design-system/icons/Magnifier.svg";
import {
  EFLCell,
  NoPlansFoundText,
  PlanList,
  PlanListHeader,
  PlanListWrapper,
  PlanNameWrapper,
  PriceCell,
  ProviderLogoCell,
} from "@portal/components/RepPriceComparisonPlanList/RepPriceComparisonPlanList.styled";
import { RepPriceComparisonPlanListRow } from "@portal/components/RepPriceComparisonPlanList/RepPriceComparisonPlanListRow";
import { SelectedRhythmPlan } from "@portal/components/RepPriceComparisonPlanList/SelectedRhythmPlan";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { CompetitorPlan } from "@portal/types/competitorTypes";
import React, { FC } from "react";

interface RepPriceComparisonPlanListProps {
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  offerSnapshot: OfferSnapshot;
  plans: CompetitorPlan[];
  selectButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
}

export const RepPriceComparisonPlanList: FC<
  RepPriceComparisonPlanListProps
> = ({ plans, offerSnapshot, selectButtonProps, moreButtonProps }) => {
  const { t } = useRhIntl();

  return (
    <>
      <SelectedRhythmPlan
        offerSnapshot={offerSnapshot}
        moreButtonProps={moreButtonProps}
        selectButtonProps={selectButtonProps}
      />
      <PlanListWrapper>
        <PlanListHeader>
          <ProviderLogoCell>
            {t("RepPriceComparisonPlanList.provider")}
          </ProviderLogoCell>
          <PlanNameWrapper>
            {t("RepPriceComparisonPlanList.planName")}
          </PlanNameWrapper>
          <PriceCell>{t("RepPriceComparisonPlanList.price")}</PriceCell>
          <EFLCell>{t("RepPriceComparisonPlanList.efl")}</EFLCell>
        </PlanListHeader>
        <PlanList className={plans ? "plansLoaded" : "plansLoading"}>
          {!plans && <RhCircularProgress />}
          {plans && !plans.length && (
            <NoPlansFoundText>
              <Magnifier />{" "}
              <span>{t("RepPriceComparisonPlanList.noPlansFound")}</span>
            </NoPlansFoundText>
          )}
          {plans &&
            plans.length > 0 &&
            plans.map((plan) => (
              <RepPriceComparisonPlanListRow key={plan.id} plan={plan} />
            ))}
        </PlanList>
      </PlanListWrapper>
    </>
  );
};
