import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Outlet } from "react-router-dom";

interface LoggedOutOnlyProps {
  redirectPath: string;
}

export const LoggedOutOnly = (props: LoggedOutOnlyProps) => {
  const { redirectPath } = props;
  const { authState } = useOktaAuth();

  if (!authState) {
    // Wait for authState to be initialized
    // before deciding if we should render a redirect
    // or the component
    return null;
  }

  if (authState.isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/styles/awsConnectWrapper.css"
        />
        <script
          type="text/javascript"
          src="assets/js/loggedOutAwsConnectChat.js"
        />
      </Helmet>
      <Outlet />
    </>
  );
};
