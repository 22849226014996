import { PremiseStatus } from "@common/enums/premise.enum";
import { Premise } from "@common/models/Premise.model";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { devicePremiseSelectorTranslations } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.en.i18n";
import {
  StyledCheckIcon,
  StyledPortalSelectorListItem,
  StyledPortalSelectorStatusIndicator,
  StyledPortalSelectorStatusText,
  StyledPortalSelectorSubtitle,
  StyledPortalSelectorTitle,
  StyledPortalSelectorTitleContainer,
} from "@portal/components/PortalSelector/PortalSelector.styled";
import { premiseStatusColor } from "@portal/helpers/premiseStatusColor";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface DevicePremiseListItemProps {
  isInMenu?: boolean;
  isSelectedPremise?: boolean;
  premise: Premise | PremiseListItemType;
}

export const DevicePremiseListItem = (props: DevicePremiseListItemProps) => {
  const { premise, isSelectedPremise, isInMenu } = props;
  const { translate } = useTranslations();
  const {
    tDevicePremiseSelectorActive,
    tDevicePremiseSelectorInactive,
    tDevicePremiseSelectorPending,
    tDevicePremiseSelectorAccountNumber,
  } = translate(devicePremiseSelectorTranslations);

  const premiseState = (status: PremiseStatus) => {
    if (status === PremiseStatus.ACTIVE) {
      return tDevicePremiseSelectorActive;
    }

    if (status === PremiseStatus.PENDING) {
      return tDevicePremiseSelectorPending;
    }

    return tDevicePremiseSelectorInactive;
  };

  return (
    <StyledPortalSelectorListItem>
      {!isInMenu ? (
        <StyledPortalSelectorStatusIndicator
          $statusColor={premiseStatusColor(premise.status)}
          data-testid="DevicePremiseSelector__StatusIndicator"
        />
      ) : null}

      <StyledPortalSelectorTitleContainer>
        <StyledPortalSelectorTitle $isSelected={Boolean(isSelectedPremise)}>
          {premise.serviceAddress?.deliveryLine1}
        </StyledPortalSelectorTitle>

        <StyledPortalSelectorSubtitle>
          {tDevicePremiseSelectorAccountNumber} | {premise.billingAccountNumber}
        </StyledPortalSelectorSubtitle>

        {isInMenu ? (
          <StyledPortalSelectorStatusText
            $statusColor={premiseStatusColor(premise.status)}
          >
            {premiseState(premise.status)}
          </StyledPortalSelectorStatusText>
        ) : null}

        {isSelectedPremise && isInMenu ? <StyledCheckIcon aria-hidden /> : null}
      </StyledPortalSelectorTitleContainer>
    </StyledPortalSelectorListItem>
  );
};
