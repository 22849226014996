import { portalApi } from "@portal/api/api";
import { QuickPayQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useQuickPayAddPaymentMethod = (token: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: portalApi.quickPay.addPaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QuickPayQueryKeys.paymentMethods(token),
      });
    },
  });

  return mutation;
};
