import { MOVE_IN, SWITCH } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhIconRadioField } from "@design-system/components/RhIconRadioField/RhIconRadioField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as MoveInIcon } from "@design-system/icons/MoveInIcon.svg";
import { ReactComponent as SwitchIcon } from "@design-system/icons/SwitchIcon.svg";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { SignUpServiceStartDateField } from "@portal/components/SignUpServiceStartDateField/SignUpServiceStartDateField";
import { useServiceStartDates } from "@portal/hooks/useServiceStartDates";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpEnrollmentPageTranslations } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentPage.en.i18n";
import { selectChosenOfferSnapshotId } from "@portal/selectors/signUpSelectors";
import { ActionType, AnalyticsEvent } from "@portal/services/segment.service";
import React, { FocusEvent, useEffect } from "react";
import { useSelector } from "react-redux";

interface SignUpEnrollmentFieldsProps {
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onChange?: (dateString: string) => void;
  trackEvent: (event: Partial<AnalyticsEvent>) => void;
}

export const SignUpEnrollmentFields = ({
  trackEvent,
  onBlur,
  onChange,
}: SignUpEnrollmentFieldsProps) => {
  const flash = useRhFlash();
  const offersnapshotId = useSelector(selectChosenOfferSnapshotId);
  const { data: allowedDates, requestMonitor } =
    useServiceStartDates(offersnapshotId);
  const { translate } = useTranslations();

  const {
    tSignUpEnrollmentPageGetStartDatesError,
    tSignUpEnrollmentPageMoveIn,
    tSignUpEnrollmentPageSwitch,
  } = translate(signUpEnrollmentPageTranslations);

  useEffect(() => {
    if (requestMonitor.didFail) {
      flash.error(tSignUpEnrollmentPageGetStartDatesError);
    }
  }, [flash, requestMonitor.didFail, tSignUpEnrollmentPageGetStartDatesError]);

  const trackClick = (label: string) => {
    trackEvent({ action: ActionType.click, label });
  };

  const minDate = allowedDates?.[0];
  const maxDate = allowedDates?.[allowedDates.length - 1];

  return (
    <LoggedOutFieldsLayout>
      <RhFlexBox
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <RhIconRadioField
          value={MOVE_IN}
          icon={<MoveInIcon />}
          label={tSignUpEnrollmentPageMoveIn}
          onClick={() => trackClick("movingIcon")}
          onBlur={onBlur}
          name="enrollmentType"
        />
        <RhIconRadioField
          value={SWITCH}
          icon={<SwitchIcon />}
          label={tSignUpEnrollmentPageSwitch}
          onClick={() => trackClick("switchingIcon")}
          onBlur={onBlur}
          name="enrollmentType"
        />
      </RhFlexBox>
      {allowedDates ? (
        <SignUpServiceStartDateField
          name="serviceStartDate"
          disabled={!allowedDates}
          minDate={minDate}
          maxDate={maxDate}
          allowedDates={allowedDates}
          InputProps={{
            onClick: () => trackClick("moveInDate"),
          }}
          onChange={onChange}
        />
      ) : (
        <RhCircularProgress />
      )}
    </LoggedOutFieldsLayout>
  );
};
