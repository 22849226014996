import { ajax } from "@common/services/ajax";
import {
  OneClickRenewalOfferSnapshotsResponseType,
  PortalOneClickRenewalDefaultPaymentMethodResponseType,
  PortalOneClickRenewalResponseType,
} from "@common/types/apiResponseTypes";
import { OneClickRenewalApiUrls } from "@portal/api/oneClickRenewalApiUrls";

export const oneClickRenewalApi = {
  allRenewableOfferSnapshots: (renewalToken: string) =>
    ajax.get<OneClickRenewalOfferSnapshotsResponseType>(
      OneClickRenewalApiUrls.allRenewableOfferSnapshots(renewalToken)
    ),
  defaultPaymentMethod: (renewalToken: string) =>
    ajax.get<PortalOneClickRenewalDefaultPaymentMethodResponseType>(
      OneClickRenewalApiUrls.defaultPaymentMethod(renewalToken)
    ),
  renew: ({
    renewalOfferSnapshotId,
    renewalToken,
    paperless,
    autopay,
  }: {
    autopay?: boolean;
    paperless?: boolean;
    renewalOfferSnapshotId: string;
    renewalToken: string;
  }) =>
    ajax.put<PortalOneClickRenewalResponseType>(
      OneClickRenewalApiUrls.renew(renewalToken),
      {
        autopay,
        paperless,
        renewalOfferSnapshotId,
      }
    ),
  retrieve: (renewalToken: string) =>
    ajax.get<PortalOneClickRenewalResponseType>(
      OneClickRenewalApiUrls.retrieve(renewalToken)
    ),
};
