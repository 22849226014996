import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { offerSnapshotFilterTabsTranslations } from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.en.i18n";
import {
  FilterTabs,
  FilterTabsContainer,
  SelectContainer,
} from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.styled";
import {
  OfferSnapshotFilterCategories,
  OfferSnapshotFilterType,
} from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.types";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useId } from "react";
import { useMediaQuery } from "usehooks-ts";

interface OfferSnapshotFilterTabsProps {
  offerSnapshots: OfferSnapshot[];
  selectedFilter: OfferSnapshotFilterType;
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<OfferSnapshotFilterType>
  >;
}

export const OfferSnapshotFilterTabs = (
  props: OfferSnapshotFilterTabsProps
) => {
  const { offerSnapshots, selectedFilter, setSelectedFilter } = props;

  const isMobile = useMediaQuery(`(max-width: ${RhythmBreakpoints.XS}px)`);

  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFilterTabsSelectLabel,
    tOfferSnapshotFilterTabsAllFilter,
    tOfferSnapshotFilterTabsFeaturedFilter,
    tOfferSnapshotFilterTabsMonthToMonthFilter,
  } = translate(offerSnapshotFilterTabsTranslations);

  const hasFeatured = offerSnapshots.some(
    (offerSnapshot) => offerSnapshot.isFeatured
  );
  const uniqueTermMonths = Array.from(
    new Set(offerSnapshots.map((offerSnapshot) => offerSnapshot.termMonths))
  ).sort();

  const featuredFilter = hasFeatured
    ? {
        label: tOfferSnapshotFilterTabsFeaturedFilter,
        value: OfferSnapshotFilterCategories.Featured,
      }
    : undefined;

  const allFilter = {
    label: tOfferSnapshotFilterTabsAllFilter,
    value: OfferSnapshotFilterCategories.All,
  };

  const termFilters = uniqueTermMonths.map((termMonth) => {
    const { tOfferSnapshotFilterTabsXMonths } = translateJsx({
      tOfferSnapshotFilterTabsXMonths: {
        termMonth,
      },
    });

    return {
      label:
        termMonth === 1
          ? tOfferSnapshotFilterTabsMonthToMonthFilter
          : tOfferSnapshotFilterTabsXMonths,
      value: termMonth.toString(),
    };
  });

  const offerSnapshotFilters = [
    featuredFilter,
    ...termFilters,
    allFilter,
  ].filter((filter) => filter !== undefined);

  const selectFieldId = useId();

  return (
    <FilterTabsContainer>
      {isMobile ? (
        <SelectContainer>
          <RhSelectLabel htmlFor={selectFieldId}>
            {tOfferSnapshotFilterTabsSelectLabel}
          </RhSelectLabel>

          <RhSelect
            id={selectFieldId}
            onChange={(e) => {
              const { value } = e.target;

              if (value) {
                setSelectedFilter(value as OfferSnapshotFilterType);
              }
            }}
            value={selectedFilter}
          >
            {offerSnapshotFilters.map((option) => {
              return (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              );
            })}
          </RhSelect>
        </SelectContainer>
      ) : (
        <FilterTabs align="center" contained>
          {offerSnapshotFilters.map(({ label, value }) => (
            <RhRoundTabItem
              key={value}
              label={label}
              onClick={() => {
                setSelectedFilter(value as OfferSnapshotFilterType);
              }}
            />
          ))}
        </FilterTabs>
      )}
    </FilterTabsContainer>
  );
};
