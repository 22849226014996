import {
  StyledBillBreakdownCard,
  StyledBillBreakdownContainer,
} from "@portal/components/BillBreakdownCard/BillBreakdownCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";

export const BillBreakdownCardSkeleton = () => (
  <StyledBillBreakdownCard>
    <StyledBillBreakdownContainer>
      <Skeleton />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
    </StyledBillBreakdownContainer>
  </StyledBillBreakdownCard>
);
