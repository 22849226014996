import { EnvTypes } from "@common/enums/envTypes.enum";
import {
  getEnv,
  isLocal,
} from "@common/services/getEnvApplicationSettings.service";

// TODO Split this by external cookies and internal rhythm cookies
export enum CookieName {
  CampaignTfn = "rhCampaignTfn",
  DjangoLanguage = "django_language",
  FeatureFlagUserId = "rhFeatureFlagUserId",
  FullStoryUserId = "_fs_uid",
  GoogleAnalyticsClientId = "_ga",
  OfferPageScrollTo = "rhOfferPageScrollTo",
  ProspectUuid = "rhProspectUuid",
  SegmentAnonymousId = "ajs_anonymous_id",
  SwitchEBillToPaperlessModal = "rhSwitchEBillToPaperlessModal",
}

export type EnvCookie = `${CookieName}-${EnvTypes}`;

const getAllCookies = () =>
  document.cookie
    .split("; ")
    .reduce<{ [key: string]: string }>((result, cookie) => {
      const [key, value] = cookie.split("=");

      // eslint-disable-next-line no-param-reassign
      result[key] = value && value.replace(/%22/g, "");
      return result;
    }, {});

export const getCookieByKey = (key: CookieName | EnvCookie): string | null =>
  getAllCookies()[key] ?? null;

export const setCookieItem = (
  key: CookieName | EnvCookie,
  value: string,
  numberOfDays: number,
  numberOfHours: number = 0
) => {
  const expires = new Date(
    Date.now() + numberOfDays * 864e5 + numberOfHours * 36e5
  ).toUTCString();

  const cookieEnvOptions = () => {
    if (isLocal()) {
      return "path=/";
    }

    return "domain=.gotrhythm.com; path=/";
  };

  document.cookie = `${key}=${value}; expires=${expires}; ${cookieEnvOptions()}`;
};

export const removeCookieItem = (key: CookieName | EnvCookie) => {
  const expires = "Thu, 01 Jan 1970 00:00:00 GMT";

  const cookieEnvOptions = () => {
    if (getEnv() === EnvTypes.local) {
      return "path=/";
    }

    return "domain=.gotrhythm.com; path=/";
  };

  document.cookie = `${key}=; expires=${expires}; ${cookieEnvOptions()}`;
};

export const buildEnvCookieKey = (cookie: CookieName) => {
  let cookieWithEnv = `${cookie}`;

  if (getEnv() !== EnvTypes.production) {
    cookieWithEnv = `${cookie}-${getEnv()}`;
  }

  return cookieWithEnv as EnvCookie;
};

export const getProspectUuidCookie = () =>
  getCookieByKey(buildEnvCookieKey(CookieName.ProspectUuid));
