import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { boxShadows } from "@design-system/constants/boxShadows";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled, { css } from "styled-components";

interface RenewalOfferSnapshotCardStyledProps {
  $calloutColor: string;
  $hasCallout?: boolean;
}

export const RenewalCardContainer = styled.article<RenewalOfferSnapshotCardStyledProps>`
  background-color: ${trueWhite};
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  padding-top: ${rhSpacingPx(1.5)};
  position: relative;
  width: 100%;

  ${({ $hasCallout }) =>
    $hasCallout &&
    css`
      padding-top: 0;
    `}

  &:hover {
    border-color: ${({ $calloutColor }) => $calloutColor ?? darkPurple["500"]};
    cursor: pointer;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 280px;
  }
`;

export const CalloutHeader = styled.div<RenewalOfferSnapshotCardStyledProps>`
  background-color: ${({ $calloutColor }) => $calloutColor};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: ${grey[50]};
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(2)};
`;

export const ButtonContainer = styled.div`
  border-top: 1px solid ${grey[100]};
  display: flex;
  gap: ${rhSpacingPx(2)};
  padding: ${rhSpacingPx(2)};
`;

export const RenewalCardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  padding: 0 ${rhSpacingPx(2)};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
`;

export const FeaturesContainer = styled.div`
  border-bottom: 1px solid ${grey[100]};
  border-top: 1px solid ${grey[100]};
  display: none;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(1.5)} 0;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: flex;
  }
`;

export const StyledOfferSnapshotDescription = styled(RhTypography)`
  display: none;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: block;
  }
`;

export const StyledCompareButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${darkPurple.main};
  display: flex;
  flex: 1;
  flex-direction: row;
  font-family: inherit;
  font-size: 14px;
  gap: 0.5rem;
  justify-content: center;
  line-height: 1.1;
  margin: 0;
  max-width: 100%;
  padding: 0;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CompareContainer = styled.div`
  border-top: 1px solid ${grey[100]};
  display: flex;
  margin-top: auto;
  padding-top: ${rhSpacingPx(1.5)};
`;
