import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as InformationIcon } from "@design-system/icons/InformationIcon.svg";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledGuestPayAccountLookup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2.5)};
  max-width: 20rem;
`;

export const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  width: 100%;
`;

export const StyledInformationIcon = styled(InformationIcon)`
  color: ${darkPurple.main};

  &:hover {
    color: ${darkPurple["300"]};
  }
`;

export const StyledGuestPayButton = styled(RhButton)`
  &.MuiButton-root {
    width: 100%;
  }
`;

export const OrSeparator = styled.span`
  color: ${grey["300"]};
  font-size: 14px;
`;

export const StyledLabelContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(0.5)};
`;

export const StyledSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    max-width: 380px;
    text-align: center;
  }
`;
