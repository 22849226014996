import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { UUIDType } from "@common/types/apiTypes";
import { signUpApi } from "@portal/api/signUpApi";
import { SignUpQueryKeys } from "@portal/constants/querykeys.constants";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@portal/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseSignUpOfferSnapshotQueryProps {
  offerSnapshotUUID: UUIDType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useSignUpOfferSnapshotQuery = (
  props: UseSignUpOfferSnapshotQueryProps
) => {
  const { queryOptions: { enabled = true } = {}, offerSnapshotUUID } = props;

  return useQuery({
    enabled,
    queryFn: () => signUpApi.offerSnapshots.retrieve(offerSnapshotUUID),
    queryKey: SignUpQueryKeys.offerSnapshots.retrieve(offerSnapshotUUID),
    select: (data) => new OfferSnapshot(data),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });
};
