import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { AddBankAccountTranslations } from "@portal/components/AddBankAccount/AddBankAccount.en.i18n";
import {
  StyledCardContent,
  StyledRhInnerCard,
  StyledTitle,
} from "@portal/components/AddBankAccount/AddBankAccount.styled";
import {
  PaymentMethodOptions,
  PaymentMethodOptionsType,
} from "@portal/components/PaymentMethodOptions/PaymentMethodOptions";
import { ZuoraAddBankAccountForm } from "@portal/components/ZuoraAddBankAccountForm/ZuoraAddBankAccountForm";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useRef, useState } from "react";

interface AddBankAccountProps {
  onBankAccountAdded(
    response: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ): void;
  showFlashSuccess?: boolean;
  showPaymentOptions?: boolean;
}

export const AddBankAccount = ({
  onBankAccountAdded,
  showFlashSuccess = true,
  showPaymentOptions,
}: AddBankAccountProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { translate } = useTranslations();

  const {
    tAddBankAccountAriaCloseLabel,
    tAddBankAccountAriaLabel,
    tAddBankAccountNewBankAccount,
    tAddBankAccountNewAccountAddedSuccessfully,
    tAddBankAccountTitle,
  } = translate(AddBankAccountTranslations);
  const flash = useRhFlash();

  const paymentOptionsRef = useRef<PaymentMethodOptionsType>({
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  });

  return (
    <>
      <StyledRhInnerCard onClick={() => setIsModalOpen(true)}>
        <StyledCardContent>
          <RhTypography variant="subtitle2" fontWeight={FontWeight.Semibold}>
            {tAddBankAccountNewBankAccount}
          </RhTypography>
          <RightChevron />
        </StyledCardContent>
      </StyledRhInnerCard>
      {isModalOpen ? (
        <RhModal
          ariaLabel={tAddBankAccountAriaLabel}
          ariaCloseLabel={tAddBankAccountAriaCloseLabel}
          handleClose={() => setIsModalOpen(false)}
        >
          <StyledTitle>{tAddBankAccountTitle}</StyledTitle>
          <ZuoraAddBankAccountForm
            onSuccess={(response) => {
              onBankAccountAdded(response, paymentOptionsRef.current);
              setIsModalOpen(false);
              if (showFlashSuccess) {
                flash.success(tAddBankAccountNewAccountAddedSuccessfully);
              }
            }}
            onFailure={(errorMessage) => {
              setIsModalOpen(false);
              flash.error(errorMessage);
            }}
            paymentOptions={paymentOptionsRef.current}
          />
          <PaymentMethodOptions
            showPaymentOptions={showPaymentOptions}
            paymentOptionsRef={paymentOptionsRef.current}
          />
        </RhModal>
      ) : null}
    </>
  );
};
