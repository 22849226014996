import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import {
  OFFER_CONTAINER_WIDTH_3CARD,
  OFFER_CONTAINER_WIDTH_4CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  StyledBox,
  StyledInnerBox,
  StyledTandemBikeLeft,
  StyledTandemBikeRight,
} from "@portal/components/OfferGrid/OfferGrid.styled";
import { RhOffersGrid } from "@portal/components/RhOffersGrid/RhOffersGrid";
import { SignUpOfferCoordinator } from "@portal/components/SignUpOfferCoordinator/SignUpOfferCoordinator";
import { useHideCostEstimator } from "@portal/hooks/useHideCostEstimator";
import { ReactComponent as TandemBikeLeft } from "@portal/images/TandemBikeLeft.svg";
import { ReactComponent as TandemBikeRight } from "@portal/images/TandemBikeRight.svg";
import { sortBy } from "lodash";
import React from "react";

export interface SignUpOfferGridProps {
  offerSnapshots: OfferSnapshotType[];
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshotType) => void;
}

export const OfferGrid = ({
  offerSnapshots,
  onSelectOfferSnapshot,
}: SignUpOfferGridProps) => {
  const offerCount: number = offerSnapshots.length;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const hideCostEstimatorButton = useHideCostEstimator(offerSnapshots);

  const sortedOffers = sortBy(offerSnapshots, (offerSnapshot) =>
    Number(offerSnapshot.priority)
  );

  const signUpOfferCoordinators = sortedOffers.map(
    (offerSnapshot: OfferSnapshotType, index: number) => (
      <SignUpOfferCoordinator
        key={offerSnapshot.id}
        index={index}
        allOfferSnapshots={offerSnapshots}
        offerSnapshot={offerSnapshot}
        onSelectOfferSnapshot={onSelectOfferSnapshot}
        hideCostEstimatorButton={hideCostEstimatorButton}
      />
    )
  );

  const boxMaxWidth =
    offerCount === 4
      ? OFFER_CONTAINER_WIDTH_4CARD
      : OFFER_CONTAINER_WIDTH_3CARD;

  return offerSnapshots ? (
    <StyledBox $boxMaxWidth={boxMaxWidth} $isMobile={isMobile}>
      <StyledInnerBox $boxMaxWidth={boxMaxWidth} $isMobile={isMobile}>
        <div>
          <RhOffersGrid limitMaxWidth offerCount={offerCount}>
            {signUpOfferCoordinators}
          </RhOffersGrid>
          <StyledTandemBikeLeft>
            <TandemBikeLeft />
          </StyledTandemBikeLeft>
          <StyledTandemBikeRight>
            <TandemBikeRight />
          </StyledTandemBikeRight>
        </div>
      </StyledInnerBox>
    </StyledBox>
  ) : null;
};
