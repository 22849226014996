import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { StyledPowerToChooseLegal1Box } from "@portal/components/PowerToChooseLegal/PowerToChooseLegal.styled";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

export const PowerToChooseLegal: FC = () => {
  const { t } = useRhIntl();

  return (
    <div>
      <StyledPowerToChooseLegal1Box>
        <RhTypography>
          {t("PowerToChooseLegal.powerToChooseLegal1")}
        </RhTypography>
      </StyledPowerToChooseLegal1Box>
      <div>
        <RhTypography>
          {t("PowerToChooseLegal.powerToChooseLegal2")}
        </RhTypography>
      </div>
    </div>
  );
};
