import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { OfferFilterCategories } from "@portal/components/OfferFilterTabs/OfferFilterTabs";
import { DEFAULT_TERM_LENGTH_FILTER } from "@portal/constants/offer.constant";

export function calculateOfferSnapshotsToDisplay(
  offerSnapshots: OfferSnapshotType[] | null,
  termMonthsFilter:
    | OfferFilterCategories.All
    | OfferFilterCategories.Featured
    | string = DEFAULT_TERM_LENGTH_FILTER,
  displayTermLengthToggle: boolean = true
) {
  if (offerSnapshots === null) {
    return null;
  }

  if (!displayTermLengthToggle) {
    return offerSnapshots;
  }
  if (termMonthsFilter === OfferFilterCategories.All) {
    return offerSnapshots;
  }

  if (termMonthsFilter === OfferFilterCategories.Featured) {
    return offerSnapshots.filter(
      (offerSnapshot) => new OfferSnapshot(offerSnapshot).isFeatured
    );
  }

  return offerSnapshots.filter(
    (offerSnapshot) => offerSnapshot.termMonths === Number(termMonthsFilter)
  );
}

export function calculateTermMonthsFilter(
  selectedTermMonthsTab: OfferFilterCategories | string | null,
  uniqueOfferTermMonths: Set<number>
): OfferFilterCategories | string {
  if (selectedTermMonthsTab === OfferFilterCategories.All) {
    return OfferFilterCategories.All;
  }
  if (selectedTermMonthsTab === OfferFilterCategories.Featured) {
    return OfferFilterCategories.Featured;
  }

  if (
    uniqueOfferTermMonths.size &&
    uniqueOfferTermMonths.has(Number(selectedTermMonthsTab))
  ) {
    return selectedTermMonthsTab ?? DEFAULT_TERM_LENGTH_FILTER;
  }

  return DEFAULT_TERM_LENGTH_FILTER;
}
