import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import {
  rafPointsAmount,
  rafPointsValue,
} from "@common/constants/rewards.constant";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CopyReferralLinkButton } from "@portal/components/CopyReferralLinkButton/CopyReferralLinkButton";
import {
  StyledReferAFriendDialog,
  StyledReferAFriendDialogSubtitle,
} from "@portal/components/ReferAFriendDialog/ReferAFriendDialog.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { REWARDS_FAQ_URL } from "@portal/routes/routePaths";
import React from "react";

import { referAFriendDialogTranslations } from "./ReferAFriendDialog.en.i18n";

export interface ReferAFriendDialogProps {
  onClose: () => void;
}

export const ReferAFriendDialog = ({ onClose }: ReferAFriendDialogProps) => {
  const { translate, translateJsx } = useTranslations();
  const { tReferAFriendDialogTitle } = translate(
    referAFriendDialogTranslations
  );
  const { tReferAFriendDialogSubtitle } = translateJsx({
    tReferAFriendDialogSubtitle: {
      linkChunk: (content: string) => (
        <DynamicLink external to={REWARDS_FAQ_URL}>
          {content}
        </DynamicLink>
      ),
      pointsAmount: rafPointsAmount,
      pointsValue: rafPointsValue,
    },
  });

  return (
    <StyledReferAFriendDialog open onClose={onClose} size="medium">
      <RhTypography variant="h2" color="textPrimary">
        {tReferAFriendDialogTitle}
      </RhTypography>
      <StyledReferAFriendDialogSubtitle variant="body2">
        {tReferAFriendDialogSubtitle}
      </StyledReferAFriendDialogSubtitle>
      <CopyReferralLinkButton dense />
    </StyledReferAFriendDialog>
  );
};
