import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const BILLING_PREFERENCE_DETAIL_MAX_WIDTH = "365px";

export const StyledPreferencesSwitchInputCard = styled(RhInnerCard)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledPreferencesSwitchInputDetail = styled(RhTypography)`
  &.MuiTypography-root {
    display: block;
  }
`;

export const StyledSwitchContainer = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
`;

export const StyledPreferencesSwitchInputLabelText = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey[500]};
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-right: ${rhSpacingPx(1)};
    text-transform: uppercase;
  }
`;

export const StyledPreferencesSwitchInputTextWrap = styled.div`
  margin-bottom: ${rhSpacingPx(1)};
  padding-right: ${rhSpacingPx(2)};
`;
