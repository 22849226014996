import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { formatCurrency } from "@common/utils/dataFormatters";
import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { COST_ESTIMATOR_SCROLL_TO_POINT } from "@portal/components/CostEstimator/CostEstimator";
import { OfferCardProps } from "@portal/components/OfferCard/OfferCard.interfaces";
import { StyledCard } from "@portal/components/OfferCard/OfferCard.styled";
import { OfferCardDesktop } from "@portal/components/OfferCard/OfferCardDesktop";
import { OfferCardMobile } from "@portal/components/OfferCard/OfferCardMobile";
import { RHYTHM_GENERAL_SALES_PHONE_NUMBER } from "@portal/constants/misc.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const OfferCard = ({
  offerSnapshot,
  selectButtonProps,
  moreButtonProps,
  compareButtonProps,
  hideCostEstimatorButton,
}: OfferCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const hasPromo = offerSnapshot.hasPromo();
  const hasCallout = offerSnapshot.hasCallout();
  const calloutBackgroundColor = offerSnapshot.calloutBackgroundColor();
  const {
    acquisition: { acquisitionTfn },
  } = useSelector(selectSignUpState);
  const { trackEvent } = useSignUpFlow();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const { t, tJsx } = useRhIntl();
  const { featureFlagClient } = useFeatureFlagClient();

  const { portalCondensedMobileOfferCard } = featureFlagClient.getFlags([
    ["portalCondensedMobileOfferCard", false],
  ]);

  const calculateCtaMessage = t("OfferCard.calculateYourBill");

  const handleCalculateBillClick = () => {
    const element = document.getElementById(
      COST_ESTIMATOR_SCROLL_TO_POINT
    ) as HTMLElement;

    scrollToOffsetPosition(element);

    trackEvent({
      action: ActionType.click,
      label: "calculateYourBillCTA",
    });
  };

  let calloutText;

  // We want promos to always take precedence over tags
  if (hasPromo) {
    calloutText = tJsx("OfferCard.promoText", {
      promoAmount: formatCurrency(
        offerSnapshot?.promo?.presentationValue || 0,
        0
      ),
    });
  } else if (hasCallout) {
    calloutText = offerSnapshot.calloutText(locale);
  }

  const trackPhoneClick = () => {
    featureFlagClient.trackMetric(
      FeatureFlagMetric.PortalProspectOfferPhoneNumberClicked
    );
  };

  const offerCardPhoneNumber =
    acquisitionTfn || RHYTHM_GENERAL_SALES_PHONE_NUMBER;

  useEffect(() => {
    if (isMobile) {
      portalCondensedMobileOfferCard.recordVisitor();
    }
  }, [isMobile, portalCondensedMobileOfferCard]);

  return (
    <StyledCard
      $hasCallout={hasCallout}
      $hasPromo={hasPromo}
      $calloutBackgroundColor={calloutBackgroundColor}
      data-testid="OfferCard__container"
      id={`offerSnapshot-${offerSnapshot.id}`}
    >
      {isMobile && portalCondensedMobileOfferCard.value ? (
        <OfferCardMobile
          calculateCtaMessage={calculateCtaMessage}
          calloutBackgroundColor={calloutBackgroundColor}
          calloutText={calloutText}
          compareButtonProps={compareButtonProps}
          handleCalculateBillClick={handleCalculateBillClick}
          hideCostEstimatorButton={hideCostEstimatorButton}
          locale={locale}
          moreButtonProps={moreButtonProps}
          offerSnapshot={offerSnapshot}
          selectButtonProps={selectButtonProps}
        />
      ) : (
        <OfferCardDesktop
          calculateCtaMessage={calculateCtaMessage}
          calloutBackgroundColor={calloutBackgroundColor}
          calloutText={calloutText}
          compareButtonProps={compareButtonProps}
          handleCalculateBillClick={handleCalculateBillClick}
          hideCostEstimatorButton={hideCostEstimatorButton}
          locale={locale}
          moreButtonProps={moreButtonProps}
          offerCardPhoneNumber={offerCardPhoneNumber}
          offerSnapshot={offerSnapshot}
          selectButtonProps={selectButtonProps}
          trackPhoneClick={trackPhoneClick}
        />
      )}
    </StyledCard>
  );
};
