import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { allEnvApplicationSettings } from "@portal/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const FACEBOOK_URL = "https://www.facebook.com/rhythmenergy/";
export const FAQ_URL = "https://support.gotrhythm.com";
export const INSTAGRAM_URL = "https://www.instagram.com/rhythmenergy/";
export const IRONHIDE_SIGN_IN_URL = "https://account.gotrhythm.com";
export const LINKEDIN_URL = "https://www.linkedin.com/company/rhythmhq/";
export const PRIVACY_POLICY_URL = "https://gotrhythm.com/privacy-notice";
export const MARKETING_RHYTHM_URL = envApplicationSettings.marketingRhythmUrl;
export const MARKETING_ENERGY_TEXAS_URL =
  envApplicationSettings.marketingEnergyTexasUrl;
export const TERMS_OF_USE_URL = "https://gotrhythm.com/terms-of-use";
export const TWITTER_URL = "https://twitter.com/GotRhythmHQ/";

export const OUTAGE_SUPPORT_URL =
  "https://support.gotrhythm.com/hc/en-us/articles/360053151792-How-can-I-report-or-get-information-on-a-power-outage-";

export const DOCUMENTS_URL =
  "https://support.gotrhythm.com/hc/en-us/articles/360052365491-There-is-a-switch-hold-on-the-home-I-am-moving-into-What-can-I-do-";

// Also not really a path, but not sure where else we'd put this
export const ENERGY_SAVING_TIPS_URL =
  "https://www.gotrhythm.com/blog/sustainable-lifestyle/10-tips-to-reduce-energy-bills-this-summer";
