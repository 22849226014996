import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const DevicePremiseSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: flex-start;
  text-align: left;
`;

export const StyledDevicePremiseMenuItem = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  color: ${grey[900]};
  cursor: pointer;
  display: flex;
  margin: 0;
  min-height: 80px;
  padding: ${rhSpacingPx(2)};
  text-align: left;
  width: 100%;

  &:focus,
  &:focus-within {
    background-color: ${grey[50]};
    cursor: pointer;
    outline: none;
  }
`;
