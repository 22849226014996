import { prospectApi } from "@common/api/prospectApi";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  selectProspectId,
  selectReferralCode,
} from "@portal/selectors/signUpSelectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const ReferralCodeAnnouncement = () => {
  const prospectId = useSelector(selectProspectId);
  const { announceNotice } = useRhAnnouncement();
  const referralCode = useSelector(selectReferralCode);
  const { t } = useRhIntl();

  useEffect(() => {
    (async () => {
      if (!prospectId || !referralCode) {
        return;
      }

      const [error, response] = await handleAjaxCall(
        prospectApi.getReferralCode(prospectId, referralCode)
      );

      if (error || !response) {
        return null;
      }

      const referralCodeApplied = t(
        "ReferralCodeAnnouncement.referralCodeApplied"
      );

      announceNotice(referralCodeApplied);
    })();
  }, [prospectId, referralCode]);

  return null;
};
