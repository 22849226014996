import { grey } from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  padding-bottom: 1rem;
  width: 100%;
`;

export const StyledSummaryBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem 0 0;
  width: 100%;

  &:not(:first-child) {
    border-top: 1px solid ${grey[100]};
  }
`;
