import { premiseApi } from "@common/api/premiseApi";
import { formatMaskNumber } from "@common/forms/formatters";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { formatShorthandMonthDay } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { Invoice } from "@portal/models/Invoice.model";
import { myAccountPath } from "@portal/routes/routePaths";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface AutoPayInfoProps {
  autopayEnabled: boolean;
  billingPaymentMethodId?: string | null;
  invoiceToPay: Invoice | null;
}

export const AutoPayInfoV2 = ({
  autopayEnabled,
  invoiceToPay,
  billingPaymentMethodId,
}: AutoPayInfoProps) => {
  const { t, tJsx } = useRhIntl();
  const [paymentMethodMaskNumber, setPaymentMethodMaskNumber] = useState<
    string | null
  >(null);
  const premiseId = useSelector(selectCustomerPremisePreference);

  useEffect(() => {
    if (autopayEnabled && billingPaymentMethodId && premiseId) {
      premiseApi.paymentMethods
        .list(premiseId)
        // No need to catch error. In this case it is ok to fail silently
        .then((response) => {
          const foundPaymentMethod = response.paymentMethods.find(
            (pm) => pm.id === billingPaymentMethodId
          );

          if (foundPaymentMethod) {
            const paymentMethod = new PaymentMethod(foundPaymentMethod);

            setPaymentMethodMaskNumber(paymentMethod.maskedNumber);
          }
        });
    }
  }, [billingPaymentMethodId, autopayEnabled, premiseId]);

  if (!autopayEnabled) {
    return (
      <Link
        to={{
          pathname: myAccountPath(),
        }}
        state={{ anchorId: "billingPreferences" }}
      >
        <RhTypography variant="body1">
          {t("account.autopayInfo.autoPaySetup")}
        </RhTypography>
      </Link>
    );
  }

  // Auto-pay in enabled and there is nothing to pay yet
  let finalMessage: string | ReactNode = t(
    "account.autopayInfo.autoPayIsEnabled"
  );

  // Auto-pay in enabled and there is something to pay
  if (invoiceToPay && invoiceToPay.balance > 0) {
    if (paymentMethodMaskNumber) {
      finalMessage = tJsx(
        "account.autopayInfo.autoPayIsEnabledWithDateAndCard",
        {
          card: formatMaskNumber(paymentMethodMaskNumber),
          date: formatShorthandMonthDay(invoiceToPay.dueDate),
        }
      );
    } else {
      finalMessage = tJsx("account.autopayInfo.autoPayIsEnabledWithDate", {
        date: formatShorthandMonthDay(invoiceToPay.dueDate),
      });
    }
  }

  return <RhTypography variant="body1">{finalMessage}</RhTypography>;
};
