import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import {
  competitorPlanOfferSnapshotUUIDAtom,
  competitorPlanTermMonthsAtom,
} from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison.atoms";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { OfferSnapshotCard } from "@portal/components/OfferSnapshotCard/OfferSnapshotCard";
import { OfferSnapshotCardSkeleton } from "@portal/components/OfferSnapshotCard/OfferSnapshotCard.skeleton";
import { OfferSnapshotFilterTabs } from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs";
import {
  OfferSnapshotFilterCategories,
  OfferSnapshotFilterType,
} from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.types";
import { PlansPageEmptyState } from "@portal/components/PlansPageEmptyState/PlansPageEmptyState";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { RHYTHM_GENERAL_SALES_PHONE_NUMBER } from "@portal/constants/misc.constant";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpPlansPageOfferSnapshotCardsTranslations } from "@portal/pages/SignUpPlansPage/SignUpPlansPageOfferSnapshotCards/SignUpPlansPageOfferSnapshotCards.en.i18n";
import { OfferSnapshotCardContainer } from "@portal/pages/SignUpPlansPage/SignUpPlansPageOfferSnapshotCards/SignUpPlansPageOfferSnapshotCards.styled";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { useSetAtom } from "jotai";
import React, { useState } from "react";
import { useSelector } from "react-redux";

interface SignUpPlansPageOfferSnapshotCardsProps {
  billCalculatorId: string;
  competitorComparisonId: string;
  estimatedMonthlyUsage: number;
  isPending: boolean;
  offerSnapshots: OfferSnapshot[];
  showBillCostEstimator: boolean;
  showCompetitorComparison: boolean;
}

const determineViewableOfferSnapshots = ({
  offerSnapshots,
  filter,
  showFilter,
}: {
  filter: string;
  offerSnapshots: OfferSnapshot[];
  showFilter: boolean;
}) => {
  if (filter === "all" || !showFilter) {
    return offerSnapshots;
  }

  if (filter === "featured") {
    return offerSnapshots.filter(
      (offerSnapshot) => new OfferSnapshot(offerSnapshot).isFeatured
    );
  }

  return offerSnapshots.filter(
    (offerSnapshot) => offerSnapshot.termMonths === Number(filter)
  );
};

export const SignUpPlansPageOfferSnapshotCards = (
  props: SignUpPlansPageOfferSnapshotCardsProps
) => {
  const {
    estimatedMonthlyUsage,
    isPending,
    offerSnapshots,
    billCalculatorId,
    competitorComparisonId,
    showBillCostEstimator,
    showCompetitorComparison,
  } = props;

  const { addModal } = useModals();
  const { translate } = useTranslations();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const prospect = useProspectFromContext();

  const setCompetitorPlanOfferSnapshotUUIDAtom = useSetAtom(
    competitorPlanOfferSnapshotUUIDAtom
  );

  const setCompetitorPlanTermMonthsAtom = useSetAtom(
    competitorPlanTermMonthsAtom
  );

  const campaignSlug = prospect.rcid ?? CampaignSlugs.Default;
  const { acquisition, selectedTermMonthsTab } = useSelector(selectSignUpState);

  const [selectedFilter, setSelectedFilter] = useState<OfferSnapshotFilterType>(
    (selectedTermMonthsTab as OfferSnapshotFilterType) ??
      OfferSnapshotFilterCategories.Featured
  );

  const { tSignUpPlansPageOfferSnapshotCardsSelectPlanCta } = translate(
    signUpPlansPageOfferSnapshotCardsTranslations
  );
  const acquisitionTfn =
    acquisition.acquisitionTfn || RHYTHM_GENERAL_SALES_PHONE_NUMBER;

  if (isPending) {
    return (
      <OfferSnapshotCardContainer>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <OfferSnapshotCardSkeleton key={index} />
        ))}
      </OfferSnapshotCardContainer>
    );
  }

  const noOfferSnapshots = !isPending && offerSnapshots.length === 0;

  if (noOfferSnapshots) {
    return (
      <OfferSnapshotCardContainer>
        <PlansPageEmptyState />
      </OfferSnapshotCardContainer>
    );
  }

  const handleCalculateBillClick = () => {
    document
      ?.querySelector(`#${CSS.escape(billCalculatorId)}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const handleCompareRatesClick = (offerSnapshot: OfferSnapshot) => {
    setCompetitorPlanTermMonthsAtom(offerSnapshot.termMonths.toString());
    setCompetitorPlanOfferSnapshotUUIDAtom(offerSnapshot.uuid);

    document
      ?.querySelector(`#${CSS.escape(competitorComparisonId)}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const showFilter = !campaignSlug || campaignSlug === CampaignSlugs.Default;

  const filteredOfferSnapshots = determineViewableOfferSnapshots({
    filter: selectedFilter,
    offerSnapshots,
    showFilter,
  });

  return (
    <>
      <OfferSnapshotFilterTabs
        offerSnapshots={offerSnapshots}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />

      <OfferSnapshotCardContainer>
        {filteredOfferSnapshots.map((offerSnapshot) => {
          const handleLearnMoreClick = () => {
            addModal({
              offerSnapshotLearnMore: {
                offerSnapshotUUID: offerSnapshot.uuid,
                onEnrollClick: () => {
                  handleEnrollNowClick({
                    estimatedMonthlyUsage,
                    offerSnapshot,
                  });
                },
                selectPlanCtaText:
                  tSignUpPlansPageOfferSnapshotCardsSelectPlanCta,
              },
            });
          };

          return (
            <OfferSnapshotCard
              key={offerSnapshot.id}
              offerSnapshot={offerSnapshot}
              onEnrollClick={() =>
                handleEnrollNowClick({
                  estimatedMonthlyUsage,
                  offerSnapshot,
                })
              }
              onCompareRatesClick={
                showCompetitorComparison && !offerSnapshot.solarEligible
                  ? handleCompareRatesClick
                  : undefined
              }
              phoneNumber={acquisitionTfn}
              onLearnMoreClick={handleLearnMoreClick}
              onCalculateBillClick={
                showBillCostEstimator && !offerSnapshot.solarEligible
                  ? handleCalculateBillClick
                  : undefined
              }
            />
          );
        })}
      </OfferSnapshotCardContainer>
    </>
  );
};
