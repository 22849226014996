import { rhythmRTBsTranslations } from "@portal/components/RhythmRTBs/RhythmRTBs.en.i18n";
import {
  LottieContainer,
  StyledRhythmRTBsContainer,
  StyledRtb,
} from "@portal/components/RhythmRTBs/RhythmRTBs.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import thirtyDay from "@portal/images/30-day.lottie.json";
import bestPrice from "@portal/images/best-price.lottie.json";
import greenEnergy from "@portal/images/green-energy.lottie.json";
import rhythmRateLock from "@portal/images/rhythm-rate-lock.lottie.json";
import Lottie from "lottie-react";
import React from "react";

interface RTB {
  desc: string;
  lottie: Record<string, unknown>;
  title: string;
}

export const RhythmRTBs = () => {
  const { translate } = useTranslations();

  const {
    tRhythmRTBs30Day,
    tRhythmRTBs30DayDesc,
    tRhythmRTBsBestPrice,
    tRhythmRTBsBestPriceDesc,
    tRhythmRTBsGreenEnergy,
    tRhythmRTBsGreenEnergyDesc,
    tRhythmRTBsRateLock,
    tRhythmRTBsRateLockDesc,
  } = translate(rhythmRTBsTranslations);

  const RTBs: RTB[] = [
    {
      desc: tRhythmRTBsBestPriceDesc,
      lottie: bestPrice,
      title: tRhythmRTBsBestPrice,
    },
    {
      desc: tRhythmRTBsRateLockDesc,
      lottie: rhythmRateLock,
      title: tRhythmRTBsRateLock,
    },
    {
      desc: tRhythmRTBsGreenEnergyDesc,
      lottie: greenEnergy,
      title: tRhythmRTBsGreenEnergy,
    },
    {
      desc: tRhythmRTBs30DayDesc,
      lottie: thirtyDay,
      title: tRhythmRTBs30Day,
    },
  ];

  return (
    <StyledRhythmRTBsContainer>
      {RTBs.map((rtb) => (
        <StyledRtb key={rtb.title}>
          <LottieContainer>
            <Lottie
              animationData={rtb.lottie}
              autoPlay
              loop
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              style={{
                height: 36,
                width: 36,
              }}
            />
            <h3>{rtb.title}</h3>
            <p>{rtb.desc}</p>
          </LottieContainer>
        </StyledRtb>
      ))}
    </StyledRhythmRTBsContainer>
  );
};
