import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { NotificationOptionSwitches } from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  StyledNotificationsCoulmn,
  StyledNotificationsHeader,
  StyledNotificationsWrapper,
} from "@portal/pages/NotificationsPage/NotificationsPage.styled";
import React from "react";
import { Helmet } from "react-helmet-async";

export const NotificationsPage = () => {
  const { t } = useRhIntl();

  const pageHeader = t("NotificationsPage.notifications");
  const siteTitle = t("navigation.title.rhythm");

  return (
    <>
      <Helmet>
        <title>{`${pageHeader} - ${siteTitle}`}</title>
      </Helmet>
      <PortalPageLayout>
        <StyledNotificationsWrapper>
          <StyledNotificationsCoulmn>
            <StyledNotificationsHeader>
              <RhTypography variant="h1">{pageHeader}</RhTypography>
            </StyledNotificationsHeader>
            <NotificationOptionSwitches />
          </StyledNotificationsCoulmn>
        </StyledNotificationsWrapper>
      </PortalPageLayout>
    </>
  );
};
