import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(4)};
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
`;

export const StyledLabel = styled.label`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  gap: ${rhSpacingPx(2)};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  text-align: center;
  width: 100%;
`;

export const StyledBackLink = styled(Link)`
  font-size: 1rem;
`;

export const StyledCheckbox = styled.input`
  accent-color: ${darkPurple.main};
  transform: translateY(2px);
`;

export const StyledYourDocumentsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
  justify-content: flex-start;
  padding-bottom: ${rhSpacingPx(4)};
  padding-left: 36px;
  width: 100%;
`;

export const StyledLegalDocLinkContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(1)};
  justify-content: flex-start;
`;

export const StyledLegalDocIcon = styled(PDFIcon)`
  color: ${darkPurple[500]};
`;
