import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledBillEnergyContainer = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding: ${rhSpacingPx(2.5)};
  }
`;

export const StyledBillEnergyHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rhSpacingPx(2.5)} ${rhSpacingPx(2.5)} 0;
  text-align: start;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding: 0;
  }
`;

export const StyledBillEnergyDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${grey[100]};
    margin-top: ${rhSpacingPx(1)};
  }
`;

export const StyledRadiosContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const StyledBillEnergyTemperatureSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  padding: ${rhSpacingPx(2)} 0;
`;

export const StyledEnergyGraphContainer = styled.div`
  padding: 0 ${rhSpacingPx(0.5)} ${rhSpacingPx(2.5)};
`;

export const StyledBillEnergyUsageTemperatureErrorContainer = styled.div`
  display: flex;
  padding-top: ${rhSpacingPx(1)};
`;
