import {
  StyledRhButtonToggle,
  StyledRhButtonToggleContainer,
} from "@design-system/components/RhButtonToggle/RhButtonToggle.styled";
import React, { useState } from "react";

interface Labels {
  left: string;
  right: string;
}
type Sides = keyof Labels;

interface RhButtonToggleProps {
  color?: "primary" | "secondary";
  defaultValue?: string;
  fullWidth?: boolean;
  labels: Labels;
  onChange?: (value: string) => void;
  size?: "small" | "medium" | "large";
}

export const RhButtonToggle = ({
  labels,
  fullWidth,
  defaultValue,
  onChange = () => {},
  size = "small",
  color = "primary",
}: RhButtonToggleProps) => {
  const [current, setCurrent] = useState<string | undefined>(defaultValue);
  const choose = (val: string): void => {
    setCurrent(val);
    onChange(val);
  };
  const isCurrent = (label: string) => current === label;
  const renderButton = (label: string, side: Sides): JSX.Element => {
    return (
      <StyledRhButtonToggle
        key={side}
        $isActive={isCurrent(label)}
        onClick={() => choose(labels[side])}
      >
        {label}
      </StyledRhButtonToggle>
    );
  };

  return (
    <StyledRhButtonToggleContainer
      fullWidth={!!fullWidth}
      color={color}
      size={size}
    >
      {(["left", "right"] as Sides[]).map((side) =>
        renderButton(labels[side], side)
      )}
    </StyledRhButtonToggleContainer>
  );
};
