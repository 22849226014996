export const RHYTHM = "Rhythm";
export const RHYTHM_EXIT_INTENT_PHONE_NUMBER = "1-888-987-0393";

// Use anywhere in the LOGGED IN experience
export const RHYTHM_CUSTOMER_CARE_PHONE_NUMBER = "1-833-774-9846";
// Use anywhere in the SIGN UP flow
export const RHYTHM_GENERAL_SALES_PHONE_NUMBER = "1-888-408-2836";

export const RHYTHM_LLC = "Rhythm Ops, LLC";
export const RHYTHM_PO_BOX = "PO BOX 735903";
export const RHYTHM_ADDRESS = "Dallas, TX 75373";
