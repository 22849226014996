import { borders } from "@design-system/constants/borders";
import { theme } from "@design-system/theme/theme.styled";
import { Badge } from "@mui/material";
import styled, { css } from "styled-components";

interface CartToggleContainerProps {
  $isMobileCart?: boolean;
}

export const CartToggleContainer = styled.div<CartToggleContainerProps>`
  background: transparent;
  border-right: ${({ $isMobileCart }) => ($isMobileCart ? 0 : borders[100])};
  cursor: pointer;
  display: flex;
  height: 60px;

  &:hover {
    background: ${({ $isMobileCart }) =>
      $isMobileCart ? "transparent" : theme.palette.grey[50]};
  }
`;

export const CartToggleButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${theme.palette.black};
  display: flex;
  font-family: inherit;
  gap: 1.1rem;
  height: 100%;
  padding: 0 1.5rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const CartToggleButtonText = styled.span`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  text-align: left;
`;

export const CartToggleButtonTextLabel = styled.span`
  font-size: 0.6rem;
`;

export const CartToggleButtonTextTitle = styled.span`
  font-weight: bold;
`;

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white};
  }
`;

interface CartToggleIconProps {
  $open: boolean;
}

export const CartToggleIcon = styled.span<CartToggleIconProps>`
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 0.25s ease-in-out;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;
