import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const PortalCheckboxLabel = styled.label`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: ${FontWeight.Regular};
`;

export const PortalCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;

  border: 2px solid ${grey["500"]};
  border-radius: 0.25em;

  display: grid;
  height: 22px;
  /* Not removed via appearance */
  margin: 0;
  min-width: 22px;
  padding: 0;
  place-content: center;
  width: 22px;

  &::before {
    box-shadow: inset 1em 1em #fff;
    clip-path: polygon(13% 37%, 2% 48%, 37% 90%, 99% 18%, 87% 7%, 37% 65%);
    content: "";
    height: 14px;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 80ms transform ease-in-out;
    width: 14px;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    background-color: ${darkPurple.main};
    border: 2px solid transparent;
  }
`;
