import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhSpacingPx(3)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const DescriptionImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-right: ${rhSpacingPx(2)};
  }
`;

export const StyledUsageSliderUnorderedList = styled.ul`
  li {
    margin-bottom: 2px;

    &:before {
      content: "•";
      display: inline-block;
      margin-top: 1px;
      padding-right: 5px;
      vertical-align: top;
    }

    p {
      color: ${grey["600"]};
      display: inline-block;
      vertical-align: top;
      width: 93%;
    }
  }
`;

export const MonthlyUsageSliderContainer = styled.div`
  margin-bottom: ${rhSpacingPx(7)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 500px;
  }
`;
