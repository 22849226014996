import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as CloudPlug } from "@portal/images/CloudPlug.svg";
import { signUpProvideAdditionalInformationPageTranslations } from "@portal/pages/SignUpProvideAdditionalInformationPage/SignUpProvideAdditionalInformationPage.en.i18n";
import { StyledBold } from "@portal/pages/SignUpProvideAdditionalInformationPage/SignUpProvideAdditionalInformationPage.styled";
import React from "react";

const OPS_PHONE_NUMBER = "+18887749846";
const OPS_DISPLAY_PHONE_NUMBER = "1-888-7RHYTHM";

export const SignUpProvideAdditionalInformationPage = () => {
  const { translate, translateJsx } = useTranslations();
  const { tSignUpProvideAdditionalInformationPageGetSetUp } = translate(
    signUpProvideAdditionalInformationPageTranslations
  );

  const { tSignUpProvideAdditionalInformationPageContactUs } = translateJsx({
    tSignUpProvideAdditionalInformationPageContactUs: {
      phone: OPS_DISPLAY_PHONE_NUMBER,
      phoneTag: (content: string) => (
        <StyledBold>
          <RhAnchor href={`tel:${OPS_PHONE_NUMBER}`} color="textSecondary">
            {content}
          </RhAnchor>
        </StyledBold>
      ),
    },
  });

  return (
    <SignUpPageLayout hideBackButton>
      <Box mb={5}>
        <CloudPlug />
      </Box>
      <LoggedOutPageHeader
        headerText={tSignUpProvideAdditionalInformationPageGetSetUp}
      />
      <Box mb={2} mt={2}>
        <RhTypography variant="subtitle1" align="center" color="textSecondary">
          {tSignUpProvideAdditionalInformationPageContactUs}
        </RhTypography>
      </Box>
    </SignUpPageLayout>
  );
};
