import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { boxShadows } from "@design-system/constants/boxShadows";
import { darkPurple, grey, red } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Card, CardProps } from "@mui/material";
import styled from "styled-components";

const cardSize = {
  height: 158,
  width: 164,
};

interface RhIconRadioFieldProps {
  $hasErrors: boolean;
  checked: boolean;
}

const getColor = ({ checked }: { checked: boolean }) => {
  if (checked) {
    return darkPurple.main;
  }
  return grey["400"];
};

const getBorderColor = ({ $hasErrors, checked }: RhIconRadioFieldProps) => {
  if ($hasErrors) {
    return red.main;
  } else if (checked) {
    return darkPurple.main;
  } else {
    return grey["100"];
  }
};

const getHoverColor = ({ checked }: RhIconRadioFieldProps) =>
  checked ? getColor({ checked }) : darkPurple.light;

export const StyledCardLabel = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey[900]};
    line-height: 1.2;
    padding: ${rhSpacingPx(0.5)};
    text-align: center;
  }
`;

interface StyledCardProps extends CardProps {
  $hasErrors: boolean;
  checked: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  &:hover {
    border-color: ${getHoverColor};
    box-shadow: ${boxShadows.active};
    color: ${getHoverColor};
  }
  align-items: center;
  border-color: ${getBorderColor};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: ${boxShadows.default};
  color: ${getColor};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  min-height: ${cardSize.height}px;
  padding: 0 ${rhSpacingPx(1)};
  width: 100%;
`;

export const StyledIconContainer = styled(RhFlexBox)`
  align-items: center;
  height: 43px;
  justify-content: center;
  margin-top: 27px;
  width: 43px;
`;

export const StyledLabelContainer = styled(RhFlexBox)`
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  min-height: 36px;
  overflow: hidden;
`;

export const StyledRadioContainer = styled(RhFlexBox)`
  margin-top: -1px;

  .MuiSvgIcon-root {
    margin-right: 0;
  }
`;
