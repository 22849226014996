import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import {
  CompetitorsArray,
  useRepPriceComparisonCompetitors,
} from "@portal/components/RepPriceComparisonChart/useRepPriceComparisonCompetitors";
import { RHYTHM } from "@portal/constants/misc.constant";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { competitorPlanAveragePricePerTermMonth } from "@portal/helpers/competitorPlans";
import { CompetitorNames, CompetitorPlan } from "@portal/types/competitorTypes";
import { useEffect, useState } from "react";

export interface RhythmData {
  x: string;
  y: number;
}

export interface CompetitorData {
  x: CompetitorNames;
  y: number;
}

export interface RepPriceComparisonChartData {
  competitors: CompetitorData[];
  highestCompetitorPlanPrice: number;
  rhythm: RhythmData;
}

interface UseRepPriceComparisonChartHookParams {
  competitorPlans: CompetitorPlan[];
  offerSnapshots: OfferSnapshot[] | null;
}

interface UseRepPriceComparisonChartHook {
  chartData: RepPriceComparisonChartData | null;
}

export const buildChartData = (
  competitorPlans: CompetitorPlan[],
  comparisonChartOfferSnapshot: OfferSnapshot,
  competitorsArray: CompetitorsArray,
  comparisonChartTermMonths: number
) => {
  const highestCompetitorPlanPrice = Math.max(
    ...competitorPlans.map((plan) => Number(plan.price2000Kwh))
  );

  const { displayPrice } = comparisonChartOfferSnapshot;

  const rhythm = {
    x: RHYTHM,
    y: displayPrice(),
  };

  // Map our competitor data using defined competitorArray
  const competitors = competitorsArray.map((competitorName) => ({
    x: competitorName,
    y: Number(
      competitorPlanAveragePricePerTermMonth(competitorPlans)
        .get(competitorName)
        ?.get(comparisonChartTermMonths)
    ),
  }));

  // Filter out any competitors that don't have a value for this specific termMonth
  // and competitors whose average price is bigger than ours`
  const orderedCompetitors = competitors
    .filter(
      (competitorPlan) =>
        !isNaN(competitorPlan.y) && competitorPlan.y > rhythm.y
    )
    .sort((a, b) => a.y - b.y)
    .slice(-4); // Show only the four highest competitors

  return {
    competitors: orderedCompetitors,
    highestCompetitorPlanPrice,
    rhythm,
  };
};

export const useRepPriceComparisonChartData = ({
  competitorPlans,
  offerSnapshots,
}: UseRepPriceComparisonChartHookParams): UseRepPriceComparisonChartHook => {
  const { comparisonChartOfferSnapshot, comparisonChartTermMonths } =
    useRepPriceComparisonChart();
  const competitorsArray = useRepPriceComparisonCompetitors({
    competitorPlans,
    offerSnapshots,
  });
  const [chartData, setChartData] =
    useState<RepPriceComparisonChartData | null>(null);

  useEffect(() => {
    if (
      comparisonChartOfferSnapshot &&
      comparisonChartTermMonths &&
      competitorPlans.length &&
      competitorsArray.length > 0
    ) {
      const chart = buildChartData(
        competitorPlans,
        comparisonChartOfferSnapshot,
        competitorsArray,
        comparisonChartTermMonths
      );

      setChartData(chart);
    }
  }, [
    comparisonChartOfferSnapshot,
    comparisonChartTermMonths,
    competitorPlans,
    competitorsArray,
  ]);

  return { chartData };
};
