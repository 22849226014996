import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { theme } from "@design-system/theme/theme.styled";
import styled from "styled-components";

export const RepPriceComparisonFiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    align-items: flex-start;
    flex-flow: column nowrap;
    max-width: 200px;
    min-width: 150px;
  }
`;

export const RhythmPlansWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export const RhythmPlansLabel = styled.strong`
  color: ${theme.palette.grey[600]};
  font-size: 12px;
`;

export const RepPriceComparisonFiltersSelectWrapper = styled.div`
  width: 100%;
`;
