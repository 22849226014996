import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { boxShadows } from "@design-system/constants/boxShadows";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: ${trueWhite};
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  overflow: hidden;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 280px;
  }
`;

export const StyledTitle = styled(RhTypography)`
  &.MuiTypography-root {
    background-color: ${grey[300]};
    color: ${grey[50]};
    padding: ${rhSpacingPx(1)} ${rhSpacingPx(2)};
  }
`;

export const StyledBody = styled.div`
  border-bottom: 1px solid ${grey[100]};
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1.5)};
  padding: ${rhSpacingPx(2)};
  padding-top: 0;
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
`;

export const StyledFeatures = styled.div`
  border-bottom: 1px solid ${grey[100]};
  border-top: 1px solid ${grey[100]};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(1.5)} 0;
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: 0 ${rhSpacingPx(2)} ${rhSpacingPx(2)};
`;

export const StyledFooterItem = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(1)};
`;

export const StyledPurpleText = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${darkPurple[500]};
    text-decoration: underline;
  }
`;
