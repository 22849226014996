import { grey } from "@design-system/theme/palette.colors";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    background-color: ${grey["200"]};
  }

  &.MuiDivider-light {
    background-color: ${grey["100"]};
  }
`;
