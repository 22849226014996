import { UUIDType } from "@common/types/apiTypes";
import { RhError } from "@common/types/errorTypes";
import { prospectApi } from "@portal/api/prospectApi";
import { prospectQueryKeys } from "@portal/constants/querykeys.constants";
import { GetReferralCodeResponse } from "@portal/types/prospectTypes";
import { useQuery } from "@tanstack/react-query";

export const useProspectReferralCodeQuery = (
  prospectUuid: UUIDType,
  referralCode: string | null
) => {
  const query = useQuery<GetReferralCodeResponse, RhError>({
    enabled: referralCode !== null && referralCode !== "",
    queryFn: () =>
      prospectApi.getReferralCode(prospectUuid || "", referralCode || ""),
    queryKey: prospectQueryKeys.getReferralCode(
      prospectUuid || "",
      referralCode || ""
    ),
  });

  return query;
};
