import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledPaymentMethodViewCardExpiration = styled(RhTypography)`
  &.MuiTypography-root {
    margin-left: ${rhSpacingPx(1)};
  }
`;

export const StyledPaymentMethodViewCardInfo = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const StyledPaymentMethodViewSingleCardOption = styled.div`
  height: 20px;
  margin-left: ${rhSpacingPx(2)};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPaymentMethodViewCardOptions = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

export const StyledPaymentMethodViewDefaultPaymentText = styled.div`
  height: 20px;
  margin-right: auto;
`;

interface StyledPaymentMethodCardProps {
  $isCardHighlighted: boolean;
  $isDefault: boolean;
  $isDisabled: boolean;
}

export const StyledPaymentMethodCard = styled(
  RhInnerCard
)<StyledPaymentMethodCardProps>`
  border-color: ${({ $isCardHighlighted }) =>
    $isCardHighlighted ? darkPurple.main : grey[100]};
  margin-bottom: ${rhSpacingPx(2.5)};
  padding: ${({ $isDefault }) =>
    $isDefault ? `${rhSpacingPx(1.5)} ${rhSpacingPx(2.5)}` : rhSpacingPx(2.5)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "initial")};
`;
