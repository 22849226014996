import { billingApi } from "@common/api/billingApi";
import { premiseApi } from "@common/api/premiseApi";
import { formatMaskNumber } from "@common/forms/formatters";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import {
  DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { PaymentMethodViewTranslations } from "@portal/components/PaymentMethodView/PaymentMethodView.en.i18n";
import {
  StyledPaymentMethodCard,
  StyledPaymentMethodViewCardExpiration,
  StyledPaymentMethodViewCardInfo,
  StyledPaymentMethodViewCardOptions,
  StyledPaymentMethodViewDefaultPaymentText,
  StyledPaymentMethodViewSingleCardOption,
} from "@portal/components/PaymentMethodView/PaymentMethodView.styled";
import {
  PremiseQueryKeys,
  customerQueryKeys,
} from "@portal/constants/querykeys.constants";
import { useTranslations } from "@portal/hooks/useTranslations";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import {
  billingDetailsRefetch,
  paymentMethodSetAsDefault,
} from "@portal/slices/billingDetailsSlice";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface PaymentMethodProps {
  allowDelete?: boolean;
  displayOptions?: boolean;
  highlighted?: boolean;
  isOneTimePayment?: boolean;
  paymentMethod: PaymentMethod;
}

export const PaymentMethodView = ({
  paymentMethod,
  highlighted = false,
  displayOptions = false,
  allowDelete = false,
  isOneTimePayment,
}: PaymentMethodProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const premiseId = useSelector(selectCustomerPremisePreference);
  const flash = useRhFlash();

  const { translate } = useTranslations();
  const {
    tPaymentMethodViewSwitchDefaultPaymentApiError,
    tPaymentMethodViewPreferencesUpdated,
    tPaymentMethodViewDefaultPayment,
    tPaymentMethodViewDelete,
    tPaymentMethodViewSetAsDefault,
    tPaymentMethodViewOneTimePayment,
    tPaymentMethodViewDeletePaymentApiError,
    tPaymentMethodViewTurnOffAutopay,
  } = translate(PaymentMethodViewTranslations);

  const setPaymentMethodAsDefault = (paymentMethodId: string) => {
    setIsDisabled(true);

    billingApi.paymentMethods
      .default(paymentMethodId, { premiseId })
      .then(() => {
        dispatch(paymentMethodSetAsDefault({ id: paymentMethodId }));

        flash.success(tPaymentMethodViewPreferencesUpdated);
      })
      .catch((error: RhApiError) => {
        flash.error(tPaymentMethodViewSwitchDefaultPaymentApiError);
      })
      .finally(() => {
        setIsDisabled(false);
        queryClient.invalidateQueries({
          queryKey: PremiseQueryKeys.retrieve(premiseId),
        });
        queryClient.invalidateQueries({
          queryKey: customerQueryKeys.me(),
        });
      });
  };

  const deleteCard = (paymentMethodId: string) => {
    setIsDisabled(true);

    premiseApi.paymentMethods
      .delete(premiseId, paymentMethodId)
      .then(() => {
        dispatch(billingDetailsRefetch());
        flash.success(tPaymentMethodViewPreferencesUpdated);
      })
      .catch((error: RhApiError) => {
        if (
          error.data.errorCode === DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR
        ) {
          flash.error(tPaymentMethodViewTurnOffAutopay);
        } else {
          flash.error(tPaymentMethodViewDeletePaymentApiError);
        }
      })
      .finally(() => {
        setIsDisabled(false);
        queryClient.invalidateQueries({
          queryKey: PremiseQueryKeys.retrieve(premiseId),
        });
      });
  };

  return (
    <StyledPaymentMethodCard
      key={paymentMethod.id}
      data-testid={`PaymentMethod-${paymentMethod.companyName}-${paymentMethod.maskedNumber}`}
      $isDisabled={isDisabled}
      $isDefault={Boolean(paymentMethod.defaultPaymentMethod)}
      $isCardHighlighted={highlighted}
    >
      <StyledPaymentMethodViewCardInfo>
        <RhTypography variant="button">
          {paymentMethod.companyName}

          <StyledPaymentMethodViewCardExpiration
            component="span"
            variant="body1"
            color="textSecondary"
          >
            {paymentMethod.secondaryInfo}
          </StyledPaymentMethodViewCardExpiration>
        </RhTypography>

        <RhTypography variant="body1">
          {formatMaskNumber(paymentMethod.maskedNumber)}
        </RhTypography>
      </StyledPaymentMethodViewCardInfo>
      <StyledPaymentMethodViewCardOptions
        data-testid={`PaymentMethod__options__${paymentMethod.id}`}
      >
        {paymentMethod.defaultPaymentMethod && (
          <StyledPaymentMethodViewDefaultPaymentText>
            <RhTypography variant="body1" color="textSecondary">
              {tPaymentMethodViewDefaultPayment}
            </RhTypography>
          </StyledPaymentMethodViewDefaultPaymentText>
        )}

        {isOneTimePayment && (
          <StyledPaymentMethodViewDefaultPaymentText>
            <RhTypography variant="body1" color="textSecondary">
              {tPaymentMethodViewOneTimePayment}
            </RhTypography>
          </StyledPaymentMethodViewDefaultPaymentText>
        )}

        {displayOptions && !paymentMethod.defaultPaymentMethod && (
          <StyledPaymentMethodViewSingleCardOption>
            <RhTypography
              component="a"
              variant="body1"
              color="textSecondary"
              onClick={() => setPaymentMethodAsDefault(paymentMethod.id)}
            >
              {tPaymentMethodViewSetAsDefault}
            </RhTypography>
          </StyledPaymentMethodViewSingleCardOption>
        )}

        {displayOptions && allowDelete && (
          <StyledPaymentMethodViewSingleCardOption>
            <RhTypography
              component="a"
              variant="body1"
              color="textSecondary"
              onClick={() => deleteCard(paymentMethod.id)}
            >
              {tPaymentMethodViewDelete}
            </RhTypography>
          </StyledPaymentMethodViewSingleCardOption>
        )}
      </StyledPaymentMethodViewCardOptions>
    </StyledPaymentMethodCard>
  );
};
