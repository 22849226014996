import { IdType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import { userLoggedOut } from "@portal/slices/authenticationSlice";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

export type CustomerPreferencesStateType = {
  languagePreference: SupportedPortalLanguage | null;
  premiseId: IdType | null;
};

export const initialCustomerPreferencesState =
  Object.freeze<CustomerPreferencesStateType>({
    languagePreference: null,
    premiseId: null,
  });

// Handlers
export const languagePreferenceUpdatedHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<SupportedPortalLanguage>
> = (state, action) => ({
  ...state,
  languagePreference: action.payload,
});

export const premisePreferenceUpdatedHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<IdType>
> = (state, action) => ({
  ...state,
  premiseId: action.payload,
});

export const customerPreferenceUpdatedHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<CustomerPreferencesStateType>
> = (state, action) => {
  const preferencesUpdated: Partial<CustomerPreferencesStateType> = {
    languagePreference: action.payload.languagePreference,
  };

  if (!state.premiseId) {
    preferencesUpdated.premiseId = action.payload.premiseId;
  }

  return {
    ...state,
    ...preferencesUpdated,
  };
};

export const clearCustomerPreferenceHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction
> = (state, action) => {
  return { ...state, ...initialCustomerPreferencesState };
};

export const receivedCustomerHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<CustomerType>
> = (state, action) => {
  const preferencesUpdated: Partial<CustomerPreferencesStateType> = {};

  if (action.payload.languagePreference) {
    preferencesUpdated.languagePreference = action.payload.languagePreference;
  }
  if (!state.premiseId && action.payload.premises) {
    preferencesUpdated.premiseId = action.payload.premises[0].id;
  }
  return { ...state, ...preferencesUpdated };
};

const customerPreferencesReducers = {
  clearPreferences: clearCustomerPreferenceHandler,
  languagePreferenceUpdated: languagePreferenceUpdatedHandler,
  premiseUpdated: premisePreferenceUpdatedHandler,
  updateCustomerPreferences: customerPreferenceUpdatedHandler,
};

const customerPreferenceSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, clearCustomerPreferenceHandler);
  },

  initialState: initialCustomerPreferencesState,
  name: "customerPreferences",
  reducers: customerPreferencesReducers,
});

export const customerPreferencesReducer = customerPreferenceSlice.reducer;

export const customerPreferencesLanguageUpdated =
  customerPreferenceSlice.actions.languagePreferenceUpdated;
export const customerPreferencesPremiseUpdated =
  customerPreferenceSlice.actions.premiseUpdated;
export const customerPreferencesCleared =
  customerPreferenceSlice.actions.clearPreferences;

export const { updateCustomerPreferences } = customerPreferenceSlice.actions;
