import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as LeftChevron } from "@design-system/icons/LeftChevron.svg";
import { RenewalSuccess } from "@portal/components/RenewalSuccess/RenewalSuccess";
import { RenewalSummaryForm } from "@portal/components/RenewalSummaryForm/RenewalSummaryForm";
import { RenewalSummaryPlanInfo } from "@portal/components/RenewalSummaryPlanInfo/RenewalSummaryPlanInfo";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { renewalsPageOfferSnapshotAtom } from "@portal/pages/RenewalsPage/RenewalsPage.atoms";
import { renewalsSummaryPageTranslations } from "@portal/pages/RenewalsSummaryPage/RenewalsSummaryPage.en.i18n";
import {
  RenewalSummaryContainer,
  StyledBackButtonContainer,
  StyledPortalPageLayout,
  StyledTitle,
} from "@portal/pages/RenewalsSummaryPage/RenewalsSummaryPage.styled";
import { customerHomePath, renewalsPath } from "@portal/routes/routePaths";
import { useAtomValue } from "jotai";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const RenewalsSummaryPage = () => {
  const navigate = useNavigate();
  const { translate, translateJsx } = useTranslations();

  const customer = useCustomerFromContext();
  const { premise } = usePremise();
  const offerSnapshot = useAtomValue(renewalsPageOfferSnapshotAtom);

  if (!premise || !premise?.currentOrder || !offerSnapshot) {
    return <Navigate to={renewalsPath()} />;
  }

  const { tRenewalsSummaryPageSuccessButtonText, tRenewalsSummaryPageBack } =
    translate(renewalsSummaryPageTranslations);

  const { tRenewalsSummaryPageTitle } = translateJsx({
    tRenewalsSummaryPageTitle: {
      customerName: customer.fullName,
    },
  });

  if (premise.futureOrder) {
    return (
      <StyledPortalPageLayout>
        <RenewalSuccess
          order={premise.futureOrder}
          ctaPath={customerHomePath()}
          ctaText={tRenewalsSummaryPageSuccessButtonText}
        />
      </StyledPortalPageLayout>
    );
  }

  return (
    <StyledPortalPageLayout>
      <RenewalSummaryContainer>
        <StyledTitle>
          <RhTypography variant="h1">{tRenewalsSummaryPageTitle}</RhTypography>
        </StyledTitle>

        <RenewalSummaryPlanInfo
          currentOrder={premise.currentOrder}
          offerSnapshot={offerSnapshot}
          serviceAddress={premise.serviceAddress}
          customerFullName={customer.fullName}
        />

        <RenewalSummaryForm offerSnapshot={offerSnapshot} premise={premise} />

        <StyledBackButtonContainer
          data-tracking-click={{
            event: "Customer going back to renewals page",
          }}
          variant="text"
          onClick={() => navigate(renewalsPath())}
        >
          <LeftChevron />
          {tRenewalsSummaryPageBack}
        </StyledBackButtonContainer>
      </RenewalSummaryContainer>
    </StyledPortalPageLayout>
  );
};
