import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { Grid } from "@mui/material";
import { MyAccountLegalDocuments } from "@portal/components/MyAccountYourPlanCard/MyAccountLegalDocuments";
import { MyAccountOfferCard } from "@portal/components/MyAccountYourPlanCard/MyAccountOfferCard";
import { myAccountYourPlanCardTranslations } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.en.i18n";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal/components/PortalCard/PortalCard.styled";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface MyAccountYourPlanCardProps {
  orderType?: "currentOrder" | "futureOrder";
}

export const MyAccountYourPlanCard = ({
  orderType = "currentOrder",
}: MyAccountYourPlanCardProps) => {
  const { premise } = usePremise();

  const { translate } = useTranslations();

  const { tMyAccountYourPlanCardYourPlan } = translate(
    myAccountYourPlanCardTranslations
  );

  if (!premise) {
    return (
      <PortalCard>
        <PortalCardHeader>
          <PortalCardTitle>{tMyAccountYourPlanCardYourPlan}</PortalCardTitle>
        </PortalCardHeader>
        <RhCircularProgress />
      </PortalCard>
    );
  }

  const order = premise[orderType];

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{tMyAccountYourPlanCardYourPlan}</PortalCardTitle>
      </PortalCardHeader>
      {order && (
        <Grid container>
          <Grid item sm={12} md={6}>
            <MyAccountOfferCard order={order} />
          </Grid>

          <Grid item sm={12} md={6}>
            <MyAccountLegalDocuments
              premiseId={premise.id}
              orderId={order.id}
            />
          </Grid>
        </Grid>
      )}
    </PortalCard>
  );
};
