import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  oneTimePaymentMethodIdAtom,
  paymentExtensionActiveStep,
} from "@portal/components/PaymentExtension/PaymentExtension.atoms";
import { paymentExtensionTranslations } from "@portal/components/PaymentExtension/PaymentExtension.en.i18n";
import { PaymentExtensionSteps } from "@portal/components/PaymentExtension/PaymentExtension.types";
import { PaymentExtensionConfirm } from "@portal/components/PaymentExtensionConfirm/PaymentExtensionConfirm";
import { PaymentExtensionForm } from "@portal/components/PaymentExtensionForm/PaymentExtensionForm";
import { PaymentExtensionSuccess } from "@portal/components/PaymentExtensionSuccess/PaymentExtensionSuccess";
import { useFetchSinglePaymentMethodQuery } from "@portal/hooks/queries/useFetchSinglePaymentMethod.query";
import { useTranslations } from "@portal/hooks/useTranslations";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";

interface PaymentExtensionProps {
  disconnectionDueDate: string;
  pastDueBalance: string;
  paymentMethods: PaymentMethod[];
  premiseId: string;
}

export const PaymentExtension = ({
  pastDueBalance,
  paymentMethods,
  disconnectionDueDate,
  premiseId,
}: PaymentExtensionProps) => {
  const activeStep = useAtomValue(paymentExtensionActiveStep);
  const oneTimePaymentMethodId = useAtomValue(oneTimePaymentMethodIdAtom);

  const oneTimePaymentMethodQuery = useFetchSinglePaymentMethodQuery({
    enabled: oneTimePaymentMethodId !== null,
    paymentMethodId: oneTimePaymentMethodId || "",
    premiseId,
  });

  const flash = useRhFlash();
  const { translate } = useTranslations();

  const { tPaymentExtensionOneTimePaymentError } = translate(
    paymentExtensionTranslations
  );

  useEffect(() => {
    if (oneTimePaymentMethodId && oneTimePaymentMethodQuery.isError) {
      flash.error(tPaymentExtensionOneTimePaymentError);
    }
  }, [
    oneTimePaymentMethodQuery.isError,
    oneTimePaymentMethodId,
    flash,
    tPaymentExtensionOneTimePaymentError,
  ]);

  if (activeStep === PaymentExtensionSteps.confirmation) {
    return (
      <PaymentExtensionConfirm
        disconnectionDueDate={disconnectionDueDate}
        pastDueBalance={pastDueBalance}
        paymentMethods={
          oneTimePaymentMethodQuery.data
            ? [...paymentMethods, oneTimePaymentMethodQuery.data]
            : paymentMethods
        }
        premiseId={premiseId}
      />
    );
  }

  if (activeStep === PaymentExtensionSteps.success) {
    return <PaymentExtensionSuccess />;
  }

  return (
    <PaymentExtensionForm
      disconnectionDueDate={disconnectionDueDate}
      oneTimePaymentMethod={oneTimePaymentMethodQuery.data}
      pastDueBalance={pastDueBalance}
      paymentMethods={paymentMethods}
      isOneTimePaymentMethodPending={
        oneTimePaymentMethodId !== null && oneTimePaymentMethodQuery.isPending
      }
    />
  );
};
