import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  text-align: center;
`;

export const StyledNumberedList = styled.ol`
  border-radius: 10px;
  counter-reset: list;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  list-style-type: none;
  overflow: hidden;
`;

export const StyledListItemNumber = styled.span`
  color: ${trueWhite};
  display: flex;
  padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};

  &:before {
    content: counter(list) ".";
    display: inline-block;
    font-size: 3rem;
    margin: auto;
  }
`;

export const StyledListItem = styled.li`
  align-items: stretch;
  counter-increment: list;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
  min-height: 105px;

  &:nth-of-type(1) ${StyledListItemNumber} {
    background-color: ${darkPurple[300]};
  }

  &:nth-of-type(2) ${StyledListItemNumber} {
    background-color: ${darkPurple.main};
  }

  &:nth-of-type(3) ${StyledListItemNumber} {
    background-color: ${darkPurple[700]};
  }
`;

export const StyledListItemText = styled.div`
  align-items: flex-start;
  background-color: ${grey[50]};
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
  padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
  text-align: left;
`;
