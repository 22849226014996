import "chartjs-adapter-dayjs-4";

import {
  backgroundPlugin,
  customLegendPlugin,
} from "@common/components/EnergyUsageGraphV2/chartJsPlugins";
import { getChartData } from "@common/components/EnergyUsageGraphV2/getChartData";
import { getChartOptions } from "@common/components/EnergyUsageGraphV2/getChartOptions";
import { TemperatureLabelsType } from "@common/components/EnergyUsageGraphV2/types";
import {
  ChartDirection,
  ChartType,
  GraphTemperatureValue,
  TemperatureType,
  UsageType,
} from "@common/types/usageTypes";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement,
  LineController
);

const ChartContainer = styled.div`
  height: 600px;
  position: relative;
  width: 100%;
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    height: 450px;
  }
`;

interface EnergyUsageGraphLabelProps {
  costTooltipLabel: string;
  dateHourTitle?: string;
  dateTitle: string;
  defaultTierTitle?: string;
  earnedTooltipLabel?: string;
  generationLabelTitle?: string;
  generationTitle?: string;
  generationTooltipLabel?: string;
  temperatureLabels?: TemperatureLabelsType;
  usageAxisTitle: string;
  usageLabelTitle: string;
  usageTitle: string;
  usageTooltipLabel: string;
}

interface EnergyUsageGraphProps {
  chartDirection: ChartDirection;
  chartType: ChartType;
  currentTierNames?: string[];
  labels?: EnergyUsageGraphLabelProps;
  latestAvailableDate: string;
  selectedTemperatureValue?: GraphTemperatureValue;
  showConsumption?: boolean;
  showEarned: boolean;
  showGeneration: boolean;
  temperatures?: TemperatureType[];
  usageData: UsageType[];
}

export const EnergyUsageGraphV2 = (props: EnergyUsageGraphProps) => {
  const {
    chartType,
    usageData,
    chartDirection,
    currentTierNames,
    showConsumption = true,
    showEarned,
    showGeneration,
    labels: {
      costTooltipLabel = "Cost:",
      dateHourTitle = "Date/Hour:",
      dateTitle = "Date:",
      defaultTierTitle = "All-day rate",
      earnedTooltipLabel = "Surplus Credits:",
      generationTitle = "Surplus Generation",
      generationTooltipLabel = "Surplus Generation:",
      usageAxisTitle = "Usage (kWh)",
      usageTitle = "Usage",
      usageTooltipLabel = "Usage:",
      usageLabelTitle = "Usage",
      generationLabelTitle = "Surplus Generation",
      temperatureLabels = {
        average: "Average",
        averageTemp: "Average Temperature",
        high: "High",
        highTemp: "High Temperature",
        low: "Low",
        lowTemp: "Low Temperature",
        temperature: "Temperature",
        temperatureAxisTitle: "Temperature (°F)",
      },
    } = {},
    latestAvailableDate,
    selectedTemperatureValue,
    temperatures,
  } = props;

  const ref =
    useRef<
      ChartJSOrUndefined<
        "bar" | "line",
        UsageType[] | TemperatureType[],
        unknown
      >
    >();

  const hasGeneration =
    showGeneration &&
    usageData.some((usage) => Number(usage.generationKwh) > 0);

  const hasTiers = usageData.some((usage) => !!usage.consumptionTierName);

  const chartOptions = useMemo(() => {
    return getChartOptions({
      chartDirection,
      chartOptionsLabels: {
        costTooltipLabel,
        dateHourTitle,
        dateTitle,
        defaultTierTitle,
        earnedTooltipLabel,
        generationTitle,
        generationTooltipLabel,
        temperatureLabels,
        usageAxisTitle,
        usageTitle,
        usageTooltipLabel,
      },
      chartType,
      currentTierNames,
      latestAvailableDate,
      withConsumption: showConsumption,
      withEarned: showEarned,
      withGeneration: hasGeneration,
      withTemperatures: temperatures && temperatures.length > 0,
      withTiers: hasTiers,
    });
  }, [
    chartDirection,
    chartType,
    costTooltipLabel,
    dateHourTitle,
    dateTitle,
    defaultTierTitle,
    earnedTooltipLabel,
    generationTitle,
    generationTooltipLabel,
    hasGeneration,
    hasTiers,
    showEarned,
    usageAxisTitle,
    usageTitle,
    usageTooltipLabel,
    showConsumption,
    currentTierNames,
    latestAvailableDate,
    temperatures,
    temperatureLabels,
  ]);

  const chartData = useMemo(() => {
    return getChartData({
      chartDirection,
      chartType,
      defaultTierTitle,
      generationLabelTitle,
      selectedTemperatureValue,
      temperatureLabels,
      temperatures,
      usageData,
      usageLabelTitle,
      withConsumption: showConsumption,
      withGeneration: hasGeneration,
      withTiers: hasTiers,
    });
  }, [
    chartDirection,
    chartType,
    defaultTierTitle,
    generationLabelTitle,
    hasGeneration,
    hasTiers,
    usageData,
    usageLabelTitle,
    showConsumption,
    temperatures,
    selectedTemperatureValue,
    temperatureLabels,
  ]);

  useEffect(() => {
    if (ref.current) {
      ref.current.data = chartData;
      // If chartData or chartOptions change we need to force an update of the chart
      ref.current.update();

      // If we show both consumption and generation we want to make yAxis simetrical
      if (
        chartDirection === "horizontal" &&
        showConsumption &&
        showGeneration &&
        ref.current.options.scales?.y
      ) {
        const yMaxValue = Math.max(
          Math.abs(ref.current.scales.y.min),
          ref.current.scales.y.max
        );

        ref.current.options.scales.y.suggestedMax = yMaxValue;
        ref.current.options.scales.y.suggestedMin = -yMaxValue;
        ref.current.update();
      }

      // add padding to weather scale
      if (ref.current.options.scales?.weather) {
        const WEATHER_PADDING = 10;

        ref.current.options.scales.weather.min =
          ref.current.scales.weather.min - WEATHER_PADDING;
        ref.current.options.scales.weather.max =
          ref.current.scales.weather.max + WEATHER_PADDING;
        ref.current.update();
      }
    }
  }, [
    chartData,
    chartDirection,
    chartOptions,
    showConsumption,
    showGeneration,
    ref,
  ]);

  return (
    <div>
      <ChartContainer className="chart-container">
        <Chart
          type="bar"
          options={chartOptions}
          ref={ref}
          data={chartData}
          updateMode="resize"
          plugins={[backgroundPlugin, customLegendPlugin]}
        />
      </ChartContainer>
      <div id="legend-container" />
    </div>
  );
};
