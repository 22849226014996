import { api } from "@common/api/api";
import { AuthClient } from "@common/services/AuthClient.service";
import {
  CookieName,
  buildEnvCookieKey,
  removeCookieItem,
} from "@common/utils/getCookies";
import { homePath } from "@portal/routes/routePaths";
import {
  OKTA_CONFIG,
  OKTA_IDENTITY_PROVIDER_ID,
} from "@portal/settings/config";
import {
  userLoggedIn,
  userLoggedOut,
} from "@portal/slices/authenticationSlice";
import { store } from "@portal/store/store";

export class PortalAuthClient extends AuthClient {
  public override signIn(email: string, password: string) {
    return super
      .signIn(email, password)
      .then(PortalAuthClient.handleSuccessfulSignIn);
  }

  public signInWithGoogle() {
    return this.authClient.signInWithRedirect({
      idp: OKTA_IDENTITY_PROVIDER_ID,
      idpScope: ["email"],
    });
  }

  public resetPassword(payload: { password: string; recoveryToken: string }) {
    return api.customers.password
      .reset({
        ...payload,
        confirmPassword: payload.password,
      })
      .then(({ sessionToken }) => {
        return this.redirectToRedirectUri(sessionToken);
      })
      .then(PortalAuthClient.handleSuccessfulSignIn);
  }

  public createPassword(payload: {
    activationToken?: string;
    password: string;
    stateToken?: string;
  }) {
    return api.customers.password
      .set({
        ...payload,
        confirmPassword: payload.password,
      })
      .then(({ sessionToken }) => {
        return this.redirectToRedirectUri(sessionToken);
      })
      .then(PortalAuthClient.handleSuccessfulSignIn);
  }

  public signOut() {
    store.dispatch(userLoggedOut());
    return this.authClient.signOut({
      postLogoutRedirectUri: `${window.location.origin}${homePath()}`,
    });
  }

  private static handleSuccessfulSignIn() {
    removeCookieItem(buildEnvCookieKey(CookieName.ProspectUuid));
    store.dispatch(userLoggedIn());
  }
}

export const portalAuthClient = new PortalAuthClient(OKTA_CONFIG);
