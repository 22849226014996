import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { useEffect, useState } from "react";

interface UseRepPriceComparisonTermMonthsProps {
  offerSnapshots: OfferSnapshot[] | null;
}

export const useRepPriceComparisonTermMonths = ({
  offerSnapshots,
}: UseRepPriceComparisonTermMonthsProps): number[] | null => {
  const [termMonths, setTermMonths] = useState<number[]>([]);
  const { setComparisonChartTermMonths } = useRepPriceComparisonChart();

  useEffect(() => {
    if (offerSnapshots) {
      const offerSnapshotTermMonths = offerSnapshots.map(
        (offerSnapshot) => offerSnapshot.termMonths
      );

      const termMonthsArray = Array.from(new Set(offerSnapshotTermMonths)).sort(
        (a, b) => a - b
      );

      setTermMonths(termMonthsArray);

      if (termMonthsArray.includes(12)) {
        setComparisonChartTermMonths(12);
      } else if (termMonthsArray.includes(24)) {
        setComparisonChartTermMonths(24);
      } else {
        setComparisonChartTermMonths(termMonthsArray[0]);
      }
    }
  }, [offerSnapshots, setComparisonChartTermMonths]);

  return termMonths;
};
