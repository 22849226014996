import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import {
  IconContainer,
  LegalDocumentsContainer,
  StyledDescription,
  StyledPdfIcon,
  StyledPdfIconContainer,
} from "@portal/components/LegalDocuments/LegalDocuments.styled";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import {
  ActionType,
  TrackEventHandler,
} from "@portal/services/segment.service";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React from "react";
import { useSelector } from "react-redux";

export interface LegalDocumentsProps {
  flexDirection?: "row" | "column";
  inCart?: boolean;
  offerSnapshot: OfferSnapshot;
  trackEvent?: TrackEventHandler;
}

export const LegalDocuments = ({
  offerSnapshot,
  flexDirection = "row",
  trackEvent = () => null,
  inCart = false,
}: LegalDocumentsProps) => {
  const { t } = useRhIntl();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const formattedMessage = (name: string) =>
    t(`LegalDocuments.${name}` as TranslationKey);

  const makeTracking = (label: string) => () =>
    trackEvent({
      action: ActionType.click,
      label,
    });
  const trackEfl = makeTracking("contractDocumentEfl");
  const trackTos = makeTracking("contractDocumentTos");
  const trackYrac = makeTracking("contractDocumentYrac");

  const yracTitle = formattedMessage("yrac");
  const tosTitle = formattedMessage("tos");
  const eflTitle = formattedMessage("efl");

  return (
    <>
      <Box pt={inCart ? 1 : 3}>
        <RhTypography
          variant={inCart ? "h3" : "h2"}
          fontWeight={inCart ? FontWeight.Semibold : FontWeight.Bold}
        >
          {formattedMessage("contractDocuments")}
        </RhTypography>
      </Box>

      <Box pt={2}>
        <LegalDocumentsContainer
          flexDirection={isMobile ? "column" : flexDirection}
        >
          <RhFlexBox flexDirection="row">
            <IconContainer>
              <EFLLink
                onClick={trackEfl}
                offerId={offerSnapshot.id}
                locale={locale}
              >
                <StyledPdfIcon title={eflTitle} />
              </EFLLink>
            </IconContainer>
            <Box>
              <EFLLink
                onClick={trackEfl}
                offerId={offerSnapshot.id}
                locale={locale}
              >
                <RhTypography variant="body1">{eflTitle}</RhTypography>
              </EFLLink>
              <StyledDescription variant="body1" color="textSecondary">
                {formattedMessage("eflDescription")}
              </StyledDescription>
            </Box>
          </RhFlexBox>

          {!inCart && (
            <>
              <RhFlexBox flexDirection="row">
                <StyledPdfIconContainer>
                  <TOSLink
                    onClick={trackTos}
                    tosUrl={offerSnapshot.rhythmTosLink(locale)}
                  >
                    <StyledPdfIcon title={tosTitle} />
                  </TOSLink>
                </StyledPdfIconContainer>
                <Box>
                  <TOSLink
                    onClick={trackTos}
                    tosUrl={offerSnapshot.rhythmTosLink(locale)}
                  >
                    <RhTypography variant="body1">{tosTitle}</RhTypography>
                  </TOSLink>
                  <StyledDescription variant="body1" color="textSecondary">
                    {formattedMessage("tosDescription")}
                  </StyledDescription>
                </Box>
              </RhFlexBox>

              <RhFlexBox flexDirection="row">
                <IconContainer>
                  <YRACLink onClick={trackYrac} locale={locale}>
                    <StyledPdfIcon title={yracTitle} />
                  </YRACLink>
                </IconContainer>
                <Box mb={2}>
                  <YRACLink onClick={trackYrac} locale={locale}>
                    <RhTypography variant="body1">{yracTitle}</RhTypography>
                  </YRACLink>
                  <StyledDescription variant="body1" color="textSecondary">
                    {formattedMessage("yracDescription")}
                  </StyledDescription>
                </Box>
              </RhFlexBox>
            </>
          )}
        </LegalDocumentsContainer>
      </Box>
    </>
  );
};
