export const englishLanguageCode = "en";
export const spanishLanguageCode = "es";

export const AllSupportedPortalLanguages = [
  englishLanguageCode,
  spanishLanguageCode,
] as const;
export type SupportedPortalLanguage =
  (typeof AllSupportedPortalLanguages)[number];
export type SupportedPortalLanguageLabel = "Spanish" | "English";
