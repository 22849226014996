import { borders } from "@design-system/constants/borders";
import { grey } from "@design-system/theme/palette.colors";
import { Dialog, DialogProps } from "@mui/material";
import React, { FC } from "react";

interface RhDialogProps extends DialogProps {
  size?: "small" | "medium" | "large";
}

const sizeMap = {
  large: 500,
  medium: 430,
  small: 400,
};

export const RhDialog: FC<React.PropsWithChildren<RhDialogProps>> = ({
  children,
  size = "medium",
  ...restProps
}) => {
  return (
    <Dialog
      {...restProps}
      BackdropProps={{
        style: {
          background: grey[700],
          opacity: 0.65,
        },
      }}
      PaperProps={{
        style: {
          border: borders[100],
          boxShadow: "none",
          width: sizeMap[size],
        },
      }}
    >
      {children}
    </Dialog>
  );
};
