import { EnergyUsageChartMissingTranslations } from "@portal/components/EnergyUsageChartMissing/EnergyUsageChartMissing.en.i18n";
import { EnergyUsageChartMissingText } from "@portal/components/EnergyUsageChartMissing/EnergyUsageChartMissing.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { CustomerHomeSectionInfoMessageContainer } from "@portal/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import React from "react";

export const EnergyUsageChartMissing = () => {
  const { translate } = useTranslations();

  const { tEnergyUsageChartMissingDataWillAppear } = translate(
    EnergyUsageChartMissingTranslations
  );

  return (
    <CustomerHomeSectionInfoMessageContainer>
      <EnergyUsageChartMissingText variant="subtitle1" color="textSecondary">
        {tEnergyUsageChartMissingDataWillAppear}
      </EnergyUsageChartMissingText>
    </CustomerHomeSectionInfoMessageContainer>
  );
};
