import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import {
  ModalWidths,
  RhModalTitle,
} from "@design-system/components/RhModal/RhModal.styled";
import {
  RepPriceModalContainer,
  RepPriceModalTitleContainer,
} from "@portal/components/RepPriceComparisonModal/RepPriceComparisonModal.styled";
import { RepPriceComparisonPlanList } from "@portal/components/RepPriceComparisonPlanList/RepPriceComparisonPlanList";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { CompetitorPlan } from "@portal/types/competitorTypes";
import React, { FC } from "react";

interface RepPriceComparisonModalProps {
  close: () => void;
  competitorPlans: CompetitorPlan[];
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  offerSnapshot: OfferSnapshot;
  selectButtonProps: {
    onClick: () => void;
    text: string;
  };
}

export const RepPriceComparisonModal: FC<RepPriceComparisonModalProps> = ({
  close,
  competitorPlans = [],
  moreButtonProps,
  offerSnapshot,
  selectButtonProps,
}) => {
  const { t } = useRhIntl();

  if (!offerSnapshot) {
    return null;
  }

  return (
    <RhModal
      ariaLabel="Rep Price Comparison Modal"
      ariaCloseLabel="Close Modal"
      handleClose={close}
      modalId="rhRepPriceComparisonModal"
      showPortalHeader
      size={ModalWidths.md}
      withGradientBorder
    >
      <RepPriceModalContainer>
        <RepPriceModalTitleContainer>
          <RhModalTitle>{t("RepPriceComparisonModal.modalTitle")}</RhModalTitle>
        </RepPriceModalTitleContainer>
        <RepPriceComparisonPlanList
          plans={competitorPlans}
          offerSnapshot={offerSnapshot}
          selectButtonProps={selectButtonProps}
          moreButtonProps={moreButtonProps}
        />
      </RepPriceModalContainer>
    </RhModal>
  );
};
