import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { visuallyHidden } from "@design-system/theme/helpers/visuallyHidden";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  color: ${grey[900]};
  font-size: 14px;
  margin-top: ${rhSpacingPx(1)};

  caption {
    ${visuallyHidden}
  }

  th {
    font-weight: ${FontWeight.Regular};
    padding: ${rhSpacingPx(0.5)} ${rhSpacingPx(2)} ${rhSpacingPx(1)};
    text-align: left;
  }

  tbody {
    tr {
      border-top: 1px solid ${grey[100]};
    }

    td {
      padding: ${rhSpacingPx(0.5)} ${rhSpacingPx(2)};
    }
  }
`;
