import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  StyledMyAccountContactFields,
  StyledMyAccountContactLabel,
  StyledMyAccountContactReadOnlyValue,
} from "@portal/components/MyAccountContact/MyAccountContact.styled";
import { myAccountContactFormValidator } from "@portal/components/MyAccountContact/MyAccountContactFormValidator";
import { MyAccountSubmitButton } from "@portal/components/MyAccountSubmitButton/MyAccountSubmitButton";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal/components/PortalCard/PortalCard.styled";
import { useCustomerLegacyUpdateMutation } from "@portal/hooks/mutations/useCustomerLegacyUpdate.mutation";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { Customer } from "@portal/models/Customer.model";
import { MyAccountEvents } from "@portal/services/segment.service";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";

type MyAccountContactProps = {
  customer: Customer;
};

export type MyAccountContactFormValues = {
  email: string;
  phone: string;
};

export const MyAccountContact = ({ customer }: MyAccountContactProps) => {
  const { t } = useRhIntl();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();
  const customerUpdateMutation = useCustomerLegacyUpdateMutation();

  const contactFormInitialValues: MyAccountContactFormValues = {
    email: customer.email,
    phone: customer.phone,
  };

  const handleSubmit = (
    formattedValues: MyAccountContactFormValues,
    formApi: FormApi<MyAccountContactFormValues>
  ) => {
    const { email, phone } = formattedValues;

    const values: MyAccountContactFormValues = {
      email,
      phone: unmaskPhone(phone),
    };

    customerUpdateMutation.mutate(
      {
        customerData: values,
        customerId: customer.id,
      },
      {
        onError: () => {
          flash.error(t("MyAccountContact.errorSavingContactInfo"));
        },
        onSuccess: (data) => {
          formApi.initialize(formattedValues);
          flash.success(t("MyAccountContact.preferencesUpdated"));

          track({
            event: MyAccountEvents.contactInfoUpdated,
            label: "Updated contact info",
          });
        },
      }
    );
  };

  const nameLabel = t("MyAccountContact.name");

  const dateOfBirthLabel = t("MyAccountContact.dateOfBirth");
  const dateOfBirth = formatMonthDayYear(customer.dateOfBirth);

  const email = t("MyAccountContact.email");
  const phone = t("MyAccountContact.phone");
  const title = t("MyAccountContact.contact");

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>

      <Form<MyAccountContactFormValues>
        initialValues={contactFormInitialValues}
        onSubmit={handleSubmit}
        validate={myAccountContactFormValidator}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <StyledMyAccountContactFields>
              <RhFlexBox flexDirection="column">
                <StyledMyAccountContactLabel>
                  {nameLabel}
                </StyledMyAccountContactLabel>
                <StyledMyAccountContactReadOnlyValue>
                  {customer.fullName}
                </StyledMyAccountContactReadOnlyValue>
              </RhFlexBox>
              <RhFlexBox flexDirection="column">
                <StyledMyAccountContactLabel>
                  {dateOfBirthLabel}
                </StyledMyAccountContactLabel>
                <StyledMyAccountContactReadOnlyValue>
                  {dateOfBirth}
                </StyledMyAccountContactReadOnlyValue>
              </RhFlexBox>
              <RhTextField name="email" type="email" autoComplete="email">
                {email}
              </RhTextField>
              <RhTextField
                autoComplete="phone"
                format={maskPhone}
                name="phone"
                type="tel"
                placeholder="___-___-____"
                inputMode="numeric"
              >
                {phone}
              </RhTextField>
            </StyledMyAccountContactFields>
            <RhFlexBox justifyContent="flex-end">
              <MyAccountSubmitButton isMobile={isMobile} />
            </RhFlexBox>
          </form>
        )}
      />
    </PortalCard>
  );
};
