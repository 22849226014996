import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useRepPriceComparisonOfferSnapshots } from "@portal/components/RepPriceComparisonFilters//useRepPriceComparisonOfferSnapshots";
import { useRepPriceComparisonTermMonths } from "@portal/components/RepPriceComparisonFilters//useRepPriceComparisonTermMonths";
import { OfferSnapshotRadioGroup } from "@portal/components/RepPriceComparisonFilters/OfferSnapshotRadioGroup";
import {
  RepPriceComparisonFiltersContainer,
  RepPriceComparisonFiltersSelectWrapper,
  RhythmPlansLabel,
  RhythmPlansWrapper,
} from "@portal/components/RepPriceComparisonFilters/RepPriceComparisonFilters.styled";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import React, { ChangeEvent, ReactNode, useCallback } from "react";

interface TermSelectOption {
  key?: string;
  label: string | ReactNode;
  value: string;
}

const createOfferSnapshotMapById = (offerSnapshots: OfferSnapshot[] | null) => {
  if (!offerSnapshots) {
    return new Map();
  }

  return new Map(
    offerSnapshots.map((offerSnapshot) => [offerSnapshot.id, offerSnapshot])
  );
};

interface RepPriceComparisonFiltersProps {
  offerSnapshots: OfferSnapshot[] | null;
}

export const RepPriceComparisonFilters = ({
  offerSnapshots,
}: RepPriceComparisonFiltersProps) => {
  const theme = useTheme();
  const { t, tJsx } = useRhIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const {
    comparisonChartTermMonths,
    comparisonChartOfferSnapshot,
    setComparisonChartOfferSnapshot,
    setComparisonChartTermMonths,
  } = useRepPriceComparisonChart();
  const termMonths = useRepPriceComparisonTermMonths({
    offerSnapshots,
  });
  const offerSnapshotsByTermMonths = useRepPriceComparisonOfferSnapshots({
    offerSnapshots,
  });

  const offerSnapshotMap = createOfferSnapshotMapById(offerSnapshots);

  const handleTermMonthChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      const {
        target: { value },
      } = event;

      const numericalValue = Number(value);

      if (numericalValue && comparisonChartTermMonths !== numericalValue) {
        track({
          action: ActionType.changedTermMonths,
          event: EventType.enrollmentClick,
          label: "repPriceChartTermMonthsChange",
          termMonths: numericalValue,
        });

        setComparisonChartTermMonths(numericalValue);
      }
    },
    [comparisonChartTermMonths, setComparisonChartTermMonths]
  );

  const handleOfferSnapshotSelectChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      const {
        target: { value: id },
      } = event;

      if (
        id &&
        comparisonChartOfferSnapshot &&
        comparisonChartOfferSnapshot.id !== id
      ) {
        track({
          action: ActionType.changedPlan,
          event: EventType.enrollmentClick,
          label: "repPriceChartOfferSnapshotChange",
          offerSnapshotId: Number(id),
        });

        setComparisonChartOfferSnapshot(offerSnapshotMap.get(id));
      }
    },
    [
      comparisonChartOfferSnapshot,
      offerSnapshotMap,
      setComparisonChartOfferSnapshot,
    ]
  );

  const handleOfferSnapshotRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: id },
      } = event;

      if (
        id &&
        comparisonChartOfferSnapshot &&
        comparisonChartOfferSnapshot.id !== id
      ) {
        track({
          action: ActionType.changedPlan,
          event: EventType.enrollmentClick,
          label: "repPriceChartOfferSnapshotChange",
          offerSnapshotId: Number(id),
        });

        setComparisonChartOfferSnapshot(offerSnapshotMap.get(id));
      }
    },
    [
      offerSnapshotMap,
      comparisonChartOfferSnapshot,
      setComparisonChartOfferSnapshot,
    ]
  );

  const termLengthMap = new Map([[1, t("RepPriceComparisonFilters.mtm")]]);

  let termOptions: TermSelectOption[] = [];

  if (termMonths) {
    termOptions = termMonths
      .map((months) => ({
        key: `${months}`,
        label: termLengthMap.has(months)
          ? termLengthMap.get(months)
          : tJsx("RepPriceComparisonFilters.xMonths", {
              TermMonths: () => months,
            }),
        value: months.toString(),
      }))
      .sort((a, b) => Number(a.value) - Number(b.value));
  }

  let sortedOfferSnapshotsByDisplayPrice: OfferSnapshot[] = [];

  if (offerSnapshotsByTermMonths) {
    sortedOfferSnapshotsByDisplayPrice = offerSnapshotsByTermMonths.sort(
      (a, b) => a.displayPrice() - b.displayPrice()
    );
  }

  let offerSnapshotOptions: TermSelectOption[] = [];

  if (sortedOfferSnapshotsByDisplayPrice) {
    offerSnapshotOptions = sortedOfferSnapshotsByDisplayPrice.map(
      (offerSnapshot) => ({
        key: offerSnapshot.id,
        label: offerSnapshot.title,
        value: offerSnapshot.id,
      })
    );
  }

  if (!comparisonChartOfferSnapshot || !comparisonChartTermMonths) {
    return null;
  }

  return (
    <RhCard>
      <RepPriceComparisonFiltersContainer>
        <div>
          <RhSelectLabel htmlFor="rhythmPlansSelectTermLength">
            {t("RepPriceComparisonFilters.label")}
          </RhSelectLabel>

          <RhSelect
            id="rhythmPlansSelectTermLength"
            onChange={handleTermMonthChange}
            defaultValue={comparisonChartTermMonths}
          >
            {termOptions.map((option) => {
              return (
                <option value={option.value} key={option.key}>
                  {option.label}
                </option>
              );
            })}
          </RhSelect>
        </div>

        {isMobile ? (
          <RepPriceComparisonFiltersSelectWrapper>
            <RhSelectLabel htmlFor="rhythmPlansSelectRhythmPlans">
              {t("RepPriceComparisonFilters.rhythmLabel")}
            </RhSelectLabel>

            <RhSelect
              id="rhythmPlansSelectRhythmPlans"
              onChange={handleOfferSnapshotSelectChange}
            >
              {offerSnapshotOptions.map((option) => {
                return (
                  <option value={option.value} key={option.key}>
                    {option.label}
                  </option>
                );
              })}
            </RhSelect>
          </RepPriceComparisonFiltersSelectWrapper>
        ) : (
          <RhythmPlansWrapper>
            <RhythmPlansLabel>
              {t("RepPriceComparisonFilters.rhythmLabel")}
            </RhythmPlansLabel>
            <OfferSnapshotRadioGroup
              comparisonChartOfferSnapshot={comparisonChartOfferSnapshot}
              offerSnapshots={sortedOfferSnapshotsByDisplayPrice}
              handleOfferSnapshotChange={handleOfferSnapshotRadioChange}
            />
          </RhythmPlansWrapper>
        )}
      </RepPriceComparisonFiltersContainer>
    </RhCard>
  );
};
