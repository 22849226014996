import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { grey, red } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledBalanceDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  text-align: center;
`;

export const StyledBalanceDisconnectionNotice = styled(RhTypography).attrs({
  variant: "subtitle2",
})`
  &.MuiTypography-root {
    color: ${grey[400]};
    line-height: 1.2;
    margin-bottom: 0.75rem;
    text-align: center;

    span {
      color: ${red.main};
      font-weight: ${FontWeight.Semibold};
    }
  }
`;
