import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { BillBreakdownCard } from "@portal/components/BillBreakdownCard/BillBreakdownCard";
import { BillBreakdownInvoiceCard } from "@portal/components/BillBreakdownInvoiceCard/BillBreakdownInvoiceCard";
import { BillComparisonCard } from "@portal/components/BillComparisonCard/BillComparisonCard";
import { BillEnergyUsageTemperatureCard } from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard";
import { BillFactorsCard } from "@portal/components/BillFactorsCard/BillFactorsCard";
import { BillingSection } from "@portal/components/BillingSection/BillingSection";
import { BillTipsCard } from "@portal/components/BillTipsCard/BillTipsCard";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { useInvoiceHistoryQuery } from "@portal/hooks/queries/useInvoiceHistory";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { billBreakdownPageTranslations } from "@portal/pages/BillBreakdownPage/BillBreakdownPage.en.i18n";
import {
  BillBreakdownForContainer,
  BillBreakdownPageMainSection,
  StyledBillBreakdownPage,
  StyledRhSelect,
  StyledStickySection,
} from "@portal/pages/BillBreakdownPage/BillBreakdownPage.styled";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import { billBreakdownPath } from "@portal/routes/routePaths";
import { InvoiceTypeEnum } from "@portal/types/invoiceTypes";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const BillBreakdownPage = () => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const navigate = useNavigate();

  const { premise } = usePremise();

  const invoiceHistoryQuery = useInvoiceHistoryQuery({
    premiseId: premise?.id ?? "",
    queryOptions: {
      enabled: Boolean(premise),
    },
  });

  const { translate } = useTranslations();
  const { tBillBreakdownPageTitle } = translate(billBreakdownPageTranslations);

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalBillBreakdownGraph } = featureFlagClient.getFlags([
    ["portalBillBreakdownGraph", false],
  ]);

  if (invoiceHistoryQuery.isPending) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (!premise || invoiceHistoryQuery.isError) {
    return <Error500Page />;
  }

  return (
    <PortalPageLayout>
      <StyledBillBreakdownPage>
        <BillBreakdownPageMainSection>
          <BillBreakdownForContainer>
            <RhTypography variant="h1">{tBillBreakdownPageTitle}</RhTypography>
            <StyledRhSelect
              value={invoiceId}
              onChange={(event) => {
                navigate(billBreakdownPath(event.target.value));
              }}
            >
              {invoiceHistoryQuery.data?.results
                .filter(
                  (invoice) => invoice.invoiceType === InvoiceTypeEnum.ENERGY
                )
                .map((invoice) => {
                  return (
                    <option key={invoice.id} value={invoice.id}>
                      {invoice.formattedInvoiceServicePeriod}
                    </option>
                  );
                })}
            </StyledRhSelect>
          </BillBreakdownForContainer>
          <BillBreakdownInvoiceCard invoiceId={invoiceId} premise={premise} />
          <BillFactorsCard invoiceId={invoiceId} />
          <BillComparisonCard invoiceId={invoiceId} />
          {portalBillBreakdownGraph.value ? (
            <BillEnergyUsageTemperatureCard invoiceId={invoiceId} />
          ) : null}
          <BillBreakdownCard invoiceId={invoiceId} />
          <BillTipsCard invoiceId={invoiceId} />
        </BillBreakdownPageMainSection>
        <StyledStickySection>
          <BillingSection showBillBreakdownLink={false} />
        </StyledStickySection>
      </StyledBillBreakdownPage>
    </PortalPageLayout>
  );
};
