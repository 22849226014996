import { darkPurple, grey } from "@design-system/theme/palette.colors";
import styled from "styled-components";

interface StepperIconContainerProps {
  $active?: boolean;
  $completed?: boolean;
}

export const StyledStepperIconContainer = styled.div<StepperIconContainerProps>`
  color: ${(props) =>
    props.$active || props.$completed ? darkPurple.main : grey[200]};
  height: 14px;
  margin: 0 auto;
  width: 14px;
`;
