import { useAcquisitionCampaignFromQueryParam } from "@portal/hooks/useAcquisitionCampaignFromQueryParam";
import {
  SIGN_UP_LEAD_GEN_SUB_PATHNAME,
  SIGN_UP_RESUME_SUB_PATHNAME,
  SignUpStepType,
  signUpBasePath,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { selectVisitedSignUpPages } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const SignUpOutlet = () => {
  const { visitedSteps } = useSelector(selectVisitedSignUpPages);
  const location = useLocation();
  const basePath = signUpBasePath();

  const { pathname } = location;
  const subpath = pathname.replace(basePath, "").split("/");
  const requestedStep = subpath[1] as SignUpStepType;

  const isLeadGen =
    subpath[1] === SIGN_UP_LEAD_GEN_SUB_PATHNAME ||
    subpath[1] === SIGN_UP_RESUME_SUB_PATHNAME;
  const isVisitedStep = visitedSteps.includes(requestedStep as SignUpStepType);
  const isAvailability = requestedStep === "availability";

  useAcquisitionCampaignFromQueryParam();

  if (isLeadGen || isVisitedStep || isAvailability) {
    return <Outlet />;
  }

  return <Navigate to={signUpStepPath("availability")} />;
};
