import { rhWindow } from "@common/utils/rhWindow";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import {
  SupportedPortalLanguage,
  generateIntl,
} from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { useSelector } from "react-redux";

export const useDynamicIntl = (): IntlShape => {
  const customerLanguagePreference = useSelector<
    PortalStoreState,
    SupportedPortalLanguage | null
  >(selectCustomerLanguagePreference);

  const [intl, setIntl] = useState(
    generateIntl(customerLanguagePreference || navigator?.language)
  );

  useEffect(() => {
    const newIntl = generateIntl(
      customerLanguagePreference || navigator?.language
    );

    setIntl(newIntl);

    const handleLanguageChange = () => {
      if (!customerLanguagePreference) {
        const language = navigator?.language;

        setIntl(generateIntl(language));
      }
    };

    rhWindow.addEventListener("languagechange", handleLanguageChange);

    return () => {
      rhWindow.removeEventListener("languagechange", handleLanguageChange);
    };
  }, [customerLanguagePreference]);

  return intl;
};
