import { TypedEntries } from "@common/types/typeUtils";
import { AreaNotServiceableModal } from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModal";
import { CustomerProvider } from "@portal/components/CustomerProvider/CustomerProvider";
import { DisconnectDeviceModal } from "@portal/components/DisconnectDeviceModal/DisconnectDeviceModal";
import { EnergyBreakdownModal } from "@portal/components/EnergyBreakdownModal/EnergyBreakdownModal";
import { useLoader } from "@portal/components/Loader/useLoader";
import { getModalsByPriority } from "@portal/components/ModalsManager/getModalsByPriority";
import {
  ModalsAtomType,
  initialModalsAtom,
} from "@portal/components/ModalsManager/ModalsManager.atoms";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { MultipleSignUpFlowTabsModal } from "@portal/components/MultipleSignUpFlowTabsModal/MultipleSignUpFlowTabsModal";
import { MultipleTdspModal } from "@portal/components/MultipleTdspModal/MultipleTdspModal";
import { OfferSnapshotLearnMore } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore";
import { OfferSnapshotThermostatRequiredModal } from "@portal/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal";
import { ProductAddonsOptInConfirmationModal } from "@portal/components/ProductAddonsOptInConfirmationModal/ProductAddonsOptInConfirmationModal";
import { RenewalReminderModal } from "@portal/components/RenewalReminderModal/RenewalReminderModal";
import { SwitchEBillToPaperlessModal } from "@portal/components/SwitchEBillToPaperlessModal/SwitchEBillToPaperlessModal";
import { SwitchPrintToPaperlessModal } from "@portal/components/SwitchPrintToPaperlessModal/SwitchPrintToPaperlessModal";
import { UpdateDevicePremiseModal } from "@portal/components/UpdateDevicePremiseModal/UpdateDevicePremiseModal";
import React, { ReactPortal } from "react";
import { createPortal } from "react-dom";

type ModalsMappedKeys = keyof typeof initialModalsAtom;
type ModalsPriorityKeys = ReturnType<typeof getModalsByPriority>[number];

type SameModalKeys = ModalsMappedKeys extends ModalsPriorityKeys
  ? ModalsPriorityKeys extends ModalsMappedKeys
    ? true
    : false
  : false;

// Dummy check to ensure modalsPriority has all modals
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: SameModalKeys = true;

export const ModalsManager = () => {
  const { modals, removeModal } = useModals();
  const { isLoading } = useLoader();

  if (isLoading) {
    return null;
  }

  const modalEntries = Object.entries(modals) as TypedEntries<typeof modals>;

  const modalsPrioritized = modalEntries
    .filter(([, value]) => value !== null)
    .sort((a, b) => {
      const modalsByPriority = getModalsByPriority();

      return modalsByPriority.indexOf(a[0]) - modalsByPriority.indexOf(b[0]);
    });

  if (modalsPrioritized.length === 0) {
    return null;
  }

  const [modalKey, modalProps] = modalsPrioritized[0];

  let Modal: JSX.Element | null;

  switch (modalKey) {
    case "areaNotServiceable": {
      Modal = (
        <AreaNotServiceableModal
          handleClose={() => removeModal("areaNotServiceable")}
        />
      );
      break;
    }
    case "contractsAdded": {
      const props = modalProps as NonNullable<ModalsAtomType["contractsAdded"]>;

      Modal = (
        <ProductAddonsOptInConfirmationModal
          contractIdsAdded={props.ids}
          handleClose={() => {
            removeModal("contractsAdded");
          }}
        />
      );
      break;
    }
    case "disconnectDevice": {
      Modal = (
        <DisconnectDeviceModal
          handleClose={() => removeModal("disconnectDevice")}
        />
      );
      break;
    }
    case "energyBreakdown": {
      const props = modalProps as NonNullable<
        ModalsAtomType["energyBreakdown"]
      >;

      Modal = (
        <EnergyBreakdownModal
          offerSnapshotId={props.offerSnapshotId}
          handleClose={() => {
            removeModal("energyBreakdown");
          }}
        />
      );
      break;
    }
    case "multipleSignUpFlowTabs": {
      Modal = <MultipleSignUpFlowTabsModal />;
      break;
    }
    case "multipleTdsp": {
      const props = modalProps as NonNullable<ModalsAtomType["multipleTdsp"]>;

      Modal = (
        <MultipleTdspModal
          zipCode={props.zipCode}
          handleClose={() => {
            removeModal("multipleTdsp");
          }}
          prospectUUID={props.prospectUUID}
        />
      );
      break;
    }

    case "offerSnapshotLearnMore": {
      const props = modalProps as NonNullable<
        ModalsAtomType["offerSnapshotLearnMore"]
      >;

      Modal = (
        <OfferSnapshotLearnMore
          handleClose={() => {
            props.onClose?.();
            removeModal("offerSnapshotLearnMore");
          }}
          offerSnapshotUUID={props.offerSnapshotUUID}
          onEnrollClick={props.onEnrollClick}
          selectPlanCtaText={props.selectPlanCtaText}
        />
      );

      break;
    }

    case "offerSnapshotThermostatRequired": {
      const props = modalProps as NonNullable<
        ModalsAtomType["offerSnapshotThermostatRequired"]
      >;

      Modal = (
        <OfferSnapshotThermostatRequiredModal
          handleClose={() => {
            removeModal("offerSnapshotThermostatRequired");
          }}
          onEnrollClick={props.onEnrollClick}
          offerSnapshot={props.offerSnapshot}
        />
      );

      break;
    }

    case "switchEBillToPaperless": {
      const { premiseId } = modalProps as NonNullable<
        ModalsAtomType["switchEBillToPaperless"]
      >;

      Modal = (
        <SwitchEBillToPaperlessModal
          handleClose={() => {
            removeModal("switchEBillToPaperless");
          }}
          premiseId={premiseId}
        />
      );
      break;
    }

    case "switchPrintToPaperless": {
      const { premiseId, showRewardsIncentive } = modalProps as NonNullable<
        ModalsAtomType["switchPrintToPaperless"]
      >;

      Modal = (
        <SwitchPrintToPaperlessModal
          handleClose={() => {
            removeModal("switchPrintToPaperless");
          }}
          premiseId={premiseId}
          showRewardsIncentive={showRewardsIncentive}
        />
      );
      break;
    }

    case "renewalReminder": {
      Modal = (
        <CustomerProvider>
          <RenewalReminderModal
            handleClose={() => {
              removeModal("renewalReminder");
            }}
          />
        </CustomerProvider>
      );
      break;
    }

    case "updateDevicePremise": {
      Modal = (
        <CustomerProvider>
          <UpdateDevicePremiseModal
            handleClose={() => removeModal("updateDevicePremise")}
          />
        </CustomerProvider>
      );
      break;
    }

    default: {
      // Ensure all modal cases have been covered
      modalKey satisfies never;

      Modal = null;
      break;
    }
  }

  if (Modal === null) {
    return null;
  }

  return createPortal(Modal, document.body) as ReactPortal;
};
