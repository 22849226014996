import {
  ModalsAtomType,
  initialModalsAtom,
  modalsStateAtom,
} from "@portal/components/ModalsManager/ModalsManager.atoms";
import { OneKeyAtMostOf } from "@portal/types/oneKeyAtMostOfTypes";
import { useAtom } from "jotai";
import { useCallback } from "react";

export const useModals = () => {
  const [modalsAtom, setModalsAtom] = useAtom(modalsStateAtom);
  const addModal = useCallback(
    (data: OneKeyAtMostOf<ModalsAtomType>) => {
      setModalsAtom((previousModal) => {
        return {
          ...previousModal,
          ...data,
        };
      });
    },
    [setModalsAtom]
  );

  const removeModal = useCallback(
    (modalKey: keyof ModalsAtomType) => {
      setModalsAtom((previousModal) => {
        return {
          ...previousModal,
          [modalKey]: initialModalsAtom[modalKey],
        };
      });
    },
    [setModalsAtom]
  );

  return {
    addModal,
    modals: modalsAtom,
    removeModal,
  };
};
