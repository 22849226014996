import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { oneClickRenewalApi } from "@portal/api/oneClickRenewalApi";
import { PortalQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseOneClickRenewalOfferSnapshots {
  enabled: boolean;
  renewalToken: string;
}

export const useOneClickRenewalOfferSnapshots = ({
  enabled,
  renewalToken,
}: UseOneClickRenewalOfferSnapshots) => {
  const query = useQuery({
    enabled,
    queryFn: () => oneClickRenewalApi.allRenewableOfferSnapshots(renewalToken),
    queryKey: PortalQueryKeys.oneClickRenewalOfferSnapshots(renewalToken),
    select: (data) => {
      return {
        ...data,
        myAccountOfferSnapshots: data.myAccountOfferSnapshots.map(
          (offerSnapshot) => new OfferSnapshot(offerSnapshot)
        ),
        oneClickRenewalOfferSnapshot: new OfferSnapshot(
          data.oneClickRenewalOfferSnapshot
        ),
      };
    },
  });

  return query;
};
