import { CSSProperties } from "react";
import styled, { css } from "styled-components";

interface DisclaimerContainerProps {
  textAlign: CSSProperties["textAlign"];
}

export const DisclaimerContainer = styled.div<DisclaimerContainerProps>`
  margin: 2rem auto;
  max-width: 100ch;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`;
