import { RhButton } from "@design-system/components/RhButton/RhButton";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as RightArrow } from "@design-system/icons/RightArrow.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { guestPayBasePath } from "@portal/routes/routePaths";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const useSignInGoToGuestPayTranslation = () => {
  const { t } = useRhIntl();

  const tTitle = t("SignInGoToGuestPay.title");
  const tButtonCta = t("SignInGoToGuestPay.buttonCta");

  return {
    tButtonCta,
    tTitle,
  };
};

const StyledSignInGoToGuestPay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${rhSpacingPx(3)};
  width: 100vw;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 400px;
  }
`;

const StyledButtonCta = styled.span`
  display: inline-block;
  margin-right: ${rhSpacingPx(1)};
`;

const StyledTitle = styled.h1`
  font-size: 18px;
  font-weight: ${FontWeight.Regular};
  margin-bottom: ${rhSpacingPx(3)};
  text-align: center;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    font-size: 24px;
  }
`;

const StyledGoToButton = styled(RhButton)`
  &.MuiButton-root {
    &.MuiButton-sizeSmall {
      padding: ${rhSpacingPx(2)} ${rhSpacingPx(4)};
    }
  }
`;

export const SignInGoToGuestPay = () => {
  const { tButtonCta, tTitle } = useSignInGoToGuestPayTranslation();
  const navigate = useNavigate();

  return (
    <StyledSignInGoToGuestPay>
      <StyledTitle>{tTitle}</StyledTitle>
      <StyledGoToButton
        data-tracking-click={{ event: "Guest Pay Flow Started" }}
        color="primary"
        size="small"
        variant="outlined"
        onClick={() => {
          track({
            action: ActionType.click,
            event: EventType.quickPay,
            label: "Guest Pay Flow Started",
          });
          navigate(guestPayBasePath());
        }}
      >
        <StyledButtonCta>{tButtonCta}</StyledButtonCta>
        <RightArrow aria-hidden />
      </StyledGoToButton>
    </StyledSignInGoToGuestPay>
  );
};
