import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as MapPin } from "@design-system/icons/MapPin.svg";
import {
  OFFER_CONTAINER_WIDTH_4CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { AppSource } from "@portal/enums/QueryParamValues.enum";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpOfferPageTranslations } from "@portal/pages/SignUpOfferPage/SignUpOfferPage.en.i18n";
import {
  StyledChangeZip,
  StyledHeaderWrapper,
  StyledPageBackground,
} from "@portal/pages/SignUpOfferPage/SignUpOfferPage.styled";
import { SignUpOfferPageContent } from "@portal/pages/SignUpOfferPage/SignUpOfferPageContent";
import { SignUpOfferPageHeader } from "@portal/pages/SignUpOfferPage/SignUpOfferPageHeader";
import { enrollOffersPath, signUpStepPath } from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";

export interface SignUpOfferFormValues {
  estimatedMonthlyUsage: number;
  offersnapshotId: IdType;
}

export const SignUpOfferPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const { translate } = useTranslations();

  const { serviceAddress } = useSelector(selectSignUpState);

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalNewPlansPage } = featureFlagClient.getFlags([
    ["portalNewPlansPage", false],
  ]);

  const { appSource, prospectUuid } = useSelector(selectSignUpState);

  const comesFromEnergyTexas = appSource === AppSource.MarketingEnergyTexas;

  const { recordVisitor, value: portalNewPlansPageValue } = portalNewPlansPage;

  useEffect(() => {
    recordVisitor();
  }, [recordVisitor]);

  const {
    tSignUpOfferPageEnergyTexasDisclaimer,
    tSignUpOfferPageYourZipcode,
    tSignUpOfferPageUpdate,
  } = translate(signUpOfferPageTranslations);

  if (portalNewPlansPageValue && prospectUuid) {
    return <Navigate to={enrollOffersPath(prospectUuid)} />;
  }

  if (portalNewPlansPageValue) {
    return <RhCircularProgress />;
  }

  return (
    <SignUpPageLayout hideBackButton maxWidth={OFFER_CONTAINER_WIDTH_4CARD}>
      <StyledHeaderWrapper>
        {comesFromEnergyTexas ? (
          <RhTypography variant="body1" color="textSecondary" fontWeight="Bold">
            {tSignUpOfferPageEnergyTexasDisclaimer}
          </RhTypography>
        ) : null}
        <StyledChangeZip>
          <MapPin /> {tSignUpOfferPageYourZipcode}{" "}
          <b>{serviceAddress.zipCode}</b>{" "}
          <Link to={signUpStepPath("availability")}>
            {tSignUpOfferPageUpdate}
          </Link>
        </StyledChangeZip>
        <SignUpOfferPageHeader isMobile={isMobile} />
      </StyledHeaderWrapper>
      <SignUpOfferPageContent isMobile={isMobile} />
      <StyledPageBackground />
    </SignUpPageLayout>
  );
};
