import { ajax } from "@common/services/ajax";
import { UUIDType } from "@common/types/apiTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { prospectUrls } from "@portal/api/prospectUrls";
import {
  GetReferralCodeResponse,
  ProspectUpdateRequestType,
} from "@portal/types/prospectTypes";

export interface ProspectCreateRequest {
  acquisitionCampaign?: string | null;
  acquisitionContent?: string | null;
  acquisitionMedium?: string | null;
  acquisitionSource?: string | null;
  featureFlagUserId?: string | null;
  languagePreference?: string | null;
  rcid?: string | null;
  referralCode?: string | null;
  segmentAnonId?: string | null;
  zipCode?: string | null;
}

export const prospectApi = {
  create: (body: ProspectCreateRequest): Promise<ProspectType> => {
    return ajax.post(prospectUrls.create(), body);
  },
  getReferralCode: (
    prospectUuid: UUIDType,
    code: string
  ): Promise<GetReferralCodeResponse> => {
    return ajax.get(prospectUrls.getReferralCode(prospectUuid, code));
  },
  retrieve: (prospectUuid: UUIDType): Promise<ProspectType> => {
    return ajax.get<ProspectType>(prospectUrls.retrieve(prospectUuid));
  },
  update: ({
    uuid,
    data,
  }: {
    data: ProspectUpdateRequestType;
    uuid: UUIDType;
  }) => ajax.patch<null>(prospectUrls.update(uuid), data),
};
