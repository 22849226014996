import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { competitorPlanAveragePricePerTermMonth } from "@portal/helpers/competitorPlans";
import { CompetitorNames, CompetitorPlan } from "@portal/types/competitorTypes";
import { useEffect, useState } from "react";

interface UseRepPriceComparisonCompetitorsProps {
  competitorPlans: CompetitorPlan[];
  offerSnapshots: OfferSnapshot[] | null;
}

export type CompetitorsArray = CompetitorNames[] | [];

export const useRepPriceComparisonCompetitors = ({
  offerSnapshots,
  competitorPlans,
}: UseRepPriceComparisonCompetitorsProps): CompetitorsArray => {
  const [competitorsArray, setCompetitorsArray] = useState<CompetitorsArray>(
    []
  );

  useEffect(() => {
    if (competitorPlans && offerSnapshots) {
      const filteredOfferSnapshots =
        offerSnapshots?.filter(
          (offerSnapshot) => !offerSnapshot.solarEligible
        ) ?? [];

      // Get the lowest displayPrice (energy vs rhythmKwh rate)
      const lowestOfferSnapshotPrice = Math.min(
        ...filteredOfferSnapshots.map((offerSnapshot) =>
          offerSnapshot.displayPrice()
        )
      );

      // Get all the competitor plan averages mapped by competitor/termMonths
      const competitorPlansMap =
        competitorPlanAveragePricePerTermMonth(competitorPlans);

      // Iterate through the map to find average plan prices lower than our lowest price, and then delete that key from the map
      for (const [key, termMonths] of competitorPlansMap) {
        for (const [, averagePrice] of termMonths) {
          if (
            !averagePrice ||
            Number(averagePrice) < lowestOfferSnapshotPrice
          ) {
            competitorPlansMap.delete(key);
          }
        }
      }

      // Take the map we just transformed and output it to a CompetitorsArray
      setCompetitorsArray(Array.from(competitorPlansMap.keys()));
    }
  }, [offerSnapshots, competitorPlans]);

  return competitorsArray;
};
