import { ediApi } from "@common/api/ediApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import axios from "axios";
import { useEffect } from "react";

export const useServiceStartDates = (offerSnapshotId?: IdType) => {
  const [response, { setSuccess, setPending, setFailure }] =
    useAjaxState<string[]>();

  const source = axios.CancelToken.source();
  const cancelPrevRequest = () => {
    source.cancel("Operation canceled by the user.");
  };

  useEffect(() => {
    if (response.requestMonitor.isPending) {
      cancelPrevRequest();
    }

    if (!offerSnapshotId) {
      return;
    }

    setPending();

    ediApi
      .getServiceStartDates(offerSnapshotId)
      .then(setSuccess)
      .catch(setFailure);

    return cancelPrevRequest;
  }, [offerSnapshotId]);

  return response;
};
