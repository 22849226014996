import { useCookie } from "@common/hooks/useCookie";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { CookieName } from "@common/utils/getCookies";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useLanguagePreference = () => {
  const [djangoLanguage, updateDjangoLanguage] = useCookie(
    CookieName.DjangoLanguage,
    "en"
  );
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  useEffect(() => {
    if (locale !== djangoLanguage) {
      updateDjangoLanguage(locale, 180);
    }
  }, [djangoLanguage, locale, updateDjangoLanguage]);
};
