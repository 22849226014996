import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const SignUpPlansPageRepPriceComparisonChartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${rhSpacingPx(4)};
  max-width: 1100px;
  width: 100%;
`;
