import { useOktaAuth } from "@okta/okta-react";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { useOfferSnapshotQuery } from "@portal/hooks/queries/useOfferSnapshot.query";
import { useGetCustomerRewards } from "@portal/hooks/rewardsHooks";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { usePremise } from "@portal/hooks/usePremise";
import { useEffect } from "react";

export enum SwitchPrintToPaperlessModalStates {
  HIDDEN = "hidden",
  SHOW = "show",
}

export const useSwitchPrintToPaperlessModal = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated = false } = authState || {};
  const { premise } = usePremise();
  const { rewards } = useGetCustomerRewards();
  const { cookies } = usePortalCookies();

  const { rhSwitchPrintToPaperlessModal } = cookies;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise?.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise?.currentOrder?.offersnapshotId),
    },
  });

  const { addModal } = useModals();

  const offerSnapshot = offerSnapshotQuery.data;

  useEffect(() => {
    if (!isAuthenticated || !premise || !rewards || !offerSnapshot) {
      return;
    }

    if (premise.isPrint && rhSwitchPrintToPaperlessModal === "show") {
      const hasPaperlessOrEBillReward = rewards.history.some(
        (reward) => reward.name === "eBill" || reward.name === "Paperless"
      );

      const showRewardsIncentive =
        !hasPaperlessOrEBillReward &&
        !offerSnapshot.isAutoPayPaperlessDiscountOffer;

      addModal({
        switchPrintToPaperless: {
          premiseId: premise.id,
          showRewardsIncentive,
        },
      });
    }
  }, [
    addModal,
    isAuthenticated,
    offerSnapshot,
    premise,
    rewards,
    rhSwitchPrintToPaperlessModal,
  ]);

  return null;
};
