import {
  CookieName,
  EnvCookie,
  getCookieByKey,
  removeCookieItem,
  setCookieItem,
} from "@common/utils/getCookies";
import { useCallback, useState } from "react";

type UseCookie = [
  cookie: string,
  updateCookie: (
    value: string,
    numberOfDays: number,
    numberOfHours?: number
  ) => void,
  removeCookie: () => void,
];

export const useCookie = (
  key: CookieName | EnvCookie,
  defaultValue: string
): UseCookie => {
  const getCookie = () => getCookieByKey(key) || defaultValue;
  const [cookie, setCookie] = useState<string>(getCookie());

  const updateCookie = useCallback(
    (value: string, numberOfDays: number, numberOfHours: number = 0) => {
      setCookie(value);
      setCookieItem(key, value, numberOfDays, numberOfHours);
    },
    [key]
  );

  const removeCookie = useCallback(() => {
    removeCookieItem(key);
    setCookie("");
  }, [key]);

  return [cookie, updateCookie, removeCookie];
};
