import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { UUIDType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { ProductTierGrid } from "@portal/components/ProductTierGrid/ProductTierGrid";
import { useSignUpProductTiers } from "@portal/hooks/queries/useSignUpProductTiers.query";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType, LabelType } from "@portal/services/segment.service";
import { signUpSlice } from "@portal/slices/signUpSlice";
import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledSignUpProductTiers = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 4rem;
  width: 100%;
`;

const StyledLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 400px;

  > * {
    margin: auto;
  }
`;

const StyledNextButton = styled(RhButton)`
  &.MuiButton-root {
    max-width: ${LOGGED_OUT_FORM_WIDTH}px;
    width: 100%;
  }
`;

export const SignUpProductTiers: FC = () => {
  const { t } = useRhIntl();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const productTiersQuery = useSignUpProductTiers();
  const { featureFlagClient } = useFeatureFlagClient();

  const dispatch = useDispatch();

  const { productPrices } = useSelector(selectSignUpState);

  const onConfirmSelection = useCallback(() => {
    signUpClickNextStepHandler({
      nextStep: "summary",
      signUpData: {
        productPrices,
      },
      track: false,
    });

    trackEvent({
      action: ActionType.clickedNextPage,
      label: LabelType.ConfirmedProductAddOnSelection,
    });
  }, [signUpClickNextStepHandler, trackEvent, productPrices]);

  if (productTiersQuery.isError) {
    return null;
  }

  if (productTiersQuery.isPending) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress />
      </StyledLoadingContainer>
    );
  }

  const handleTierCardClick = (priceId: UUIDType) => {
    if (productPrices.includes(priceId)) {
      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalRemovedProductAddOnPlan
      );

      dispatch(signUpSlice.actions.removeProductPrice(priceId));
    } else {
      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalSelectedProductAddOnPlan
      );

      dispatch(signUpSlice.actions.addProductPrice(priceId));
    }
  };

  return (
    <StyledSignUpProductTiers>
      <ProductTierGrid
        productTiers={productTiersQuery.data}
        onTierSelected={handleTierCardClick}
        selectedProductPriceIds={productPrices}
      />

      <StyledNextButton
        data-tracking-click={{
          event: "Prospect going to from product addons step to next step",
        }}
        variant="contained"
        color="primary"
        size="large"
        onClick={onConfirmSelection}
        disabled={productPrices.length === 0}
      >
        {t("SignUpProductTiers.nextButton")}
      </StyledNextButton>
    </StyledSignUpProductTiers>
  );
};
