import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { OFFER_CONTAINER_WIDTH_3CARD } from "@design-system/theme/style.constant";
import { Box } from "@mui/material";
import { StyledOfferFilterTabs } from "@portal/components/OfferFilterTabs/OfferFilterTabs.styled";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

export enum OfferFilterCategories {
  All = "all",
  Featured = "featured",
}

interface OfferFilterTabsSelectOption {
  key?: string;
  label: string | ReactNode;
  value: string;
}

interface OfferFilterTabsProps {
  currentValue?: string;
  hasFeatured?: boolean;
  isMobile?: boolean;
  offerTermMonths: number[];
}

function getIndexOfInitialTab(
  selectedTermMonthsTab: OfferFilterCategories | string | null,
  offerFiltersArray: OfferFilterTabsSelectOption[],
  offerTermMonths: number[]
) {
  if (selectedTermMonthsTab === OfferFilterCategories.All) {
    return offerFiltersArray.length - 1;
  }

  if (offerTermMonths.includes(Number(selectedTermMonthsTab))) {
    return offerFiltersArray.findIndex(
      (item) => item.value === selectedTermMonthsTab
    );
  }
  return 0;
}

export const OfferFilterTabs = ({
  offerTermMonths,
  isMobile,
  currentValue,
  hasFeatured,
}: OfferFilterTabsProps) => {
  const { t, tJsx } = useRhIntl();
  const dispatch = useDispatch();
  const { selectedTermMonthsTab } = useSelector(selectSignUpState);

  const termLengthMap = new Map([[1, t("OfferFilterTabs.monthToMonth")]]);

  const featuredFilter: OfferFilterTabsSelectOption = {
    label: t("OfferFilterTabs.featuredFilter"),
    value: OfferFilterCategories.Featured,
  };

  const allFilter: OfferFilterTabsSelectOption = {
    label: t("OfferFilterTabs.allFilter"),
    value: OfferFilterCategories.All,
  };

  const termFilters: OfferFilterTabsSelectOption[] = offerTermMonths
    .map((termMonths: number) => ({
      key: `${termMonths} months`,
      label: termLengthMap.has(termMonths)
        ? termLengthMap.get(termMonths)
        : tJsx("OfferFilterTabs.xMonths", { TermMonths: () => termMonths }),
      value: termMonths.toString(),
    }))
    .sort((a, b) => Number(a.value) - Number(b.value));

  const offerFiltersArray = [...termFilters, allFilter];

  if (hasFeatured) {
    offerFiltersArray.unshift(featuredFilter);
  }

  const initialSelected = getIndexOfInitialTab(
    selectedTermMonthsTab,
    offerFiltersArray,
    offerTermMonths
  );

  const trackFilterClick = (value: string) => {
    let formattedValue = `${value} months`;

    if (value === "featured" || value === "all") {
      formattedValue = value;
    }

    if (value === "1") {
      formattedValue = "month-to-month";
    }

    track({
      action: ActionType.click,
      category: "onboarding.plans.termFilter",
      event: EventType.enrollmentClick,
      label: "Clicked Term Filter Tab",
      value: formattedValue,
    });
  };

  const handleClick = (value: string) => {
    dispatch(
      setSignUpInfo({
        selectedTermMonthsTab: value,
      })
    );
    trackFilterClick(value);
  };

  return (
    <Box
      pb={isMobile ? 4 : 2}
      px={3}
      maxWidth="100%"
      width={isMobile ? "100%" : OFFER_CONTAINER_WIDTH_3CARD}
    >
      {isMobile ? (
        <Box maxWidth={310} margin="0 auto">
          <RhSelectLabel htmlFor="offerFilterTabsSelect">
            {t("OfferFilterTabs.selectLabel")}
          </RhSelectLabel>

          <RhSelect
            id="offerFilterTabsSelect"
            onChange={(e) => {
              const { value } = e.target;

              if (value) {
                handleClick(value as string);
              }
            }}
          >
            {offerFiltersArray.map((option) => {
              return (
                <option value={option.value} key={option.key}>
                  {option.label}
                </option>
              );
            })}
          </RhSelect>
        </Box>
      ) : (
        <StyledOfferFilterTabs
          align="center"
          initialSelected={initialSelected}
          contained
        >
          {offerFiltersArray.map(({ label, value }) => (
            <RhRoundTabItem
              key={value}
              label={label}
              onClick={() => {
                handleClick(value);
              }}
            />
          ))}
        </StyledOfferFilterTabs>
      )}
    </Box>
  );
};
