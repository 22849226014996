import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { PaymentExtension } from "@portal/components/PaymentExtension/PaymentExtension";
import { PaymentExtensionUnableToCollect } from "@portal/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremisePaymentMethodsQuery } from "@portal/hooks/queries/usePremisePaymentMethods.query";
import { usePremise } from "@portal/hooks/usePremise";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import React from "react";

export const PaymentExtensionPage = () => {
  const { premise, requestMonitor: premiseRequestMonitor } = usePremise();

  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    enabled: premise !== null,
    premiseId: premise?.id || "",
  });

  const premiseMethodsQuery = usePremisePaymentMethodsQuery({
    premiseId: premise?.id || "",
    queryOptions: {
      enabled: !!premise,
    },
  });

  if (
    premiseRequestMonitor.isPending ||
    accountSummaryQuery.isPending ||
    premiseMethodsQuery.isPending
  ) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (premiseRequestMonitor.didFail || accountSummaryQuery.isError) {
    return (
      <PortalPageLayout>
        <Error500Page />
      </PortalPageLayout>
    );
  }

  if (premise?.isNoCheckOrAch && premise.isNoCreditOrDebitCard) {
    return <PaymentExtensionUnableToCollect />;
  }

  const accountSummary = accountSummaryQuery.data;

  return (
    <PaymentExtension
      disconnectionDueDate={
        premise?.latestDisconnectNotice?.disconnectionDate || ""
      }
      pastDueBalance={accountSummary.pastDueBalance}
      paymentMethods={premiseMethodsQuery.data?.paymentMethods || []}
      premiseId={premise?.id || ""}
    />
  );
};
