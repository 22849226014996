import { prospectApi } from "@common/api/prospectApi";
import { IdType } from "@common/types/apiTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { isBot } from "@common/utils/isBot";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import {
  ManuallySetSignUpIdAction,
  SetSignUpInfoAction,
  createProspect,
  prospectFetch,
  prospectReceived,
  prospectRefetch,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import { convertSignUpStatePayloadToProspectType } from "@portal/utils/signUpHelpers";
import { captureException, withScope } from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import {
  SagaReturnType,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

const DEBOUNCE_DELAY_MS = 250;

export function* fetchProspectWorker({
  payload: id,
}: ManuallySetSignUpIdAction) {
  if (id) {
    const prospect: ProspectType = yield call(prospectApi.retrieve, id);

    if (prospect) {
      yield put(prospectReceived(prospect));
    }
  }
}

export function* refetchProspectWorker() {
  const prospectId: IdType = yield select(selectProspectId);

  // eslint-disable-next-line no-useless-catch
  try {
    const prospect: ProspectType = yield call(prospectApi.retrieve, prospectId);

    yield put(prospectReceived(prospect));
  } catch (err) {
    // This catch is here to remind us that we might want
    // to do something more than just notify Sentry
    throw err;
  }
}

export function* updateProspectWorker({ payload }: SetSignUpInfoAction) {
  if (isBot() || isEmpty(payload)) {
    // If this is a bot, don't create a prospect -- there's no chance
    // it'll sign up!
    return;
  }

  const prospectId: SagaReturnType<typeof selectProspectId> = yield select(
    selectProspectId
  );

  const prospectData = convertSignUpStatePayloadToProspectType(payload);

  if (prospectId && !isEmpty(prospectData)) {
    // eslint-disable-next-line no-useless-catch
    try {
      yield call(prospectApi.update, prospectId, prospectData);
    } catch (err) {
      withScope(() => {
        captureException(err);
      });
    }
  }
}

export function* createProspectWorker({ payload }: SetSignUpInfoAction) {
  const { featureFlagUserId, segmentAnonId } = payload;

  if (isBot()) {
    // If this is a bot, don't create a prospect -- there's no chance
    // it'll sign up!
    return;
  }
  try {
    const prospect: ProspectType = yield call(prospectApi.create, {
      featureFlagUserId,
      segmentAnonId,
    });

    yield put(
      prospectReceived({
        featureFlagUserId: prospect.featureFlagUserId,
        id: prospect.id,
        uuid: prospect.uuid,
      })
    );
  } catch (err) {
    yield put(prospectReceived({ id: "" }));
    throw err;
  }
}

export function* fetchProspectWatcher() {
  yield debounce(DEBOUNCE_DELAY_MS, prospectFetch.type, fetchProspectWorker);
}

export function* refetchProspectWatcher() {
  yield takeLatest(prospectRefetch.type, refetchProspectWorker);
}

export function* updateProspectWatcher() {
  yield takeEvery(setSignUpInfo.type, updateProspectWorker);
}

export function* createProspectWatcher() {
  yield takeLatest(createProspect.type, createProspectWorker);
}
