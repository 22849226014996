import { addressStates } from "@common/constants/addressStates.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import {
  EMAIL_VALIDATION_REGEX,
  SSN_VALIDATION_REGEX,
  ZIPCODE_VALIDATION_REGEX,
} from "@common/constants/regex.constant";
import { unmaskPhone } from "@common/forms/phone.mask";
import { globalIntl } from "@common/i18n/globalIntl";
import { ZuoraIdType } from "@common/types/apiTypes";
import { CreditScoreEvaluationType } from "@common/types/creditCheckTypes";
import { EmailValidator } from "commons-validator-js";
import dayjs from "dayjs";

export type FinalFormFieldValidator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>,
> = (value: InputValueType | null, allValues: FormState) => string | undefined;

export type Validator<
  InputValueType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormState extends Record<string, any> = Record<string, any>,
> = (
  value: InputValueType | null,
  allValues?: FormState,
  messageId?: string
) => string | undefined;

export type ValidationDictionary<FormState extends object> = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FinalFormFieldValidator<any, FormState>[]
>;

export const isPositiveNumeric: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!/^[.\d]+$/.test(value)) {
    return globalIntl.messages["validator.isNotAPositiveNumber"] as string;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired: Validator<any> = (
  value,
  _allValues,
  messageId = "validator.required"
) => {
  if (!value) {
    return globalIntl.messages[messageId] as string;
  }
};

export const isValidSlug: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (/\s/g.test(value)) {
    return globalIntl.messages["validator.slugMustContainNoSpaces"] as string;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPaymentMethodRequired: Validator<ZuoraIdType> = (
  value,
  allValues,
  messageId = "validator.required"
) => {
  if (!allValues?.creditCheckSummary) {
    return globalIntl.messages["validator.creditCheckIsRequired"] as string;
  }
  if (
    !value &&
    (allValues?.autopay ||
      (allValues?.creditCheckSummary.depositAmount ?? 0) > 0)
  ) {
    return globalIntl.messages[messageId] as string;
  }
};

export const isValidCreditCheck: Validator<CreditScoreEvaluationType> = (
  value,
  prospect,
  _messageId = ""
) => {
  if (!value) {
    return globalIntl.messages["validator.creditCheckIsRequired"] as string;
  }
  if (prospect?.bypassedCreditCheck) {
    return undefined;
  }
  if (value?.ssnRequired && !value?.ssnProvided) {
    return globalIntl.messages["validator.creditCheckIsIncomplete"] as string;
  }
};

export const isValidZipCode: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!ZIPCODE_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidZipCode"] as string;
  }
};

export const isValidUSState: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (
    !addressStates.find(
      ({ abbreviation }) => abbreviation.toLowerCase() === value?.toLowerCase()
    )
  ) {
    return globalIntl.messages["validator.invalidUSState"] as string;
  }
};

export const isValidDateTodayOrFuture: Validator<string> = (value) => {
  if (!value) {
    return;
  }
  const date = dayjs(value, ISO8601_DATE_FORMAT, true);
  const today = dayjs().startOf("day");

  if (!date.isValid()) {
    return globalIntl.messages["validator.invalidDateFormat"] as string;
  }
  if (date.isBefore(today)) {
    return globalIntl.messages["validator.invalidDateValue"] as string;
  }
};

export const isValidAdultBirthday: Validator<string> = (value) => {
  if (!value) {
    return;
  }
  const dob = dayjs(value, [DOB_DATE_FORMAT, ISO8601_DATE_FORMAT], true);

  if (!dob.isValid()) {
    return globalIntl.messages[
      "validator.invalidBirthdayFieldDateFormat"
    ] as string;
  }

  const today = dayjs().startOf("day");
  const earliest18thBirthday = today.subtract(18, "year");

  if (dob.isAfter(earliest18thBirthday)) {
    return globalIntl.messages["validator.invalidBirthday"] as string;
  }
};

export const isSameOrAfterDate = (valueDate: string, earliestDate: string) => {
  // Same day is acceptable
  const first = dayjs(earliestDate);
  const last = dayjs(valueDate);

  if (!earliestDate || !first.isValid()) {
    throw new Error("Comparison date is invalid");
  }
  if (!valueDate || !last.isValid()) {
    throw new Error("Date to check is invalid");
  }
  if (first.isAfter(last) && !first.isSame(last)) {
    return globalIntl.messages["validator.mustBeAfterStartDate"] as string;
  }
};

export const isAfterDate = (valueDate: string, earliestDate: string) => {
  // Same day is acceptable
  const first = dayjs(earliestDate);
  const last = dayjs(valueDate);

  if (!earliestDate || !first.isValid()) {
    throw new Error("Comparison date is invalid");
  }
  if (!valueDate || !last.isValid()) {
    throw new Error("Date to check is invalid");
  }
  if (last.isSame(first) || first.isAfter(last)) {
    return globalIntl.messages["validator.mustBeAfterStartDate"] as string;
  }
};

export const isValidPhoneNumber: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (unmaskPhone(value).length !== 10) {
    return globalIntl.messages["validator.invalidPhoneNumber"] as string;
  }
};

export const isValidEmail: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!EMAIL_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidEmail"] as string;
  }

  const emailValidator = new EmailValidator();

  if (!emailValidator.isValid(value)) {
    return globalIntl.messages["validator.invalidEmail"] as string;
  }
};

export const isValidConfirmationEmail = (
  value: string,
  comparisonValue: string
) => {
  if (value !== comparisonValue) {
    return globalIntl.messages["validator.invalidConfirmEmail"] as string;
  }
};

export const isValidOneOrBothRequired = (
  valueOne: string,
  valueTwo: string
) => {
  if (!valueOne && !valueTwo) {
    return "One or both values required.";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidUSAddress: ValidationDictionary<Record<string, any>> = {
  addressLine1: [isRequired],
  city: [isRequired],
  state: [isRequired, isValidUSState],
  zipCode: [isRequired, isValidZipCode],
};

export const isEqual = <T>(
  value: T,
  comparisonValue: T,
  errorMessage: string
) => {
  if (value !== comparisonValue) {
    return errorMessage;
  }
};

export const isNotEqual = <T>(
  value: T,
  comparisonValue: T,
  errorMessage: string
) => {
  if (value === comparisonValue) {
    return errorMessage;
  }
};

export const mustBeFalse = (value: boolean | null) =>
  isEqual<boolean | null>(value, false, "must be false.");

export const isValidSSN: Validator<string | undefined> = (
  value,
  formValues
) => {
  if (formValues?.runCreditCheckType === "skipCheck") {
    return;
  }

  if (!value || !SSN_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages["validator.invalidSSN"] as string;
  }
};

export const isAllCaps: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (value !== value.toUpperCase()) {
    return globalIntl.messages["validator.allCapsRequired"] as string;
  }
};
