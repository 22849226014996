import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as InformationIcon } from "@design-system/icons/InformationIcon.svg";
import { darkPurple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledFlexBoxColumn = styled.div<{ $inDialog: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.25)};
  padding-bottom: ${({ $inDialog }) => ($inDialog ? rhSpacingPx(0.5) : 0)};
`;

export const StyledFlexBoxRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
`;

export const StyledPerKWHWrap = styled.div`
  display: inline;
  padding-left: ${rhSpacingPx(0.75)};
`;

export const StyledPrice = styled(RhTypography).attrs({
  component: "span", // mui "as" version
  variant: "h1",
})`
  &.MuiTypography-root {
    font-size: 2rem;
    font-weight: ${FontWeight.Bold};
    line-height: 1;
  }
`;

export const StyledInformationIcon = styled(InformationIcon)`
  color: ${darkPurple.main};

  &:hover {
    color: ${darkPurple["300"]};
  }
`;

export const StyledOfferCaption = styled(RhTypography)`
  &.MuiTypography-root {
    line-height: 1.2;
  }
`;

export const OfferRateAndEstimateTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
`;
