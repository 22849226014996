import { useRhIntl } from "@portal/hooks/useRhIntl";
import { ReactComponent as LightSwitch } from "@portal/images/LightSwitch.svg";
import { StyledLightSwitch } from "@portal/pages/AccountCreationPendingPage/AccountCreationPendingPage.styled";
import { BaseErrorPage } from "@portal/pages/BaseErrorPage/BaseErrorPage";
import React from "react";

export const AccountCreationPendingPage = () => {
  const { t } = useRhIntl();
  const header = t("AccountCreationPendingPage.accountBeingCreated");
  const message = t("AccountCreationPendingPage.checkBackSoon");

  const illustration = (
    <StyledLightSwitch>
      <LightSwitch title={header} />
    </StyledLightSwitch>
  );

  return (
    <BaseErrorPage
      header={header}
      message={message}
      illustration={illustration}
      showLinkToHome={false}
    />
  );
};
