import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { QuickPay } from "@portal/components/QuickPay/QuickPay";
import React from "react";

export const QuickPayPage = () => {
  return (
    <LoggedOutPageLayout>
      <QuickPay />
    </LoggedOutPageLayout>
  );
};
