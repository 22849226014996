import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { atom } from "jotai";

interface ContractAddedModal {
  ids: UUIDType[];
}

interface EnergyBreakdown {
  offerSnapshotId: IdType;
}

interface MultipleTdsp {
  prospectUUID: UUIDType;
  zipCode: string;
}

interface OfferSnapshotLearnMore {
  offerSnapshotUUID: UUIDType;
  onClose?: () => void;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
  selectPlanCtaText: string;
}

interface OfferSnapshotThermostatRequired {
  offerSnapshot: OfferSnapshot;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
}

interface SwitchEBillToPaperless {
  premiseId: IdType;
}

interface SwitchPrintToPaperless {
  premiseId: IdType;
  showRewardsIncentive: boolean;
}

export interface ModalsAtomType {
  areaNotServiceable: true | null;
  contractsAdded: ContractAddedModal | null;
  disconnectDevice: true | null;
  energyBreakdown: EnergyBreakdown | null;
  multipleSignUpFlowTabs: true | null;
  multipleTdsp: MultipleTdsp | null;
  offerSnapshotLearnMore: OfferSnapshotLearnMore | null;
  offerSnapshotThermostatRequired: OfferSnapshotThermostatRequired | null;
  renewalReminder: true | null;
  switchEBillToPaperless: SwitchEBillToPaperless | null;
  switchPrintToPaperless: SwitchPrintToPaperless | null;
  updateDevicePremise: true | null;
}

export const initialModalsAtom: ModalsAtomType = {
  areaNotServiceable: null,
  contractsAdded: null,
  disconnectDevice: null,
  energyBreakdown: null,
  multipleSignUpFlowTabs: null,
  multipleTdsp: null,
  offerSnapshotLearnMore: null,
  offerSnapshotThermostatRequired: null,
  renewalReminder: null,
  switchEBillToPaperless: null,
  switchPrintToPaperless: null,
  updateDevicePremise: null,
};

export const modalsStateAtom = atom<ModalsAtomType>(initialModalsAtom);
