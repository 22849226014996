import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSignUpOfferSnapshots = () => {
  const [response, { setSuccess, setPending, setFailure }] =
    useAjaxState<OfferSnapshotType[]>();

  const { dunsNumber, campaignSlug, prospectId } =
    useSelector(selectSignUpState);
  const dispatch = useDispatch();

  const source = axios.CancelToken.source();
  const cancelPrevRequest = () => {
    source.cancel("Operation canceled by the user.");
  };

  useEffect(() => {
    if (response.requestMonitor.isPending) {
      cancelPrevRequest();
    }

    if (!dunsNumber || !prospectId) {
      return cancelPrevRequest;
    }

    setPending();

    api.pricing.offerSnapshots
      .search(dunsNumber, campaignSlug ?? "", prospectId)
      .then(({ campaignPromo, offerSnapshots }) => {
        setSuccess(offerSnapshots);
        dispatch(setSignUpInfo({ campaignPromo }));
      })
      .catch(setFailure);

    return cancelPrevRequest;
  }, [dunsNumber, campaignSlug, prospectId]);

  return response;
};
