import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import Switch from "@mui/material/Switch";
import styled from "styled-components";

export const StyledToggleSwitch = styled(Switch)`
  &.MuiSwitch-root {
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    flex-shrink: 0;
    height: 20px;
    overflow: hidden;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 40px;

    & .MuiButtonBase-root {
      color: ${grey[100]};
      left: 1px;
      padding: 0;
      position: absolute;
      top: 1px;
    }

    & .MuiSwitch-track {
      background-color: ${grey[300]};
      border-radius: 10px;
      opacity: 1;
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: ${darkPurple[500]};
      opacity: 1;
    }

    & .MuiSwitch-thumb {
      color: ${trueWhite};
      height: 18px;
      width: 18px;
    }
  }
`;
