import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const RepPriceComparisonSelectPlanCardContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  justify-content: center;
`;

export const OfferSnapshotTitle = styled.h3`
  text-align: center;
  text-wrap: balance;
`;

export const StyledPerKWHSection = styled.div`
  display: inline;
  padding-left: ${rhSpacingPx(0.3)};
`;
