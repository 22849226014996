import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalToggle } from "@portal/components/PortalToggle/PortalToggle";
import styled from "styled-components";

export const BalancedText = styled(RhTypography)`
  text-align: center;
  text-wrap: balance;
`;

export const LegalText = styled(RhTypography)`
  width: 75%;
`;

export const AutoPayFormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(5)};
  justify-content: space-between;
  margin-top: ${rhSpacingPx(3)};
`;

export const AutoPayToggle = styled(PortalToggle)`
  display: flex;
  flex-direction: row;
  font-size: 16px;

  font-weight: ${FontWeight.Semibold};
  justify-content: space-between;
  width: 100%;
`;

export const LoadingIndicator = styled.div`
  left: 10%;
  position: absolute;
  top: 25%;
`;

export const AutoPayModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
`;

export const AutoPayForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;
