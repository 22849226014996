import { UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { GuestPayQueryKeys } from "@portal/constants/querykeys.constants";
import { QUERY_STALE_TIME_DEFAULT_MEDIUM } from "@portal/constants/queryOptions.constants";
import { GuestPayAccountSummaryType } from "@portal/types/guestPayTypes";
import { useQuery } from "@tanstack/react-query";

export const useGuestPayAccountBalanceByToken = ({
  initialData = undefined,
  token = "",
}: {
  initialData?: GuestPayAccountSummaryType;
  token: UUIDType | undefined;
}) => {
  const query = useQuery<GuestPayAccountSummaryType>({
    enabled: Boolean(token),
    initialData,
    queryFn: () => portalApi.guestPay.accountSummaryByToken(token),
    queryKey: GuestPayQueryKeys.accountBalance(token),
    // We don't want to refetch on error as this is considered isPending on refetch
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_MEDIUM,
  });

  return query;
};
