export const translations: Record<string, string> = {
  "common.password.minimumRequirements":
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  "navigation.title.rhythm": "GotRhythm",
  "rhythm.button.save": "Save",
  "rhythm.input.cancel": "Cancel",
  "rhythm.input.confirm": "Confirm",
  "rhythm.input.enroll": "Enroll",
  "rhythm.input.ok": "OK",
  "rhythm.input.submit": "Submit",
  "rhythm.input.today": "Today",
  "validator.allCapsRequired": "Must be all capital letters.",
  "validator.creditCheckIsIncomplete":
    "The credit check needs more information before it is complete.",
  "validator.creditCheckIsRequired": "Credit check is required.",
  "validator.invalidBirthday": "You must be 18 years or older to sign up.",
  "validator.invalidBirthdayFieldDateFormat":
    "Please enter a valid date (MM/DD/YYYY).",
  "validator.invalidConfirmEmail": "Emails must match.",
  "validator.invalidDateFormat": "Please enter a valid date (YYYY-mm-dd).",
  "validator.invalidDateValue": "Please enter a date in the future.",
  "validator.invalidEmail": "Invalid Email.",
  "validator.invalidPhoneNumber": "Invalid Phone Number.",
  "validator.invalidSSN": "Invalid Social Security Number",
  "validator.invalidUSState": "Invalid US State.",
  "validator.invalidZipCode": "Invalid Zipcode.",
  "validator.isNotAPositiveNumber": "Is not a positive number.",
  "validator.mustBeAfterStartDate": "Must be after start date",
  "validator.required": "Some required information is missing.",
  "validator.slugMustContainNoSpaces": "Slug cannot contain any spaces.",
};
