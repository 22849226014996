import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { DatePickerToolbar, MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

export const StyledInputContainer = styled.div`
  .MuiTextField-root {
    width: 100%;
  }
`;

export const StyledDatePickerToolbar = styled(DatePickerToolbar)`
  &.MuiPickersToolbar-root {
    background-color: ${darkPurple.main};
    font-size: 12px;
    & .MuiTypography-root {
      color: ${trueWhite};
      font-size: 20px;
    }
  }
`;

export const datePickerLayoutSxStyles = {
  "& .MuiButtonBase-root, & .MuiDayCalendar-weekDayLabel, & .MuiPickersCalendarHeader-labelContainer, & .MuiPickersDay-root.Mui-selected":
    {
      fontFamily,
    },
  "& .MuiDialogActions-root .MuiButtonBase-root": {
    borderRadius: "1000px",
    color: darkPurple.main,
    fontWeight: "bold",
    padding: `${rhSpacingPx(1)} ${rhSpacingPx(3)}`,
    textTransform: "initial",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    "&:hover": {
      backgroundColor: `${darkPurple.main}`,
    },
    backgroundColor: `${darkPurple.main} !important`,
  },
  "& .MuiPickersDay-today.MuiPickersDay-root:not(.Mui-selected)": {
    border: 0,
  },
  "& .MuiPickersSlideTransition-root": {
    minHeight: "200px",
  },
};

export const StyledMobileDatePicker = styled(MobileDatePicker)`
  & .MuiInputBase-input {
    border: 1px solid ${grey[200]};
    border-radius: 4px;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    font-weight: ${FontWeight.Medium};
    padding: ${rhSpacingPx(1.5)};
  }
  & .MuiInputBase-root {
    &:focus-visible {
      outline: 1px solid ${darkPurple.main};
    }
    :focus-visible {
      outline: 1px solid ${darkPurple.main};
    }
  }
  & .MuiInputBase-input,
  & .MuiButtonBase-root,
  & .MuiPickersDay-day {
    font-family: ${fontFamily};
  }
`;

export const StyledLabel = styled.label`
  color: ${grey[600]};
  font-weight: ${FontWeight.Bold};
  padding-bottom: ${rhSpacingPx(0.25)};
`;
