import { prospectUrls } from "@common/api/prospectUrls";
import { ajax } from "@common/services/ajax";
import { CreditScoreResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import {
  GetCustomerRewardsResponseType,
  ProspectCreatePostDataType,
  ProspectCreditCheckRequestType,
  ProspectSearchRequestType,
  ProspectTrackEnteredSignupPostDataType,
  ProspectTrackServiceUnavailablePostDataType,
  ProspectType,
} from "@common/types/prospectTypes";

export const prospectApi = {
  create: <R = ProspectType>(
    prospectData: ProspectCreatePostDataType
  ): Promise<R> => {
    return ajax.post<R, ProspectCreatePostDataType>(
      prospectUrls.create(),
      prospectData
    );
  },
  creditScoreEvaluation: <R = CreditScoreResponseType>(
    customerData: ProspectCreditCheckRequestType
  ): Promise<R> => {
    return ajax.post<R, Partial<CustomerType>>(
      prospectUrls.creditScoreEvaluation(),
      customerData
    );
  },
  getReferralCode: (
    prospectId: IdType,
    code: string
  ): Promise<GetCustomerRewardsResponseType> => {
    return ajax.get(prospectUrls.getReferralCode(prospectId, code));
  },
  retrieve: <R = ProspectType>(prospectId: IdType | UUIDType): Promise<R> => {
    return ajax.get<R>(prospectUrls.retrieve(prospectId));
  },
  search: (
    queryParams?: ProspectSearchRequestType
  ): Promise<ProspectType[]> => {
    return ajax.get<ProspectType[]>(prospectUrls.list(), {
      params: queryParams,
    });
  },
  trackEnteredSignup: (id: IdType | null, email: string): Promise<void> => {
    if (!id) {
      return Promise.resolve();
    }
    return ajax.post<void, ProspectTrackEnteredSignupPostDataType>(
      prospectUrls.trackEnteredSignup(id),
      { email }
    );
  },
  trackServiceUnavailable: ({
    email,
    id,
  }: {
    email: string;
    id: IdType | null;
  }): Promise<void> => {
    if (!id) {
      return Promise.resolve();
    }
    return ajax.post<void, ProspectTrackServiceUnavailablePostDataType>(
      prospectUrls.trackServiceUnavailable(id),
      { email }
    );
  },
  update: <R = void>(
    prospectId: IdType,
    prospectData: ProspectCreatePostDataType
  ): Promise<R> => {
    return ajax.patch<R, ProspectCreatePostDataType>(
      prospectUrls.update(prospectId),
      prospectData
    );
  },
  validatePhoneNumber: (phoneNumber: string) => {
    return ajax.post(prospectUrls.validatePhoneNumber(), {
      phone_number: phoneNumber,
    });
  },
};
