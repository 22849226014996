import { boxShadows } from "@design-system/constants/boxShadows";
import {
  darkPurple,
  grey,
  red,
  trueBlack,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

const cardSize = {
  height: 158,
  width: 164,
};

interface RhIconRadioFieldProps {
  $checked: boolean;
  $hasErrors: boolean;
}

const getColor = ({ $checked }: { $checked: boolean }) => {
  if ($checked) {
    return darkPurple.main;
  }
  return trueBlack;
};

const getBorderColor = ({ $checked, $hasErrors }: RhIconRadioFieldProps) => {
  if ($hasErrors) {
    return red.main;
  } else if ($checked) {
    return darkPurple.main;
  } else {
    return grey["100"];
  }
};

const getHoverColor = ({ $checked, $hasErrors }: RhIconRadioFieldProps) => {
  if ($hasErrors) {
    return red.main;
  } else if ($checked) {
    return darkPurple.main;
  } else {
    return darkPurple.main;
  }
};

interface StyledCardProps {
  $checked: boolean;
  $hasErrors: boolean;
}

export const StyledCard = styled.div<StyledCardProps>`
  align-items: center;
  border-color: ${getBorderColor};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: ${boxShadows.default};
  color: ${getColor};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  min-height: ${cardSize.height}px;
  padding: 0;
  width: 100%;

  &:hover {
    border-color: ${getHoverColor};
    box-shadow: ${boxShadows.active};
    color: ${getColor};
  }
`;

export const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: ${rhSpacingPx(4)};
  height: 60px;
  justify-content: center;
  padding-top: ${rhSpacingPx(1)};

  > * {
    margin: auto;
    max-width: 100%;
  }
`;

export const StyledLabelContainer = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: ${rhSpacingPx(1.25)} ${rhSpacingPx(1)};
  width: 100%;
`;
