import { formatCurrency, formatToCents } from "@common/utils/dataFormatters";
import { isDeviceTouchScreen } from "@common/utils/isDeviceTouchScreen";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { theme } from "@design-system/theme/theme.styled";
import { renderLogo } from "@portal/components/RepPriceComparisonChart/renderLogo";
import {
  VictoryLabelPerKwh,
  VictoryLabelWrapper,
  VictoryTooltipGradientWrapper,
  VictoryTooltipWrapper,
} from "@portal/components/RepPriceComparisonChart/RepPriceComparisonChart.styled";
import {
  CompetitorData,
  RepPriceComparisonChartData,
} from "@portal/components/RepPriceComparisonChart/useRepPriceComparisonChartData";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC, useState } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLine,
  VictoryStack,
  VictoryTooltip,
  VictoryTooltipProps,
} from "victory";

const chartText = {
  fontFamily:
    '"Averta", "Helvetica Neue", Helvetica, Arial, "color-emoji", sans-serif',
  fontSize: 6,
  lineHeight: 1.25,
};

const chartStyles = {
  axis: {
    axis: {
      fill: "none",
      stroke: theme.palette.grey[100],
      strokeWidth: 1,
    },
    grid: {
      fill: "none",
      stroke: theme.palette.grey[100],
    },
    tickLabels: chartText,
  },
  chartText,
};

const barLabels = {
  ...chartStyles.chartText,
  fontSize: 12,
  fontWeight: FontWeight.Semibold,
};

const animation = {
  duration: 500,
  onLoad: { duration: 500 },
};

const LogoTick = (props: VictoryLabelProps) => {
  return (
    <foreignObject
      x={props.x ? props.x - 25 : props.x}
      y={props.y}
      width={50}
      height={23}
    >
      {renderLogo(props.text)}
    </foreignObject>
  );
};

interface Datum {
  text?: string | string[];
  x: number;
  y: number;
}

const LabelText = (props: Datum) => {
  const { t } = useRhIntl();

  return (
    <foreignObject
      dx="0"
      x={props.x - 15}
      y={props.y - 20}
      width="100px"
      height="27px"
    >
      <VictoryLabelWrapper>
        <strong>{props.text}</strong>
        <VictoryLabelPerKwh>
          {t("RepPriceComparisonChart.perKwh")}
        </VictoryLabelPerKwh>
      </VictoryLabelWrapper>
    </foreignObject>
  );
};

const AnnualSavingsTooltip = (props: VictoryTooltipProps) => {
  const { t } = useRhIntl();
  const { y: priceDifference } = props.datum as Datum;
  const { x, y } = props;

  const savings = formatCurrency(
    (priceDifference / 100) * DEFAULT_AVERAGE_MONTHLY_USAGE * 12
  );

  return (
    <foreignObject
      x={x ? x - 50 : x}
      y={y ? y - 60 : y}
      width="100px"
      height="100px"
      style={{
        height: "100px",
        width: "100px",
      }}
    >
      <VictoryTooltipGradientWrapper>
        <VictoryTooltipWrapper>
          <strong>{t("RepPriceComparisonChart.annualSavingsTooltip")}</strong>
          <span>{savings}</span>
        </VictoryTooltipWrapper>
      </VictoryTooltipGradientWrapper>
    </foreignObject>
  );
};

type CompetitorLabelAndTooltipProps = VictoryLabelProps &
  VictoryTooltipProps & {
    cost: string;
    isTooltipActive: boolean;
    samePrice: boolean;
  };

const CompetitorLabelAndTooltip = (props: CompetitorLabelAndTooltipProps) => {
  const { cost, samePrice, isTooltipActive = false } = props;
  const { x, y } = props.datum as Datum;

  const mobileTooltipProps = isDeviceTouchScreen
    ? { active: isTooltipActive }
    : {};

  return (
    <g>
      <VictoryLabel
        {...props}
        textComponent={<LabelText text={cost} x={x} y={y} />}
      />
      {!samePrice && (
        <VictoryTooltip
          {...props}
          {...mobileTooltipProps}
          orientation="left"
          pointerLength={0}
          flyoutComponent={<AnnualSavingsTooltip />}
          flyoutWidth={110}
          flyoutHeight={65}
          flyoutStyle={{
            fill: theme.palette.white,
            stroke: theme.palette.primary.main,
          }}
          style={{
            ...barLabels,
            fontWeight: FontWeight.Semibold,
            lineHeight: 1.2,
          }}
        />
      )}
    </g>
  );
};

CompetitorLabelAndTooltip.defaultEvents = VictoryTooltip.defaultEvents;

const RhythmLabelText = (props: VictoryLabelProps) => {
  const { datum, text } = props;
  const { x, y } = datum as Datum;

  return (
    <g>
      <VictoryLabel
        {...props}
        textComponent={<LabelText text={text as string} x={x} y={y + 25} />}
      />
    </g>
  );
};

interface BarData {
  barRatio: number;
  xDomainPadding: number;
}

const calculateBarData = (competitors: CompetitorData[]): BarData => {
  if (competitors.length >= 4) {
    return {
      barRatio: 7,
      xDomainPadding: 40,
    };
  }

  if (competitors.length === 3) {
    return {
      barRatio: 8,
      xDomainPadding: 50,
    };
  }

  if (competitors.length === 2) {
    return {
      barRatio: 12,
      xDomainPadding: 60,
    };
  }

  return {
    barRatio: 14,
    xDomainPadding: 80,
  };
};

interface RepPriceComparisonChartProps {
  chartData: RepPriceComparisonChartData | null;
}

export const RepPriceComparisonChart: FC<RepPriceComparisonChartProps> = ({
  chartData,
}) => {
  const [tooltipActive, setTooltipActive] = useState<Record<string, boolean>>(
    {}
  );

  if (!chartData) {
    return null;
  }

  const { competitors, rhythm, highestCompetitorPlanPrice } = chartData;

  const { barRatio, xDomainPadding } = calculateBarData(competitors);

  return (
    <VictoryChart
      animate={{ duration: 500 }}
      domain={{ y: [0, Math.ceil(highestCompetitorPlanPrice) + 3] }}
      domainPadding={{ x: xDomainPadding }}
      padding={{ bottom: 40, left: 25, right: 5, top: 5 }}
    >
      <VictoryAxis tickLabelComponent={<LogoTick />} style={chartStyles.axis} />
      <VictoryAxis
        dependentAxis
        tickFormat={(y: number) => formatToCents(y, true, 0)}
        style={chartStyles.axis}
      />

      <VictoryStack
        colorScale={[theme.palette.primary.main]}
        labels={[formatToCents(rhythm.y)]}
        labelComponent={<RhythmLabelText />}
        animate={animation}
      >
        <VictoryBar
          data={[{ x: rhythm.x, y: rhythm.y }]}
          barRatio={barRatio}
          alignment="middle"
        />
      </VictoryStack>

      {competitors.map((competitor) => {
        const roundedCompetitorY = Number(competitor.y.toFixed(1));
        const roundedRhythmY = Number(rhythm.y.toFixed(1));

        if (!competitor.y || roundedCompetitorY < roundedRhythmY) {
          return null;
        }

        const isSamePrice = roundedCompetitorY === roundedRhythmY;

        const onVictoryBarClick = () => {
          setTooltipActive((previousState) => {
            return {
              [competitor.x]: Boolean(!previousState[competitor.x]),
            };
          });
        };

        return (
          <VictoryStack
            key={competitor.x}
            colorScale={[
              theme.palette.grey[200],
              isSamePrice ? "transparent" : theme.palette.gold.main,
            ]}
            labels={() => ""}
            labelComponent={
              <CompetitorLabelAndTooltip
                isTooltipActive={Boolean(tooltipActive[competitor.x])}
                cost={formatToCents(competitor.y)}
                samePrice={isSamePrice}
              />
            }
            animate={animation}
            style={{ labels: barLabels }}
          >
            <VictoryBar
              data={[{ x: competitor.x, y: rhythm.y }]}
              barRatio={barRatio}
              alignment="middle"
              events={[
                {
                  eventHandlers: {
                    onClick: onVictoryBarClick,
                  },
                  target: "data",
                },
              ]}
            />
            <VictoryBar
              data={[
                {
                  x: competitor.x,
                  y: isSamePrice
                    ? competitor.y - rhythm.y + 0.1 // hack for the bar label to stay at top position
                    : competitor.y - rhythm.y,
                },
              ]}
              barRatio={barRatio}
              alignment="middle"
              events={[
                {
                  eventHandlers: {
                    onClick: onVictoryBarClick,
                  },
                  target: "data",
                },
              ]}
            />
          </VictoryStack>
        );
      })}

      <VictoryLine
        y={() => rhythm.y}
        style={{
          data: {
            stroke: theme.palette.primary.main,
            strokeDasharray: "4px",
            strokeWidth: "1px",
          },
        }}
      />
    </VictoryChart>
  );
};
