import { LONG_FRIENDLY_DATE_FORMAT } from "@common/constants/date.constant";
import { useModalState } from "@common/hooks/useModalState";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { isDeviceTouchScreen } from "@common/utils/isDeviceTouchScreen";
import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as BigMagnifier } from "@design-system/icons/BigMagnifier.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useModals } from "@portal/components/ModalsManager/useModals";
import {
  ChartUpdatedTime,
  NoCompetitorsChartOverlay,
  NoCompetitorsText,
  RepPriceComparisonChartContainer,
  RepPriceComparisonContainer,
  RepPriceComparisonFiltersWrapper,
  RepPriceComparisonGraphContainer,
  RepPriceComparisonTitle,
} from "@portal/components/RepPriceComparison/RepPriceComparison.styled";
import { useRepPriceCompetitors } from "@portal/components/RepPriceComparison/useRepPriceCompetitors";
import { RepPriceComparisonChart } from "@portal/components/RepPriceComparisonChart/RepPriceComparisonChart";
import { useRepPriceComparisonChartData } from "@portal/components/RepPriceComparisonChart/useRepPriceComparisonChartData";
import { RepPriceComparisonChartDialogs } from "@portal/components/RepPriceComparisonChartDialogs/RepPriceComparisonChartDialogs";
import { RepPriceComparisonFilters } from "@portal/components/RepPriceComparisonFilters/RepPriceComparisonFilters";
import { SelectedRhythmPlan } from "@portal/components/RepPriceComparisonPlanList/SelectedRhythmPlan";
import { RepPriceComparisonSelectPlanCard } from "@portal/components/RepPriceComparisonSelectPlanCard/RepPriceComparisonSelectPlanCard";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

interface RepPriceComparisonProps {
  offerSnapshots: OfferSnapshot[] | null;
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshot) => void;
}

export const RepPriceComparison = ({
  offerSnapshots,
  onSelectOfferSnapshot,
}: RepPriceComparisonProps) => {
  const { trackEvent } = useSignUpFlow();
  const theme = useTheme();
  const { t, tJsx } = useRhIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const {
    isOpen: modalOpen,
    close: closeModal,
    open: openModal,
  } = useModalState();
  const { addModal } = useModals();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const { comparisonChartOfferSnapshot, comparisonChartTermMonths } =
    useRepPriceComparisonChart();
  const competitorPlans = useRepPriceCompetitors(comparisonChartOfferSnapshot);
  const handleEnrollNowClick = useHandleEnrollNowClick();

  const { chartData } = useRepPriceComparisonChartData({
    competitorPlans,
    offerSnapshots,
  });

  if (!competitorPlans.length || !comparisonChartOfferSnapshot) {
    return null;
  }

  const handleSelect = () => {
    if (comparisonChartOfferSnapshot) {
      onSelectOfferSnapshot(comparisonChartOfferSnapshot);
    }
  };

  const handleOpenModal = () => {
    trackEvent({
      action: ActionType.click,
      label: "openedViewPlansModal",
    });

    document.body.classList.add(CSSClasses.OverflowHidden);
    openModal();
  };

  const selectButtonProps = {
    onClick: () => {
      if (comparisonChartOfferSnapshot) {
        trackEvent({
          action: ActionType.click,
          baseCharge: Number(comparisonChartOfferSnapshot.baseChargeAmount),
          energyRate: Number(comparisonChartOfferSnapshot.rhythmKwhRate),
          label: "repPriceComparisonSelectPlan",
          planDate: comparisonChartOfferSnapshot.startDate,
          planLength: comparisonChartOfferSnapshot.termMonths,
          price: Number(comparisonChartOfferSnapshot.price),
          tdspRate: Number(comparisonChartOfferSnapshot.tdspKwhRate),
          title: comparisonChartOfferSnapshot.title,
          value: `offerId${comparisonChartOfferSnapshot.id}`,
        });
      }

      document.body.classList.remove(CSSClasses.OverflowHidden);

      return handleSelect();
    },
    text: t("RepPriceComparisonChartDialogs.selectPlan"),
  };

  const learnMoreProps = (isModal: boolean = false) => ({
    onClick: () => {
      if (comparisonChartOfferSnapshot) {
        trackEvent({
          action: ActionType.click,
          label: isModal
            ? "repPriceComparisonModalLearnMore"
            : "repPriceComparisonSelectedPlanLearnMore",
          value: `offerId${comparisonChartOfferSnapshot.id}`,
        });

        addModal({
          offerSnapshotLearnMore: {
            offerSnapshotUUID: comparisonChartOfferSnapshot.uuid,
            onClose: () => {
              trackEvent({
                action: ActionType.click,
                label: "dialogClose",
                value: `offerId${comparisonChartOfferSnapshot.id}`,
              });
            },
            onEnrollClick: () => {
              handleEnrollNowClick({
                estimatedMonthlyUsage,
                offerSnapshot: comparisonChartOfferSnapshot,
              });
            },
            selectPlanCtaText: t("RepPriceComparison.selectPlanCtaText"),
          },
        });
      }
    },
    text: t("RepPriceComparisonChartDialogs.learnMore"),
  });

  const learnMoreModalButtonProps = learnMoreProps(true);
  const learnMoreButtonProps = learnMoreProps();

  const competitorPlansShownInGraph =
    chartData?.competitors.map((competitor) => competitor.x) || [];

  const competitorPlansToShow = competitorPlans.filter((competitorPlan) =>
    competitorPlansShownInGraph.includes(competitorPlan.competitorName)
  );

  // Set rep price modal to only show plans for the current selected term month in the chart
  const competitorPlansByTermMonths = competitorPlansToShow.filter(
    (plan) => plan.termMonths === comparisonChartTermMonths
  );

  const repPriceComparisonSubtitle = isDeviceTouchScreen
    ? t("RepPriceComparison.subtitleMobile")
    : t("RepPriceComparison.subtitleDesktop");

  return (
    <RepPriceComparisonContainer>
      <RepPriceComparisonTitle>
        <RhTypography variant="h2">
          {t("RepPriceComparison.title")}
        </RhTypography>
        <RhTypography variant="subtitle2" color="textSecondary">
          {repPriceComparisonSubtitle}
        </RhTypography>
      </RepPriceComparisonTitle>

      <RepPriceComparisonChartContainer>
        <RepPriceComparisonFiltersWrapper>
          <RepPriceComparisonFilters offerSnapshots={offerSnapshots} />
          {!isMobile && (
            <RepPriceComparisonSelectPlanCard
              offerSnapshot={comparisonChartOfferSnapshot}
              moreButtonProps={learnMoreButtonProps}
              selectButtonProps={selectButtonProps}
            />
          )}
        </RepPriceComparisonFiltersWrapper>
        {isMobile && (
          <RhCard>
            <SelectedRhythmPlan
              offerSnapshot={comparisonChartOfferSnapshot}
              moreButtonProps={learnMoreButtonProps}
              selectButtonProps={selectButtonProps}
              highlightPlan={false}
            />
          </RhCard>
        )}
        <RepPriceComparisonGraphContainer>
          {(chartData?.competitors.length === 0 ||
            competitorPlansByTermMonths.length === 0) && (
            <NoCompetitorsChartOverlay>
              <BigMagnifier />
              <NoCompetitorsText>
                {t("RepPriceComparison.noCompetitorPlans")}
              </NoCompetitorsText>
            </NoCompetitorsChartOverlay>
          )}
          <RepPriceComparisonChart chartData={chartData} />
        </RepPriceComparisonGraphContainer>
      </RepPriceComparisonChartContainer>

      <RhButton
        data-tracking-click={{
          event:
            "Rep Price comparison. Prospect opening competitor plans modal",
        }}
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleOpenModal}
      >
        {t("RepPriceComparison.viewPlans")}
      </RhButton>

      <ChartUpdatedTime>
        {tJsx("RepPriceComparison.lastUpdatedOn", {
          date: () =>
            dayjs(competitorPlansToShow[0]?.parsedDatetime).format(
              LONG_FRIENDLY_DATE_FORMAT
            ),
        })}
      </ChartUpdatedTime>

      {comparisonChartOfferSnapshot && (
        <RepPriceComparisonChartDialogs
          offerSnapshot={comparisonChartOfferSnapshot}
          competitorPlans={competitorPlansByTermMonths}
          modalOpen={modalOpen}
          closeModal={closeModal}
          moreButtonProps={learnMoreModalButtonProps}
          selectButtonProps={selectButtonProps}
        />
      )}
    </RepPriceComparisonContainer>
  );
};
