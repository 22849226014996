import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as TDSPIcon } from "@design-system/icons/bill-factors/TDSPChange.svg";
import { StyledBillFactor } from "@portal/components/BillFactors/BillFactors.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface BillFactorTDSPChangeProps {
  differencePercentage: number;
  utilityName: string;
}

export const BillFactorTDSPChange = ({
  differencePercentage,
  utilityName,
}: BillFactorTDSPChangeProps) => {
  const { translateJsx } = useTranslations();

  const {
    tBillFactorsDeliveryChargeChanges,
    tBillFactorsUtilityWhichControls,
  } = translateJsx({
    tBillFactorsDeliveryChargeChanges: { utilityName },
    tBillFactorsUtilityWhichControls: {
      percentage: differencePercentage,
      utilityName,
    },
  });

  return (
    <StyledBillFactor>
      <TDSPIcon />
      <RhTypography fontWeight="Bold">
        {tBillFactorsDeliveryChargeChanges}
      </RhTypography>
      <RhTypography variant="body2">
        {tBillFactorsUtilityWhichControls}
      </RhTypography>
    </StyledBillFactor>
  );
};
