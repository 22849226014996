/// <reference types="@types/segment-analytics" />
import { isBot } from "@common/utils/isBot";

// window.analytics is added by a script in Google Tag Manager.
declare global {
  interface Window {
    FS: {
      event: (event: string, properties: Record<string, unknown>) => void;
    };
    analytics: SegmentAnalytics.AnalyticsJS;
    dataLayer: unknown[];
  }
}

interface GTMPageEventProps {
  event: string;
  page: string;
}

export enum MyAccountEvents {
  addNewCard = "addNewCard",
  addNewCardSuccess = "addNewCardSuccess",
  applyCredit = "applyCredit",
  autoPayToggle = "autoPayToggle",
  averageBillingToggle = "averageBillingToggle",
  billingDueDateSelected = "billingDueDateSelected",
  contactInfoUpdated = "contactInfoUpdated",
  deviceSelectedFromMenu = "deviceSelectedFromMenu",
  downloadBill = "downloadBill",
  downloadDocument = "downloadDocument",
  downloadWelcomePacket = "downloadWelcomePacket",
  eBillToggle = "eBillToggle",
  languagePreferencesUpdated = "languagePreferencesUpdated",
  mailingAddressUpdated = "mailingAddressUpdated",
  passwordUpdated = "passwordUpdated",
  paymentSubmittedError = "paymentSubmittedError",
  paymentSubmittedSuccess = "paymentSubmittedSuccess",
  premiseChanged = "premiseChanged",
  protectionPlanPurchased = "protectionPlanPurchased",
  readMoreEnergySaving = "readMoreEnergySaving",
  referAFriendClicked = "referAFriendClicked",
  renewalClick = "renewalClick",
  viewPriceBreakdown = "viewPriceBreakdown",
  viewProductAddonsClick = "viewProductAddonsClick",
}

export enum EnrollmentEvents {
  enrollmentClick = "enrollmentClick",
  identify = "identify",
}

export enum LoggedOutEvents {
  portalCustomerLogin = "portalCustomerLogin",
  quickPay = "quickPay",
}

export enum DeviceEnrollmentEvents {
  DeviceBrandSelected = "deviceBrandSelected",
  DeviceTermsAgreed = "deviceTermsAgreed",
  DevicesClicked = "devicesClicked",
  DevicesSelected = "devicesSelected",
}

export const EventType = {
  ...EnrollmentEvents,
  ...LoggedOutEvents,
  ...MyAccountEvents,
  ...DeviceEnrollmentEvents,
};

export enum CategoryType {
  Availability = "availability",
  BillingPreferences = "billing-preferences",
  Contact = "contact",
  FullStory = "fullstory",
  MyAccountRenewals = "My Account Renewals",
  Name = "name",
  OffersPage = "onboarding.plans",
  OneClickRenewals = "One-click Renewals",
  Premise = "premise",
  Renewals = "renewals",
}

export enum ActionType {
  billingPreferencesSubmitted = "billingPreferencesSubmitted",
  changedPlan = "changedPlan",
  changedTermMonths = "changedTermMonths",
  click = "click",
  clickedBackPage = "clickedBackPage",
  clickedEnrollmentSubmit = "clickedEnrollmentSubmit",
  clickedNextPage = "clickedNextPage",
  contactSubmitted = "contactSubmitted",
  customerLogin = "customerLogin",
  emailSubmitted = "emailSubmitted",
  focus = "fieldFocus",
  formSubmit = "formSubmit",
  marketingAddressFormSubmitted = "marketingAddressFormSubmitted",
  nameSubmitted = "nameSubmitted",
  openedCart = "openedCart",
  portalZipCodeSubmitted = "portalZipCodeSubmitted",
  premiseSubmitted = "premiseSubmitted",
  prospectCreated = "prospectCreated",
  userIdentified = "userIdentified",
}

export enum LabelType {
  ActiveServiceArea = "Active Service Area",
  ClickedEFL = "Clicked EFL",
  ClickedOtherOffersToggle = "Clicked other offers toggle",
  ClickedProductTierSeeMore = "Clicked product tier see-more button",
  ClickedRenewalCompetitorChartSelectPlan = "Clicked Renewal Competitor Chart Select Plan",
  ClickedRenewalOfferCardLearnMore = "Clicked Renewal Offer Card Learn More",
  ClickedRenewalOfferCardRenewNow = "Clicked Renewal Offer Card Renew Now",
  ClickedRenewalSubmit = "Clicked Renewal Submit",
  ClickedRenewalsCta = "Clicked Renewals CTA",
  ConfirmedProductAddOnSelection = "Confirmed product add-on selection",
  EnrollmentFromOffersPageToNextPage = "plansNextPage",
  FullStoryUserIdentified = "FullStory User Identified",
  InvalidServiceArea = "Invalid Service Area",
  OpenedCart = "Opened Cart",
  SelectedProductTier = "Selected product tier",
  SkippedProductAddOnsSelection = "Skipped product add-ons selection",
}

export interface AnalyticsEvent {
  [key: string]: string | number | boolean;
  action: ActionType;
  category: string;
  event:
    | EnrollmentEvents
    | LoggedOutEvents
    | MyAccountEvents
    | DeviceEnrollmentEvents;
  label: string;
  value: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

export const track = (options: Partial<AnalyticsEvent>) => {
  if (isBot()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.FS?.event(event, properties);
    window.analytics?.track?.(event, properties);
    window.dataLayer?.push(options);
  }
};

export const createTrackEvent = (
  base: Partial<AnalyticsEvent>
): TrackEventHandler => {
  return (options: Partial<AnalyticsEvent>) => {
    track({ ...base, ...options });
  };
};

export const trackPage = (url?: string): void => {
  if (isBot() || !url) {
    return;
  }

  const pageEvent: GTMPageEventProps = {
    event: "pageview",
    page: url,
  };

  window.dataLayer?.push(pageEvent);

  window.analytics?.page();
};
