import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { selectedDevicePremiseAtom } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { DeviceSwapPremiseSelector } from "@portal/components/DevicePremiseSelector/DeviceSwapPremiseSelector";
import { devicesSelectorAtom } from "@portal/components/DeviceSelector/DeviceSelector.atoms";
import { updateDevicePremiseModalTranslations } from "@portal/components/UpdateDevicePremiseModal/UpdateDevicePremiseModal.en.i18n";
import {
  StyledButtonGroup,
  StyledContainer,
  StyledSVGWrapper,
} from "@portal/components/UpdateDevicePremiseModal/UpdateDevicePremiseModal.styled";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useUpdateDevicePremiseMutation } from "@portal/hooks/mutations/useUpdateDevicePremise.mutation";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as Thermostat } from "@portal/images/Thermostat.svg";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import React from "react";

interface UpdateDevicePremiseModalProps {
  handleClose: () => void;
}

export const UpdateDevicePremiseModal = (
  props: UpdateDevicePremiseModalProps
) => {
  const { handleClose } = props;

  const flash = useRhFlash();
  const queryClient = useQueryClient();
  const customer = useCustomerFromContext();
  const { premise } = usePremise();
  const [currentSelectedDevice, setCurrentSelectedDevice] =
    useAtom(devicesSelectorAtom);
  const [currentSelectedDevicePremise, setSelectedDevicePremise] = useAtom(
    selectedDevicePremiseAtom
  );
  const updateDevicePremiseMutation = useUpdateDevicePremiseMutation();
  const { translate } = useTranslations();
  const {
    tUpdateDevicePremiseModalAddress,
    tUpdateDevicePremiseModalCancel,
    tUpdateDevicePremiseModalConfirm,
    tUpdateDevicePremiseModalError,
    tUpdateDevicePremiseModalSuccess,
    tUpdateDevicePremiseModalTitle,
  } = translate(updateDevicePremiseModalTranslations);

  if (
    !currentSelectedDevice ||
    !currentSelectedDevicePremise.premiseId ||
    !premise ||
    !customer
  ) {
    return null;
  }

  const invalidateAttachedDevicesQueries = async () => {
    await queryClient.invalidateQueries({
      queryKey: PremiseQueryKeys.attachedDevices(premise.id),
    });

    await queryClient.invalidateQueries({
      queryKey: PremiseQueryKeys.attachedDevices(
        currentSelectedDevicePremise?.premiseId ?? ""
      ),
    });
  };

  const handleCancel = () => {
    setSelectedDevicePremise({ premiseId: premise?.id ?? "" });
    handleClose();
  };

  const handleConfirm = async () => {
    try {
      await updateDevicePremiseMutation.mutateAsync(
        {
          newPremiseId: currentSelectedDevicePremise?.premiseId ?? "",
          smartThermostatId: currentSelectedDevice.smartThermostatId,
        },
        {
          onError: () => {
            setSelectedDevicePremise(RESET);
            setCurrentSelectedDevice(RESET);
            flash.error(tUpdateDevicePremiseModalError);
          },
          onSuccess: async () => {
            await invalidateAttachedDevicesQueries();

            setSelectedDevicePremise(RESET);
            setCurrentSelectedDevice(RESET);

            flash.success(tUpdateDevicePremiseModalSuccess);

            return handleClose();
          },
        }
      );
    } catch (error) {
      setSelectedDevicePremise(RESET);
      setCurrentSelectedDevice(RESET);
    }
  };

  return (
    <RhModal
      ariaCloseLabel="Close"
      ariaLabel="Modal"
      handleClose={handleCancel}
      overflowVisible
      size={ModalWidths.xs}
    >
      <StyledContainer>
        <StyledSVGWrapper>
          <Thermostat aria-hidden />
        </StyledSVGWrapper>
        <RhTypography variant="h2">
          {tUpdateDevicePremiseModalTitle}
        </RhTypography>
        <DeviceSwapPremiseSelector
          device={currentSelectedDevice}
          premises={customer.premises}
          label={tUpdateDevicePremiseModalAddress}
        />
        <StyledButtonGroup>
          <RhButton
            variant="outlined"
            onClick={handleCancel}
            data-tracking-click={{ event: "click" }}
          >
            {tUpdateDevicePremiseModalCancel}
          </RhButton>
          <RhButton
            disabled={currentSelectedDevicePremise?.premiseId === premise?.id}
            onClick={handleConfirm}
            fullWidth={false}
            data-tracking-click={{
              action: "clickedUpdatePremiseConfirm",
              event: "Confirmed update premise for device",
            }}
          >
            {tUpdateDevicePremiseModalConfirm}
          </RhButton>
        </StyledButtonGroup>
      </StyledContainer>
    </RhModal>
  );
};
