import { passwordTokenParam } from "@common/services/resetPassword";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LoginCallback } from "@okta/okta-react";
import { ActivationController } from "@portal/components/ActivationController/ActivationController";
import { AuthError } from "@portal/components/AuthError/AuthError";
import { CustomerProvider } from "@portal/components/CustomerProvider/CustomerProvider";
import { EnrollReferralCodeAnnouncement } from "@portal/components/EnrollReferralCodeAnnouncement/EnrollReferralCodeAnnouncement";
import { FeatureFlagRoute } from "@portal/components/FeatureFlagRoute/FeatureFlagRoute";
import { GuestPayAccountLookup } from "@portal/components/GuestPayAccountLookup/GuestPayAccountLookup";
import { GuestPayAccountSummary } from "@portal/components/GuestPayAccountSummary/GuestPayAccountSummary";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { MyAccountHeader } from "@portal/components/MyAccountHeader/MyAccountHeader";
import { PremiseProvider } from "@portal/components/PremiseProvider/PremiseProvider";
import { ProductAddOnsOptInRoutes } from "@portal/components/ProductAddOnsOptIn/ProductAddOnsOptIn.route";
import { ProspectProvider } from "@portal/components/ProspectProvider/ProspectProvider";
import { usePremiseQueryParams } from "@portal/hooks/usePremiseQueryParams";
import { useSignUpQueryParams } from "@portal/hooks/useSignUpQueryParams";
import { AccountCreationPendingPage } from "@portal/pages/AccountCreationPendingPage/AccountCreationPendingPage";
import { BillBreakdownPage } from "@portal/pages/BillBreakdownPage/BillBreakdownPage";
import { CreatePasswordPage } from "@portal/pages/CreatePasswordPage/CreatePasswordPage";
import { CustomerHomePage } from "@portal/pages/CustomerHomePage/CustomerHomePage";
import { DevicesEnrollChooseBrandPage } from "@portal/pages/DevicesEnrollChooseBrandPage/DevicesEnrollChooseBrandPage";
import { DevicesEnrollSelectDevicesPage } from "@portal/pages/DevicesEnrollSelectDevicesPage/DevicesEnrollSelectDevicesPage";
import { DevicesEnrollSummaryPage } from "@portal/pages/DevicesEnrollSummaryPage/DevicesEnrollSummaryPage";
import { DevicesEnrollTermsPage } from "@portal/pages/DevicesEnrollTermsPage/DevicesEnrollTermsPage";
import { DevicesPage } from "@portal/pages/DevicesPage/DevicesPage";
import { Error404Page } from "@portal/pages/Error404Page/Error404Page";
import { ExpiredPasswordTokenPage } from "@portal/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage";
import { ForgotPasswordPage } from "@portal/pages/ForgotPasswordPage/ForgotPasswordPage";
import { GuestAndQuickPayConfirmationPage } from "@portal/pages/GuestAndQuickPayConfirmationPage/GuestAndQuickPayConfirmationPage";
import { MyAccountPage } from "@portal/pages/MyAccountPage/MyAccountPage";
import { NotificationsPage } from "@portal/pages/NotificationsPage/NotificationsPage";
import { OneClickRenewalPage } from "@portal/pages/OneClickRenewalPage/OneClickRenewalPage";
import { OneClickRenewalSummaryPage } from "@portal/pages/OneClickRenewalSummaryPage/OneClickRenewalSummaryPage";
import { PayBillPage } from "@portal/pages/PayBillPage/PayBillPage";
import { PayBillSuccessPage } from "@portal/pages/PayBillSuccessPage/PayBillSuccessPage";
import { PaymentExtensionPage } from "@portal/pages/PaymentExtensionPage/PaymentExtensionPage";
import { QuickPayPage } from "@portal/pages/QuickPayPage/QuickPayPage";
import { RenewalsPage } from "@portal/pages/RenewalsPage/RenewalsPage";
import { RenewalsSummaryPage } from "@portal/pages/RenewalsSummaryPage/RenewalsSummaryPage";
import { ResetPasswordPage } from "@portal/pages/ResetPasswordPage/ResetPasswordPage";
import { RewardsPage } from "@portal/pages/RewardsPage/RewardsPage";
import { SignInPage } from "@portal/pages/SignInPage/SignInPage";
import { SignUpPlansPage } from "@portal/pages/SignUpPlansPage/SignUpPlansPage";
import { UnauthorizedPage } from "@portal/pages/UnauthorizedPage/UnauthorizedPage";
import { CustomerHomeOutlet } from "@portal/routes/CustomerHomeOutlet";
import { DeviceManagementOutlet } from "@portal/routes/DeviceManagementOutlet";
import { featureFlagRoutes } from "@portal/routes/FeatureFlagRoutes";
import { FetchPortalDependencies } from "@portal/routes/FetchPortalDependencies";
import { LandingPageRedirect } from "@portal/routes/LandingPageRedirect";
import { LoggedOutOnly } from "@portal/routes/LoggedOutOnly";
import { RhSecurePortalRoute } from "@portal/routes/RhSecurePortalRoute";
import {
  accountCreationPendingPath,
  activatePath,
  billBreakdownPath,
  createPasswordPath,
  customerHomePath,
  devicesEnrollChooseYourBrandDeviceStep,
  devicesEnrollSelectDevicesStep,
  devicesEnrollStepPath,
  devicesEnrollSummaryStep,
  devicesEnrollTermsStep,
  enrollBasePath,
  enrollOffersPath,
  expiredPasswordPath,
  forgotPasswordPath,
  guestPayAccountLookUpPath,
  guestPayAccountSummaryPath,
  guestPayBasePath,
  guestPayConfirmationPath,
  loginCallbackPath,
  myAccountPath,
  notificationsPath,
  oneClickRenewalsPath,
  payBillPath,
  payBillSuccessPath,
  paymentExtensionPath,
  productAddOnsOptInPath,
  quickPayConfirmationPath,
  quickPayPath,
  renewalsPath,
  resetPasswordPath,
  rewardsPath,
  signInPath,
  signOutPath,
  signUpBasePath,
  unauthorizedPath,
} from "@portal/routes/routePaths";
import { SentryRoutes } from "@portal/routes/SentryRoutes";
import { SignOut } from "@portal/routes/SignOut";
import { SignUpOutlet } from "@portal/routes/SignUpOutlet";
import { SignUpRoutes } from "@portal/routes/SignUpRoutes";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Outlet, Route } from "react-router-dom";

export const PortalRoutes = () => {
  const queryParamRequest = useSignUpQueryParams();

  usePremiseQueryParams();

  if (queryParamRequest.isWaiting) {
    return <RhCircularProgress />;
  }

  return (
    <SentryRoutes>
      <Route element={<LoggedOutOnly redirectPath={customerHomePath()} />}>
        <Route
          element={
            <ProspectProvider>
              <SignUpOutlet />
            </ProspectProvider>
          }
        >
          <Route path={`${signUpBasePath()}/*`} element={<SignUpRoutes />} />
        </Route>
        <Route path={createPasswordPath()} element={<CreatePasswordPage />} />
        <Route
          path={expiredPasswordPath()}
          element={<ExpiredPasswordTokenPage />}
        />
        <Route
          path={activatePath(":activationToken")}
          element={<ActivationController />}
        />
        <Route path={signInPath()} element={<SignInPage />} />
        <Route path={forgotPasswordPath()} element={<ForgotPasswordPage />} />
        <Route
          path={resetPasswordPath(passwordTokenParam)}
          element={<ResetPasswordPage />}
        />
      </Route>

      <Route element={<LoggedOutOnly redirectPath={payBillPath()} />}>
        <Route path={quickPayPath(":token")} element={<QuickPayPage />} />
        <Route
          path={quickPayConfirmationPath()}
          element={
            <LoggedOutPageLayout>
              <GuestAndQuickPayConfirmationPage />
            </LoggedOutPageLayout>
          }
        />
        <Route
          path={guestPayBasePath()}
          element={
            <LoggedOutPageLayout>
              <Outlet />
            </LoggedOutPageLayout>
          }
        >
          <Route
            path={guestPayAccountLookUpPath()}
            element={<GuestPayAccountLookup />}
          />
          <Route
            path={guestPayAccountSummaryPath()}
            element={<GuestPayAccountSummary />}
          />
          <Route
            path={guestPayConfirmationPath()}
            element={<GuestAndQuickPayConfirmationPage />}
          />
        </Route>
      </Route>

      <Route index element={<LandingPageRedirect />} />

      <Route
        element={
          <RhSecurePortalRoute>
            <Helmet>
              <link
                rel="stylesheet"
                type="text/css"
                href="/assets/styles/awsConnectWrapper.css"
              />
              <script
                type="text/javascript"
                src="assets/js/customerAwsConnectChat.js"
              />
            </Helmet>
            <CustomerProvider>
              <PremiseProvider>
                <MyAccountHeader />
                <FetchPortalDependencies />
              </PremiseProvider>
            </CustomerProvider>
          </RhSecurePortalRoute>
        }
      >
        <Route path={customerHomePath()} element={<CustomerHomeOutlet />}>
          <Route index element={<CustomerHomePage />} />
        </Route>
        <Route
          path={`${billBreakdownPath(":invoiceId")}/*`}
          element={<BillBreakdownPage />}
        />

        <Route path={payBillSuccessPath()} element={<PayBillSuccessPage />} />
        <Route path={payBillPath()} element={<PayBillPage />} />
        <Route
          path={paymentExtensionPath()}
          element={<PaymentExtensionPage />}
        />
        <Route path={rewardsPath()} element={<RewardsPage />} />
        <Route path={myAccountPath()} element={<MyAccountPage />} />
        <Route path={myAccountPath()} element={<MyAccountPage />} />
        <Route
          path={`${productAddOnsOptInPath()}/*`}
          element={<ProductAddOnsOptInRoutes />}
        />
        <Route path={notificationsPath()} element={<NotificationsPage />} />
        <Route path={`${renewalsPath()}/*`}>
          <Route index element={<RenewalsPage />} />
          <Route path="summary" element={<RenewalsSummaryPage />} />
        </Route>
        <Route path="devices" element={<DeviceManagementOutlet />}>
          <Route index element={<DevicesPage />} />
          <Route path="enroll" element={<Outlet />}>
            <Route
              index
              element={
                <Navigate
                  to={devicesEnrollStepPath("choose-your-device-brand")}
                />
              }
            />
            <Route
              path={devicesEnrollChooseYourBrandDeviceStep()}
              element={<DevicesEnrollChooseBrandPage />}
            />
            <Route
              path={devicesEnrollTermsStep()}
              element={<DevicesEnrollTermsPage />}
            />
            <Route
              path={devicesEnrollSelectDevicesStep()}
              element={<DevicesEnrollSelectDevicesPage />}
            />
            <Route
              path={devicesEnrollSummaryStep()}
              element={<DevicesEnrollSummaryPage />}
            />
          </Route>
        </Route>
      </Route>

      <Route path={signOutPath()} element={<SignOut />} />

      <Route path={unauthorizedPath()} element={<UnauthorizedPage />} />

      <Route
        path={loginCallbackPath()}
        element={<LoginCallback errorComponent={AuthError} />}
      />

      <Route
        path={accountCreationPendingPath()}
        element={<AccountCreationPendingPage />}
      />

      <Route path={`${oneClickRenewalsPath(":renewalToken")}/*`}>
        <Route index element={<OneClickRenewalPage />} />
        <Route path="summary" element={<OneClickRenewalSummaryPage />} />
      </Route>

      {featureFlagRoutes.map(({ component, featureFlag, path }) => (
        <Route
          key={path}
          path={path}
          element={
            <FeatureFlagRoute featureFlag={featureFlag} component={component} />
          }
        />
      ))}

      <Route element={<LoggedOutOnly redirectPath={customerHomePath()} />}>
        <Route
          path={enrollBasePath()}
          element={
            <ProspectProvider>
              <EnrollReferralCodeAnnouncement />
              <Outlet />
            </ProspectProvider>
          }
        >
          <Route
            path={enrollOffersPath(":prospectUuid?")}
            element={<SignUpPlansPage />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Error404Page />} />
    </SentryRoutes>
  );
};
