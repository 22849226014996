import { darkPurple } from "@design-system/theme/palette.colors";
import { Radio, SvgIcon, SvgIconProps } from "@mui/material";
import styled from "styled-components";

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: ${darkPurple.main};
    padding: 0;
  }
`;

interface StyledSvgIconProps extends SvgIconProps {
  component: React.FunctionComponent;
}

export const StyledSvgIcon = styled(SvgIcon)<StyledSvgIconProps>`
  &.MuiSvgIcon-root {
    color: ${darkPurple.main};
    font-size: 1.25rem;
    margin-right: 0.75rem;
    width: auto;
  }
`;
