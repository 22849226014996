import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalToggle } from "@portal/components/PortalToggle/PortalToggle";
import styled from "styled-components";

export const PaperlessToggle = styled(PortalToggle)`
  display: flex;
  flex-direction: row;
  font-size: 16px;

  font-weight: ${FontWeight.Semibold};
  justify-content: space-between;
  width: 100%;
`;

export const LegalText = styled(RhTypography)`
  width: 75%;
`;

export const PaperlessModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
  padding: ${rhSpacingPx(3)};
`;

export const PaperlessFormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(5)};
  justify-content: space-between;
  margin-top: ${rhSpacingPx(3)};
`;

export const BalancedText = styled(RhTypography)<{ $upperCase?: boolean }>`
  text-align: center;
  text-transform: ${({ $upperCase }) => ($upperCase ? "uppercase" : "none")};
  text-wrap: balance;
`;
