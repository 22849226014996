import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledBillBreakdownCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const StyledBillBreakdownDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${grey[100]};
    margin-top: ${rhSpacingPx(1)};
  }
`;

export const StyledBillBreakdownContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  margin: ${rhSpacingPx(3)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin: ${rhSpacingPx(3)} 0;
  }
`;

export const StyledBillBreakdownRow = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  row-gap: ${rhSpacingPx(1)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    column-gap: ${rhSpacingPx(3)};
    grid-template-columns: 100px 1fr;
    row-gap: 0;
    text-align: start;
  }
`;

export const StyledBillBreakdownImageContainer = styled.div`
  align-items: center;
  display: flex;
  grid-row: span 2;
  justify-content: center;
`;

export const StyledBillBreakdownTitle = styled(RhTypography).attrs({
  fontWeight: "Bold",
  variant: "subtitle2",
})`
  width: 100%;
`;

export const StyledArithmeticIconContainer = styled.div`
  display: flex;
`;

export const StyledLearnMoreContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  margin: ${rhSpacingPx(3)};
  text-align: center;
  text-wrap: balance;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-top: ${rhSpacingPx(3)};
  }
`;
