import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box } from "@mui/material";
import { MyAccountBillingPreferencesCardTranslations } from "@portal/components/MyAccountBillingPreferencesCard/MyAccountBillingPreferencesCard.en.i18n";
import {
  StyledPyoddButton,
  StyledPyoddDetail,
  StyledPyoddInnerCard,
  StyledPyoddMessage,
} from "@portal/components/MyAccountBillingPreferencesCard/PickYourOwnDueDayBox.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface PickYourOwnDueDayBoxProps {
  name: string;
  openModal: () => void;
  pickedBillingDueDay: number | null;
}
export const PickYourOwnDueDayBox = ({
  name,
  pickedBillingDueDay,
  openModal,
}: PickYourOwnDueDayBoxProps) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tMyAccountBillingPreferencesCardPickYourOwnDateDescription,
    tMyAccountBillingPreferencesCardPickYourOwnDateSelectDueDate,
    tMyAccountBillingPreferencesCardPickYourOwnDateTitle,
  } = translate(MyAccountBillingPreferencesCardTranslations);

  const { tMyAccountBillingPreferencesCardPickYourOwnDateDisplayDay } =
    translateJsx({
      tMyAccountBillingPreferencesCardPickYourOwnDateDisplayDay: {
        day: pickedBillingDueDay,
      },
    });

  return (
    <StyledPyoddInnerCard>
      <Box pr={2} mb={1}>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {tMyAccountBillingPreferencesCardPickYourOwnDateTitle}
        </RhTypography>
        <StyledPyoddDetail
          color="textSecondary"
          variant="body1"
          display="block"
        >
          {tMyAccountBillingPreferencesCardPickYourOwnDateDescription}
        </StyledPyoddDetail>
      </Box>

      {pickedBillingDueDay ? (
        <StyledPyoddMessage>
          {tMyAccountBillingPreferencesCardPickYourOwnDateDisplayDay}
        </StyledPyoddMessage>
      ) : (
        <StyledPyoddButton
          data-tracking-click={{
            event: "Customer opening Pick Your Own Dua Day modal",
          }}
          color="primary"
          size="small"
          onClick={openModal}
        >
          {tMyAccountBillingPreferencesCardPickYourOwnDateSelectDueDate}
        </StyledPyoddButton>
      )}
    </StyledPyoddInnerCard>
  );
};
