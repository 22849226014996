import { noop } from "@common/utils/genericFunctions";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { SubmissionErrors, ValidationErrors } from "final-form";
import React from "react";

interface MyAccountSubmitButtonProps {
  isMobile: boolean;
  onError?: (submitErrors: SubmissionErrors | ValidationErrors) => void;
  text?: string;
}

export const MyAccountSubmitButton = ({
  onError,
  isMobile,
  text,
}: MyAccountSubmitButtonProps) => {
  const { t } = useRhIntl();
  const buttonText = text ?? t("rhythm.button.save");

  return (
    <RhSubmitButton
      data-tracking-click={{ event: "" }}
      disallowPristineSubmit
      restartOnSuccess
      onError={onError || noop}
      fullWidth={isMobile}
    >
      {buttonText}
    </RhSubmitButton>
  );
};
