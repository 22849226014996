import {
  StyledBillTipsCard,
  StyledTipsList,
} from "@portal/components/BillTipsCard/BillTipsCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";

export const BillTipsCardSkeleton = () => (
  <StyledBillTipsCard>
    <Skeleton />
    <StyledTipsList>
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
    </StyledTipsList>
  </StyledBillTipsCard>
);
