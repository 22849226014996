import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { borders } from "@design-system/constants/borders";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { AppBar } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledNavIconAnchor = styled(RhAnchor)`
  align-items: center;
  display: flex;

  & svg {
    margin-right: ${rhSpacingPx(1)};
  }

  &:hover {
    color: ${muiTheme.palette.primary.main} !important;
  }
`;

const StyledLinkOrAnchor = css`
  &:hover {
    color: ${muiTheme.palette.primary.main};
    text-decoration: none !important;
  }
`;

export const StyledSignUpLink = styled(NavLink)`
  ${StyledLinkOrAnchor}
  font-size: inherit;
`;

export const StyledNavLink = styled(NavLink)`
  color: ${muiTheme.palette.common.black};
  ${StyledLinkOrAnchor}
`;

export const StyledAnchor = styled(RhAnchor)`
  color: ${muiTheme.palette.common.black};
  ${StyledLinkOrAnchor}
`;

export const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    align-items: center;
    background-color: ${trueWhite};
    border-bottom: ${borders[100]};
    flex-direction: row;
    flex-wrap: nowrap;
    height: ${rhSpacingPx(RH_HEADER_HEIGHT)};
    padding: 0;
  }
`;

export const StyledListContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: flex-end;
  list-style: none;
`;

export const StyledItem = styled.li<{ $divideLastItem?: boolean }>`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 100%;
  padding: ${rhSpacingPx(2)};
  white-space: nowrap;

  &:hover {
    color: ${darkPurple.main};
  }

  ${({ $divideLastItem }) =>
    $divideLastItem &&
    css`
      &:last-of-type {
        border-left: 1px solid ${muiTheme.palette.grey[100]};
      }
    `}
`;

export const StyledListItemDivider = styled.li`
  height: 100%;
  margin-right: ${rhSpacingPx(3)};
`;

export const StyledActionContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const StyledLogoContainer = styled.div`
  color: ${grey[500]};
  display: flex;
  height: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  justify-content: flex-start;
  padding: 0 ${rhSpacingPx(2)};
  transition: color 200ms;

  & svg {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  &:hover {
    color: ${grey[300]};
  }
`;

export const StyledCartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
`;

export const StyledPhoneContainer = styled.div`
  & svg {
    display: block;
    height: 24px;
    width: 24px;
  }
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  padding: 0 ${rhSpacingPx(3)};
`;
