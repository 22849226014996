import {
  CookieName,
  getCookieByKey,
  removeCookieItem,
} from "@common/utils/getCookies";
import { useLayoutEffect, useRef } from "react";

export enum OfferPageScrollToSections {
  BillCalculator = "bill-calculator",
  Plans = "plans",
  RepPriceComparisonChart = "rep-price-comparison-chart",
}

export const useOfferPageScrollTo = () => {
  const ref = useRef<HTMLDivElement>(null);
  const offerPageScrollToCookie = getCookieByKey(CookieName.OfferPageScrollTo);

  useLayoutEffect(() => {
    const scrollAfterDelay = setTimeout(() => {
      if (ref.current) {
        if (offerPageScrollToCookie === ref.current.id) {
          ref.current.scrollIntoView({ behavior: "smooth" });
        }
      }

      // Reset cookie item so the scroll will not happen again when navigating back in the flow
      removeCookieItem(CookieName.OfferPageScrollTo);
    }, 250);

    return () => clearTimeout(scrollAfterDelay);
  }, [ref.current, offerPageScrollToCookie]);

  return { ref };
};
