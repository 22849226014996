import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { renewalPageLayoutTranslations } from "@portal/components/RenewalPageLayout/RenewalPageLayout.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";
import styled from "styled-components";

interface RenewalDisclaimerProps {
  currentOfferSnapshot: OfferSnapshot;
  renewalOfferSnapshot: OfferSnapshot;
}

const RenewalDisclaimerContainer = styled.div`
  margin-top: ${rhSpacingPx(3)};
  padding: ${rhSpacingPx(2)};
`;

export const RenewalDisclaimer = (props: RenewalDisclaimerProps) => {
  const { currentOfferSnapshot, renewalOfferSnapshot } = props;

  const { translate } = useTranslations();

  const {
    tRenewalPageLayoutSavingsDisclaimer,
    tRenewalPageLayoutSavingsDisclaimerTou,
  } = translate(renewalPageLayoutTranslations);

  const disclaimerText =
    currentOfferSnapshot.isTimeOfUse || renewalOfferSnapshot.isTimeOfUse
      ? tRenewalPageLayoutSavingsDisclaimerTou
      : tRenewalPageLayoutSavingsDisclaimer;

  return (
    <RenewalDisclaimerContainer>
      <RhTypography variant="body2">
        <sup>1</sup> {disclaimerText}
      </RhTypography>
    </RenewalDisclaimerContainer>
  );
};
