import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  SingleProductTiePriceDisplayWrapper,
  SingleProductTierPriceContainer,
} from "@portal/components/ProductAddonsOptInConfirmationModal/ProductAddonsOptInConfirmationModal.style";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

interface SingleProductContractDisplayProps {
  productContractDescription: string;
  productContractName: string;
  productContractRate: string;
  productContractTermLength: string;
}

export const SingleProductTiePriceDisplay = (
  props: SingleProductContractDisplayProps
) => {
  const { t } = useRhIntl();

  const {
    productContractTermLength,
    productContractDescription,
    productContractName,
    productContractRate,
  } = props;

  const tAdditionalServiceTitle = t(
    "ProductAddonsOptInConfirmationModal.additionalService"
  );
  const tAdditionalServicePriceTitle = t(
    "ProductAddonsOptInConfirmationModal.price"
  );
  const tAdditionalServiceTermLengthTitle = t(
    "ProductAddonsOptInConfirmationModal.termLength"
  );

  return (
    <SingleProductTiePriceDisplayWrapper>
      <SingleProductTierPriceContainer>
        <RhTypography variant="body2" color="textSecondary" align="right">
          {tAdditionalServiceTitle}
        </RhTypography>
        <div>
          <RhTypography variant="body2" fontWeight={FontWeight.Semibold}>
            {productContractName}
          </RhTypography>
          <RhTypography variant="body2" color="textSecondary">
            {productContractDescription}
          </RhTypography>
        </div>
        <RhTypography variant="body2" color="textSecondary" align="right">
          {tAdditionalServicePriceTitle}
        </RhTypography>
        <RhTypography variant="body2">{productContractRate}</RhTypography>
        <RhTypography variant="body2" color="textSecondary" align="right">
          {tAdditionalServiceTermLengthTitle}
        </RhTypography>
        <RhTypography variant="body2">{productContractTermLength}</RhTypography>
      </SingleProductTierPriceContainer>
    </SingleProductTiePriceDisplayWrapper>
  );
};
