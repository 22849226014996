import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { GuestPayQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseGuestPayPaymentMethodProps {
  enabled?: boolean;
  retry?: number;
  token: UUIDType;
}

export const useGuestPayPaymentMethod = (
  props: UseGuestPayPaymentMethodProps
) => {
  const { token, enabled = true, retry = 3 } = props;

  const query = useQuery({
    enabled,
    queryFn: () => portalApi.guestPay.getPaymentMethod(token),
    queryKey: GuestPayQueryKeys.getPaymentMethod(token),

    // This prevents refetch on the zuora iFrame being added/removed
    refetchOnWindowFocus: false,
    retry,
    select: (data) => {
      return {
        paymentMethod: new PaymentMethod(data),
      };
    },
  });

  return query;
};
