import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import { isCSRs, isOps } from "@common/utils/authenticationHelpers";
import { rhWindow } from "@common/utils/rhWindow";
import { useOktaAuth } from "@okta/okta-react";
import { selectAnalytics } from "@portal/selectors/analyticsSelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import { RHAPSODY_BASE_URL } from "@portal/settings/config";
import { analyticsDisableSendCustomerLoginEvent } from "@portal/slices/analyticsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useRedirectIfCsrOrOps = () => {
  const { data: groups } = useAuthenticatedUserGroups();

  if (isCSRs(groups) || isOps(groups)) {
    rhWindow.location.replace(RHAPSODY_BASE_URL);
  }
};

export const useSendCustomerLoginEvent = () => {
  const { sendCustomerLoginEvent } = useSelector(selectAnalytics);
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;
  const dispatch = useDispatch();
  const customerId = useSelector(selectCurrentCustomerId);

  useEffect(() => {
    if (isAuthenticated && sendCustomerLoginEvent && customerId) {
      track({
        action: ActionType.customerLogin,
        category: "portal",
        event: EventType.portalCustomerLogin,
        label: "Customer logged in",
        value: customerId,
      });
      dispatch(analyticsDisableSendCustomerLoginEvent());
    }
  }, [customerId, dispatch, isAuthenticated, sendCustomerLoginEvent]);
};

export const useFeatureFlagIdentifyCredit = () => {
  const { creditEvaluation, depositAmount, ssnRequired } =
    useSelector(selectSignUpState);

  const {
    featureFlagClient: { sendIdentifyEvent },
  } = useFeatureFlagClient();

  useEffect(() => {
    if (creditEvaluation) {
      sendIdentifyEvent({
        creditEvaluation: determineCreditEvaluationDefinition({
          creditEvaluation,
          depositAmount,
          ssnRequired,
        }),
      });
    }
  }, [creditEvaluation, depositAmount, sendIdentifyEvent, ssnRequired]);
};

export const useFingerPrintIdentify = () => {
  const { fingerprintId } = useSelector(selectSignUpState);

  useEffect(() => {
    if (fingerprintId) {
      track({
        action: ActionType.userIdentified,
        category: "fingerprint",
        event: EventType.identify,
        label: "Fingerprint User Identified",
        value: fingerprintId,
      });
    }
  }, [fingerprintId]);
};
