import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import {
  formatDateOfBirth,
  unformatDateOfBirth,
} from "@common/forms/formatters";
import { ProspectType } from "@common/types/prospectTypes";
import { isValidAdultBirthday } from "@common/utils/dateHelpers";
import { ESI_ID_NOT_FOUND } from "@portal/constants/offer.constant";
import { SignUpStateType } from "@portal/slices/signUpSlice";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export const convertSignUpStatePayloadToProspectType = (
  signUpData: Partial<SignUpStateType>
): Partial<ProspectType> => {
  const {
    acquisition,
    areaNotCovered,
    autopay,
    billingPaymentMethodId,
    campaignSlug,
    dateOfBirth,
    depositAlternativeElected,
    email,
    enrollmentType,
    esiId,
    estimatedMonthlyUsage,
    fingerprintId,
    firstName,
    googleClientId,
    invoiceByPrint,
    lastName,
    offersnapshotId,
    phone,
    productPrices,
    segmentAnonId,
    sendMarketingPromos,
    serviceAddress,
    serviceStartDate,
  } = signUpData;

  const data: Partial<ProspectType> = {
    ...serviceAddress,
    ...acquisition,
    areaNotCovered,
    autopay,
    billingPaymentMethodId,
    dateOfBirth:
      !isNil(dateOfBirth) && isValidAdultBirthday(dateOfBirth)
        ? unformatDateOfBirth(formatDateOfBirth(dateOfBirth || ""))
        : undefined,
    depositAlternativeElected,
    eBillOnly: !isNil(invoiceByPrint) ? !invoiceByPrint : undefined,
    email: email && EMAIL_VALIDATION_REGEX.test(email) ? email : undefined,
    enrollmentType,
    estimatedUsageKilowattHours: estimatedMonthlyUsage,
    fingerprintId,
    firstName,
    googleClientId,
    lastName,
    marketingEmailOptIn: !isNil(sendMarketingPromos)
      ? sendMarketingPromos
      : undefined,
    offersnapshotId,
    phone,
    productPrices,
    rcid: isEmpty(campaignSlug) ? null : campaignSlug,
    segmentAnonId,
    serviceStartDate,
  };

  const cleansedData = omitBy(data, isNil);

  // If we reset esiId (maybe from something to null)
  // we need to make sure it's included in the payload
  // so that we'll unset the value in the db.
  // That means this step has to happen after the above cleansing
  if (esiId === ESI_ID_NOT_FOUND || esiId === null) {
    cleansedData.meterId = null;
  } else if (esiId !== undefined) {
    cleansedData.meterId = esiId;
  }
  return cleansedData;
};
