import { premiseUrls } from "@common/api/premiseUrls";
import { ajax } from "@common/services/ajax";
import {
  AccountSummaryResponseType,
  CreatePaymentExtensionResponseType,
  CreatePaymentResponseType,
  DeferredPaymentPlanListResponseType,
  EDIMessageListResponseType,
  EnrollmentStatusUpdatedResponseType,
  OneClickRenewalResponseType,
  PaymentListResponseType,
  RedeemRewardsResponseType,
  UsageHistoryResponseType,
  WelcomePacketMetadataResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { CreatePaymentExtensionPayloadType } from "@common/types/billingTypes";
import {
  AddressFormType,
  EDIMessagePostDataType,
} from "@common/types/customerTypes";
import {
  CreateDeferredPaymentPlanResponseType,
  CreateDeferredPaymentPlanType,
  SettleDeferredPaymentPlanResponseType,
  SettleDeferredPaymentPlanType,
} from "@common/types/deferredPaymentPlanTypes";
import { EDIMessageType } from "@common/types/ediTypes";
import {
  CreatePaymentDataType,
  CreditCardRetrieveType,
  PaymentMethodsResponseType,
} from "@common/types/paymentMethodTypes";
import {
  PremiseCreateDataType,
  PremiseType,
  WinbackDataType,
} from "@common/types/premiseTypes";

export const premiseApi = {
  accountSummary: {
    retrieve: (premiseId: IdType): Promise<AccountSummaryResponseType> => {
      return ajax.get(premiseUrls.accountSummary.retrieve(premiseId));
    },
  },
  contracts: {
    renew: ({
      premiseId,
      offerSnapshotId,
    }: {
      offerSnapshotId: IdType;
      premiseId: IdType;
    }): Promise<PremiseType> => {
      return ajax.post(premiseUrls.contracts.renew(premiseId), {
        offerSnapshotId,
      });
    },
    swap: (
      id: IdType,
      offerId: IdType,
      swapType: string
    ): Promise<PremiseType> => {
      return ajax.post(premiseUrls.contracts.swap(id), {
        offerSnapshotId: offerId,
        swapType,
      });
    },
  },
  create: (data: PremiseCreateDataType): Promise<PremiseType> => {
    return ajax.post(premiseUrls.create(), data);
  },
  deferredPaymentPlanList: {},
  deferredPaymentPlans: {
    create: (
      premiseId: IdType,
      deferredPaymentPlanData: CreateDeferredPaymentPlanType
    ): Promise<CreateDeferredPaymentPlanResponseType> => {
      return ajax.post<CreateDeferredPaymentPlanResponseType>(
        premiseUrls.deferredPaymentPlans.create(premiseId),
        deferredPaymentPlanData
      );
    },
    downloadContract: (
      premiseId: IdType,
      dppId: IdType
    ): Promise<DeferredPaymentPlanListResponseType> => {
      return ajax.get(premiseUrls.deferredPaymentPlans.list(premiseId));
    },
    list: (premiseId: IdType): Promise<DeferredPaymentPlanListResponseType> => {
      return ajax.get(premiseUrls.deferredPaymentPlans.list(premiseId));
    },
    settle: (
      premiseId: IdType,
      dppId: IdType,
      settleDppData: SettleDeferredPaymentPlanType
    ): Promise<SettleDeferredPaymentPlanResponseType> => {
      return ajax.post<SettleDeferredPaymentPlanResponseType>(
        premiseUrls.deferredPaymentPlans.settle(premiseId, dppId),
        settleDppData
      );
    },
  },
  ediMessages: {
    create: (
      id: IdType,
      messageData: EDIMessagePostDataType,
      premiseId?: IdType
    ): Promise<EDIMessageType> => {
      return ajax.post(premiseUrls.ediMessages.create(id), {
        ...messageData,
        activePremiseId: premiseId,
      });
    },
    list: (premiseId: IdType): Promise<EDIMessageListResponseType> => {
      return ajax.get(premiseUrls.ediMessages.list(premiseId));
    },
  },
  getWelcomePacketMetadata: (
    premiseId: IdType,
    orderId: IdType
  ): Promise<WelcomePacketMetadataResponseType> => {
    return ajax.get(premiseUrls.welcomePacketMetadata(premiseId, orderId));
  },
  paymentExtensions: {
    create: (
      premiseId: IdType,
      paymentExtensionData: CreatePaymentExtensionPayloadType
    ): Promise<CreatePaymentExtensionResponseType> => {
      return ajax.post<CreatePaymentExtensionResponseType>(
        premiseUrls.paymentExtensions.create(premiseId),
        paymentExtensionData
      );
    },
    update: ({
      premiseId,
      paymentExtensionId,
      paymentExtensionData,
    }: {
      paymentExtensionData: CreatePaymentExtensionPayloadType;
      paymentExtensionId: IdType;
      premiseId: IdType;
    }): Promise<CreatePaymentExtensionResponseType> => {
      return ajax.patch<CreatePaymentExtensionResponseType>(
        premiseUrls.paymentExtensions.update(premiseId, paymentExtensionId),
        paymentExtensionData
      );
    },
  },
  paymentMethods: {
    delete: (
      premiseId: IdType,
      paymentMethodId: string
    ): Promise<CreditCardRetrieveType> => {
      return ajax.destroy(
        premiseUrls.paymentMethods.delete(premiseId, paymentMethodId)
      );
    },
    list: (premiseId: IdType): Promise<PaymentMethodsResponseType> => {
      return ajax.get(premiseUrls.listPaymentMethods(premiseId));
    },
  },
  payments: {
    create: (
      premiseId: IdType,
      paymentData: CreatePaymentDataType
    ): Promise<CreatePaymentResponseType> => {
      return ajax.post(premiseUrls.payments.create(premiseId), paymentData);
    },
    list: (premiseId: IdType): Promise<PaymentListResponseType> => {
      return ajax.get(premiseUrls.payments.listPaymentHistory(premiseId));
    },
  },
  redeemRewards: (
    id: IdType,
    amount: number
  ): Promise<RedeemRewardsResponseType> =>
    ajax.post<RedeemRewardsResponseType>(premiseUrls.redeemRewards(id), {
      amount,
    }),
  renewalToken: {
    renew: (
      renewalToken: string,
      renewed: boolean
    ): Promise<OneClickRenewalResponseType> => {
      return ajax.patch(premiseUrls.renewalToken(renewalToken), {
        renewed,
      });
    },
    retrieve: (renewalToken: string): Promise<OneClickRenewalResponseType> => {
      return ajax.get(premiseUrls.renewalToken(renewalToken));
    },
  },
  retrieve: (premiseId: IdType): Promise<PremiseType> => {
    return ajax.get<PremiseType>(premiseUrls.retrieve(premiseId));
  },
  setAveragedBilling: (
    premiseId: IdType,
    value: boolean
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.averagedBilling(premiseId), { value });
  },
  setAveragedBillingMonthlyCharge: (
    premiseId: IdType,
    averagedBillingMonthlyCharge: string
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.averagedBillingMonthlyCharge(premiseId), {
      averagedBillingMonthlyCharge,
    });
  },
  setEnrollmentStatus: (
    id: IdType,
    status: string
  ): Promise<EnrollmentStatusUpdatedResponseType> => {
    return ajax.patch(premiseUrls.setEnrollmentStatus(id), {
      enrollmentStatus: status,
    });
  },
  setEsiId: (id: IdType, value: string): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.esiId(id), { value });
  },
  setMailingAddress: (
    premiseId: IdType,
    values: Partial<AddressFormType>
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.mailingAddress(premiseId), values);
  },
  setPickedBillingDueDay: (
    premiseId: IdType,
    value: number
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.pickedBillingDueDay(premiseId), {
      value,
    });
  },
  updateServiceEndDate: (
    premiseId: IdType,
    endDate: string
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.serviceEndDate(premiseId), {
      endDate,
    });
  },
  updateServiceStartDate: (
    premiseId: IdType,
    startDate: string
  ): Promise<PremiseType> => {
    return ajax.patch(premiseUrls.serviceStartDate(premiseId), {
      startDate,
    });
  },
  usageHistory: (id: IdType): Promise<UsageHistoryResponseType> => {
    return ajax.get(premiseUrls.usageHistory(id));
  },
  winback: (
    premiseId: IdType,
    winbackData: WinbackDataType
  ): Promise<PremiseType> =>
    ajax.post<PremiseType>(premiseUrls.winback(premiseId), winbackData),
};
