import { GuestAndQuickPayConfirmation } from "@portal/components/GuestAndQuickPayConfirmation/GuestAndQuickPayConfirmation";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const GuestAndQuickPayConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [remainingBalance, setRemainingBalance] = useState<string | null>(null);
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
  const [confirmationNumber, setConfirmationNumber] = useState<string | null>(
    null
  );

  const { state, pathname } = location;

  useEffect(() => {
    if (state?.remainingBalance && state?.confirmationNumber) {
      setRemainingBalance(state.remainingBalance);
      setConfirmationNumber(state.confirmationNumber);
      if (state?.isDisconnected) {
        setIsDisconnected(state.isDisconnected);
      }

      // This will clear the state from the router but remain on the page
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, state]);

  if (!remainingBalance || !confirmationNumber) {
    return null;
  }

  return (
    <GuestAndQuickPayConfirmation
      remainingBalance={remainingBalance}
      confirmationNumber={confirmationNumber}
      isDisconnected={isDisconnected}
    />
  );
};
