import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PaymentMethodsSection } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal/components/PortalCard/PortalCard.styled";
import { usePremise } from "@portal/hooks/usePremise";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

export const MyAccountPaymentMethodsCard = () => {
  const { t } = useRhIntl();
  const { premise, requestMonitor } = usePremise();

  const title = t("MyAccountPaymentMethodsCard.title");

  if (!requestMonitor.didSucceed || !premise) {
    return null;
  }

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>
      {premise.isNoCheckOrAch && premise.isNoCreditOrDebitCard ? (
        <RhTypography variant="body1">
          {t("MyAccountPaymentMethodsCard.unableToCollect")}
        </RhTypography>
      ) : (
        <PaymentMethodsSection />
      )}
    </PortalCard>
  );
};
