import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { IterableOptionType } from "@common/types/notificationTypes";
import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  StyledNotificationsOptionRow,
  StyledNotificationsOptionText,
} from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches.styled";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";
import { useField } from "react-final-form";

interface NotificationOptionRowComponentProps {
  customerId: IdType;
  option: IterableOptionType;
}

export const NotificationOptionRow = ({
  option,
  customerId,
}: NotificationOptionRowComponentProps) => {
  const { t } = useRhIntl();
  const flash = useRhFlash();

  const optionMessageName = `messageId${option.messageId}`;
  const {
    input: { checked, onChange },
  } = useField<boolean>(optionMessageName, { type: "checkbox" });

  const updateSubscriptionStatus = () => {
    const updateStatus = !checked;
    const action = updateStatus ? "subscribe" : "unsubscribe";
    const { subscriptionGroup, messageId } = option;

    api.customers
      .updateSubscriptionStatus(customerId, {
        action,
        channelId: messageId,
        subscriptionGroup,
      })
      .then(() => {
        flash.success(t("NotificationOptionSwitches.successfullyUpdated"));
        onChange(updateStatus);
      })
      .catch((error: RhApiError) => {
        flash.error(
          t("NotificationOptionSwitches.apiErrorUpdatingNotifications")
        );
      });
  };

  return (
    <StyledNotificationsOptionRow>
      <StyledNotificationsOptionText variant="subtitle2">
        {t(option.channelNameIntlId as TranslationKey)}
      </StyledNotificationsOptionText>
      <RhToggle
        name={optionMessageName}
        checked={checked}
        onChange={updateSubscriptionStatus}
      />
    </StyledNotificationsOptionRow>
  );
};
