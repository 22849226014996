import { noop } from "@common/utils/genericFunctions";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { areaNotServiceableModalTranslations } from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModal.en.i18n";
import { AreaNotServiceableModalConfirmation } from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModalConfirmation";
import { AreaNotServiceableModalForm } from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModalForm";
import { MARKETING_RHYTHM_URL } from "@portal/constants/urls.constant";
import { useTrackServiceUnavailableMutation } from "@portal/hooks/mutations/useTrackServiceUnavailable.mutation";
import { useTranslations } from "@portal/hooks/useTranslations";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

interface UtilityInput {
  email: string;
}

export interface AreaNotServiceableModalProps {
  handleClose: () => void;
}

export const AreaNotServiceableModal = ({
  handleClose,
}: AreaNotServiceableModalProps) => {
  const { translate } = useTranslations();
  const dispatch = useDispatch();
  const prospectId = useSelector(selectProspectId);
  const [isEmailSubmited, setIsEmailSubmited] = useState<boolean>(false);
  const { mutate } = useTrackServiceUnavailableMutation();
  const { tAreaNotServiceableModalTitle } = translate(
    areaNotServiceableModalTranslations
  );

  const handleEmailSubmit: SubmitHandler<UtilityInput> = ({ email }) => {
    dispatch(setSignUpInfo({ email }));
    setIsEmailSubmited(true);
    mutate({ email, id: prospectId });
  };

  const handleTryAnotherZipCode = () => {
    const updateZipCodeInput = document.querySelector(
      "#updateZipCodeInput"
    ) as HTMLInputElement;

    if (updateZipCodeInput) {
      updateZipCodeInput.focus();
      updateZipCodeInput.select();
      handleClose();
    }
  };

  const handleBackToMarketing = () => {
    handleClose();
    return window.location.replace(MARKETING_RHYTHM_URL);
  };

  return (
    <RhModal
      disableBackgroundClose
      hideCloseButton
      ariaLabel={tAreaNotServiceableModalTitle}
      ariaCloseLabel=""
      handleClose={noop}
      size={ModalWidths.md}
    >
      {!isEmailSubmited ? (
        <AreaNotServiceableModalForm
          onEmailSubmit={handleEmailSubmit}
          onTryAnotherZipCode={handleTryAnotherZipCode}
        />
      ) : (
        <AreaNotServiceableModalConfirmation
          onBackToHome={handleBackToMarketing}
          onTryAnotherZipCode={handleTryAnotherZipCode}
        />
      )}
    </RhModal>
  );
};
