export const deviceConnectionActivityTableTranslations = {
  tDeviceConnectionActivityTableActivity: "Device Activity",
  tDeviceConnectionActivityTableDate: "Date",
  tDeviceConnectionActivityTableDeviceAuthorized: "Device Authorized",
  tDeviceConnectionActivityTableDeviceOffline: "Device Offline",
  tDeviceConnectionActivityTableDeviceOnline: "Device Online",
  tDeviceConnectionActivityTableDeviceOptOut: "Demand Response Opt Out",
  tDeviceConnectionActivityTableDeviceUnauthorized: "Device Unauthorized",
  tDeviceConnectionActivityTableTime: "Time",
};
