import { darkPurple } from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const PortalRadioButton = styled.input`
  /* Remove most all native input styles */
  appearance: none;
  border: 1px solid ${darkPurple[300]};
  border-radius: 50%;
  color: currentColor;
  cursor: pointer;
  display: grid;
  font: inherit;
  height: 18px;
  /* Not removed via appearance */
  margin: 0;
  place-content: center;
  transform: translateY(-0.075em);
  width: 18px;

  &:hover {
    background-color: #4500a80a;
  }

  &:checked::before {
    background-color: ${darkPurple[500]};
    border-radius: 50%;
    box-shadow: inset 1px 1px ${darkPurple[500]};
    content: "";
    height: 12px;
    width: 12px;
  }
`;

export const PortalRadioLabel = styled.label`
  cursor: pointer;
  display: grid;
  font-size: 14px;
  gap: 12px;
  grid-template-columns: 1em auto;
  line-height: 1.1;
`;
