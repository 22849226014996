import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { grey } from "@design-system/theme/palette.colors";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Tab } from "@mui/material";
import styled from "styled-components";

export const StyledRhRoundTabItem = styled(Tab)`
  &.MuiTab-root {
    font-family: ${fontFamily};
    font-size: 12px;
    font-weight: ${FontWeight.Medium};
    height: 32px;
    letter-spacing: normal;
    line-height: 1;
    min-height: 0;
    opacity: 1;
    padding: 6px 12px;
    text-transform: capitalize;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      min-width: 70px;
    }
  }

  & .MuiTab-wrapped {
    color: ${grey[900]};
  }
`;
