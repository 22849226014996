import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { Order } from "@common/models/Order.model";
import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  competitorPlanOfferSnapshotUUIDAtom,
  competitorPlanTermMonthsAtom,
} from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison.atoms";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { MINIMUM_AMOUNT_TO_SHOW_SAVINGS_TAG } from "@portal/components/RenewalAnnualSavingsTag/RenewalAnnualSavingsTag.constants";
import { RenewalCurrentPlanCard } from "@portal/components/RenewalCurrentPlanCard/RenewalCurrentPlanCard";
import { RenewalOfferSnapshotCard } from "@portal/components/RenewalOfferSnapshotCard/RenewalOfferSnapshotCard";
import { RenewalDisclaimer } from "@portal/components/RenewalPageLayout/RenewalDisclaimer";
import { renewalPageLayoutTranslations } from "@portal/components/RenewalPageLayout/RenewalPageLayout.en.i18n";
import {
  StyledCompetitorPlanChart,
  StyledOffersContainer,
} from "@portal/components/RenewalPageLayout/RenewalPageLayout.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as TandemBikeLeft } from "@portal/images/TandemBikeLeft.svg";
import { ReactComponent as TandemBikeRight } from "@portal/images/TandemBikeRight.svg";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import {
  StyledExploreTitle,
  StyledPageBackground,
  StyledTandemBikeLeft,
  StyledTandemBikeRight,
  StyledTitle,
  StyledTitleContainer,
} from "@portal/styled/portalPageLayout.styled";
import { useSetAtom } from "jotai";
import React, { useEffect, useId } from "react";

interface RenewalPageLayoutProps {
  currentOrder: Order;
  currentOrderOfferSnapshot: OfferSnapshot;
  estimatedSavings?: string;
  exploreOtherOffersTitle: string;
  handleSelectOfferSnapshot: (offerSnapshot: OfferSnapshot) => void;
  otherOfferSnapshots: OfferSnapshot[] | undefined;
  premiseId?: IdType;
  renewalOfferSnapshot: OfferSnapshot | null;
  subtitle: string | JSX.Element;
  title: string;
}

export const RenewalPageLayout = (props: RenewalPageLayoutProps) => {
  const {
    currentOrder,
    currentOrderOfferSnapshot,
    exploreOtherOffersTitle,
    handleSelectOfferSnapshot,
    otherOfferSnapshots,
    premiseId,
    renewalOfferSnapshot,
    subtitle,
    title,
    estimatedSavings,
  } = props;

  const { translate } = useTranslations();
  const { addModal } = useModals();

  const { featureFlagClient } = useFeatureFlagClient();

  const setCompetitorPlanOfferSnapshotUUIDAtom = useSetAtom(
    competitorPlanOfferSnapshotUUIDAtom
  );

  const setCompetitorPlanTermMonthsAtom = useSetAtom(
    competitorPlanTermMonthsAtom
  );

  const { portalRenewalsCompetitorPlanChart, portalEstimatedAnnualSavingsTag } =
    featureFlagClient.getFlags([
      ["portalRenewalsCompetitorPlanChart", false],
      ["portalEstimatedAnnualSavingsTag", false],
    ]);

  const { tRenewalPageLayoutSelectPlan } = translate(
    renewalPageLayoutTranslations
  );

  const showCurrentPlanCard = renewalOfferSnapshot
    ? renewalOfferSnapshot.price2000Kwh <=
      currentOrderOfferSnapshot.price2000Kwh
    : true;

  const allOfferSnapshots = [
    ...(renewalOfferSnapshot ? [renewalOfferSnapshot] : []),
    ...(otherOfferSnapshots ?? []),
  ];

  const showCompetitorPlanChart =
    portalRenewalsCompetitorPlanChart.value &&
    allOfferSnapshots.every((offerSnapshot) => !offerSnapshot.solarEligible);

  useEffect(() => {
    // If we have a renewal offersnapshot available set it and its term months and initial values
    if (
      renewalOfferSnapshot &&
      !renewalOfferSnapshot.solarEligible &&
      showCompetitorPlanChart
    ) {
      setCompetitorPlanTermMonthsAtom(
        renewalOfferSnapshot.termMonths.toString()
      );
      setCompetitorPlanOfferSnapshotUUIDAtom(renewalOfferSnapshot.uuid);
    }
  }, [
    renewalOfferSnapshot,
    setCompetitorPlanOfferSnapshotUUIDAtom,
    setCompetitorPlanTermMonthsAtom,
    showCompetitorPlanChart,
  ]);

  const onLearnMoreClick = (offerSnapshot: OfferSnapshot) => {
    track({
      action: ActionType.click,
      category: CategoryType.Renewals,
      event: EventType.renewalClick,
      label: LabelType.ClickedRenewalOfferCardLearnMore,
      offerSnapshotId: offerSnapshot.id,
      offerSnapshotPrice: offerSnapshot.price,
      offerSnapshotTitle: offerSnapshot.title,
      orderId: currentOrder.id,
      premiseId,
    });

    addModal({
      offerSnapshotLearnMore: {
        offerSnapshotUUID: offerSnapshot.uuid,
        onEnrollClick: handleSelectOfferSnapshot,
        selectPlanCtaText: tRenewalPageLayoutSelectPlan,
      },
    });
  };

  const competitorPlansChartId = useId();

  const compareRatesClickHandler = (
    offerSnapshot: OfferSnapshot,
    position: string | number
  ) => {
    track({
      action: ActionType.click,
      event: EventType.renewalClick,
      label: "Renewal Offer Compare Rates",
      offerSnapshotId: offerSnapshot.id,
      orderId: currentOrder.id,
      position,
      premiseId,
    });

    setCompetitorPlanTermMonthsAtom(offerSnapshot.termMonths.toString());
    setCompetitorPlanOfferSnapshotUUIDAtom(offerSnapshot.uuid);

    document
      ?.querySelector(`#${CSS.escape(competitorPlansChartId)}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const onSelectOfferSnapshotCardClick = (offerSnapshot: OfferSnapshot) => {
    track({
      action: ActionType.click,
      category: CategoryType.Renewals,
      event: EventType.renewalClick,
      label: LabelType.ClickedRenewalOfferCardRenewNow,
      offerSnapshotId: offerSnapshot.id,
      offerSnapshotPrice: offerSnapshot.price,
      offerSnapshotTitle: offerSnapshot.title,
      orderId: currentOrder.id,
      premiseId,
    });

    handleSelectOfferSnapshot(offerSnapshot);
  };

  const onSelectCompetitorPlanClick = (offerSnapshot: OfferSnapshot) => {
    track({
      action: ActionType.click,
      category: CategoryType.Renewals,
      event: EventType.renewalClick,
      label: LabelType.ClickedRenewalCompetitorChartSelectPlan,
      offerSnapshotId: offerSnapshot.id,
      orderId: currentOrder.id,
      premiseId,
    });

    handleSelectOfferSnapshot(offerSnapshot);
  };

  const showSavingsDisclaimer =
    portalEstimatedAnnualSavingsTag.value &&
    renewalOfferSnapshot &&
    Number(estimatedSavings) >= MINIMUM_AMOUNT_TO_SHOW_SAVINGS_TAG;

  return (
    <PortalPageLayout>
      <StyledTandemBikeLeft>
        <TandemBikeLeft />
      </StyledTandemBikeLeft>
      <StyledTandemBikeRight>
        <TandemBikeRight />
      </StyledTandemBikeRight>
      <StyledPageBackground />
      <StyledTitleContainer>
        <StyledTitle align="center" variant="h1">
          {title}
        </StyledTitle>
        <RhTypography align="center" variant="subtitle1">
          {subtitle}
        </RhTypography>
      </StyledTitleContainer>
      <StyledOffersContainer>
        {showCurrentPlanCard && (
          <RenewalCurrentPlanCard
            order={currentOrder}
            offerSnapshot={currentOrderOfferSnapshot}
            premiseId={premiseId}
          />
        )}
        {renewalOfferSnapshot ? (
          <RenewalOfferSnapshotCard
            recommended
            offerSnapshot={renewalOfferSnapshot}
            onLearnMoreClick={onLearnMoreClick}
            onSelect={onSelectOfferSnapshotCardClick}
            estimatedSavings={estimatedSavings}
            onCompareClick={
              showCompetitorPlanChart && !renewalOfferSnapshot.solarEligible
                ? () =>
                    compareRatesClickHandler(
                      renewalOfferSnapshot,
                      "recommended"
                    )
                : undefined
            }
          />
        ) : null}
      </StyledOffersContainer>
      <StyledExploreTitle align="center" variant="h2">
        {exploreOtherOffersTitle}
      </StyledExploreTitle>
      {otherOfferSnapshots ? (
        <StyledOffersContainer>
          {otherOfferSnapshots.map((offerSnapshot, index) => {
            return (
              <RenewalOfferSnapshotCard
                key={offerSnapshot.uuid}
                offerSnapshot={offerSnapshot}
                onLearnMoreClick={onLearnMoreClick}
                onSelect={onSelectOfferSnapshotCardClick}
                onCompareClick={
                  showCompetitorPlanChart && !offerSnapshot.solarEligible
                    ? () => compareRatesClickHandler(offerSnapshot, index + 1)
                    : undefined
                }
              />
            );
          })}
        </StyledOffersContainer>
      ) : null}

      {showCompetitorPlanChart ? (
        <StyledCompetitorPlanChart
          offerSnapshots={allOfferSnapshots}
          miniCardSubmitText={tRenewalPageLayoutSelectPlan}
          onSelectPlanClick={onSelectCompetitorPlanClick}
          onLearnMoreClick={onLearnMoreClick}
          id={competitorPlansChartId}
        />
      ) : null}

      {showSavingsDisclaimer ? (
        <RenewalDisclaimer
          currentOfferSnapshot={currentOrderOfferSnapshot}
          renewalOfferSnapshot={renewalOfferSnapshot}
        />
      ) : null}
    </PortalPageLayout>
  );
};
