import { formatShorthandMonthDayYear } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { renewalReminderModalTranslations } from "@portal/components/RenewalReminderModal/RenewalReminderModal.en.i18n";
import {
  RenewalReminderContentContainer,
  RenewalReminderPaperButton,
  RenewalReminderReasonsList,
  RenewalReminderTitle,
  RenewalReminderTitleContainer,
  RenewalReminderWhyRhythm,
  StyledRenewalReminderModal,
} from "@portal/components/RenewalReminderModal/RenewalReminderModal.styled";
import { TrackingView } from "@portal/components/TrackingView/TrackingView";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as GreyCircularHeartIcon } from "@portal/images/GreyCircularHeartIcon.svg";
import { ReactComponent as GreyCircularLockIcon } from "@portal/images/GreyCircularLockIcon.svg";
import { ReactComponent as GreyCircularWalletIcon } from "@portal/images/GreyCircularWalletIcon.svg";
import { renewalsPath } from "@portal/routes/routePaths";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

interface RenewalReminderModalProps {
  handleClose: () => void;
}

export const RenewalReminderModal = (props: RenewalReminderModalProps) => {
  const { handleClose } = props;

  const navigate = useNavigate();

  const { setCookie } = usePortalCookies();

  const { translate, translateJsx } = useTranslations();
  const {
    tRenewalReminderModalWhyRhythm,
    tRenewalReminderModalBestRate,
    tRenewalReminderModalFixedRate,
    tRenewalReminderModalEnjoyElectricity,
    tRenewalReminderModalCTA,
    tRenewalReminderModalClose,
    tRenewalReminderModalModalTitle,
  } = translate(renewalReminderModalTranslations);

  const customer = useCustomerFromContext();
  const { premise } = usePremise();

  if (!premise) {
    return null;
  }

  if (!premise.currentOrder) {
    return null;
  }

  const { tRenewalReminderModalSubTitle, tRenewalReminderModalTitle } =
    translateJsx({
      tRenewalReminderModalSubTitle: {
        contractEndDate: formatShorthandMonthDayYear(
          premise.currentOrder.expectedEndDate ?? ""
        ),
      },
      tRenewalReminderModalTitle: {
        firstName: customer.firstName,
      },
    });

  const handleOnClose = () => {
    const daysUntilOrderExpires = Number(
      premise.currentOrder?.daysUntilOrderExpires
    );
    let reminderDuration;

    switch (true) {
      case daysUntilOrderExpires <= 14: {
        reminderDuration = 1;
        break;
      }
      case daysUntilOrderExpires > 14 && daysUntilOrderExpires < 21: {
        reminderDuration = daysUntilOrderExpires - 14;
        break;
      }
      default: {
        reminderDuration = 7;
      }
    }

    setCookie("rhRenewalReminderModal", "hide", {
      expires: dayjs().add(reminderDuration, "days").toDate(),
    });

    handleClose();
  };

  const handleViewRenewalOptionsClick = () => {
    setCookie("rhRenewalReminderModal", "hide", {
      expires: dayjs().add(1, "days").toDate(),
    });
    navigate(renewalsPath());
    handleClose();
  };

  return (
    <StyledRenewalReminderModal
      ariaLabel={tRenewalReminderModalModalTitle}
      handleClose={handleOnClose}
      ariaCloseLabel=""
      noPadding
      hideCloseButton
    >
      <TrackingView data={{ event: "Renewal modal being shown" }} />
      <RenewalReminderTitleContainer>
        <RenewalReminderTitle variant="h1">
          {tRenewalReminderModalTitle}
        </RenewalReminderTitle>
        <RhTypography color="textSecondary" variant="subtitle2">
          {tRenewalReminderModalSubTitle}
        </RhTypography>
      </RenewalReminderTitleContainer>
      <RenewalReminderContentContainer>
        <RenewalReminderWhyRhythm variant="h4">
          {tRenewalReminderModalWhyRhythm}
        </RenewalReminderWhyRhythm>

        <RenewalReminderReasonsList>
          <li>
            <GreyCircularWalletIcon />
            <RhTypography
              variant="subtitle2"
              color="textSecondary"
              component="span"
            >
              {tRenewalReminderModalBestRate}
            </RhTypography>
          </li>

          <li>
            <GreyCircularLockIcon />
            <RhTypography
              variant="subtitle2"
              color="textSecondary"
              component="span"
            >
              {tRenewalReminderModalFixedRate}
            </RhTypography>
          </li>

          <li>
            <GreyCircularHeartIcon />
            <RhTypography
              variant="subtitle2"
              color="textSecondary"
              component="span"
            >
              {tRenewalReminderModalEnjoyElectricity}
            </RhTypography>
          </li>
        </RenewalReminderReasonsList>

        <RhButton
          data-tracking-click={{ event: "Customer going to renewals page" }}
          fullWidth
          onClick={handleViewRenewalOptionsClick}
        >
          {tRenewalReminderModalCTA}
        </RhButton>
        <RenewalReminderPaperButton
          data-tracking-click={JSON.stringify({
            event: "Customer clicked remind me later on renewals modal",
          })}
          onClick={handleOnClose}
        >
          {tRenewalReminderModalClose}
        </RenewalReminderPaperButton>
      </RenewalReminderContentContainer>
    </StyledRenewalReminderModal>
  );
};
