import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as CalculatorIcon } from "@design-system/icons/CalculatorIcon.svg";
import { ReactComponent as PhoneIcon } from "@design-system/icons/PhoneIcon.svg";
import { ReactComponent as Scale } from "@design-system/icons/Scale.svg";
import { OfferCardElementProps } from "@portal/components/OfferCard/OfferCard.interfaces";
import {
  StyledCardActions,
  StyledCardCallout,
  StyledCardDescription,
  StyledCardHeader,
  StyledCompareButton,
  StyledOfferFeatures,
  StyledPhoneLink,
  StyledPhoneWrapper,
  StyledRateCalculateText,
  StyledRateInfo,
  StyledRateInfoCalculateButton,
  StyledRateInfoChild,
} from "@portal/components/OfferCard/OfferCard.styled";
import { OfferFeatures } from "@portal/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate";
import React from "react";

export const OfferCardDesktop = ({
  offerSnapshot,
  calloutBackgroundColor,
  calloutText,
  hideCostEstimatorButton,
  handleCalculateBillClick,
  calculateCtaMessage,
  compareButtonProps,
  moreButtonProps,
  selectButtonProps,
  offerCardPhoneNumber,
  trackPhoneClick,
  locale = "en",
}: OfferCardElementProps) => {
  return (
    <>
      {Boolean(calloutText) && (
        <StyledCardCallout $calloutBackgroundColor={calloutBackgroundColor}>
          <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
            {calloutText}
          </RhTypography>
        </StyledCardCallout>
      )}

      <StyledCardHeader $desktop>
        <RhTypography variant="h3">{offerSnapshot.title}</RhTypography>
        <StyledCardDescription variant="body1" color="textSecondary">
          {offerSnapshot.description(locale)}
        </StyledCardDescription>
      </StyledCardHeader>

      <RhDivider light />

      <StyledOfferFeatures>
        <OfferFeatures offerSnapshot={offerSnapshot} />
      </StyledOfferFeatures>

      <RhDivider light />

      <StyledRateInfo>
        <StyledRateInfoChild>
          <OfferRateAndEstimate offerSnapshot={offerSnapshot} />
        </StyledRateInfoChild>
        {!hideCostEstimatorButton && (
          <StyledRateInfoChild>
            <StyledRateInfoCalculateButton
              type="button"
              onClick={handleCalculateBillClick}
              data-testid="OfferCard__CalculateBillButton"
              $desktop
            >
              <CalculatorIcon />
              <StyledRateCalculateText variant="body3" component="span">
                {calculateCtaMessage}
              </StyledRateCalculateText>
            </StyledRateInfoCalculateButton>
          </StyledRateInfoChild>
        )}
      </StyledRateInfo>

      <RhDivider light />

      {compareButtonProps.showCompareButton && (
        <>
          <RhFlexBox py={1.5}>
            <StyledCompareButton
              type="button"
              onClick={compareButtonProps.onClick}
              aria-label={compareButtonProps.text}
              $desktop
            >
              <Scale /> {compareButtonProps.text}
            </StyledCompareButton>
          </RhFlexBox>

          <RhDivider light />
        </>
      )}

      <StyledCardActions data-testid="OfferCard__cardActions">
        <RhFlexBox
          gap="10px"
          flexDirection="row"
          data-testid="OfferCard__buttonGroup"
        >
          <RhButton
            data-tracking-click={{
              event: "Prospect opening learn more modal",
            }}
            color="primary"
            size="small"
            variant="outlined"
            onClick={moreButtonProps.onClick}
            id={`offerSnapshot-learnMore-${offerSnapshot.id}`}
            style={{
              flex: 1,
              order: 0,
            }}
          >
            {moreButtonProps.text}
          </RhButton>
          <RhButton
            data-tracking-click={{
              event: "Prospect selecting a plan",
            }}
            color="primary"
            size="small"
            onClick={selectButtonProps.onClick}
            id={`offerSnapshot-selectButton offerSnapshot-selectButton-${offerSnapshot.id}`}
            data-testid={`OfferCard__SelectOfferButton-${offerSnapshot.id}`}
            style={{
              flex: 1,
              order: 1,
            }}
          >
            {selectButtonProps.text}
          </RhButton>
        </RhFlexBox>
        <RhFlexBox alignItems="center" justifyContent="center" pt={2}>
          <StyledPhoneLink
            href={`tel:${offerCardPhoneNumber}`}
            onClick={trackPhoneClick}
          >
            <StyledPhoneWrapper as="span">
              <PhoneIcon />
              <RhTypography variant="body1" fontWeight={FontWeight.Regular}>
                {offerCardPhoneNumber}
              </RhTypography>
            </StyledPhoneWrapper>
          </StyledPhoneLink>
        </RhFlexBox>
      </StyledCardActions>
    </>
  );
};
