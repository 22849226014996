import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useModals } from "@portal/components/ModalsManager/useModals";
import {
  StyledMiniOfferCard,
  StyledMiniOfferCardActions,
  StyledMiniOfferCardBody,
  StyledMiniOfferCardContent,
  StyledMiniOfferCardRoot,
  StyledMiniOfferCardTitle,
} from "@portal/components/OfferMiniChooser/MiniOfferCard.styled";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";

interface MiniOfferCardProps {
  offerSnapshot: OfferSnapshotType;
  onSelect: (offerSnapshot: OfferSnapshotType) => void;
}

export const MiniOfferCard = ({
  offerSnapshot: rawOffer,
  onSelect,
}: MiniOfferCardProps) => {
  const { addModal } = useModals();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const offerSnapshot = new OfferSnapshot(rawOffer);
  const { t, tJsx } = useRhIntl();
  const { estimatedMonthlyUsage } = useSelector(selectSignUpState);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(RhythmBreakpoints.XS));
  const { title } = offerSnapshot;
  const price = formatCurrency(
    offerSnapshot.averageMonthlyPrice(estimatedMonthlyUsage),
    0
  );
  const perMonth = tJsx("OfferMiniChooser.perMonth", {
    price,
    priceTag: (content) => (
      <RhTypography component="span" variant="h4" fontWeight={FontWeight.Bold}>
        {content}
      </RhTypography>
    ),
  });
  const selectPlanText = t("OfferMiniChooser.selectPlan");
  const learnMoreText = t("OfferMiniChooser.learnMore");

  return (
    <StyledMiniOfferCard>
      <StyledMiniOfferCardRoot>
        <StyledMiniOfferCardContent>
          <StyledMiniOfferCardTitle>
            <RhTypography variant="body1" fontWeight={FontWeight.Bold}>
              {title}
            </RhTypography>
          </StyledMiniOfferCardTitle>
          <StyledMiniOfferCardBody>
            <RhTypography variant="body1">{perMonth}</RhTypography>
          </StyledMiniOfferCardBody>
        </StyledMiniOfferCardContent>
        <StyledMiniOfferCardActions>
          <RhButton
            data-tracking-click={{
              event: "Prospect selecting a plan",
            }}
            fullWidth={isDesktop}
            size="small"
            onClick={(_event) => onSelect(rawOffer)}
            color="primary"
          >
            {selectPlanText}
          </RhButton>

          <RhButton
            data-tracking-click={{
              event: "Prospect opening learn more modal",
            }}
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              addModal({
                offerSnapshotLearnMore: {
                  offerSnapshotUUID: offerSnapshot.uuid,
                  onEnrollClick: () => {
                    handleEnrollNowClick({
                      estimatedMonthlyUsage,
                      offerSnapshot,
                    });
                  },
                  selectPlanCtaText: selectPlanText,
                },
              });
            }}
          >
            {learnMoreText}
          </RhButton>
        </StyledMiniOfferCardActions>
      </StyledMiniOfferCardRoot>
    </StyledMiniOfferCard>
  );
};
