import { customerPreferencesPremiseUpdated } from "@portal/slices/customerPreferencesSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const getPremiseQueryParams = (search: string) => {
  const query = new URLSearchParams(search);

  return {
    premise: query.get("premise") ?? null,
  };
};

export const usePremiseQueryParams = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { premise } = getPremiseQueryParams(search);

  useEffect(() => {
    if (premise) {
      dispatch(customerPreferencesPremiseUpdated(premise));
    }
  }, []);
};
