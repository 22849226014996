import { IdType } from "@common/types/apiTypes";
import { CustomerPreferencesStateType } from "@portal/slices/customerPreferencesSlice";
import {
  AllSupportedPortalLanguages,
  SupportedPortalLanguage,
  englishLanguageCode,
} from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";

export const selectCustomerLanguagePreference = ({
  customerPreferences: { languagePreference },
}: {
  customerPreferences: CustomerPreferencesStateType;
}): SupportedPortalLanguage => {
  if (languagePreference) {
    return languagePreference;
  }

  const navigatorLangCode = window.navigator?.language.slice(0, 2); // en-US = en

  if (
    navigatorLangCode &&
    AllSupportedPortalLanguages.includes(
      navigatorLangCode as SupportedPortalLanguage
    )
  ) {
    return navigatorLangCode as SupportedPortalLanguage;
  }

  return englishLanguageCode;
};

export const selectCustomerPremisePreference = ({
  customerPreferences: { premiseId },
}: PortalStoreState): IdType => premiseId || "";
