import { ajax } from "@common/services/ajax";
import { UUIDType } from "@common/types/apiTypes";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import {
  SignUpOfferSnapshotUrls,
  SignUpProductUrls,
} from "@portal/api/signUpUrls";
import { ProductTierType } from "@portal/types/productTypes";

export const signUpApi = {
  offerSnapshots: {
    retrieve: (uuid: UUIDType) =>
      ajax.get<OfferSnapshotType>(SignUpOfferSnapshotUrls.retrieve(uuid)),
  },
  products: {
    tiers: {
      list: () => ajax.get<ProductTierType[]>(SignUpProductUrls.list()),
    },
  },
};
