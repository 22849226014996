import { formatCurrency } from "@common/utils/dataFormatters";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { renewalAnnualSavingsTagTranslations } from "@portal/components/RenewalAnnualSavingsTag/RenewalAnnualSavingsTag.en.i18n";
import {
  SavingsTagContainer,
  Tag,
  TextContainer,
} from "@portal/components/RenewalAnnualSavingsTag/RenewalAnnualSavingsTag.styled";
import { ReactComponent as String } from "@portal/components/RenewalAnnualSavingsTag/SavingsTagString.svg";
import { TrackingView } from "@portal/components/TrackingView/TrackingView";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

interface RenewalAnnualSavingsTagProps {
  estimatedSavings: string;
}

export const RenewalAnnualSavingsTag = (
  props: RenewalAnnualSavingsTagProps
) => {
  const { estimatedSavings } = props;

  const { translate } = useTranslations();

  const { tRenewalAnnualSavingsTagEstimatedSavings } = translate(
    renewalAnnualSavingsTagTranslations
  );

  const roundedEstimatedSavings =
    Number(estimatedSavings) < 1000
      ? Math.round(Number(estimatedSavings) / 10) * 10
      : Math.round(Number(estimatedSavings) / 100) * 100;

  return (
    <SavingsTagContainer aria-label="Annual Renewal Savings tag">
      <TrackingView
        data={{
          event: "Annual Renewal Savings Tag viewed",
          savingsAmount: estimatedSavings,
        }}
      />
      <String aria-hidden />

      <Tag>
        <TextContainer>
          <RhTypography variant="body2">
            {tRenewalAnnualSavingsTagEstimatedSavings}
            <sup>1</sup>
          </RhTypography>

          <RhDivider />

          <RhTypography variant="body2">
            {formatCurrency(roundedEstimatedSavings, 0)}
          </RhTypography>
        </TextContainer>
      </Tag>
    </SavingsTagContainer>
  );
};
