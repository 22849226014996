import { UUIDType } from "@common/types/apiTypes";

const PROSPECT_BASE_URL = "/api/prospects";

export const prospectUrls = {
  create: () => `${PROSPECT_BASE_URL}/`,
  getReferralCode: (uuid: UUIDType, code: string) =>
    `${PROSPECT_BASE_URL}/${uuid}/referral-code/${code}/`,
  retrieve: (uuid: UUIDType) => `${PROSPECT_BASE_URL}/${uuid}`,
  update: (uuid: UUIDType) => `${PROSPECT_BASE_URL}/${uuid}`,
};
