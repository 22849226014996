import {
  ZUORA_ADD_BANK_ACCOUNT_PAGE_ID,
  ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID,
  ZUORA_URL,
} from "@portal/settings/config";

export const getZuoraParams = (locale: string, isAddBankAccount = false) => ({
  id: isAddBankAccount
    ? ZUORA_ADD_BANK_ACCOUNT_PAGE_ID
    : ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID,
  locale,
  param_supportedTypes: "AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
  style: "inline",
  submitEnabled: "true",
  url: `${ZUORA_URL}/apps/PublicHostedPageLite.do`,
});
