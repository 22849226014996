import { darkPurple } from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const RhAnchor = styled.a`
  color: ${darkPurple["500"]};
  text-decoration: none;

  &:hover {
    color: ${darkPurple["300"]};
    text-decoration: underline;
  }

  &:active {
    color: ${darkPurple["700"]};
    text-decoration: underline;
  }
`;
