import { RhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs";
import styled from "styled-components";

export const StyledDatePickerWrapper = styled.div`
  margin-right: auto;
  max-width: 150px;

  & .MuiInputBase-input {
    height: 30px;
    padding: 0 12px;
  }
`;

export const TabContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const StyledRhRoundTabs = styled(RhRoundTabs)`
  align-items: center;
  max-width: 100%;
`;

export const EnergyGraphContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DateAndTotalsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  text-align: center;
`;
