import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { offerSnapshotThermostatRequiredModalTranslations } from "@portal/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal.en.i18n";
import {
  StyledBrandIcon,
  StyledBrandIconContainer,
  StyledContainer,
  StyledTextButton,
  StyledTitle,
} from "@portal/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as Amazon } from "@portal/images/amazon.svg";
import { ReactComponent as Honeywell } from "@portal/images/honeywell.svg";
import React from "react";

interface OfferSnapshotThermostatRequiredModalProps {
  handleClose: () => void;
  offerSnapshot: OfferSnapshot;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
}

export const OfferSnapshotThermostatRequiredModal = (
  props: OfferSnapshotThermostatRequiredModalProps
) => {
  const { handleClose, onEnrollClick, offerSnapshot } = props;
  const { featureFlagClient } = useFeatureFlagClient();

  const { translate } = useTranslations();
  const {
    tOfferSnapshotThermostatRequiredModalTitle,
    tOfferSnapshotThermostatRequiredModalConfirm,
    tOfferSnapshotThermostatRequiredModalNevermind,
  } = translate(offerSnapshotThermostatRequiredModalTranslations);

  const { portalDeviceManagementAmazon, portalDeviceManagementHoneywell } =
    featureFlagClient.getFlags([
      ["portalDeviceManagementAmazon", false],
      ["portalDeviceManagementHoneywell", false],
    ]);

  return (
    <RhModal
      ariaCloseLabel="Close"
      ariaLabel="Modal"
      handleClose={handleClose}
      size={ModalWidths.sm}
    >
      <StyledContainer>
        <StyledTitle>{tOfferSnapshotThermostatRequiredModalTitle}</StyledTitle>
        <StyledBrandIconContainer>
          {portalDeviceManagementAmazon.value ? (
            <StyledBrandIcon>
              <Amazon title="Amazon" />
            </StyledBrandIcon>
          ) : null}
          {portalDeviceManagementHoneywell.value ? (
            <StyledBrandIcon>
              <Honeywell title="Honeywell" />
            </StyledBrandIcon>
          ) : null}
        </StyledBrandIconContainer>
        <RhButton
          data-tracking-click={{
            event: "Prospect selecting a thermostat required plan",
          }}
          onClick={() => {
            onEnrollClick(offerSnapshot);
            handleClose();
          }}
        >
          {tOfferSnapshotThermostatRequiredModalConfirm}
        </RhButton>
        <StyledTextButton
          data-tracking-click={{
            event: "Cancel selecting a thermostat required plan",
          }}
          onClick={handleClose}
        >
          {tOfferSnapshotThermostatRequiredModalNevermind}
        </StyledTextButton>
      </StyledContainer>
    </RhModal>
  );
};
