import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";

interface AveragedBillingDialogProps {
  averagedBillingMonthlyCharge?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const AveragedBillingDialog = ({
  averagedBillingMonthlyCharge,
  isOpen,
  onClose,
  onSubmit,
}: AveragedBillingDialogProps) => {
  const { t, tJsx } = useRhIntl();

  const title = t("averagedBillingDialog.enroll");
  const enrollCta = t("rhythm.input.enroll");
  const cancel = t("rhythm.input.cancel");
  const estimatedMonthlyAverage = tJsx(
    "averagedBillingDialog.estimatedMonthlyAverage",
    {
      // This template format is needed to keep the dollar sign and also pass prettier/linter rules
      amount: () => <strong>{`$${averagedBillingMonthlyCharge}`}</strong>,
    }
  );

  return (
    <RhDialog open={isOpen} onClose={onClose} size="medium">
      <RhDialogTitle>
        <RhFlexBox marginBottom={1} justifyContent="center" width="100%">
          <RhTypography variant="h2">{title}</RhTypography>
        </RhFlexBox>
        <RhFlexBox alignItems="center" justifyContent="center">
          <RhTypography color="textSecondary" variant="body1">
            {estimatedMonthlyAverage}
          </RhTypography>
        </RhFlexBox>
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          "data-tracking-click": {
            event: "Customer enrolling in average billing",
          },
          label: enrollCta,
          onClick: onSubmit,
        }}
        secondaryButton={{
          "data-tracking-click": {
            event: "Customer cancelling enrolling in average billing",
          },
          label: cancel,
          onClick: onClose,
        }}
      />
    </RhDialog>
  );
};
