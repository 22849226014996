import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const RepPriceComparisonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 0 1rem;
  height: 100%;
  justify-content: stretch;
  padding: 2rem 0 0;
  width: 100%;
`;

export const RepPriceComparisonChartContainer = styled(
  RepPriceComparisonContainer
)`
  align-items: flex-start;
  flex-flow: column nowrap;
  padding: 0 0 1rem;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: row nowrap;
  }
`;

export const RepPriceComparisonTitle = styled.header`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 90ch;
  padding: 0 0 1.5rem 0;
  text-align: center;
`;

export const RepPriceComparisonFiltersWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

export const RepPriceComparisonGraphContainer = styled.div`
  flex: 4;
  height: 100%;
  position: relative;
`;

export const NoCompetitorsChartOverlay = styled.div`
  align-items: center;
  background-image: linear-gradient(white, rgba(255, 255, 255, 0.85));
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  height: 90%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 10;
`;

export const NoCompetitorsText = styled(RhTypography).attrs({
  fontWeight: FontWeight.Regular,
  variant: "h3",
})`
  &.MuiTypography-root {
    margin: 0 auto;
    max-width: 30ch;
  }
`;

export const ChartUpdatedTime = styled.p`
  padding-top: 1rem;
`;
