import { formatMaskNumber } from "@common/forms/formatters";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { MouseEvent } from "react";
import styled from "styled-components";

const PaymentCardContainer = styled.div`
  border-radius: 6px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};

  height: 40px;
  justify-content: center;
  outline: 1px solid ${grey["100"]};
  padding: ${rhSpacingPx(2)};
  & > * {
    min-height: 20px;
  }

  &[aria-selected="true"] {
    outline-color: ${darkPurple.main};
  }
`;

const DeletePaymentContainer = styled(RhTypography)`
  &.MuiTypography-root {
    &:hover {
      color: ${darkPurple.dark};
    }
  }
`;

const TopRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(2)};
  justify-content: center;

  & > :last-child {
    flex: 1;
    text-align: right;
  }
`;

const BottomRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;

  & > :first-child {
    flex: 1;
    text-align: left;
  }
`;

const PaymentCardButton = styled.button`
  all: unset;
  cursor: pointer;
`;

type PaymentMethodCardProps = {
  className?: string;
  isDeleting?: boolean;
  isOneTimePayment?: boolean;
  onDefaultClick?: () => void;
  onDeleteClick?: () => void;
  paymentMethod: PaymentMethod;
  selected?: boolean;
};

export const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  const {
    isDeleting = false,
    isOneTimePayment,
    selected = false,
    onDefaultClick,
    onDeleteClick,
    paymentMethod,
    className,
  } = props;

  const { t } = useRhIntl();

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Prevents selecting the card if used in a form
    event.stopPropagation();
    onDeleteClick?.();
  };

  const handleDefaultClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Prevents selecting the card if used in a form
    event.stopPropagation();
    onDefaultClick?.();
  };

  return (
    <PaymentCardContainer
      aria-label={`Select ${paymentMethod.trimmedMaskedNumber}`}
      aria-selected={selected}
      className={className}
    >
      <TopRowContainer>
        <RhTypography variant="button">
          {paymentMethod.companyName}
        </RhTypography>
        <RhTypography variant="body1" color="textSecondary">
          {paymentMethod.secondaryInfo}
        </RhTypography>

        <RhTypography variant="body1">
          {formatMaskNumber(paymentMethod.maskedNumber)}
        </RhTypography>
      </TopRowContainer>

      <BottomRowContainer>
        <RhTypography variant="body1" color="textSecondary">
          {paymentMethod.defaultPaymentMethod && t("PaymentMethodCard.default")}
          {isOneTimePayment && t("PaymentMethodCard.oneTimePayment")}
        </RhTypography>
        {!paymentMethod.defaultPaymentMethod && onDefaultClick && (
          <PaymentCardButton type="button" onClick={handleDefaultClick}>
            <RhTypography variant="body1" color="textSecondary">
              {t("PaymentMethodCard.makeDefault")}
            </RhTypography>
          </PaymentCardButton>
        )}

        {onDeleteClick && (
          <PaymentCardButton type="button" onClick={handleDeleteClick}>
            <DeletePaymentContainer variant="body1" color="textSecondary">
              {isDeleting ? (
                <RhCircularProgress size={14} marginBottom={0} marginTop={0} />
              ) : (
                t("PaymentMethodCard.delete")
              )}
            </DeletePaymentContainer>
          </PaymentCardButton>
        )}
      </BottomRowContainer>
    </PaymentCardContainer>
  );
};
