import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  StyledCard,
  StyledIconContainer,
  StyledLabelContainer,
} from "@portal/components/PortalIconRadioField/PortalIconRadioField.styled";
import { PortalRadioButton } from "@portal/components/PortalRadioButton/PortalRadioButton";
import React, { ReactElement } from "react";

interface PortalIconRadioFieldProps {
  ariaLabel: string;
  checked: boolean;
  handleClick: () => void;
  hasErrors?: boolean;
  icon: ReactElement;
  label?: string;
  name: string;
}

export const PortalIconRadioField = (props: PortalIconRadioFieldProps) => {
  const {
    ariaLabel,
    checked,
    handleClick,
    hasErrors = false,
    icon,
    label,
    name,
  } = props;

  return (
    <StyledCard $hasErrors={hasErrors} $checked={checked} onClick={handleClick}>
      <StyledLabelContainer>
        <StyledIconContainer>{icon}</StyledIconContainer>
        {label ? (
          <RhTypography variant="subtitle1">{label}</RhTypography>
        ) : null}
        <PortalRadioButton
          aria-label={ariaLabel}
          aria-checked={checked}
          defaultChecked={checked}
          id={name}
          name={name}
          type="radio"
        />
      </StyledLabelContainer>
    </StyledCard>
  );
};
