import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { HIDE_COST_ESTIMATOR_FOR_RCIDS } from "@portal/constants/offer.constant";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { useSelector } from "react-redux";

export const useHideCostEstimator = (
  offerSnapshots?: OfferSnapshotType[] | null
) => {
  const { campaignSlug } = useSelector(selectSignUpState);

  if (campaignSlug && HIDE_COST_ESTIMATOR_FOR_RCIDS.includes(campaignSlug)) {
    return true;
  }

  if (
    offerSnapshots &&
    offerSnapshots.some((offerSnapshot) => offerSnapshot.solarEligible)
  ) {
    return true;
  }

  return false;
};
