import { billingDetailsWatcher } from "@portal/sagas/billingDetails.saga";
import { premiseWatcher } from "@portal/sagas/premise.saga";
import {
  createProspectWatcher,
  fetchProspectWatcher,
  refetchProspectWatcher,
  updateProspectWatcher,
} from "@portal/sagas/prospect.saga";
import { rewardsWatcher } from "@portal/sagas/rewards.saga";
import { all } from "redux-saga/effects";

// Register your sagas here
const sagas = [
  billingDetailsWatcher(),
  createProspectWatcher(),
  fetchProspectWatcher(),
  refetchProspectWatcher(),
  rewardsWatcher(),
  premiseWatcher(),
  updateProspectWatcher(),
];

export function* rootSaga() {
  yield all(sagas);
}
