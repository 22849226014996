import { RhSecurity } from "@common/context/RhSecurity";
import { connectApi } from "@common/services/ajax";
import {
  getEnv,
  isLocal,
} from "@common/services/getEnvApplicationSettings.service";
import { CssReset } from "@design-system/components/CssReset/CssReset";
import { RhAnnouncementProvider } from "@design-system/components/RhAnnouncement/RhAnnouncementProvider";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import isPropValid from "@emotion/is-prop-valid";
import {
  CacheLocation,
  FingerprintJSPro,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react";
import { FullStory, init as fullStoryInit } from "@fullstory/browser";
import { ThemeProvider } from "@mui/material";
import { App } from "@portal/App";
import { signInPath } from "@portal/routes/routePaths";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  FINGERPRINT_JS_API_KEY,
  FINGERPRINT_JS_URL,
  FullStoryConfig,
  GoogleTagManagerConfig,
  SENTRY_CONFIG,
} from "@portal/settings/config";
import { store } from "@portal/store/store";
import { generateTranslations } from "@portal/utils/generateTranslations.util";
import { ScrollToTop } from "@portal/utils/ScrollToTop";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider as StoreProvider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { StyleSheetManager } from "styled-components";

const queryClient = new QueryClient();

// eslint-disable-next-line react-refresh/only-export-components
const AppWithProviders = () => {
  const location = useLocation();

  return (
    <RhFlashProvider>
      <RhSecurity
        authClient={portalAuthClient}
        onAuthRequiredRedirectToPath={signInPath() + location.search}
      >
        <QueryClientProvider client={queryClient}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <App />
          </StyleSheetManager>
        </QueryClientProvider>
      </RhSecurity>
    </RhFlashProvider>
  );
};

// This implements the default behavior from styled-components v5 until we can fix all existing props
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    if (isLocal() && !isPropValid(propName)) {
      // eslint-disable-next-line no-console
      console.log({ propName, target });
    }
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const bootstrapApp = () => {
  TagManager.initialize(GoogleTagManagerConfig);

  if (FullStoryConfig) {
    fullStoryInit(FullStoryConfig);
  }

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  connectApi({
    baseURL: AXIOS_BASE_URL,
    timeout: AXIOS_TIMEOUT_MS,
  });

  if (SENTRY_CONFIG.dsn) {
    const integrations: Sentry.BrowserOptions["integrations"] = [
      Sentry.browserTracingIntegration(),
      Sentry.rewriteFramesIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType,
      }),
    ];

    if (SENTRY_CONFIG.orgSlug) {
      integrations.push(
        fullStoryIntegration(SENTRY_CONFIG.orgSlug, { client: FullStory })
      );
    }

    Sentry.init({
      dsn: SENTRY_CONFIG.dsn,
      environment: getEnv(),
      integrations,
      release: SENTRY_CONFIG.release,
      tracePropagationTargets: ["localhost", "*.gotrhythm.com"],
      tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
    });
  }

  generateTranslations();

  const container = document.getElementById("root");

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <CssReset />
      <Sentry.ErrorBoundary>
        <ThemeProvider theme={muiTheme}>
          <StoreProvider store={store}>
            <BrowserRouter>
              <ScrollToTop />
              <FpjsProvider
                cacheLocation={CacheLocation.LocalStorage}
                loadOptions={{
                  apiKey: FINGERPRINT_JS_API_KEY,
                  endpoint: [
                    FINGERPRINT_JS_URL,
                    FingerprintJSPro.defaultEndpoint,
                  ],
                  scriptUrlPattern: [
                    `${FINGERPRINT_JS_URL}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                    FingerprintJSPro.defaultScriptUrlPattern,
                  ],
                }}
              >
                <RhAnnouncementProvider>
                  <AppWithProviders />
                </RhAnnouncementProvider>
              </FpjsProvider>
            </BrowserRouter>
          </StoreProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};

bootstrapApp();
