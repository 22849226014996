import { addressStates } from "@common/constants/addressStates.constant";
import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import sortBy from "lodash/sortBy";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface StateSelectFieldProps {
  defaultValue?: string;
  name: string;
  onChange?: (event: ChangeEvent) => void;
  translationId: TranslationKey;
}

const STATE_OPTIONS = sortBy(
  addressStates.map((state) => ({
    label: state.name,
    value: state.abbreviation,
  })),
  "label"
);

export const StateSelectWrapper = styled.div`
  position: relative;
`;

export const StateSelectLabel = styled(RhSelectLabel)`
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(0) translateY(-2px);
  transform-origin: top left;
`;

export const StateSelectFieldStyledSelect = styled(RhSelect)`
  margin-bottom: 0;
  margin-top: 16px;
  min-height: ${rhSpacingPx(6)};
  padding: 13px ${rhSpacingPx(1.5)};
`;

export const StateSelectField = ({
  name,
  translationId,
  onChange = (event: ChangeEvent) => {},
  defaultValue,
}: StateSelectFieldProps) => {
  const { t } = useRhIntl();
  const placeholder = t(translationId);

  return (
    <StateSelectWrapper>
      <StateSelectLabel htmlFor="AddressFieldState">
        {placeholder}
      </StateSelectLabel>

      <StateSelectFieldStyledSelect
        data-testid="stateSelectField__state"
        id="AddressFieldState"
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
        defaultValue={defaultValue}
      >
        <option disabled selected>
          {placeholder}
        </option>
        {STATE_OPTIONS.map((option) => {
          return (
            <option value={option.value} key={option.label}>
              {option.label}
            </option>
          );
        })}
      </StateSelectFieldStyledSelect>
    </StateSelectWrapper>
  );
};
