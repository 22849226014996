import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { UUIDType } from "@common/types/apiTypes";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { SignUpStateType, setSignUpInfo } from "@portal/slices/signUpSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// TODO @cafesanu: delete useKeepPlansPageCompatibility once old sign up page is gone
export const useKeepPlansPageCompatibility = (prospectUuid?: UUIDType) => {
  const dispatch = useDispatch();
  const { serviceAddress } = useSelector(selectSignUpState);

  useEffect(() => {
    if (prospectUuid) {
      dispatch(
        setSignUpInfo({
          prospectUuid,
          visitedSteps: ["availability", "plans"],
        })
      );
    }
  }, [dispatch, prospectUuid]);

  const compatibilityFn = useCallback(
    (props: {
      campaignSlug: string;
      estimatedMonthlyUsage: number | undefined;
      offerSnapshot: OfferSnapshot;
      zipCode: string;
    }) => {
      const { campaignSlug, estimatedMonthlyUsage, offerSnapshot, zipCode } =
        props;

      const signUpData: Partial<SignUpStateType> = {
        autopay: !!offerSnapshot.isAutoPayPaperlessDiscountOffer,
        campaignSlug,
        dunsNumber: offerSnapshot.dunsNumber,
        estimatedMonthlyUsage,
        invoiceByPrint: !offerSnapshot.isAutoPayPaperlessDiscountOffer,
        offerSnapshotUuid: offerSnapshot.uuid,
        offersnapshotId: offerSnapshot.id,
        serviceAddress: {
          ...serviceAddress,
          zipCode,
        },
        visitedSteps: ["availability", "plans", "name"],
      };

      dispatch(setSignUpInfo(signUpData));
    },
    [dispatch, serviceAddress]
  );

  return compatibilityFn;
};
