import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { BaseErrorPage } from "@portal/pages/BaseErrorPage/BaseErrorPage";
import React from "react";

export const Error404Page = () => {
  const { t } = useRhIntl();

  const { featureFlagClient } = useFeatureFlagClient();

  /**
   * If we want to feature flag routes, while fetching the flags we don't want to show the 404,
   * otherwise we will see a blinking of this page while the fetch is happening.
   */
  if (featureFlagClient.isFetchingFlags()) {
    return null;
  }

  const code = "404";
  const header = t("Error404Page.thisIsAwkward");
  const message = t("Error404Page.notHere");

  return (
    <BaseErrorPage
      code={code}
      header={header}
      message={message}
      showLinkToHome
    />
  );
};
