import { StyledPaper } from "@design-system/components/RhPaper/RhPaper.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled, { css } from "styled-components";

interface StyledAtAGlanceItemContainerProps {
  $disableBorder: boolean;
  $disabled: boolean;
}

const containerPadding = `${rhSpacingPx(0.5)} ${rhSpacingPx(1)} ${rhSpacingPx(
  1
)} ${rhSpacingPx(1)}`;

const lastAtAGlanceItemContainer = css`
  border-right: 1px solid ${grey[100]};
`;

export const StyledAtAGlanceItemContainer = styled(
  StyledPaper
)<StyledAtAGlanceItemContainerProps>`
  &.MuiPaper-root {
    &:hover {
      background-color: ${({ $disabled }) =>
        $disabled ? "transparent" : grey[50]};
    }
    box-shadow: none;
    flex-direction: column;
    padding: ${containerPadding};
    width: ${rhSpacingPx(25)};
  }

  ${({ $disableBorder }) => !$disableBorder && lastAtAGlanceItemContainer}
`;

interface StyledTitleProps {
  $disabled: boolean;
}

export const StyledTitle = styled(RhTypography)<StyledTitleProps>`
  &.MuiTypography-root {
    color: ${({ $disabled }) => ($disabled ? grey[400] : grey[900])};
  }
`;
