import { trackPage } from "@portal/services/segment.service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageAnalytics = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      trackPage(pathname);
    }
  }, [pathname]);
};
