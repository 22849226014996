import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  PortalRadioButton,
  PortalRadioLabel,
} from "@portal/components/PortalRadioButton/PortalRadioButton";
import styled from "styled-components";

export const StyledSubheaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ${rhSpacingPx(2.5)} 0;
`;

export const StyledSubtitleContainer = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey[500]};
  }
`;

export const MultipleTdspModalLabel = styled(PortalRadioLabel)`
  border: 1px solid ${grey[200]};
  border-radius: 6px;
  margin-bottom: ${rhSpacingPx(1)};
  min-height: 100%;
  padding: ${rhSpacingPx(3)};

  ${PortalRadioButton} {
    border-color: ${darkPurple.main};
  }
`;

export const MultipleTdspModalForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin: ${rhSpacingPx(2)} auto;
    width: 200px;
  }
`;
