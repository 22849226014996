import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { CompetitorPlanComparison } from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpPlansPageRepPriceComparisonChartTranslations } from "@portal/pages/SignUpPlansPage/SignUpPlansPageRepPriceComparisonChart/SignUpPlansPageRepPriceComparisonChart.en.i18n";
import { SignUpPlansPageRepPriceComparisonChartContainer } from "@portal/pages/SignUpPlansPage/SignUpPlansPageRepPriceComparisonChart/SignUpPlansPageRepPriceComparisonChart.styled";
import React from "react";

interface SignUpPlansPageRepPriceComparisonChartProps {
  estimatedMonthlyUsage: number;
  id: string;
  offerSnapshots: OfferSnapshot[];
}

export const SignUpPlansPageRepPriceComparisonChart = (
  props: SignUpPlansPageRepPriceComparisonChartProps
) => {
  const { estimatedMonthlyUsage, offerSnapshots, id } = props;

  const { addModal } = useModals();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const { translate } = useTranslations();

  const { tSignUpPlansPageRepPriceComparisonChartSelectPlan } = translate(
    signUpPlansPageRepPriceComparisonChartTranslations
  );

  if (offerSnapshots.length === 0) {
    return null;
  }

  const handleLearnMoreClick = (offerSnapshot: OfferSnapshot) => {
    addModal({
      offerSnapshotLearnMore: {
        offerSnapshotUUID: offerSnapshot.uuid,
        onEnrollClick: () => {
          handleEnrollNowClick({
            estimatedMonthlyUsage,
            offerSnapshot,
          });
        },
        selectPlanCtaText: tSignUpPlansPageRepPriceComparisonChartSelectPlan,
      },
    });
  };

  return (
    <SignUpPlansPageRepPriceComparisonChartContainer>
      <CompetitorPlanComparison
        miniCardSubmitText={tSignUpPlansPageRepPriceComparisonChartSelectPlan}
        onLearnMoreClick={handleLearnMoreClick}
        onSelectPlanClick={(offerSnapshot) =>
          handleEnrollNowClick({ estimatedMonthlyUsage, offerSnapshot })
        }
        offerSnapshots={offerSnapshots}
        id={id}
      />
    </SignUpPlansPageRepPriceComparisonChartContainer>
  );
};
