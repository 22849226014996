import { signUpBasePath } from "@portal/routes/routePaths";
import { prospectFetch } from "@portal/slices/signUpSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const SignUpResume = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // Expected pathname like: `/sign-up/welcome-back/{uuid}...`
  const uuid = pathname.split("/")[3];

  if (uuid) {
    dispatch(prospectFetch(uuid));
  }

  return <Navigate to={signUpBasePath()} />;
};
