import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { RadioGroup, useMediaQuery, useTheme } from "@mui/material";
import {
  StyledMyAccountSubmitButtonWrap,
  StyledRadioGroupWrap,
} from "@portal/components/MyAccountLanguageCard/MyAccountLanguageCard.styled";
import { MyAccountSubmitButton } from "@portal/components/MyAccountSubmitButton/MyAccountSubmitButton";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal/components/PortalCard/PortalCard.styled";
import { useCustomerLegacyUpdateMutation } from "@portal/hooks/mutations/useCustomerLegacyUpdate.mutation";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { Customer } from "@portal/models/Customer.model";
import { LanguagePreferenceRadioField } from "@portal/pages/MyAccountPage/LanguagePreferenceRadioField";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { MyAccountEvents } from "@portal/services/segment.service";
import { customerPreferencesLanguageUpdated } from "@portal/slices/customerPreferencesSlice";
import {
  AllSupportedPortalLanguages,
  SupportedPortalLanguage,
  translations,
} from "@portal/translations/portalTranslations";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

type MyAccountLanguageCardProps = {
  customer: Customer;
};

export type MyAccountLanguageCardFormValues = {
  languagePreference: SupportedPortalLanguage;
};

export const MyAccountLanguageCard = ({
  customer,
}: MyAccountLanguageCardProps) => {
  const dispatch = useDispatch();
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const flash = useRhFlash();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const contactFormInitialValues: MyAccountLanguageCardFormValues = {
    languagePreference: locale,
  };
  const track = useTrackMyAccountEvents();
  const customerUpdateMutation = useCustomerLegacyUpdateMutation();

  const changeLanguage = (
    formattedValues: MyAccountLanguageCardFormValues,
    formApi: FormApi<MyAccountLanguageCardFormValues>
  ) => {
    const values: MyAccountLanguageCardFormValues = {
      ...formattedValues,
    };
    const { languagePreference } = values;

    customerUpdateMutation.mutate(
      {
        customerData: values,
        customerId: customer.id,
      },
      {
        onError: () => {
          flash.error(t("MyAccountLanguageCard.errorSwitchingLocale"));
        },
        onSuccess: (data) => {
          formApi.initialize(formattedValues);
          dispatch(customerPreferencesLanguageUpdated(languagePreference));
          flash.success(
            // t("account.contact.preferencesUpdated")
            // normally you should not access translations directly but...
            // useIntl will have a stale languagePreference, so cannot be trusted here
            translations[languagePreference][
              "MyAccountLanguageCard.preferencesUpdated"
            ]
          );

          track({
            event: MyAccountEvents.languagePreferencesUpdated,
            label: "Updated language preference",
            language: languagePreference,
          });
        },
      }
    );
  };

  const title = t("MyAccountLanguageCard.languagePreference");

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>

      <Form<MyAccountLanguageCardFormValues>
        initialValues={contactFormInitialValues}
        onSubmit={changeLanguage}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <StyledRadioGroupWrap>
              <RadioGroup>
                {AllSupportedPortalLanguages.map((languageCode) => (
                  <LanguagePreferenceRadioField
                    key={languageCode}
                    value={languageCode}
                    translationId={`MyAccountLanguageCard.${languageCode}`}
                  />
                ))}
              </RadioGroup>
            </StyledRadioGroupWrap>
            <StyledMyAccountSubmitButtonWrap>
              <MyAccountSubmitButton isMobile={isMobile} />
            </StyledMyAccountSubmitButtonWrap>
          </form>
        )}
      />
    </PortalCard>
  );
};
