import { RhTabs } from "@design-system/components/RhTabs/RhTabs";
import { accountHistoryPanelTranslations } from "@portal/components/AccountHistoryPanel/AccountHistoryPanel.en.i18n";
import { StyledCardHeader } from "@portal/components/AccountHistoryPanel/AccountHistoryPanel.styled";
import { BillingHistory } from "@portal/components/BillingHistory/BillingHistory";
import { DocumentHistory } from "@portal/components/DocumentHistory/DocumentHistory";
import { PaymentHistory } from "@portal/components/PaymentHistory/PaymentHistory";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

export const AccountHistoryPanel = () => {
  const { translate } = useTranslations();
  const {
    tAccountHistoryPanelAccountHistory,
    tAccountHistoryPanelBilling,
    tAccountHistoryPanelDocuments,
    tAccountHistoryPanelPayments,
  } = translate(accountHistoryPanelTranslations);

  return (
    <StyledCardHeader title={tAccountHistoryPanelAccountHistory}>
      <RhTabs
        tabPanelId="account-history"
        tabs={[
          {
            component: <BillingHistory />,
            title: tAccountHistoryPanelBilling,
          },
          {
            component: <PaymentHistory />,
            title: tAccountHistoryPanelPayments,
          },
          {
            component: <DocumentHistory />,
            title: tAccountHistoryPanelDocuments,
          },
        ]}
      />
    </StyledCardHeader>
  );
};
