import {
  StyledBillFactor,
  StyledBillFactorsContainer,
} from "@portal/components/BillFactors/BillFactors.styled";
import {
  StyledBillFactorsCard,
  StyledLoadingContainer,
} from "@portal/components/BillFactorsCard/BillFactorsCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";

export const BillFactorsCardSkeleton = () => (
  <StyledBillFactorsCard>
    <StyledLoadingContainer>
      <Skeleton />
      <StyledBillFactorsContainer>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
      </StyledBillFactorsContainer>
    </StyledLoadingContainer>
  </StyledBillFactorsCard>
);
