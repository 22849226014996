import { TEN_OR_ELEVEN_PHONE_DIGIT_NUMBER_REGARDLESS_OF_SYMBOLS } from "@common/constants/regex.constant";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { CookieName, getCookieByKey } from "@common/utils/getCookies";
import { RHYTHM_GENERAL_SALES_PHONE_NUMBER } from "@portal/constants/misc.constant";
import { useAcquisitionCampaignQuery } from "@portal/hooks/queries/useAcquisitionCampaign.query";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { setAcquisitionTfn } from "@portal/slices/signUpSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const cookiePhoneNumber =
  TEN_OR_ELEVEN_PHONE_DIGIT_NUMBER_REGARDLESS_OF_SYMBOLS.test(
    getCookieByKey(CookieName.CampaignTfn) || ""
  )
    ? getCookieByKey(CookieName.CampaignTfn)
    : RHYTHM_GENERAL_SALES_PHONE_NUMBER;

export const useAcquisitionCampaignFromQueryParam = () => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalAcquisitionSlugTfn } = featureFlagClient.getFlags([
    ["portalAcquisitionSlugTfn", false],
  ]);

  const { acquisition } = useSelector(selectSignUpState);
  const queryEnabled =
    Boolean(portalAcquisitionSlugTfn.value) &&
    Boolean(acquisition.acquisitionSource);

  const acquisitionCampaignQuery = useAcquisitionCampaignQuery({
    enabled: queryEnabled,
    slug: acquisition.acquisitionSource,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    let phone = cookiePhoneNumber;

    if (acquisitionCampaignQuery.data) {
      phone = acquisitionCampaignQuery.data.phone;
    }

    const formattedPhoneNumber = formatPhoneNumber(phone);

    dispatch(setAcquisitionTfn(formattedPhoneNumber));
  }, [acquisitionCampaignQuery.data, dispatch]);
};
