import { RhCard } from "@design-system/components/RhCard/RhCard";
import { boxShadows } from "@design-system/constants/boxShadows";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledMiniOfferCard = styled(RhCard)`
  &.MuiCard-root {
    box-shadow: ${boxShadows.default};
    margin: 0;
    width: 100%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      padding: ${rhSpacingPx(2.5)};
    }
  }
`;

export const StyledMiniOfferCardRoot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: column;
    gap: 0.25rem;
  }
`;

export const StyledMiniOfferCardContent = styled.div`
  flex: 1;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    text-align: center;
  }
`;

export const StyledMiniOfferCardTitle = styled.div`
  margin-bottom: ${rhSpacingPx(0.75)};
`;

export const StyledMiniOfferCardBody = styled.div`
  & > div {
    margin-bottom: ${rhSpacingPx(0.5)};
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const StyledMiniOfferCardActions = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    align-self: initial;
  }
`;
