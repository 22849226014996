import { urls } from "@common/api/urls";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { noop } from "@common/utils/genericFunctions";
import { welcomePacketDownload } from "@common/utils/welcomePacketDownload";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { MyAccountEvents } from "@portal/services/segment.service";
import { AXIOS_BASE_URL, CDN_URL } from "@portal/settings/config";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import React, { FC, MouseEventHandler } from "react";

interface DocumentLinkProps {
  locale: SupportedPortalLanguage;
  onClick?: () => void;
}

interface WelcomePacketLinkProps {
  orderId: IdType;
  premiseId: IdType;
}

interface EFLLinkProps extends DocumentLinkProps {
  offerId: IdType;
}

interface ProductContractTOSLinkProps {
  tosUrl: string;
}

interface TOSLinkProps {
  onClick?: () => void;
  tosUrl: string;
}

export const EFLLink: FC<React.PropsWithChildren<EFLLinkProps>> = ({
  offerId,
  onClick,
  locale,
  children,
}) => (
  <RhAnchor
    rel="noopener noreferrer"
    target="_blank"
    href={`${AXIOS_BASE_URL}${urls.pricing.offerSnapshots.efl(
      offerId,
      locale
    )}`}
    onClick={onClick}
  >
    {children}
  </RhAnchor>
);

export const TOSLink: FC<React.PropsWithChildren<TOSLinkProps>> = ({
  onClick,
  tosUrl,
  children,
}) => (
  <RhAnchor
    rel="noopener noreferrer"
    target="_blank"
    href={tosUrl}
    onClick={onClick}
  >
    {children}
  </RhAnchor>
);

export const YRACLink: FC<React.PropsWithChildren<DocumentLinkProps>> = ({
  onClick,
  locale,
  children,
}) => (
  <RhAnchor
    rel="noopener noreferrer"
    target="_blank"
    href={`${CDN_URL}/RhythmYRAC_${locale}.pdf`}
    onClick={onClick}
  >
    {children}
  </RhAnchor>
);

export const WelcomePacketLink: FC<
  React.PropsWithChildren<WelcomePacketLinkProps>
> = ({ premiseId, orderId, children }) => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const track = useTrackMyAccountEvents();

  const handleDownload = (): MouseEventHandler<HTMLAnchorElement> => {
    return (ev) => {
      ev.preventDefault();

      welcomePacketDownload(premiseId, orderId)
        .then(noop)
        .catch((_error: RhApiError) => {
          flash.error(t("LegalDocuments.errorDownloadingWelcomePacket"));
        });

      track({
        event: MyAccountEvents.downloadWelcomePacket,
        label: "Downloaded welcome packet",
      });
    };
  };

  return (
    <RhAnchor
      href="#"
      rel="noopener noreferrer"
      onClick={handleDownload()}
      target="_self"
      key={premiseId}
    >
      {children}
    </RhAnchor>
  );
};

export const ProductContractTosLink: FC<
  React.PropsWithChildren<ProductContractTOSLinkProps>
> = ({ tosUrl, children }) => {
  return (
    <RhAnchor href={tosUrl} rel="noopener noreferrer" target="_blank">
      {children}
    </RhAnchor>
  );
};
