import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const StyledNoContractsCardContent = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledNoContractsCardContentMessage = styled(RhTypography).attrs({
  variant: "subtitle2",
})`
  &.MuiTypography-root {
    color: ${grey["500"]};
  }
`;
