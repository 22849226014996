import { RhAnnouncement } from "@design-system/components/RhAnnouncement/RhAnnouncement";
import { rhSpacing, rhSpacingPx } from "@design-system/theme/spacing";
import {
  PAGE_GUTTER,
  RH_HEADER_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledAnnouncement = styled(RhAnnouncement)`
  position: fixed;
  top: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  z-index: 100;
`;

interface AppContainerProps {
  $announcementBannerHeight: number;
}
export const AppContainer = styled.main<AppContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh; /* 100% does nothing in flex world */
  overflow-x: visible;
  padding-left: ${PAGE_GUTTER}px;
  padding-right: ${PAGE_GUTTER}px;
  padding-top: ${({ $announcementBannerHeight }) =>
    `${rhSpacing(RH_HEADER_HEIGHT) + $announcementBannerHeight}px`};
  position: relative;

  @media (max-width: ${RhythmBreakpoints.XS}px) {
    overflow-x: hidden;
  }
`;

/* Make sure header and footer always fixed top and bottom */
export const PortalContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 100%;
`;
