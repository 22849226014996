import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PaymentMethodOptionsTranslations } from "@portal/components/PaymentMethodOptions/PaymentMethodOptions.en.i18n";
import {
  ToggleLabel,
  ToggleRow,
  ToggleSection,
} from "@portal/components/PaymentMethodOptions/PaymentMethodOptions.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useState } from "react";

export type PaymentMethodOptionsType = {
  isOneTimePayment: boolean;
  setDefaultPaymentMethod: boolean;
};

type PaymentMethodOptionsProps = {
  paymentOptionsRef: PaymentMethodOptionsType;
  showPaymentOptions?: boolean;
};

export const PaymentMethodOptions = ({
  paymentOptionsRef,
  showPaymentOptions,
}: PaymentMethodOptionsProps) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tPaymentMethodOptionsMakeDefaultMethod,
    tPaymentMethodOptionsRememberPaymentMethod,
  } = translate(PaymentMethodOptionsTranslations);

  const { tPaymentMethodOptionsRememberPaymentMethodInfo } = translateJsx({
    tPaymentMethodOptionsRememberPaymentMethodInfo: {
      bold: (str: string) => <strong>{str}</strong>,
    },
  });
  const [rememberPaymentMethod, setRememberPaymentMethod] = useState(true);
  const [makeDefaultPaymentMethod, setMakeDefaultPaymentMethod] =
    useState(false);

  if (!showPaymentOptions) {
    return null;
  }

  return (
    <ToggleSection>
      <ToggleRow>
        <RhToggle
          checked={rememberPaymentMethod}
          onChange={(event) => {
            setRememberPaymentMethod(event.target.checked);
            // eslint-disable-next-line no-param-reassign
            paymentOptionsRef.isOneTimePayment = !event.target.checked;

            if (!event.target.checked) {
              // eslint-disable-next-line no-param-reassign
              paymentOptionsRef.setDefaultPaymentMethod = false;
              setMakeDefaultPaymentMethod(false);
            }
          }}
          name="rememberPaymentMethod"
        />
        <ToggleLabel>
          <RhTypography>
            {tPaymentMethodOptionsRememberPaymentMethod}
          </RhTypography>
          <RhTypography variant="body2" color="textSecondary">
            {tPaymentMethodOptionsRememberPaymentMethodInfo}
          </RhTypography>
        </ToggleLabel>
      </ToggleRow>
      {rememberPaymentMethod && (
        <ToggleRow>
          <RhToggle
            checked={makeDefaultPaymentMethod}
            onChange={(event) => {
              setMakeDefaultPaymentMethod(event.target.checked);
              // eslint-disable-next-line no-param-reassign
              paymentOptionsRef.setDefaultPaymentMethod = event.target.checked;
            }}
            name="makeDefaultPaymentMethod"
          />
          <RhTypography>{tPaymentMethodOptionsMakeDefaultMethod}</RhTypography>
        </ToggleRow>
      )}
    </ToggleSection>
  );
};
