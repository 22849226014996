import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useKeepPlansPageCompatibility } from "@portal/hooks/useKeepPlansPageCompatibility";
import { signUpStepPath } from "@portal/routes/routePaths";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  createTrackEvent,
} from "@portal/services/segment.service";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface HandleEnrollNowClickFnArgs {
  estimatedMonthlyUsage: number | null;
  offerSnapshot: OfferSnapshot;
}

export const useHandleEnrollNowClick = () => {
  const navigate = useNavigate();
  const { featureFlagClient } = useFeatureFlagClient();
  const compatibilityFn = useKeepPlansPageCompatibility();

  const prospect = useProspectFromContext();

  const handleEnrollNowClick = useCallback(
    (props: HandleEnrollNowClickFnArgs) => {
      const { offerSnapshot, estimatedMonthlyUsage } = props;

      compatibilityFn({
        campaignSlug: offerSnapshot.campaignSlug,
        estimatedMonthlyUsage:
          estimatedMonthlyUsage || DEFAULT_AVERAGE_MONTHLY_USAGE,
        offerSnapshot,
        zipCode: prospect.zipCode,
      });

      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalProspectSelectedPlan
      );

      const trackEvent = createTrackEvent({
        category: CategoryType.OffersPage,
        event: EventType.enrollmentClick,
      });

      trackEvent({
        action: ActionType.clickedNextPage,
        label: LabelType.EnrollmentFromOffersPageToNextPage,
      });

      trackEvent({
        action: ActionType.click,
        baseCharge: Number(offerSnapshot.baseChargeAmount),
        energyRate: Number(offerSnapshot.rhythmKwhRate),
        offerSnapshotId: offerSnapshot.id,
        planDate: offerSnapshot.startDate,
        planLength: offerSnapshot.termMonths,
        price: Number(offerSnapshot.price),
        tdspRate: Number(offerSnapshot.tdspKwhRate),
        title: offerSnapshot.title,
      });
      navigate(signUpStepPath("name"));
    },
    [compatibilityFn, featureFlagClient, navigate]
  );

  return handleEnrollNowClick;
};
