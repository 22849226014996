import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpReviewRequiredPageTranslations } from "@portal/pages/SignUpReviewRequiredPage/SignUpReviewRequiredPage.en.i18n";
import React from "react";

const OPS_PHONE_NUMBER = "+18887749846";
const OPS_DISPLAY_PHONE_NUMBER = "1-888-7RHYTHM";
const OPS_SUPPORT_EMAIL = "support@gotrhythm.com";

export const SignUpReviewRequiredPage = () => {
  const { translate, translateJsx } = useTranslations();
  const { tSignUpReviewRequiredPageGetSetUp } = translate(
    signUpReviewRequiredPageTranslations
  );

  const {
    tSignUpReviewRequiredPageQuestions,
    tSignUpReviewRequiredPageToStartService,
  } = translateJsx({
    tSignUpReviewRequiredPageQuestions: {
      phone: OPS_DISPLAY_PHONE_NUMBER,
      phoneTag: (content: string) => (
        <RhAnchor href={`tel:${OPS_PHONE_NUMBER}`} color="textSecondary">
          {content}
        </RhAnchor>
      ),
    },
    tSignUpReviewRequiredPageToStartService: {
      email: OPS_SUPPORT_EMAIL,
      emailTag: (content: string) => (
        <RhAnchor href={`mailto:${OPS_SUPPORT_EMAIL}`}>{content}</RhAnchor>
      ),
    },
  });

  return (
    <SignUpPageLayout hideBackButton>
      <LoggedOutPageHeader headerText={tSignUpReviewRequiredPageGetSetUp} />
      <Box mt={LOGGED_OUT_CONTAINER_SPACING}>
        <Box mb={2}>
          <RhTypography
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            {tSignUpReviewRequiredPageToStartService}
          </RhTypography>
        </Box>
        <Box mb={2}>
          <RhTypography
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            {tSignUpReviewRequiredPageQuestions}
          </RhTypography>
        </Box>
      </Box>
    </SignUpPageLayout>
  );
};
