import { Premise } from "@common/models/Premise.model";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey, purple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { translations as EnergyUsageSectionV2Translations } from "@portal/components/EnergyUsageSectionV2/EnergyUsageSectionV2.en.i18n";
import { EnergyUsageTooltip } from "@portal/components/EnergyUsageTooltip/EnergyUsageTooltip";
import { useBillForecastSummary } from "@portal/hooks/queries/usePremiseBillForecastSummary";
import { usePremiseKwhUsageSummary } from "@portal/hooks/queries/usePremiseKwhUsageSummary";
import { useTranslations } from "@portal/hooks/useTranslations";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

const RowContainer = styled.div`
  border-bottom: 1px solid ${grey["100"]};
  border-top: 1px solid ${grey["100"]};
  display: grid;
  gap: ${rhSpacingPx(1)};
  grid-template-columns: max-content max-content;
  padding: ${rhSpacingPx(2)};

  & > button {
    margin-right: auto;
  }

  &:first-child {
    border: none;
    color: ${purple.dark};
    padding-top: 0;
    & > p {
      font-weight: 600;
    }
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    grid-template-columns: max-content min-content 1fr max-content;
    justify-items: flex-end;

    & > p:last-child {
      margin-left: ${rhSpacingPx(2)};
    }
  }
`;

interface BillSummaryProps {
  premise: Premise;
}

export const BillSummary = (props: BillSummaryProps) => {
  const { premise } = props;
  const { translate, translateJsx } = useTranslations();

  const {
    tEnergyUsageSectionV2BillToDate,
    tEnergyUsageSectionV2ProjectedBill,
    tEnergyUsageSectionV2ProjectedBillTooltip,
  } = translate(EnergyUsageSectionV2Translations);

  const { tEnergyUsageSectionV2BillToDateTooltip } = translateJsx({
    tEnergyUsageSectionV2BillToDateTooltip: {
      bold: (str: string) => <strong>{str}</strong>,
      date: dayjs().format("MM/DD/YY"),
    },
  });

  const billForecastSummaryQuery = useBillForecastSummary({
    premiseId: premise.id,
  });
  const kwhUsageSummaryQuery = usePremiseKwhUsageSummary({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  if (kwhUsageSummaryQuery.isPending || billForecastSummaryQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (kwhUsageSummaryQuery.isError || billForecastSummaryQuery.isError) {
    return null;
  }

  const { billPeriodData } = kwhUsageSummaryQuery.data;

  const billPeriodConsumptionKwh = billPeriodData
    .map((billPeriodUsage) => billPeriodUsage.consumptionKwh)
    .reduce((prev, next) => (Number(prev) + Number(next)).toString(), "0");
  const billPeriodConsumptionCost = billPeriodData
    .map((billPeriodUsage) => billPeriodUsage.consumptionCost)
    .reduce((prev, next) => (Number(prev) + Number(next)).toString(), "0");

  return (
    <>
      <RowContainer>
        <RhTypography variant="body1">
          {tEnergyUsageSectionV2BillToDate}
        </RhTypography>
        <EnergyUsageTooltip
          title={tEnergyUsageSectionV2BillToDateTooltip}
          ariaLabel={tEnergyUsageSectionV2BillToDate}
        />
        <RhTypography variant="body1">
          {formatKwh(Number(billPeriodConsumptionKwh), 0)}
        </RhTypography>
        <RhTypography variant="body1">
          {formatCurrency(billPeriodConsumptionCost)}
        </RhTypography>
      </RowContainer>
      <RowContainer>
        <RhTypography variant="body1">
          {tEnergyUsageSectionV2ProjectedBill}
        </RhTypography>
        <EnergyUsageTooltip
          title={<>{tEnergyUsageSectionV2ProjectedBillTooltip}</>}
          ariaLabel={tEnergyUsageSectionV2ProjectedBill}
        />
        <RhTypography variant="body1">
          {formatKwh(Number(billForecastSummaryQuery.data.consumptionKwh), 0)}
        </RhTypography>
        <RhTypography variant="body1">
          {formatCurrency(billForecastSummaryQuery.data.consumptionCost)}
        </RhTypography>
      </RowContainer>
    </>
  );
};
