import {
  grey,
  orange,
  purple,
  trueWhite,
} from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const VictoryLabelWrapper = styled.span`
  display: inline-flex;
  flex-direction: column;
  gap: 0;
  text-align: center;
`;

export const VictoryLabelPerKwh = styled.span`
  color: ${grey[500]};
  font-size: 8px;
  margin: 0;
`;

export const VictoryTooltipGradientWrapper = styled.div`
  background-image: linear-gradient(${orange[400]}, ${purple.main});
  border-radius: 8px;
  padding: 2px;
`;

export const VictoryTooltipWrapper = styled.div`
  align-items: center;
  background: ${trueWhite};
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  font-size: 10px;
  gap: 0.15rem;
  justify-content: center;
  padding: 0.25rem;
  text-align: center;

  strong {
    line-height: 1.1;
  }

  span {
    font-size: 12px;
  }
`;
