import { formatMaskNumber } from "@common/forms/formatters";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { Premise } from "@common/models/Premise.model";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { myAccountAutoPayTranslations } from "@portal/components/MyAccountAutoPay/MyAccountAutoPay.en.i18n";
import {
  AutoPayCard,
  BalancedText,
} from "@portal/components/MyAccountAutoPay/MyAccountAutoPay.styled";
import { MyAccountAutoPayModal } from "@portal/components/MyAccountAutoPayModal/MyAccountAutoPayModal";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremisePaymentMethodsQuery } from "@portal/hooks/queries/usePremisePaymentMethods.query";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { useState } from "react";

interface MyAccountAutoPayProps {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}

export const MyAccountAutoPay = (props: MyAccountAutoPayProps) => {
  const { premise, offerSnapshot } = props;
  const { translate } = useTranslations();
  const [showAutoPayModal, setShowAutoPayModal] = useState(false);
  const {
    tMyAccountAutoPayOff,
    tMyAccountAutoPayOn,
    tMyAccountAutoPayTitle,
    tMyAccountAutoPayEditButton,
    tMyAccountAutoPayErrorFetching,
    tMyAccountAutoPayNoPaymentMethod,
  } = translate(myAccountAutoPayTranslations);

  const paymentMethodsQuery = usePremisePaymentMethodsQuery({
    premiseId: premise.id,
  });
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise.id,
  });

  const isAutoPayOn = premise.autopay;

  const handleOpenModal = () => {
    setShowAutoPayModal(true);
  };

  const handleCloseModal = () => {
    setShowAutoPayModal(false);
  };

  if (paymentMethodsQuery.isPending || accountSummaryQuery.isPending) {
    return (
      <PortalCard $noShadow>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {tMyAccountAutoPayTitle}
        </RhTypography>
        <RhCircularProgress />
      </PortalCard>
    );
  }

  if (paymentMethodsQuery.isError || accountSummaryQuery.isError) {
    return (
      <PortalCard $noShadow>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {tMyAccountAutoPayTitle}
        </RhTypography>
        {tMyAccountAutoPayErrorFetching}
      </PortalCard>
    );
  }

  const accountSummary = accountSummaryQuery.data;
  const { paymentMethods } = paymentMethodsQuery.data;

  if (paymentMethods.length === 0) {
    return (
      <AutoPayCard $noShadow>
        <div>
          <RhTypography
            color="textPrimary"
            variant="subtitle2"
            fontWeight={FontWeight.Semibold}
          >
            {tMyAccountAutoPayTitle}
          </RhTypography>

          <BalancedText color="textSecondary" variant="body1">
            {tMyAccountAutoPayNoPaymentMethod}
          </BalancedText>
        </div>

        <RhButton
          data-tracking-click={{
            event: "",
          }}
          size="small"
          disabled
        >
          {tMyAccountAutoPayEditButton}
        </RhButton>
      </AutoPayCard>
    );
  }

  const defaultPaymentMethod = paymentMethods.find(
    (payment) => payment.defaultPaymentMethod
  );

  const showAutoPayDisabledWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !premise.autopay;

  return (
    <>
      <AutoPayCard $noShadow>
        <div>
          <RhTypography
            color="textPrimary"
            variant="subtitle2"
            fontWeight={FontWeight.Semibold}
          >
            {tMyAccountAutoPayTitle}
          </RhTypography>

          <RhTypography
            color={showAutoPayDisabledWarning ? "error" : "textSecondary"}
            variant="body1"
            textAlign="left"
          >
            {isAutoPayOn ? tMyAccountAutoPayOn : tMyAccountAutoPayOff}
          </RhTypography>
          {defaultPaymentMethod && isAutoPayOn ? (
            <RhTypography color="textSecondary" variant="body1">
              {defaultPaymentMethod?.companyName}{" "}
              {formatMaskNumber(defaultPaymentMethod.maskedNumber)}
            </RhTypography>
          ) : null}
        </div>

        <RhButton
          data-tracking-click={{
            event: "Customer opened Edit Auto Pay modal",
          }}
          size="small"
          onClick={handleOpenModal}
        >
          {tMyAccountAutoPayEditButton}
        </RhButton>
      </AutoPayCard>
      {showAutoPayModal ? (
        <MyAccountAutoPayModal
          offerSnapshot={offerSnapshot}
          accountSummary={accountSummary}
          paymentMethods={paymentMethodsQuery.data.paymentMethods}
          premise={premise}
          handleClose={handleCloseModal}
        />
      ) : null}
    </>
  );
};
