import { grey } from "@design-system/theme/palette.colors";
import styled, { css, keyframes } from "styled-components";

const shimmer = keyframes`
  from {opacity: 0.25};
  to {opacity: 1};
`;

interface SkeletonTextProps {
  $color?: string;
  $height?: string;
  $rounded?: boolean;
  $width?: string;
}

export const Skeleton = styled.div<SkeletonTextProps>`
  animation: ${shimmer} 0.5s infinite alternate
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${({ $color }) => $color || grey[100]};
  border-radius: 5px;
  display: block;
  height: ${({ $height }) => $height || "1.5em"};
  opacity: 0.25;
  overflow: hidden;
  position: relative;
  width: ${({ $width }) => $width || "100%"};

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 1000px;
    `}
`;
