export const signUpNoCoveragePageTranslations = {
  tSignUpNoCoveragePageBackToSite: "Back To Home",
  tSignUpNoCoveragePageButWellLetYouKnow: "But we'll let you know when we do.",
  tSignUpNoCoveragePageEmail: "Email",
  tSignUpNoCoveragePageRhythmHasntMadeIt:
    "Rhythm hasn't made it out your way yet.",
  tSignUpNoCoveragePageSubmitCTA: "Sign Up for Updates",
  tSignUpNoCoveragePageWellLetYouKnow:
    "We'll let you know when we've made it to your town!",
  tSignUpNoCoveragePageYoureOnTheList: "You're on the list!",
};
