import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidAdultBirthday } from "@common/forms/validators";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpNameFields } from "@portal/components/SignUpNameFields/SignUpNameFields";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpNamePageTranslations } from "@portal/pages/SignUpNamePage/SignUpNamePage.en.i18n";
import { selectSignUpNameFormValues } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  track,
} from "@portal/services/segment.service";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export type SignUpNameFormValues = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
};

const initialValues: SignUpNameFormValues = Object.freeze<SignUpNameFormValues>(
  {
    dateOfBirth: "",
    firstName: "",
    lastName: "",
  }
);

const signUpNameFormValidations = {
  dateOfBirth: [isRequired, isValidAdultBirthday],
  firstName: [isRequired],
  lastName: [isRequired],
};

const signUpNameFormValidator =
  generateValidationErrorCollector<SignUpNameFormValues>(
    signUpNameFormValidations
  );

export const SignUpNamePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const currentValues = useSelector(selectSignUpNameFormValues);
  const { featureFlagClient } = useFeatureFlagClient();
  const { translate } = useTranslations();

  const {
    tSignUpNamePageSubmitCTA,
    tSignUpNamePageTellUsAboutYourself,
    tSignUpNamePageTellUseAboutYourselfDesc,
  } = translate(signUpNamePageTranslations);

  const onSubmit = (values: SignUpNameFormValues) => {
    signUpClickNextStepHandler({
      nextStep: "contact",
      signUpData: values,
      track: true,
    });

    track({
      action: ActionType.nameSubmitted,
      category: CategoryType.Name,
      event: EventType.enrollmentClick,
      firstName: values.firstName,
      label: "Name Information",
      lastName: values.lastName,
    });

    featureFlagClient.trackMetric(
      FeatureFlagMetric.PortalProspectSubmittedNameDOB
    );
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpNamePageTellUsAboutYourself}
        subHeaderText={tSignUpNamePageTellUseAboutYourselfDesc}
      />
      <Form<SignUpNameFormValues>
        initialValues={{ ...initialValues, ...currentValues }}
        onSubmit={onSubmit}
        validate={signUpNameFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpNamePageSubmitCTA}
          >
            <SignUpNameFields
              trackEvent={trackEvent}
              disableAutofocus={isMobile}
            />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
