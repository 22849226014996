import { SPECIAL_CHAR_REGEX } from "@common/constants/regex.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useCompetitorPlans } from "@portal/hooks/queries/useCompetitorPlans";
import { CompetitorNames, CompetitorPlan } from "@portal/types/competitorTypes";
import { useEffect, useState } from "react";

const competitorsToShow: CompetitorNames[] = [
  CompetitorNames.DirectEnergy,
  CompetitorNames.GexaEnergy,
  CompetitorNames.GreenMountain,
  CompetitorNames.Reliant,
  CompetitorNames.ShellEnergy,
  CompetitorNames.SparkEnergy,
  CompetitorNames.TXU,
];

const emptyCompetitorPlans: CompetitorPlan[] = [];
const TOU_KEYWORDS = ["free", "seasonal", "hour", "pass", "nights"];

export const useRepPriceCompetitors = (
  selectedOfferSnapshot: OfferSnapshot | null
): CompetitorPlan[] => {
  const competitorPlansQuery = useCompetitorPlans();
  const [competitorPlanList, setCompetitorPlanList] = useState<
    CompetitorPlan[]
  >([]);
  const competitorPlans =
    competitorPlansQuery?.data?.results ?? emptyCompetitorPlans;

  useEffect(() => {
    if (competitorPlans.length) {
      // Filter out competitors we do not want to include in the chart
      // These could be ones still in the process of being added to the scraper or are not ready for FE
      const competitorPlansToShow = competitorPlans.filter((plan) =>
        competitorsToShow.includes(plan.competitorName)
      );

      if (selectedOfferSnapshot?.isTimeOfUse) {
        const touCompetitorPlans = competitorPlansToShow.filter((plan) => {
          const planNameArray = plan.planName.split(" ");

          return planNameArray.some((name) =>
            TOU_KEYWORDS.includes(
              name.replace(SPECIAL_CHAR_REGEX, "").toLowerCase()
            )
          );
        });

        setCompetitorPlanList(touCompetitorPlans);
      } else {
        setCompetitorPlanList(competitorPlansToShow);
      }
    }
  }, [competitorPlans, selectedOfferSnapshot]);

  return competitorPlanList;
};
