import { ReactComponent as RhythmLogoDarkPurple } from "@common/images/RhythmLogoDarkPurple.svg";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  PlanName,
  PlanPrice,
  PlanPricePerKwh,
  RhythmLearnMoreButton,
  RhythmLogoCell,
  RhythmPlanNameWrapper,
  RhythmPlanSelectButtonWrapper,
  RhythmPriceContainer,
  RhythmSelectedPlanWrapper,
} from "@portal/components/RepPriceComparisonPlanList/RepPriceComparisonPlanList.styled";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { FC } from "react";

const RhythmPrice: FC<{ offerSnapshot: OfferSnapshot }> = ({
  offerSnapshot,
}) => {
  const { t } = useRhIntl();
  const { solarEligible } = offerSnapshot;

  return (
    <RhythmPriceContainer>
      <PlanPrice>
        {solarEligible
          ? formatDollarsToCents(offerSnapshot.energyRate())
          : formatDollarsToCents(offerSnapshot.price2000Kwh)}
      </PlanPrice>
      <PlanPricePerKwh>
        {t("RepPriceComparisonPlanList.perKwh")}
      </PlanPricePerKwh>
    </RhythmPriceContainer>
  );
};

interface SelectedRhythmPlanProps {
  highlightPlan?: boolean;
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  offerSnapshot: OfferSnapshot;
  selectButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
}

export const SelectedRhythmPlan: FC<SelectedRhythmPlanProps> = ({
  highlightPlan = true,
  moreButtonProps,
  offerSnapshot,
  selectButtonProps,
}) => {
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  return (
    <RhythmSelectedPlanWrapper highlight={highlightPlan}>
      <RhythmLogoCell>
        <RhythmLogoDarkPurple />
      </RhythmLogoCell>

      <RhythmPlanNameWrapper>
        <PlanName>{offerSnapshot.title}</PlanName>
        {isMobile && <RhythmPrice offerSnapshot={offerSnapshot} />}
        <RhythmLearnMoreButton type="button" onClick={moreButtonProps.onClick}>
          {moreButtonProps.text}
        </RhythmLearnMoreButton>
      </RhythmPlanNameWrapper>

      {!isMobile && <RhythmPrice offerSnapshot={offerSnapshot} />}

      <RhythmPlanSelectButtonWrapper>
        <RhButton
          data-tracking-click={{ event: "Prospect selected a plan" }}
          color="primary"
          size="small"
          onClick={selectButtonProps.onClick}
          id={`comparePlans-selectButton-${offerSnapshot.id}`}
        >
          {isMobile
            ? t("RepPriceComparisonPlanList.selectButtonMobile")
            : selectButtonProps.text}
        </RhButton>
      </RhythmPlanSelectButtonWrapper>
    </RhythmSelectedPlanWrapper>
  );
};
