import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { grey } from "@design-system/theme/palette.colors";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { Backdrop, MenuItem } from "@mui/material";
import styled, { css } from "styled-components";

export const ACCOUNT_MENU_WIDTH = 130;

export const StyledAccountMenuBackdrop = styled(Backdrop)`
  background: ${grey[700]};
  cursor: pointer;
`;

export const StyledChevronIconContainer = styled.div`
  color: ${muiTheme.palette.primary.main};
  padding-left: ${rhSpacingPx(1)};
`;

export const StyledClickableContainer = styled.div`
  color: ${muiTheme.palette.common.black};
  position: relative;

  &:hover {
    color: ${muiTheme.palette.primary.main} !important;
    cursor: pointer;
    text-decoration: none !important;
  }
`;

const customerLoadingStyles = css`
  opacity: 0;
  width: 40px;
`;

export const StyledMenuCtaContainer = styled.div<{ $customerLoading: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: opacity 250ms ease-in-out;

  ${({ $customerLoading }) => $customerLoading && customerLoadingStyles}
`;

export const StyledStyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    box-sizing: border-box;
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: ${FontWeight.Regular};
    line-height: 1.5;
    min-height: auto;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;

    width: auto;
  }
`;

export const StyledMenuItemSpacer = styled.div`
  margin: ${rhSpacingPx(0.5)} 0 0 !important;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(2.5)};
  width: 100%;
`;

export const StyledPopupAnchor = styled.span`
  height: 10px;
  position: absolute;
  right: 0;
`;
