import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledPaymentExtensionUnableToCollectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  margin: ${rhSpacingPx(5)};
  text-align: center;
  width: 350px;
`;

export const StyledRhythmPaymentDetails = styled.div`
  color: ${grey[500]};
  display: flex;
  flex-direction: column;
`;
