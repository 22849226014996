import { StepperKey } from "@design-system/components/RhStepper/rhStepperTypes";
import { devicesEnrollStepperTranslations } from "@portal/components/DevicesEnrollStepper/DevicesEnrollStepper.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import { DevicesEnrollStepType } from "@portal/routes/routePaths";

export const useDevicesEnrollStepperSteps =
  (): StepperKey<DevicesEnrollStepType>[] => {
    const { translate } = useTranslations();

    const {
      tDevicesEnrollStepperChooseYourDevice,
      tDevicesEnrollStepperSelectDevices,
      tDevicesEnrollStepperTerms,
      tDevicesEnrollStepperSummary,
    } = translate(devicesEnrollStepperTranslations);

    return [
      {
        id: "choose-your-device-brand",
        label: tDevicesEnrollStepperChooseYourDevice,
      },
      { id: "terms-and-conditions", label: tDevicesEnrollStepperTerms },
      { id: "select-devices", label: tDevicesEnrollStepperSelectDevices },
      { id: "summary", label: tDevicesEnrollStepperSummary },
    ];
  };
