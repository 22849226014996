import { prospectApi } from "@portal/api/prospectApi";
import { prospectQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UseCreateProspectMutationArgs {
  mutationOptions: {
    retry?: number;
  };
}

export const useCreateProspectMutation = (
  props: UseCreateProspectMutationArgs
) => {
  const {
    mutationOptions: { retry = 2 },
  } = props;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: prospectApi.create,
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: prospectQueryKeys.retrieve(data.uuid),
      });
    },
    retry,
  });

  return mutation;
};
