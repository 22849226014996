import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { useLatestAvailableDate } from "@common/components/EnergyUsageGraphV2/useLatestAvailableDate";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { Premise } from "@common/models/Premise.model";
import {
  ChartDirection,
  SelectedGraph,
  SelectedGraphMode,
  chartTypeMapping,
} from "@common/types/usageTypes";
import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { EnergyUsageChartMissing } from "@portal/components/EnergyUsageChartMissing/EnergyUsageChartMissing";
import {
  DateAndTotalsContainer,
  EnergyGraphContainer,
  StyledDatePickerWrapper,
  StyledRhRoundTabs,
  TabContainer,
} from "@portal/components/EnergyUsageSectionV2/EnergyGraph.styles";
import { translations as EnergyUsageSectionV2Translations } from "@portal/components/EnergyUsageSectionV2/EnergyUsageSectionV2.en.i18n";
import { TotalUsage } from "@portal/components/EnergyUsageSectionV2/TotalUsage/TotalUsage";
import { useOfferSnapshotQuery } from "@portal/hooks/queries/useOfferSnapshot.query";
import { usePremiseKwhUsageDay } from "@portal/hooks/queries/usePremiseKwhUsageDay";
import { usePremiseKwhUsageSummary } from "@portal/hooks/queries/usePremiseKwhUsageSummary";
import { useTranslations } from "@portal/hooks/useTranslations";
import { KwhUsageSummaryType } from "@portal/types/usageTypes";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

// We don't need to map hourly data so exclude from this mapping
const usageDataMapping: Record<
  Exclude<SelectedGraph, "hourly">,
  keyof KwhUsageSummaryType
> = {
  [SelectedGraph.billPeriod]: "billPeriodData",
  [SelectedGraph.monthly]: "thirtyDaysData",
  [SelectedGraph.yearly]: "oneYearData",
};

interface EnergyGraphProps {
  premise: Premise;
}

export const EnergyGraph = (props: EnergyGraphProps) => {
  const { premise } = props;
  const theme = useTheme();

  const { translate } = useTranslations();
  const {
    tEnergyUsageSectionV2BillPeriodTab,
    tEnergyUsageSectionV2DailyTab,
    tEnergyUsageSectionV2DatePickerCancel,
    tEnergyUsageSectionV2DatePickerOk,
    tEnergyUsageSectionV2HourlyTab,
    tEnergyUsageSectionV2MonthlyTab,
    tEnergyUsageSectionV2EnergyGraphSectionLabel,
    tEnergyUsageSectionV2DateHourTitle,
    tEnergyUsageSectionV2DateTitle,
    tEnergyUsageSectionV2DefaultTierTitle,
    tEnergyUsageSectionV2GenerationLabelTitle,
    tEnergyUsageSectionV2UsageAxisTitle,
    tEnergyUsageSectionV2UsageLabelTitle,
    tEnergyUsageSectionV2UsageTooltipLabel,
    tEnergyUsageSectionV2CostTooltipLabel,
    tEnergyUsageSectionV2GenerationTooltipLabel,
    tEnergyUsageSectionV2EarnedTooltipLabel,
    tEnergyUsageSectionV2Both,
    tEnergyUsageSectionV2Consumption,
    tEnergyUsageSectionV2Surplus,
  } = translate(EnergyUsageSectionV2Translations);

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalTouGenerationUsageGraph } = featureFlagClient.getFlags([
    ["portalTouGenerationUsageGraph", false],
  ]);

  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const chartDirection: ChartDirection = isMobile ? "vertical" : "horizontal";

  const [selectedDate, setSelectedDate] = useState<string>("");

  const [selectedGraph, setSelectedGraph] = useState<SelectedGraph>(
    SelectedGraph.billPeriod
  );

  const [selectedGraphMode, setSelectedGraphMode] = useState<SelectedGraphMode>(
    SelectedGraphMode.both
  );

  const graphModeTabs: { label: string; value: SelectedGraphMode }[] = [
    { label: tEnergyUsageSectionV2Both, value: SelectedGraphMode.both },
    {
      label: tEnergyUsageSectionV2Consumption,
      value: SelectedGraphMode.consumption,
    },
    { label: tEnergyUsageSectionV2Surplus, value: SelectedGraphMode.surplus },
  ];

  const tabs: { label: string; value: SelectedGraph }[] = [
    {
      label: tEnergyUsageSectionV2BillPeriodTab,
      value: SelectedGraph.billPeriod,
    },
    { label: tEnergyUsageSectionV2HourlyTab, value: SelectedGraph.hourly },
    { label: tEnergyUsageSectionV2DailyTab, value: SelectedGraph.monthly },
    { label: tEnergyUsageSectionV2MonthlyTab, value: SelectedGraph.yearly },
  ];

  const kwhUsageSummaryQuery = usePremiseKwhUsageSummary({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  const latestAvailableDate = useLatestAvailableDate(
    kwhUsageSummaryQuery.data?.billPeriodData || [],
    setSelectedDate
  );

  const kwhUsageDayQuery = usePremiseKwhUsageDay({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt && selectedDate
    ),
    premiseId: premise.id,
    targetDate: selectedDate,
  });

  const { data: offerSnapshot } = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
  });

  if (!premise.currentOrder) {
    return null;
  }

  if (kwhUsageSummaryQuery.isError || kwhUsageDayQuery.isError) {
    return <EnergyUsageChartMissing />;
  }

  if (kwhUsageSummaryQuery.isPending || kwhUsageDayQuery.isPending) {
    return <RhCircularProgress />;
  }

  const handleDateSelection = (dateString: string | null) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const usageData =
    selectedGraph === SelectedGraph.hourly
      ? kwhUsageDayQuery.data
      : kwhUsageSummaryQuery.data[usageDataMapping[selectedGraph]];

  const chartLabels = {
    costTooltipLabel: tEnergyUsageSectionV2CostTooltipLabel,
    dateHourTitle: tEnergyUsageSectionV2DateHourTitle,
    dateTitle: tEnergyUsageSectionV2DateTitle,
    defaultTierTitle: tEnergyUsageSectionV2DefaultTierTitle,
    earnedTooltipLabel: tEnergyUsageSectionV2EarnedTooltipLabel,
    generationLabelTitle: tEnergyUsageSectionV2GenerationLabelTitle,
    generationTitle: tEnergyUsageSectionV2GenerationLabelTitle,
    generationTooltipLabel: tEnergyUsageSectionV2GenerationTooltipLabel,
    usageAxisTitle: tEnergyUsageSectionV2UsageAxisTitle,
    usageLabelTitle: tEnergyUsageSectionV2UsageLabelTitle,
    usageTitle: tEnergyUsageSectionV2UsageLabelTitle,
    usageTooltipLabel: tEnergyUsageSectionV2UsageTooltipLabel,
  };

  const { solarEligible, solarGenerationCapped } = premise.currentOrder;

  const showGraphModeTabs =
    portalTouGenerationUsageGraph.value && solarEligible;

  const currentTierNames = offerSnapshot?.priceTierSnapshots
    ? offerSnapshot.priceTierSnapshots.map(({ name }) => name)
    : undefined;

  return (
    <EnergyGraphContainer
      aria-label={tEnergyUsageSectionV2EnergyGraphSectionLabel}
    >
      <TabContainer>
        {showGraphModeTabs && (
          <StyledRhRoundTabs
            initialSelected={graphModeTabs.findIndex(
              (tab) => tab.value === selectedGraphMode
            )}
            contained
            variant="scrollable"
          >
            {graphModeTabs.map(({ label, value }) => (
              <RhRoundTabItem
                key={label}
                label={label}
                onClick={() => {
                  setSelectedGraphMode(value);
                }}
              />
            ))}
          </StyledRhRoundTabs>
        )}
        <StyledRhRoundTabs variant="scrollable">
          {tabs.map(({ label, value }) => (
            <RhRoundTabItem
              key={label}
              label={label}
              onClick={() => {
                setSelectedGraph(value);
              }}
            />
          ))}
        </StyledRhRoundTabs>
      </TabContainer>
      <DateAndTotalsContainer>
        {selectedGraph === SelectedGraph.hourly ? (
          <StyledDatePickerWrapper>
            <RhCalendarDatePicker
              label=""
              format="MM/DD/YYYY"
              inputName="usageGraphDatePicker"
              value={dayjs(selectedDate)}
              maxDate={dayjs(latestAvailableDate)}
              minDate={dayjs(premise.confirmedStartDate)}
              onChange={(date: Dayjs) => {
                const formattedDate = date.format(ISO8601_DATE_FORMAT);

                handleDateSelection(formattedDate);
              }}
              okLabel={tEnergyUsageSectionV2DatePickerOk}
              cancelLabel={tEnergyUsageSectionV2DatePickerCancel}
            />
          </StyledDatePickerWrapper>
        ) : null}
        <TotalUsage
          usageData={usageData}
          showGeneration={
            solarEligible && selectedGraphMode !== SelectedGraphMode.consumption
          }
          showEarned={!solarGenerationCapped}
          showConsumption={selectedGraphMode !== SelectedGraphMode.surplus}
        />
      </DateAndTotalsContainer>

      <EnergyUsageGraphV2
        usageData={usageData}
        chartType={chartTypeMapping[selectedGraph]}
        chartDirection={chartDirection}
        showGeneration={
          showGraphModeTabs
            ? selectedGraphMode === SelectedGraphMode.surplus ||
              selectedGraphMode === SelectedGraphMode.both
            : solarEligible
        }
        showConsumption={
          selectedGraphMode === SelectedGraphMode.consumption ||
          selectedGraphMode === SelectedGraphMode.both
        }
        showEarned={!solarGenerationCapped}
        labels={chartLabels}
        currentTierNames={currentTierNames}
        latestAvailableDate={latestAvailableDate}
      />
    </EnergyGraphContainer>
  );
};
