import { StyledBillEnergyTemperatureSkeleton } from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";

export const BillEnergyUsageTemperatureCardSkeleton = () => (
  <StyledBillEnergyTemperatureSkeleton>
    <Skeleton />
    <Skeleton $height="20em" />
  </StyledBillEnergyTemperatureSkeleton>
);
