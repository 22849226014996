import { chooseYourDeviceBrandStepAtom } from "@portal/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.atoms";
import { DevicesEnrollPageLayout } from "@portal/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout";
import { DevicesEnrollTermsForm } from "@portal/components/DevicesEnrollTermsForm/DevicesEnrollTermsForm";
import { useTranslations } from "@portal/hooks/useTranslations";
import { devicesEnrollTermsPageTranslations } from "@portal/pages/DevicesEnrollTermsPage/DevicesEnrollTermsPage.en.i18n";
import { devicesEnrollStepPath } from "@portal/routes/routePaths";
import { useAtomValue } from "jotai";
import React from "react";
import { Navigate } from "react-router-dom";

export const DevicesEnrollTermsPage = () => {
  const deviceBrand = useAtomValue(chooseYourDeviceBrandStepAtom);
  const { translate } = useTranslations();
  const { tDevicesEnrollTermsPageTitle } = translate(
    devicesEnrollTermsPageTranslations
  );

  if (!deviceBrand) {
    return <Navigate to={devicesEnrollStepPath("choose-your-device-brand")} />;
  }

  return (
    <DevicesEnrollPageLayout
      step="terms-and-conditions"
      title={tDevicesEnrollTermsPageTitle}
    >
      <DevicesEnrollTermsForm />
    </DevicesEnrollPageLayout>
  );
};
