import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { selectCampaignPromo } from "@portal/selectors/signUpSelectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const CampaignPromoAnnouncement = () => {
  const campaignPromo = useSelector(selectCampaignPromo);
  const { announceNotice } = useRhAnnouncement();
  const { tJsx } = useRhIntl();

  useEffect(() => {
    if (campaignPromo) {
      const promoCodeApplied = tJsx(
        "CampaignPromoAnnouncement.promoCodeApplied",
        {
          promoCode: campaignPromo.code,
        }
      );

      announceNotice(promoCodeApplied);
    }
  }, [campaignPromo]);
  return null;
};
