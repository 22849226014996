import {
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { PortalPasswordField } from "@portal/components/PortalPasswordField/PortalPasswordField";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as HighFives } from "@portal/images/HighFives.svg";
import { createPasswordPageTranslations } from "@portal/pages/CreatePasswordPage/CreatePasswordPage.en.i18n";
import { StyledCreatePasswordPageCaption } from "@portal/pages/CreatePasswordPage/CreatePasswordPage.styled";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

interface SignUpCreatePasswordFormValues {
  password: string;
}

export interface SignUpCreatePasswordPageValues {
  activationToken?: string;
  firstName: string;
  sessionToken?: string;
  stateToken?: string;
}

export const CreatePasswordPage = () => {
  const flash = useRhFlash();

  const {
    activationToken,
    firstName,
    stateToken: existingStateToken,
  } = useSelector<PortalStoreState, SignUpCreatePasswordPageValues>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ signUp: { activationToken, firstName, stateToken } }) => ({
      activationToken,
      firstName,
      stateToken,
    })
  );

  // On success, sign-up data is wiped, which will cause flicker in the header
  // To avoid this, save a local copy of the first name, which is discarded on unmount
  const [firstNameLocalCopy] = useState<string>(firstName);
  const [stateToken, setStateToken] = useState<string | undefined>(
    existingStateToken
  );

  const { translate, translateJsx } = useTranslations();

  const {
    tCreatePasswordPageCreateAPasswordToAccess,
    tCreatePasswordPageInvalidPassword,
    tCreatePasswordPagePasswordMinimumRequirements,
    tCreatePasswordPagePassword,
    tCreatePasswordPageSubmitCTA,
    tCreatePasswordPageTwoFriendsHighFiving,
    tCreatePasswordPageUnknownOktaIssue,
    tCreatePasswordPageWelcome,
  } = translate(createPasswordPageTranslations);

  const { tCreatePasswordPageWelcomeWithName } = translateJsx({
    tCreatePasswordPageWelcomeWithName: { firstName: firstNameLocalCopy },
  });

  const createPassword = ({ password }: SignUpCreatePasswordFormValues) => {
    return portalAuthClient
      .createPassword({
        activationToken,
        password,
        stateToken,
      })
      .catch((error: RhApiError) => {
        setStateToken(error.data.stateToken || "");

        let errorMessage: string;

        switch (error.data.errorCode) {
          case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
            errorMessage = tCreatePasswordPageInvalidPassword;
            break;
          }
          default: {
            errorMessage = tCreatePasswordPageUnknownOktaIssue;
          }
        }

        flash.error(errorMessage);

        return {
          password: [errorMessage],
        };
      });
  };

  // If we've arrived here from a link in your email, we don't know your name
  const welcomeText = firstNameLocalCopy
    ? tCreatePasswordPageWelcomeWithName
    : tCreatePasswordPageWelcome;

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader
        headerText={welcomeText}
        subHeaderText={tCreatePasswordPageCreateAPasswordToAccess}
        illustration={
          <HighFives title={tCreatePasswordPageTwoFriendsHighFiving} />
        }
      />
      <Form<SignUpCreatePasswordFormValues>
        onSubmit={createPassword}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tCreatePasswordPageSubmitCTA}
            showProgress
          >
            <LoggedOutFieldsLayout dense>
              <PortalPasswordField name="password">
                {tCreatePasswordPagePassword}
              </PortalPasswordField>
              <StyledCreatePasswordPageCaption variant="caption" align="center">
                {tCreatePasswordPagePasswordMinimumRequirements}
              </StyledCreatePasswordPageCaption>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};
