import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const RhSelectLabel = styled.label`
  color: ${grey[600]};
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
`;

export const RhSelect = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7"><path d="M6 6.5L12 0.5L0 0.499999L6 6.5Z" fill="currentColor"/></svg>')
    no-repeat right center;
  background-position: calc(100% - 12px) center;
  border: 1px solid ${grey[200]};
  border-radius: 4px;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.04);
  color: ${grey[800]};
  cursor: pointer;
  font-family: ${fontFamily};
  font-size: 16px;
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(1.75)} ${rhSpacingPx(1.5)};
  transition: border 0.25s linear;
  width: 100%;

  &:hover {
    border-color: ${darkPurple.main};
  }

  &:focus {
    border-color: ${darkPurple.main};
    outline: none;
  }
`;
