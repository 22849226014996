import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  green,
  grey,
  infoBlue,
  red,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { ANNOUNCEMENT_HEIGHT } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const RhAnnouncementContainer = styled("div")`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  justify-content: center;
  min-height: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT)};
  padding: ${rhSpacingPx(0.5)} ${rhSpacingPx(2)};
  text-align: center;
  width: 100%;

  a {
    font-weight: ${FontWeight.Bold};
    text-decoration: underline;
  }

  &.error {
    background-color: ${red[500]};
    color: ${trueWhite};

    & a {
      color: ${trueWhite};

      &:hover {
        color: ${trueWhite};
      }
    }

    &:hover {
      color: ${grey[100]};
    }

    & svg {
      color: ${trueWhite};

      &:hover {
        color: ${grey[100]};
      }
    }
  }

  &.info {
    background-color: ${infoBlue[50]};
    color: ${infoBlue[700]};

    & a {
      color: ${infoBlue[700]};

      &:hover {
        color: ${infoBlue[500]};
      }
    }

    & svg {
      color: ${infoBlue[700]};

      &:hover {
        color: ${infoBlue[500]};
      }
    }
  }

  &.notice {
    background-color: ${green[100]};
    color: ${green[700]};

    & a {
      color: ${green[700]};

      &:hover {
        color: ${green[500]};
      }
    }

    & svg {
      color: ${green[700]};

      &:hover {
        color: ${green[500]};
      }
    }
  }
`;

export const ClearButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: auto;
  margin-right: ${rhSpacingPx(2)};
  padding: 0;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  width: 0.75rem;
`;
