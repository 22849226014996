/* About these keys:

The key (after RewardsHistoryTable.rewards) is defined by the
custom event string either *in Voucherify* or one of our RewardTypes (rewards/contants.py).
These must match exactly what we have there.  They are given back to us with each
"customer.rewarded.loyalty_points" from Voucherify event that we see or from the
Reward#type if it's from our db.

The only other *special* situation is when we get a `referral` event where we set this
key manually in the get_rewards_history_from_voucherify method which identifies referral
events - when the friend who was referred redeems their side of the referral voucher.

The two possible exceptions are `OfferPromo` and `ETFCredit`.  These rewards are added
manually by simply adding loyalty points.  We keep a record of them locally in our `Reward`
table (customer_reward).  This means that we basically own the keys from start to finish.

*/
export const rewardsHistoryTableRewardTranslations = {
  tRewardsHistoryTableDefaultRewardName: "Rhythm points",
  tRewardsHistoryTableRewardAutoPay: "Auto Pay",
  tRewardsHistoryTableRewardCampaignPromo: "Rhythm promo",
  tRewardsHistoryTableRewardETFCredit: "ETF Credit",
  tRewardsHistoryTableRewardOfferPromo: "Rhythm promo",
  tRewardsHistoryTableRewardOnFlow: "Welcome Gift",
  tRewardsHistoryTableRewardPaperless: "Paperless",
  tRewardsHistoryTableRewardReferee: "Refer a Friend",
  tRewardsHistoryTableRewardReferral: "Refer a Friend",
  tRewardsHistoryTableRewardRhythmRewards: "Rhythm points",
};
