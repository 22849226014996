export const translations: Record<string, string> = {
  "common.password.minimumRequirements":
    "Utilice 8 o más caracteres con una combinación de letras, números y símbolos.",
  "navigation.title.rhythm": "GotRhythm",
  "rhythm.button.save": "Salvar",
  "rhythm.input.cancel": "Cancelar",
  "rhythm.input.confirm": "Confirmar",
  "rhythm.input.enroll": "Inscribirse",
  "rhythm.input.ok": "Okay",
  "rhythm.input.submit": "Enviar",
  "rhythm.input.today": "Hoy",
  "validator.allCapsRequired": "Debe ser todo en mayúsculas.",
  "validator.creditCheckIsIncomplete":
    "La verificación de crédito necesita más información antes de completarse.",
  "validator.creditCheckIsRequired": "Se requiere verificación de crédito",

  "validator.invalidBirthday": "Debe tener 18 años o más para inscribirse.",
  "validator.invalidBirthdayFieldDateFormat":
    "Por favor introduzca una fecha valida (MM/DD/YYYY).",
  "validator.invalidConfirmEmail": "Los correos electrónicos deben coincidir.",
  "validator.invalidDateFormat":
    "Por favor introduzca una fecha valida (YYYY-mm-dd).",
  "validator.invalidDateValue": "Por favor, introduzca una fecha en el futuro.",
  "validator.invalidEmail": "Email inválido.",
  "validator.invalidPhoneNumber": "Numero de telefono invalido.",
  "validator.invalidSSN": "Número de seguro social no válido",
  "validator.invalidUSState": "Estado inválido de EE. UU.",
  "validator.invalidZipCode": "Código postal no válido.",
  "validator.isNotAPositiveNumber": "No es un número positivo.",
  "validator.mustBeAfterStartDate": "Debe ser posterior a la fecha de inicio",
  "validator.required": "Falta alguna información requerida.",
  "validator.slugMustContainNoSpaces": "Slug no puede contener ningún espacio.",
};
