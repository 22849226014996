import {
  getConsumptionBgColor,
  getGenerationBgColor,
  getGradient,
} from "@common/components/EnergyUsageGraphV2/colorUtils";
import {
  aggregateUsageByDate,
  groupByTierAndDate,
} from "@common/components/EnergyUsageGraphV2/groupByTierAndDate";
import {
  GetChartDataProps,
  GetGroupedDataSetsProps,
  GetSingleDataSetsProps,
  GetTemperatureDataSetsProps,
} from "@common/components/EnergyUsageGraphV2/types";
import {
  ChartDirection,
  GraphTemperatureValue,
  TemperatureType,
  UsageType,
} from "@common/types/usageTypes";
import {
  blue,
  green,
  infoBlue,
  orange,
  purple,
  yellow,
} from "@design-system/theme/palette.colors";
import { ChartData, ChartDataset } from "chart.js";

const getConsumptionDataset = (props: {
  chartDirection: ChartDirection;
  tierName?: string;
  tierOrder?: number;
  usageData: UsageType[];
  usageLabelTitle: string;
  withBoth: boolean;
}) => {
  const {
    usageData,
    chartDirection,
    usageLabelTitle,
    tierName,
    tierOrder,
    withBoth,
  } = props;

  return {
    backgroundColor: withBoth ? infoBlue[200] : getConsumptionBgColor,
    data: usageData,
    label: withBoth || !tierName ? usageLabelTitle : tierName,
    order: tierOrder,
    parsing: getParsingDirection({ chartDirection, isGeneration: false }),
    pointStyle: "rectRounded",
  };
};

const getGenerationDataset = (props: {
  chartDirection: ChartDirection;
  generationLabelTitle: string;
  tierName?: string;
  tierOrder?: number;
  usageData: UsageType[];
  withBoth?: boolean;
}) => {
  const {
    usageData,
    chartDirection,
    generationLabelTitle,
    tierName,
    tierOrder,
    withBoth,
  } = props;

  return {
    backgroundColor: withBoth ? green[300] : getGenerationBgColor,
    data: usageData.map((usage) => {
      if (withBoth) {
        return {
          ...usage,
          generationKwh: (Number(usage.generationKwh) * -1).toString(),
        };
      }

      return usage;
    }),
    label: withBoth || !tierName ? generationLabelTitle : tierName,
    order: tierOrder,
    parsing: getParsingDirection({
      chartDirection,
      isGeneration: true,
    }),
    pointStyle: "rectRounded",
  };
};

const getParsingDirection = ({
  chartDirection,
  isGeneration,
}: {
  chartDirection: ChartDirection;
  isGeneration: boolean;
}) => {
  const dataAxisKey = isGeneration ? "generationKwh" : "consumptionKwh";

  return chartDirection === "horizontal"
    ? {
        xAxisKey: "datetime",
        yAxisKey: dataAxisKey,
      }
    : {
        xAxisKey: dataAxisKey,
        yAxisKey: "datetime",
      };
};

const getGroupedDataSets = (props: GetGroupedDataSetsProps) => {
  const {
    usageData,
    chartType,
    chartDirection,
    generationLabelTitle,
    withGeneration,
    withConsumption,
    defaultTierTitle,
    usageLabelTitle,
  } = props;
  const datasets: ChartDataset<"bar", UsageType[]>[] = [];
  const withBoth = withGeneration && withConsumption;

  if (withConsumption) {
    const consumptionChartData = groupByTierAndDate({
      chartType,
      defaultTierTitle,
      isGeneration: false,
      usageData,
    });

    Object.entries(consumptionChartData).map(([tierName, tierData]) =>
      datasets.push(
        getConsumptionDataset({
          chartDirection,
          tierName,
          tierOrder: tierData[0]?.consumptionTierOrder,
          usageData: tierData,
          usageLabelTitle,
          withBoth,
        })
      )
    );
  }

  if (withGeneration) {
    const generationChartData = groupByTierAndDate({
      chartType,
      defaultTierTitle,
      isGeneration: true,
      usageData,
    });

    Object.entries(generationChartData).map(([tierName, tierData]) =>
      datasets.push(
        getGenerationDataset({
          chartDirection,
          generationLabelTitle,
          tierName,
          tierOrder: tierData[0]?.generationTierOrder,
          usageData: tierData,
          withBoth,
        })
      )
    );
  }

  return datasets;
};

const getSingleDataSets = (props: GetSingleDataSetsProps) => {
  const {
    usageData,
    chartType,
    chartDirection,
    usageLabelTitle,
    withGeneration,
    withConsumption,
    generationLabelTitle,
  } = props;
  let chartData = usageData;

  if (chartType === "daily" || chartType === "monthly") {
    chartData = aggregateUsageByDate({ chartType, usageData });
  }

  const datasets: ChartDataset<"bar", UsageType[]>[] = [];

  const withBoth = withConsumption && withGeneration;

  if (withConsumption) {
    datasets.push(
      getConsumptionDataset({
        chartDirection,
        usageData: chartData,
        usageLabelTitle,
        withBoth,
      })
    );
  }

  if (withGeneration) {
    datasets.push(
      getGenerationDataset({
        chartDirection,
        generationLabelTitle,
        usageData: chartData,
        withBoth,
      })
    );
  }

  return datasets;
};

const getTemperatureDataSets = (props: GetTemperatureDataSetsProps) => {
  const { selectedTemperatureValue, temperatures, temperatureLabels } = props;

  const datasets: ChartDataset<"line", TemperatureType[]>[] = [];

  if (selectedTemperatureValue === GraphTemperatureValue.Average) {
    datasets.push({
      backgroundColor: `linear-gradient(to left, ${orange[200]}, ${purple.main})`,
      borderColor: (context) =>
        getGradient({ color1: orange[200], color2: purple.main, context }),
      data: temperatures,
      label: temperatureLabels.averageTemp,
      order: -10,
      parsing: { xAxisKey: "datetime", yAxisKey: "average" },
      pointStyle: false,
      type: "line",
      yAxisID: "weather",
    });
  } else {
    datasets.push(
      {
        backgroundColor: `linear-gradient(to left, ${blue.dark}, ${purple.medium})`,
        borderColor: (context) =>
          getGradient({ color1: blue.dark, color2: purple.medium, context }),
        data: temperatures,
        label: temperatureLabels.lowTemp,
        order: -10,
        parsing: { xAxisKey: "datetime", yAxisKey: "low" },
        pointStyle: false,
        type: "line",
        yAxisID: "weather",
      },
      {
        backgroundColor: `linear-gradient(to left, ${orange[200]}, ${yellow.dark})`,
        borderColor: (context) =>
          getGradient({ color1: orange[200], color2: yellow.dark, context }),
        data: temperatures,
        label: temperatureLabels.highTemp,
        order: -10,
        parsing: { xAxisKey: "datetime", yAxisKey: "high" },
        pointStyle: false,
        type: "line",
        yAxisID: "weather",
      }
    );
  }

  return datasets;
};

export function getChartData(props: GetChartDataProps) {
  const {
    chartType,
    usageData,
    withConsumption,
    chartDirection,
    withGeneration,
    withTiers,
    usageLabelTitle,
    generationLabelTitle,
    defaultTierTitle,
    selectedTemperatureValue,
    temperatures,
    temperatureLabels,
  } = props;

  const withBoth = withConsumption && withGeneration;

  let temperatureDataset: ChartDataset<"line", TemperatureType[]>[] = [];
  let usageDataSet: ChartDataset<"bar", UsageType[]>[] = [];

  if (!withBoth && withTiers && chartType !== "hourly") {
    usageDataSet = getGroupedDataSets({
      chartDirection,
      chartType,
      defaultTierTitle,
      generationLabelTitle,
      usageData,
      usageLabelTitle,
      withConsumption,
      withGeneration,
    });
  } else {
    usageDataSet = getSingleDataSets({
      chartDirection,
      chartType,
      generationLabelTitle,
      usageData,
      usageLabelTitle,
      withConsumption,
      withGeneration,
    });
  }

  if (temperatures && temperatures.length > 0) {
    temperatureDataset = getTemperatureDataSets({
      selectedTemperatureValue,
      temperatureLabels,
      temperatures,
    });
  }

  return { datasets: [...usageDataSet, ...temperatureDataset] } as ChartData<
    "bar" | "line",
    UsageType[] | TemperatureType[]
  >;
}
