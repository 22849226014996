import { isBot } from "@common/utils/isBot";
import {
  GoogleAnalyticsData,
  googleAnalyticsDataAtom,
} from "@portal/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

interface TrackingData {
  rhythmData: GoogleAnalyticsData;
}

export const GoogleAnalyticsTracking = () => {
  const googleAnalyticsDataAtomValue = useAtomValue(googleAnalyticsDataAtom);

  useEffect(() => {
    if (isBot()) {
      return;
    }

    const trackingData: TrackingData = {
      rhythmData: googleAnalyticsDataAtomValue,
    };

    window.dataLayer.push(trackingData);
  }, [googleAnalyticsDataAtomValue]);

  return null;
};
