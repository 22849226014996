import { EN_DASH } from "@common/constants/characters.constant";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { ProspectType } from "@common/types/prospectTypes";
import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as CircularMoneyIcon } from "@design-system/icons/CircularMoneyIcon.svg";
import { ReactComponent as PaperlessIcon } from "@design-system/icons/PaperlessIcon.svg";
import { signUpBillingPreferencesFormTranslations } from "@portal/components/SignUpBillingPreferencesForm/SignUpBillingPreferencesForm.en.i18n";
import {
  BillingPreferencesForm,
  BillingPreferencesFormHeader,
  BillingPreferencesFormPortalToggle,
  BillingPreferencesFormRow,
  BillingPreferencesFormSubHeader,
  FinePrintContainer,
} from "@portal/components/SignUpBillingPreferencesForm/SignUpBillingPreferencesForm.styled";
import { useGetSignUpBillingPreferencesNextStep } from "@portal/components/SignUpBillingPreferencesForm/useGetSignUpBillingPreferencesNextStep";
import { useProspectUpdateMutation } from "@portal/hooks/mutations/useProspectUpdate.mutation";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpStepPath } from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  track,
} from "@portal/services/segment.service";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EBILL_REWARD_POINTS = 500;
const AUTOPAY_REWARD_POINTS = 500;

interface SignUpBillingPreferencesFormProps {
  offerSnapshot: OfferSnapshot;
  prospect: ProspectType;
}

export interface SignUpBillingPreferencesFormValues {
  autopay: boolean;
  paperless: boolean;
}

export const SignUpBillingPreferencesForm = (
  props: SignUpBillingPreferencesFormProps
) => {
  const { offerSnapshot, prospect } = props;

  const flash = useRhFlash();

  const { getBillingPreferencesNextStep } =
    useGetSignUpBillingPreferencesNextStep();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visitedSteps } = useSelector(selectSignUpState);
  const prospectUpdateMutation = useProspectUpdateMutation();

  const {
    featureFlagClient: { trackMetric },
  } = useFeatureFlagClient();

  const { translate, translateJsx } = useTranslations();

  const {
    tSignUpBillingPreferencesFormEBillOnlyLabel,
    tSignUpBillingPreferencesFormEBillOnlyDescriptor,
    tSignUpBillingPreferencesFormAutoPayDescriptor,
    tSignUpBillingPreferencesFormAutoPayLabel,
    tSignUpBillingPreferencesFormSubmitCTA,
    tSignUpBillingPreferencesFormLetsTalkRewards,
    tSignUpBillingPreferencesFormLetsSetUpBilling,
    tSignUpBillingPreferencesFormEarnRewards,
    tSignUpBillingPreferencesFormPaperlessLegalText,
    tSignUpBillingPreferencesFormConfirmPaperlessDisabled,
    tSignUpBillingPreferencesFormConfirmAutopayDisabled,
    tSignUpBillingPreferencesFormProspectUpdateError,
    tSignUpBillingPreferencesFormLabel,
  } = translate(signUpBillingPreferencesFormTranslations);

  const {
    tSignUpBillingPreferencesFormEBillPointsAndValue,
    tSignUpBillingPreferencesFormAutoPayPointsAndValue,
    tSignUpBillingPreferencesFormAutopayPaperlessRequiredSubtitle,
    tSignUpBillingPreferencesFormNoDiscountMessage,
  } = translateJsx({
    tSignUpBillingPreferencesFormAutoPayPointsAndValue: {
      dash: EN_DASH,
      points: separateNumber(AUTOPAY_REWARD_POINTS),
      pointsChunk: (content: string) => (
        <RhTypography component="span" color="secondary">
          {content}
        </RhTypography>
      ),
      value: formatCurrency(rewardPointsToDollars(AUTOPAY_REWARD_POINTS)),
    },
    tSignUpBillingPreferencesFormAutopayPaperlessRequiredSubtitle: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? "",
        2
      ),
    },
    tSignUpBillingPreferencesFormEBillPointsAndValue: {
      dash: EN_DASH,
      points: separateNumber(EBILL_REWARD_POINTS),
      pointsChunk: (content: string) => (
        <RhTypography component="span" color="secondary">
          {content}
        </RhTypography>
      ),
      value: formatCurrency(rewardPointsToDollars(EBILL_REWARD_POINTS)),
    },
    tSignUpBillingPreferencesFormNoDiscountMessage: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? "",
        2
      ),
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<SignUpBillingPreferencesFormValues>({
    defaultValues: {
      autopay: prospect.autopay !== null ? prospect.autopay : false,
      paperless: prospect.eBillOnly !== null ? prospect.eBillOnly : false,
    },
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const nextStep = getBillingPreferencesNextStep({ autopay: values.autopay });

    trackMetric(FeatureFlagMetric.PortalProspectRewardsSubmitted);

    if (values.autopay) {
      trackMetric(FeatureFlagMetric.PortalProspectEnabledAutopay);
    }

    track({
      action: ActionType.billingPreferencesSubmitted,
      autopay: values.autopay,
      category: CategoryType.BillingPreferences,
      ebill: values.paperless,
      event: EventType.enrollmentClick,
      label: "Billing Preference Information",
      paperless: values.paperless,
    });

    try {
      await prospectUpdateMutation.mutateAsync(
        {
          data: {
            autopay: values.autopay,
            eBillOnly: values.paperless,
            isPaperless: values.paperless,
          },
          uuid: prospect.uuid,
        },
        {
          onSuccess: () => {
            dispatch(
              setSignUpInfo({
                autopay: values.autopay,
                invoiceByPrint: !values.paperless,
                isPaperless: values.paperless,
                visitedSteps: visitedSteps.includes(nextStep)
                  ? visitedSteps
                  : [...visitedSteps, nextStep],
              })
            );

            track({
              action: ActionType.clickedNextPage,
              label: "billing-preferencesNextPage",
            });

            navigate(signUpStepPath(nextStep));
          },
        }
      );
    } catch (error) {
      flash.error(tSignUpBillingPreferencesFormProspectUpdateError);
    }
  });

  const [selectedAutopay, selectedPaperless] = watch(["autopay", "paperless"]);

  const showRewards = !offerSnapshot.autopayPaperlessMonthlyDiscount;

  const headerText = showRewards
    ? tSignUpBillingPreferencesFormLetsTalkRewards
    : tSignUpBillingPreferencesFormLetsSetUpBilling;

  const subHeaderText = showRewards
    ? tSignUpBillingPreferencesFormEarnRewards
    : tSignUpBillingPreferencesFormAutopayPaperlessRequiredSubtitle;

  const showDisabledWarning = offerSnapshot.autopayPaperlessMonthlyDiscount;

  return (
    <>
      <BillingPreferencesFormHeader>
        <RhTypography textAlign="center" variant="h1">
          {headerText}
        </RhTypography>
        <BillingPreferencesFormSubHeader>
          <RhTypography
            color="textSecondary"
            textAlign="center"
            variant="subtitle2"
          >
            {subHeaderText}
          </RhTypography>
        </BillingPreferencesFormSubHeader>
      </BillingPreferencesFormHeader>
      <BillingPreferencesForm
        onSubmit={handleFormSubmit}
        aria-label={tSignUpBillingPreferencesFormLabel}
      >
        <BillingPreferencesFormRow>
          <BillingPreferencesFormPortalToggle
            dataTrackingClick={{
              event: "Prospect toggling paperless",
              value: String(selectedPaperless),
            }}
            inputProps={register("paperless")}
          >
            <PaperlessIcon aria-hidden />
            <RhTypography
              variant="subtitle2"
              fontWeight="Bold"
              component="span"
            >
              {tSignUpBillingPreferencesFormEBillOnlyLabel}
            </RhTypography>
          </BillingPreferencesFormPortalToggle>

          <FinePrintContainer>
            <RhTypography variant="body2" color="textSecondary">
              {tSignUpBillingPreferencesFormEBillOnlyDescriptor}
            </RhTypography>

            {selectedPaperless ? (
              <RhTypography variant="body2" color="textSecondary">
                {tSignUpBillingPreferencesFormPaperlessLegalText}
              </RhTypography>
            ) : null}

            {showDisabledWarning && !selectedPaperless ? (
              <>
                <RhTypography variant="body2" color="error">
                  {tSignUpBillingPreferencesFormConfirmPaperlessDisabled}
                </RhTypography>

                <RhTypography variant="body2" color="error">
                  {tSignUpBillingPreferencesFormNoDiscountMessage}
                </RhTypography>
              </>
            ) : null}

            {showRewards ? (
              <RhTypography variant="body2" color="textSecondary">
                {tSignUpBillingPreferencesFormEBillPointsAndValue}
              </RhTypography>
            ) : null}
          </FinePrintContainer>
        </BillingPreferencesFormRow>

        <BillingPreferencesFormRow>
          <BillingPreferencesFormPortalToggle
            dataTrackingClick={{
              event: "Prospect toggling autopay",
              value: String(selectedAutopay),
            }}
            inputProps={register("autopay")}
          >
            <CircularMoneyIcon aria-hidden />
            <RhTypography
              variant="subtitle2"
              fontWeight="Bold"
              component="span"
            >
              {tSignUpBillingPreferencesFormAutoPayLabel}
            </RhTypography>
          </BillingPreferencesFormPortalToggle>

          <FinePrintContainer>
            <RhTypography variant="body2" color="textSecondary">
              {tSignUpBillingPreferencesFormAutoPayDescriptor}
            </RhTypography>

            {showDisabledWarning && !selectedAutopay ? (
              <>
                <RhTypography variant="body2" color="error">
                  {tSignUpBillingPreferencesFormConfirmAutopayDisabled}
                </RhTypography>

                <RhTypography variant="body2" color="error">
                  {tSignUpBillingPreferencesFormNoDiscountMessage}
                </RhTypography>
              </>
            ) : null}

            {showRewards ? (
              <RhTypography variant="body2" color="textSecondary">
                {tSignUpBillingPreferencesFormAutoPayPointsAndValue}
              </RhTypography>
            ) : null}
          </FinePrintContainer>
        </BillingPreferencesFormRow>
        <RhButton
          data-tracking-click={{
            event: "Prospect going from billing preferences to next step",
          }}
          type="submit"
          fullWidth
          disabled={isSubmitting}
        >
          {tSignUpBillingPreferencesFormSubmitCTA}
        </RhButton>
      </BillingPreferencesForm>
    </>
  );
};
