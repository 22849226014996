import { Method } from "axios";

export const ALLOY_API_UNAVAILABLE = "rhythm.prospect.alloy.api_not_available";
export const AREA_NOT_SERVICED = "pricing.offers.unavailable";
export const CHAT_UNAVAILABLE_HOLIDAY = "chat.error.holiday";
export const CUSTOMER_ENROLLMENT_ERROR = "customer.enroll";
export const CUSTOMER_NOT_FOUND_ERROR = "customer.error.not_found";
export const CUSTOMER_UPDATE_FAILED = "error.customerUpdateFailed";
export const DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR =
  "billing.delete_payment_method.autopay_enabled";
export const GENERAL_ERROR = "error.general";
export const INVALID_ADDRESS = "edi.meter.invalid_address";
export const IRONHIDE_CUSTOMER = "prospect.already_ironhide_customer";
export const METER_IN_USE = "edi.meter.esi_id";
export const METER_NOT_SERVICEABLE = "edi.meter.unserviceable";
export const MULTIPLE_METERS = "edi.meter.multiple_meters";
export const OKTA_PASSWORD_REQUIREMENTS_ERROR =
  "okta.request.passwordDoesNotMeetRequirements";
export const OKTA_REQUEST_ERROR = "okta.request.failed";
export const PERMISSION_DENIED = "rhythm.app.permission_denied";
export const PREMISE_USAGE_NOT_FOUND_ERROR = "premise.usage.error.not_found";
export const PRICING_OFFERS_MULTIPLE_UTILITIES =
  "pricing.offers.multipleUtilities";
export const PRICING_ADDRESS_INCOMPLETE = "pricing.address_incomplete";

export const PRICING_OFFERSNAPSHOTS_UNAVAILABLE =
  "pricing.offersnapshot.unavailable";
export const MTM_SIGNUP_ERROR =
  "rhythm.customer.offersnapshot_id.mtm_offer_snapshot_signup";

export const INVALID_START_DATE =
  "rhythm.customer.offersnapshot_id.offer_snapshot_service_start_date";

export const INVALID_PHONE = "rhythm.customer.phone.invalid";

export enum BillingErrors {
  ZUORA_CONNECTION_ERROR = "zuora.connection.failed",
  ZUORA_DEPOSIT_ALTERNATIVE_FAILED = "zuora.deposit_alternative.failed",
  ZUORA_DEPOSIT_ERROR = "zuora.createDepositOrder.failed",
  ZUORA_DEPOSIT_PAYMENT_ERROR = "zuora.createDepositOrderPayment.failed",
  ZUORA_PAYMENT_FAILED = "zuora.payment.failed",
  ZUORA_PAYMENT_METHOD_NOT_FOUND = "zuora.payment_method.not_found",
}

export interface RhApiErrorDetail {
  code: string;
  detail?: string;
  field?: string;
}

export interface RhApiErrorResponseData {
  errorCode: string | null;
  errors: RhApiErrorDetail[];
  stateToken?: string;
}

interface RhBaseError {
  config?: {
    baseURL?: string;
    data?: string;
    method?: Method | string;
    params?: string;
    url?: string;
  };
  data: RhApiErrorResponseData;
  status?: number;
  statusText?: string;
}

export interface RhApiError extends RhBaseError {
  kind: "API";
}

export interface RhNetworkError extends RhBaseError {
  kind: "Network";
}

export interface FormError extends Record<string, string[] | FormError> {}

export type RhError = RhApiError | RhNetworkError;
