import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { devicesEnrollSelectDevicesFormTranslations } from "@portal/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm.en.i18n";
import {
  AlreadyConnectedText,
  SelectDeviceContainer,
  SelectDeviceInfoColumn,
  SelectDeviceStatus,
} from "@portal/components/DevicesEnrollSelectDevicesForm/SelectDevice.styled";
import { useDeviceStatus } from "@portal/hooks/useDeviceStatus";
import { useTranslations } from "@portal/hooks/useTranslations";
import { DeviceType } from "@portal/types/devicesTypes";
import React from "react";

interface AlreadyConnectedDeviceProps {
  device: DeviceType;
}

export const AlreadyConnectedDevice = (props: AlreadyConnectedDeviceProps) => {
  const { device } = props;
  const { deviceBrand, deviceModel, deviceName, deviceStatus } = device;
  const deviceStatusText = useDeviceStatus();
  const { translate } = useTranslations();

  const {
    tDevicesEnrollSelectDevicesFormAlreadyConnected,
    tDevicesEnrollSelectDevicesFormDeviceName,
    tDevicesEnrollSelectDevicesFormDeviceStatus,
    tDevicesEnrollSelectDevicesFormDeviceType,
    tDevicesEnrollSelectDevicesFormModelNumber,
  } = translate(devicesEnrollSelectDevicesFormTranslations);

  return (
    <SelectDeviceContainer $isAlreadyConnected>
      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceName}
          </RhTypography>
          {deviceName}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceType}
          </RhTypography>
          {deviceBrand}
        </div>
      </SelectDeviceInfoColumn>
      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormModelNumber}
          </RhTypography>
          {deviceModel}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceStatus}
          </RhTypography>
          <SelectDeviceStatus $status={deviceStatus}>
            {deviceStatusText[deviceStatus]}
          </SelectDeviceStatus>
        </div>
      </SelectDeviceInfoColumn>
      <div>
        <AlreadyConnectedText>
          {tDevicesEnrollSelectDevicesFormAlreadyConnected}
        </AlreadyConnectedText>
      </div>
    </SelectDeviceContainer>
  );
};
