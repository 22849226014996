import {
  AREA_NOT_SERVICED,
  MULTIPLE_METERS,
  PRICING_ADDRESS_INCOMPLETE,
  PRICING_OFFERSNAPSHOTS_UNAVAILABLE,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
} from "@common/types/errorTypes";

export type AvailabilityErrorPaths = Record<
  string,
  "updates" | "choose-utility" | "plans" | "availability"
>;
export const serviceUnavailableErrorPaths: AvailabilityErrorPaths = {
  [AREA_NOT_SERVICED]: "updates",
  [PRICING_OFFERSNAPSHOTS_UNAVAILABLE]: "updates",
};

export const availabilityErrorPaths: AvailabilityErrorPaths = {
  ...serviceUnavailableErrorPaths,
  [MULTIPLE_METERS]: "plans",
  [PRICING_ADDRESS_INCOMPLETE]: "availability",
  [PRICING_OFFERS_MULTIPLE_UTILITIES]: "choose-utility",
};
