import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { UsageType } from "@common/types/usageTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseKwhUsageDayOptions {
  enabled: boolean;
  premiseId: IdType;
  targetDate: string;
}

export const usePremiseKwhUsageDay = (
  options: UsePremiseKwhUsageDayOptions
) => {
  const { premiseId, enabled, targetDate } = options;

  const query = useQuery<UsageType[], RhApiError>({
    enabled,
    queryFn: () => premiseApi.kwhUsageDay(premiseId, targetDate),
    queryKey: PremiseQueryKeys.kwhUsageDay(premiseId, targetDate),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};
