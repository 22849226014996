import { AuthClient } from "@common/services/AuthClient.service";
import { Security } from "@okta/okta-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

export interface RhSecurityProps {
  authClient: AuthClient;
  onAuthRequiredRedirectToPath: string;
}

/**
 * This component is a wrapper for the Okta Security component.
 * Only supports apps running on React Router v6.
 */
export function RhSecurity({
  authClient,
  onAuthRequiredRedirectToPath,
  children,
}: PropsWithChildren<RhSecurityProps>) {
  const navigate = useNavigate();
  const restoreOriginalUri = authClient.getRestoreOriginalUri(navigate);

  return (
    <Security
      oktaAuth={authClient.authClient}
      onAuthRequired={
        onAuthRequiredRedirectToPath
          ? () => navigate(onAuthRequiredRedirectToPath)
          : undefined
      }
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
}
