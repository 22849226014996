import { IdType } from "@common/types/apiTypes";

export enum CampaignTypeEnum {
  Acquisition = "ACQUISITION",
  Renewal = "RENEWAL",
  Swap = "SWAP",
}

export interface CampaignPromoType {
  code: string;
  expirationDate?: string;
  id: IdType;
  oneTimeValue: string;
  recurringValue: string;
}

export type CampaignPromoDataType = Omit<CampaignPromoType, "id">;

export const campaignPromosTableColumns: (keyof CampaignPromoType)[] = [
  "id",
  "code",
  "oneTimeValue",
  "recurringValue",
  "expirationDate",
];

export interface AcquisitionCampaignType {
  active: boolean;
  createdAt: string;
  id: string;
  name: string;
  phone: string;
  pricingCampaignSlug?: string;
  salesChannel: string;
  slug: string;
}

export interface AcquisitionCampaignCreatePostDataType
  extends Pick<
    AcquisitionCampaignType,
    "slug" | "name" | "phone" | "pricingCampaignSlug" | "active"
  > {
  medium: string;
}

export type AcquisitionCampaignUpdatePatchDataType =
  AcquisitionCampaignCreatePostDataType;
