import {
  CompetitorPlansParams,
  competitorApi,
} from "@portal/api/competitorApi";
import { CompetitorQueryKeys } from "@portal/constants/querykeys.constants";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const useCompetitorPlans = ({
  limit,
}: Pick<CompetitorPlansParams, "limit"> = {}) => {
  const { dunsNumber } = useSelector(selectSignUpState);

  return useQuery({
    queryFn: () => competitorApi.plans({ dunsNumber, limit }),
    queryKey: CompetitorQueryKeys.plans(),
  });
};
