import { useNotificationFetchOptions } from "@common/hooks/useNotificationFetchOptions";
import { noop } from "@common/utils/genericFunctions";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { NotificationOptionRow } from "@portal/components/NotificationOptionSwitches/NotificationOptionRow";
import {
  StyledCommMediumContainer,
  StyledCommMediumHeader,
} from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches.styled";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";
import { Form } from "react-final-form";

export const NotificationOptionSwitches = () => {
  const flash = useRhFlash();
  const { t } = useRhIntl();

  const customer = useCustomerFromContext();
  const { notificationOptions, unsubscribedChannelIds, requestMonitor } =
    useNotificationFetchOptions(customer.id);

  if (requestMonitor.isWaiting) {
    return <RhCircularProgress />;
  }

  if (
    requestMonitor.didFail ||
    !notificationOptions ||
    !unsubscribedChannelIds
  ) {
    flash.error(t("NotificationOptionSwitches.apiErrorGettingNotifications"));

    return null;
  }

  const initialValues = notificationOptions.reduce(
    (result, option) => {
      const { messageId } = option;

      // eslint-disable-next-line no-param-reassign
      result[`messageId${messageId}`] =
        unsubscribedChannelIds.indexOf(messageId) === -1;
      return result;
    },
    {} as { [key: string]: boolean }
  );

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={() =>
        ["email", "sms"].map((commMedium) => (
          <StyledCommMediumContainer key={commMedium}>
            <StyledCommMediumHeader>
              <RhTypography variant="h3" fontWeight={FontWeight.Semibold}>
                {t(
                  `NotificationOptionSwitches.${commMedium}` as TranslationKey
                )}
              </RhTypography>
            </StyledCommMediumHeader>
            {notificationOptions.map(
              (option) =>
                option.commMedium === commMedium && (
                  <NotificationOptionRow
                    key={option.messageId}
                    option={option}
                    customerId={customer.id}
                  />
                )
            )}
          </StyledCommMediumContainer>
        ))
      }
    />
  );
};
