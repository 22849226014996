import { darkPurple, trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Fab } from "@mui/material";
import styled from "styled-components";

export const StyledRhDialogCloseButton = styled(Fab)`
  &.MuiButtonBase-root {
    background: ${trueWhite};
    border: 1px solid ${darkPurple[300]};

    box-shadow: none;
    color: ${darkPurple[500]};
    height: 40px;
    position: absolute;
    right: ${rhSpacingPx(3)};
    top: ${rhSpacingPx(3)};
    width: 40px;

    &:hover {
      background: ${darkPurple[50]};
    }

    @media (max-width: ${RhythmBreakpoints.XS}px) {
      position: fixed;
    }
  }

  .MuiSvgIcon-root {
    font-size: 1.71rem;
  }
`;
