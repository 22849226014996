import { useCookie } from "@common/hooks/useCookie";
import { UUIDType } from "@common/types/apiTypes";
import { CookieName, buildEnvCookieKey } from "@common/utils/getCookies";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useProspectUuid = () => {
  const { prospectUuid: prospectUuidPathParam } = useParams<{
    prospectUuid: UUIDType | undefined;
  }>();

  const [prospectUuidCookie, setProspectUuidCookie] = useCookie(
    buildEnvCookieKey(CookieName.ProspectUuid),
    ""
  );

  const prospectUuidState = useState<UUIDType | undefined>(
    prospectUuidPathParam || prospectUuidCookie || undefined
  );

  const [finalProspectUuid] = prospectUuidState;

  useEffect(() => {
    if (finalProspectUuid) {
      setProspectUuidCookie(finalProspectUuid, 30);
    }
  }, [finalProspectUuid, setProspectUuidCookie]);

  return prospectUuidState;
};
