import { formatKwh } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { RhSlider } from "@design-system/components/RhSlider/RhSlider";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  SliderOnChangeCommittedFunction,
  SliderOnChangeFunction,
} from "@design-system/types/domEventTypes";
import { Tooltip } from "@mui/material";
import {
  StyledLabelText,
  StyledPopper,
} from "@portal/components/MonthlyUsageSlider/MonthlyUsageSliderInput.styled";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { PropsWithChildren, ReactElement } from "react";

interface SliderLabelProps {
  open: boolean;
  value: number;
}
const SliderLabel = ({
  open,
  value,
  children,
}: PropsWithChildren<SliderLabelProps>) => {
  const { t } = useRhIntl();
  const theAverageHomeUses = t("MonthlyUsageSliderInput.theAverageHomeUses");

  if (!value) {
    return null;
  }

  const label = (
    <>
      {formatKwh(value, 0)}
      {value === 1000 && (
        <StyledLabelText
          component="div"
          variant="caption"
          fontWeight={FontWeight.Regular}
        >
          {theAverageHomeUses}
        </StyledLabelText>
      )}
    </>
  );

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom-start"
      title={label}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              enabled: false,
              name: "flip",
            },
          ],
        },
      }}
      components={{
        Popper: StyledPopper,
      }}
    >
      {children as ReactElement}
    </Tooltip>
  );
};

const RANGE_START = 500;
const RANGE_END = 3500;
const RANGE_STEP = 500;

interface MonthlyUsageSliderInputProps {
  initialValue: number;
  onChange?: SliderOnChangeFunction;
  onChangeCommitted?: SliderOnChangeCommittedFunction;
}
export const MonthlyUsageSliderInput = ({
  onChange = noop,
  onChangeCommitted,
  initialValue,
}: MonthlyUsageSliderInputProps) => {
  const { t } = useRhIntl();

  return (
    <RhSlider
      aria-label={t("MonthlyUsageSliderInput.sliderLabel")}
      defaultValue={initialValue}
      min={RANGE_START}
      max={RANGE_END}
      step={RANGE_STEP}
      marks
      valueLabelDisplay="on"
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      components={{
        ValueLabel: SliderLabel,
      }}
    />
  );
};
