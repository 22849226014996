import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { portalApi } from "@portal/api/api";
import { AcquisitionCampaignKeys } from "@portal/constants/querykeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

type UseAcquisitionCampaignQueryProps = {
  enabled: boolean;
  slug: string | null;
};

export const useAcquisitionCampaignQuery = ({
  slug,
  enabled,
}: UseAcquisitionCampaignQueryProps) => {
  return useQuery({
    enabled,
    queryFn: () => portalApi.acquisitionCampaign.retrieve(slug || ""),
    queryKey: AcquisitionCampaignKeys.retrieve(slug || ""),
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => ({
      ...data,
      phone: formatPhoneNumber(data.phone),
    }),
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });
};
