import { portalApi } from "@portal/api/api";
import { useMutation } from "@tanstack/react-query";

export const useQuickPayCreatePayment = () => {
  const mutation = useMutation({
    mutationFn: portalApi.quickPay.createPayment,
  });

  return mutation;
};
