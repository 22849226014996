import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as CalculatorIcon } from "@design-system/icons/CalculatorIcon.svg";
import { ReactComponent as Scale } from "@design-system/icons/Scale.svg";
import { OfferCardElementProps } from "@portal/components/OfferCard/OfferCard.interfaces";
import {
  StyledButtonGroup,
  StyledCardActions,
  StyledCardCallout,
  StyledCardHeader,
  StyledCompareButton,
  StyledRateCalculateText,
  StyledRateInfo,
  StyledRateInfoCalculateButton,
  StyledSeparator,
} from "@portal/components/OfferCard/OfferCard.styled";
import { OfferRateAndEstimate } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate";
import React from "react";

export const OfferCardMobile = ({
  offerSnapshot,
  calloutBackgroundColor,
  calloutText,
  hideCostEstimatorButton,
  handleCalculateBillClick,
  calculateCtaMessage,
  compareButtonProps,
  moreButtonProps,
  selectButtonProps,
}: OfferCardElementProps) => {
  return (
    <>
      {Boolean(calloutText) && (
        <StyledCardCallout $calloutBackgroundColor={calloutBackgroundColor}>
          <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
            {calloutText}
          </RhTypography>
        </StyledCardCallout>
      )}

      <StyledCardHeader>
        <RhTypography variant="h3">{offerSnapshot.title}</RhTypography>
      </StyledCardHeader>

      <RhFlexBox gap={1} justifyContent="space-between" py={2}>
        <StyledRateInfo>
          <OfferRateAndEstimate offerSnapshot={offerSnapshot} />
        </StyledRateInfo>
        {(compareButtonProps.showCompareButton || !hideCostEstimatorButton) && (
          <StyledButtonGroup>
            {!hideCostEstimatorButton && (
              <>
                <StyledRateInfoCalculateButton
                  type="button"
                  onClick={handleCalculateBillClick}
                  data-testid="OfferCard__CalculateBillButton"
                >
                  <CalculatorIcon />
                  <StyledRateCalculateText variant="body3" component="span">
                    {calculateCtaMessage}
                  </StyledRateCalculateText>
                </StyledRateInfoCalculateButton>
                <StyledSeparator />
              </>
            )}

            {compareButtonProps.showCompareButton && (
              <StyledCompareButton
                type="button"
                onClick={compareButtonProps.onClick}
                aria-label={compareButtonProps.text}
              >
                <Scale /> {compareButtonProps.text}
              </StyledCompareButton>
            )}
          </StyledButtonGroup>
        )}
      </RhFlexBox>

      <RhDivider light />

      <StyledCardActions data-testid="OfferCard__cardActions">
        <RhFlexBox
          gap="10px"
          flexDirection="row"
          data-testid="OfferCard__buttonGroup"
        >
          <RhButton
            data-tracking-click={{
              event: "Prospect opening learn more modal",
            }}
            color="primary"
            size="small"
            variant="outlined"
            onClick={moreButtonProps.onClick}
            id={`offerSnapshot-learnMore-${offerSnapshot.id}`}
            style={{
              flex: 1,
              order: 0,
            }}
          >
            {moreButtonProps.text}
          </RhButton>
          <RhButton
            data-tracking-click={{
              event: "Prospect selecting a plan",
            }}
            color="primary"
            size="small"
            onClick={selectButtonProps.onClick}
            id={`offerSnapshot-selectButton offerSnapshot-selectButton-${offerSnapshot.id}`}
            data-testid={`OfferCard__SelectOfferButton-${offerSnapshot.id}`}
            style={{
              flex: 1,
              order: 1,
            }}
          >
            {selectButtonProps.text}
          </RhButton>
        </RhFlexBox>
      </StyledCardActions>
    </>
  );
};
