import { ZuoraIdType } from "@common/types/apiTypes";

export interface InvoiceType {
  accountId: ZuoraIdType;
  amount: number;
  balance: number;
  dueDate: string;
  id: ZuoraIdType;
  invoiceDate: string;
  invoiceNumber: string;
  invoiceType: InvoiceTypeEnum;
  lateFeeApplied: boolean | undefined;
  rewardCreditApplied: boolean;
  serviceEndDate: string | null;
  serviceStartDate: string | null;
  totalKwh: string | null;
}

export enum InvoiceTypeEnum {
  CANCEL_REBILL = "CANCEL_REBILL",
  DEPOSIT = "DEPOSIT",
  ENERGY = "ENERGY",
}
