import { deviceFAQsCardTranslations } from "@portal/components/DeviceFAQsCard/DeviceFAQsCard.en.i18n";
import { useDeviceEligibilityInformationQuery } from "@portal/hooks/queries/useDeviceEligibilityInformation";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { ReactNode } from "react";

interface DeviceFAQType {
  faqBody: ReactNode[];
  faqTitle: ReactNode;
}

export const useDeviceFaqs = (): DeviceFAQType[] => {
  const { premise } = usePremise();
  const customer = useCustomerFromContext();

  const { data: deviceEligibilityInformationData } =
    useDeviceEligibilityInformationQuery({
      customerId: customer.id,
      queryOptions: {
        enabled: Boolean(customer?.id),
      },
    });

  const { translate, translateJsx } = useTranslations();
  const {
    tDeviceFAQsAdjustFaqBody1,
    tDeviceFAQsAdjustFaqTitle,
    tDeviceFAQsCompatibilityFaqBody1,
    tDeviceFAQsCompatibilityFaqTitle,
    tDeviceFAQsCreditsFaqBody1,
    tDeviceFAQsCreditsFaqTitle,
    tDeviceFAQsHowToConnectDevicesTitle,
    tDeviceFAQsMaxControlTimesFaqsBody1,
    tDeviceFAQsMaxControlTimesFaqsTitle,
    tDeviceFAQsMonthlyBillCreditFaqBody1,
    tDeviceFAQsMonthlyBillCreditFaqTitle,
    tDeviceFAQsNotificationsFaqBody1,
    tDeviceFAQsNotificationsFaqTitle,
    tDeviceFAQsParticipationRequirementsFaqBody1,
    tDeviceFAQsProgramFaqBody1,
    tDeviceFAQsProgramFaqBody2,
    tDeviceFAQsProgramFaqBody3,
    tDeviceFAQsProgramFaqTitle,
  } = translate(deviceFAQsCardTranslations);

  const {
    tDeviceFAQsProgramFaqBody4,
    tDeviceFAQsHowToConnectDevicesBody1,
    tDeviceFAQsParticipationRequirementsFaqTitle,
    tDeviceFAQsParticipationRequirementsFaqBody2,
    tDeviceFAQsParticipationRequirementsFaqBody3,
  } = translateJsx({
    tDeviceFAQsHowToConnectDevicesBody1: {
      link: (message: ReactNode[]) => (
        <a
          href="https://www.gotrhythm.com/blog/energy-saving-tips/how-to-connect-your-smart-thermostat"
          rel="noreferrer"
          target="_blank"
        >
          {message}
        </a>
      ),
    },
    tDeviceFAQsParticipationRequirementsFaqBody2: {
      hours: deviceEligibilityInformationData?.maxThermostatOfflinePeriodHours,
    },
    tDeviceFAQsParticipationRequirementsFaqBody3: {
      events:
        deviceEligibilityInformationData?.maxThermostatOptOutEventsAllowed,
    },
    tDeviceFAQsParticipationRequirementsFaqTitle: {
      credit: premise?.formattedThermostatManagementMonthlyDiscount ?? "",
    },
    tDeviceFAQsProgramFaqBody4: {
      events:
        deviceEligibilityInformationData?.maxThermostatOptOutEventsAllowed,
      hours: deviceEligibilityInformationData?.maxThermostatOfflinePeriodHours,
    },
  });

  return [
    {
      faqBody: [tDeviceFAQsHowToConnectDevicesBody1],
      faqTitle: tDeviceFAQsHowToConnectDevicesTitle,
    },
    {
      faqBody: [
        tDeviceFAQsParticipationRequirementsFaqBody1,
        tDeviceFAQsParticipationRequirementsFaqBody2,
        tDeviceFAQsParticipationRequirementsFaqBody3,
      ],
      faqTitle: tDeviceFAQsParticipationRequirementsFaqTitle,
    },
    {
      faqBody: [
        tDeviceFAQsProgramFaqBody1,
        tDeviceFAQsProgramFaqBody2,
        tDeviceFAQsProgramFaqBody3,
        tDeviceFAQsProgramFaqBody4,
      ],
      faqTitle: tDeviceFAQsProgramFaqTitle,
    },
    {
      faqBody: [tDeviceFAQsAdjustFaqBody1],
      faqTitle: tDeviceFAQsAdjustFaqTitle,
    },
    {
      faqBody: [tDeviceFAQsCompatibilityFaqBody1],
      faqTitle: tDeviceFAQsCompatibilityFaqTitle,
    },
    {
      faqBody: [tDeviceFAQsCreditsFaqBody1],
      faqTitle: tDeviceFAQsCreditsFaqTitle,
    },
    {
      faqBody: [tDeviceFAQsMonthlyBillCreditFaqBody1],
      faqTitle: tDeviceFAQsMonthlyBillCreditFaqTitle,
    },
    {
      faqBody: [tDeviceFAQsMaxControlTimesFaqsBody1],
      faqTitle: tDeviceFAQsMaxControlTimesFaqsTitle,
    },
    {
      faqBody: [tDeviceFAQsNotificationsFaqBody1],
      faqTitle: tDeviceFAQsNotificationsFaqTitle,
    },
  ];
};
