import { darkPurple } from "@design-system/theme/palette.colors";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import React, { ComponentPropsWithoutRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

const StyledMuiLink = styled(MuiLink)`
  &.MuiLink-root {
    color: ${darkPurple.main};
    text-decoration: none;
  }
`;

type LinkProps = ComponentPropsWithoutRef<typeof ReactRouterLink>;

export type DynamicLinkProps<C extends React.ElementType = "a"> = {
  component?: C;
  external: boolean;
  target?: string;
  to: (LinkProps["to"] & { state?: LinkProps["state"] }) | string;
} & Omit<LinkProps, "to"> &
  MuiLinkProps<C>;

export const DynamicLink = <C extends React.ElementType = "a">({
  to,
  external,
  children,
  target = "_blank",
  component,
  ...rest
}: DynamicLinkProps<C>) =>
  external && typeof to === "string" ? (
    <StyledMuiLink
      href={to}
      component={component}
      target={target}
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </StyledMuiLink>
  ) : (
    <StyledMuiLink
      to={to}
      state={typeof to !== "string" ? to.state : undefined}
      {...rest}
      component={ReactRouterLink}
    >
      {children}
    </StyledMuiLink>
  );
