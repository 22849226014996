import { LanguagePreferenceType } from "@common/types/customerTypes";
import { RhRadioCardInput } from "@design-system/components/RhRadioCardInput/RhRadioCardInput";
import React from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";

interface LanguagePreferenceRadioFieldProps {
  translationId: string;
  value: LanguagePreferenceType;
}
export const LanguagePreferenceRadioField = ({
  translationId,
  value,
}: LanguagePreferenceRadioFieldProps) => {
  const { input } = useField<LanguagePreferenceType>("languagePreference", {
    type: "radio",
    value,
  });
  const label = useIntl().formatMessage({ id: translationId });
  const inputId = `translation-radio-${value}`;

  return <RhRadioCardInput inputId={inputId} label={label} {...input} />;
};
