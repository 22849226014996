import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useRepPriceComparisonChart } from "@portal/context/repPriceComparisonChart.context";
import { useEffect, useState } from "react";

interface UseRepPriceComparisonChartFilterParams {
  offerSnapshots: OfferSnapshot[] | null;
}

export const useRepPriceComparisonOfferSnapshots = ({
  offerSnapshots = [],
}: UseRepPriceComparisonChartFilterParams) => {
  const {
    setComparisonChartOfferSnapshot,
    comparisonChartTermMonths,
    comparisonChartOfferSnapshot,
  } = useRepPriceComparisonChart();
  const [offerSnapshotsByTermMonths, setOfferSnapshotsByTermMonths] = useState<
    OfferSnapshot[]
  >([]);

  useEffect(() => {
    if (offerSnapshots && comparisonChartTermMonths) {
      const filteredOfferSnapshots = offerSnapshots
        ?.filter((offerSnapshot) => !offerSnapshot.solarEligible)
        ?.filter(
          (offerSnapshot) =>
            comparisonChartTermMonths === offerSnapshot.termMonths
        )
        .sort((a, b) => Number(a.price) - Number(b.price));

      if (filteredOfferSnapshots.length) {
        setOfferSnapshotsByTermMonths(filteredOfferSnapshots);

        // Check if our filtered offersnapshots contain the current comparison offer snapshot
        const hasSelectedOfferSnapshotId = comparisonChartOfferSnapshot
          ? filteredOfferSnapshots.find(
              (offerSnapshot) =>
                offerSnapshot.id === comparisonChartOfferSnapshot.id
            )
          : undefined;

        // Default to the first offerSnapshot for the termMonth selection onChange so the chart always has a Rhythm plan
        if (!hasSelectedOfferSnapshotId) {
          setComparisonChartOfferSnapshot(filteredOfferSnapshots[0]);
        }
      }
    }
  }, [
    offerSnapshots,
    comparisonChartTermMonths,
    comparisonChartOfferSnapshot,
    setComparisonChartOfferSnapshot,
  ]);

  return offerSnapshotsByTermMonths;
};
