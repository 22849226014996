import { RhDayPicker } from "@design-system/components/RhDayPicker/RhDayPicker";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { useState } from "react";

interface PickYourOwnDueDateDialogProps {
  onClose: () => void;
  onSubmit: (day: number | null) => void;
}

export const PickYourOwnDueDateDialog = ({
  onClose,
  onSubmit,
}: PickYourOwnDueDateDialogProps) => {
  const { t } = useRhIntl();
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  const titleText = t("PickYourOwnDueDateDialog.title");
  const subTitleText = t("PickYourOwnDueDateDialog.subTitle");
  const saveText = t("PickYourOwnDueDateDialog.primaryAction");
  const cancelText = t("PickYourOwnDueDateDialog.secondaryAction");

  return (
    <RhDialog open onClose={onClose} data-testid="PickYourOwnDueDate__title">
      <RhDialogTitle>
        {titleText}
        <RhTypography color="textSecondary" variant="body2">
          {subTitleText}
        </RhTypography>
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          "data-tracking-click": {
            event:
              "Customer changing invoice due date via Pick Your Own Due Date modal",
          },
          isDisabled: selectedDay === null,
          label: saveText,
          onClick: () => {
            onSubmit(selectedDay);
          },
        }}
        secondaryButton={{
          "data-tracking-click": {
            event: "Customer canceling selecting own due date",
          },
          label: cancelText,
          onClick: onClose,
        }}
      >
        <RhDayPicker onDaySelected={setSelectedDay} />
      </RhDialogContentWithButtons>
    </RhDialog>
  );
};
