import { boxShadows } from "@design-system/constants/boxShadows";
import { grey, trueWhite } from "@design-system/theme/palette.colors";
import {
  OFFER_CONTAINER_WIDTH_3CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledRhythmRTBsContainer = styled.div`
  align-items: center;
  background-color: ${trueWhite};
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  max-width: ${OFFER_CONTAINER_WIDTH_3CARD}px;
  overflow: hidden;
  padding: 24px;
  width: 100%;
  z-index: 2;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const StyledRtb = styled.div`
  &::after {
    border: 1px solid ${grey[50]};
    content: "";
    display: block;
    height: auto;
    margin: 0 0 1rem 0;
    transform: none;
    width: 35%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      height: 80px;
      margin: 0;
      transform: translateX(4px);
      width: auto;
    }
  }

  &:last-child::after {
    display: none;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-evenly;
  padding: 0 0.5rem;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const LottieContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
