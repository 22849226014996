import { CampaignSlugs } from "@common/constants/campaigns.constant";
import {
  AREA_NOT_SERVICED,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
} from "@common/types/errorTypes";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { RhythmRTBs } from "@portal/components/RhythmRTBs/RhythmRTBs";
import { UpdateZipCode } from "@portal/components/UpdateZipCode/UpdateZipCode";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { useProspectUpdateMutation } from "@portal/hooks/mutations/useProspectUpdate.mutation";
import { useOfferSnapshotsQuery } from "@portal/hooks/queries/useOfferSnapshots.query";
import {
  PageContainer,
  ReasonsToBelieveContainer,
  ZipCodeAndTitleHeader,
} from "@portal/pages/SignUpPlansPage/SignUpPlansPage.styled";
import { SignUpPlansPageAvailability } from "@portal/pages/SignUpPlansPage/SignUpPlansPageAvailability/SignUpPlansPageAvailability";
import { SignUpPlansPageBackground } from "@portal/pages/SignUpPlansPage/SignUpPlansPageBackground/SignUpPlansPageBackground";
import { SignUpPlansPageBillCostEstimator } from "@portal/pages/SignUpPlansPage/SignUpPlansPageBillCostEstimator/SignUpPlansPageBillCostEstimator";
import { SignUpPlansPageHeader } from "@portal/pages/SignUpPlansPage/SignUpPlansPageHeader/SignUpPlansPageHeader";
import { SignUpPlansPageOfferSnapshotCards } from "@portal/pages/SignUpPlansPage/SignUpPlansPageOfferSnapshotCards/SignUpPlansPageOfferSnapshotCards";
import { SignUpPlansPageRepPriceComparisonChart } from "@portal/pages/SignUpPlansPage/SignUpPlansPageRepPriceComparisonChart/SignUpPlansPageRepPriceComparisonChart";
import React, { useEffect, useId, useState } from "react";

const campaignSlugsToShowFilters: CampaignSlugs[] = [CampaignSlugs.Default];

export const SignUpPlansPage = () => {
  const prospect = useProspectFromContext();
  const { addModal } = useModals();

  const prospectUpdateMutation = useProspectUpdateMutation();

  const { zipCode, dunsNumber } = prospect;
  const campaignSlug = prospect.rcid ?? CampaignSlugs.Default;
  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState<number>(
    DEFAULT_AVERAGE_MONTHLY_USAGE
  );

  const offerSnapshotsQuery = useOfferSnapshotsQuery({
    queryOptions: {
      enabled: Boolean(dunsNumber) || Boolean(zipCode),
      retry: false,
    },
    searchOptions: {
      campaignSlug,
      dunsNumber: dunsNumber ?? undefined,
      zipCode: dunsNumber ? "" : zipCode,
    },
  });

  useEffect(() => {
    if (!offerSnapshotsQuery.error) {
      return;
    }

    const { error } = offerSnapshotsQuery;

    if (error.data.errorCode === PRICING_OFFERS_MULTIPLE_UTILITIES) {
      addModal({
        multipleTdsp: {
          prospectUUID: prospect.uuid,
          zipCode,
        },
      });
    }

    if (error.data.errorCode === AREA_NOT_SERVICED) {
      addModal({
        areaNotServiceable: true,
      });
    }
  }, [offerSnapshotsQuery.error, addModal, zipCode, prospect.uuid]);

  const handleZipCodeUpdate = (newZipCode: string) => {
    prospectUpdateMutation.mutate({
      data: {
        dunsNumber: "",
        zipCode: newZipCode,
      },
      uuid: prospect.uuid,
    });
  };

  const noOfferSnapshots =
    !offerSnapshotsQuery.isFetching && !offerSnapshotsQuery.data?.count;

  const showCompetitorPlanComparison =
    campaignSlugsToShowFilters.includes(campaignSlug as CampaignSlugs) &&
    !noOfferSnapshots;

  const offerSnapshots = offerSnapshotsQuery.data?.results || [];

  const showBillCostEstimator = !offerSnapshots.some(
    (offerSnapshot) => offerSnapshot.solarEligible
  );

  const billCostEstimatorId = useId();
  const competitorComparisonId = useId();

  if (!zipCode) {
    return (
      <SignUpPlansPageAvailability>
        <UpdateZipCode
          onZipCodeSubmit={handleZipCodeUpdate}
          zipCode={zipCode}
        />
      </SignUpPlansPageAvailability>
    );
  }

  return (
    <PageContainer $noPadding>
      <ZipCodeAndTitleHeader>
        <UpdateZipCode
          onZipCodeSubmit={handleZipCodeUpdate}
          zipCode={zipCode}
        />
        <SignUpPlansPageHeader />
      </ZipCodeAndTitleHeader>

      <SignUpPlansPageOfferSnapshotCards
        offerSnapshots={offerSnapshots}
        isPending={offerSnapshotsQuery.isPending}
        estimatedMonthlyUsage={estimatedMonthlyUsage}
        billCalculatorId={billCostEstimatorId}
        competitorComparisonId={competitorComparisonId}
        showBillCostEstimator={showBillCostEstimator}
        showCompetitorComparison={showCompetitorPlanComparison}
      />

      <ReasonsToBelieveContainer>
        <RhythmRTBs />
      </ReasonsToBelieveContainer>

      {showCompetitorPlanComparison ? (
        <SignUpPlansPageRepPriceComparisonChart
          estimatedMonthlyUsage={estimatedMonthlyUsage}
          offerSnapshots={offerSnapshots}
          id={competitorComparisonId}
        />
      ) : null}
      {showBillCostEstimator ? (
        <SignUpPlansPageBillCostEstimator
          setEstimatedMonthlyUsage={setEstimatedMonthlyUsage}
          estimatedMonthlyUsage={estimatedMonthlyUsage}
          offerSnapshots={offerSnapshots || []}
          id={billCostEstimatorId}
        />
      ) : null}
      <SignUpPlansPageBackground />
    </PageContainer>
  );
};
