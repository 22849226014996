import { CompetitorNames, CompetitorPlan } from "@portal/types/competitorTypes";

/* Map competitor plans by termMonths and competitor
 *
 * Usage: competitorPlansByTermMonths.get(termMonth)?.get(CompetitorName) => CompetitorPlans[]
 */

type CompetitorPlanTermMonthMap = Map<
  number,
  Map<CompetitorNames, CompetitorPlan[]>
>;

const competitorPlansMap = (plans: CompetitorPlan[], termMonthsKey: number) =>
  new Map(
    plans
      .filter((plan) => plan.termMonths === termMonthsKey)
      .map((plan) => [
        plan.competitorName,
        plans.filter(
          (planB) =>
            planB.termMonths === plan.termMonths &&
            planB.competitorName === plan.competitorName
        ),
      ])
  );

export const competitorPlansByTermMonths = (
  plans: CompetitorPlan[]
): CompetitorPlanTermMonthMap => {
  if (!plans) {
    return new Map();
  }

  return new Map(
    plans.map((plan) => [
      plan.termMonths,
      competitorPlansMap(plans, plan.termMonths),
    ])
  );
};

/* Map competitor average price by termMonths
 *
 * Usage: competitorPlanAveragePricePerTermMonth.get(CompetitorName)?.get(6) => string
 */

type CompetitorPlanAveragePricePerTermMonthMap = Map<
  CompetitorNames,
  Map<number, string | null>
>;

const averageCompetitorPlanPrice = (plans: CompetitorPlan[]): string | null => {
  if (plans.length === 0) {
    return null;
  }

  const plansPriceSum = plans.reduce(
    (sum, { price2000Kwh }) => sum + Number(price2000Kwh),
    0
  );

  return (plansPriceSum / plans.length).toFixed(4);
};

const averagePlanPriceByTermMap = (
  plans: CompetitorPlan[],
  competitorKey: CompetitorNames
) =>
  new Map(
    plans
      .filter((plan) => plan.competitorName === competitorKey)
      .map((plan) => [
        plan.termMonths,
        averageCompetitorPlanPrice(
          plans.filter(
            (planB) =>
              planB.competitorName === plan.competitorName &&
              planB.termMonths === plan.termMonths
          )
        ),
      ])
  );

export const competitorPlanAveragePricePerTermMonth = (
  plans: CompetitorPlan[]
): CompetitorPlanAveragePricePerTermMonthMap => {
  if (!plans) {
    return new Map();
  }

  return new Map(
    plans.map((plan) => [
      plan.competitorName,
      averagePlanPriceByTermMap(plans, plan.competitorName),
    ])
  );
};

export const competitorPlansByOfferSnapshotTermMonths = (
  plans: CompetitorPlan[],
  offerTermMonths: number
) => plans.filter((plan) => plan.termMonths === offerTermMonths);
