import React from "react";

interface TrackingViewProps {
  data: {
    [key: string]: string | boolean | number;
    event: string;
  };
}

export const TrackingView = (props: TrackingViewProps) => {
  const { data } = props;

  return (
    <div
      style={{
        height: "1px",
        position: "absolute",
        width: "1px",
      }}
      data-tracking-view={JSON.stringify(data)}
    />
  );
};
