import { billComparisonTableTranslations } from "@portal/components/BillComparisonTable/BillComparisonTable.en.i18n";
import { StyledBillComparisonTable } from "@portal/components/BillComparisonTable/BillComparisonTable.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  BillComparisonType,
  ComparisonPeriodType,
} from "@portal/types/billBreakdownTypes";
import React, { useMemo } from "react";

interface BillComparisonTableProps {
  comparisonData: BillComparisonType;
}

export const BillComparisonTable = (props: BillComparisonTableProps) => {
  const { comparisonData } = props;

  const { translate } = useTranslations();
  const {
    tBillComparisonTableAverageTemperature,
    tBillComparisonTableConsumed,
    tBillComparisonTableDaysInCycle,
  } = translate(billComparisonTableTranslations);

  const tableData: (ComparisonPeriodType | null)[] = useMemo(() => {
    const data = [];

    if (comparisonData.previousYearPeriod) {
      data.push(comparisonData.previousYearPeriod);
    }

    if (comparisonData.previousPeriod) {
      data.push(comparisonData.previousPeriod);
    } else {
      data.push(null);
    }

    data.push(comparisonData.currentPeriod);

    return data;
  }, [comparisonData]);

  const getCellKey = (d: ComparisonPeriodType | null) =>
    d ? `${d.startDate}-${d.endDate}` : "previous-month";

  const showTemperature = tableData.some((row) => row?.averageTemperature);

  return (
    <StyledBillComparisonTable
      $numberOfColumns={comparisonData.previousYearPeriod ? 4 : 3}
    >
      <tbody>
        <tr>
          <th>{tBillComparisonTableDaysInCycle}</th>
          {tableData.map((d) => (
            <td key={`days-${getCellKey(d)}`}>
              {d ? d.servicePeriodDays : "-"}
            </td>
          ))}
        </tr>
        <tr>
          <th>{tBillComparisonTableConsumed}</th>
          {tableData.map((d) => (
            <td key={`consumption-${getCellKey(d)}`}>
              {d ? parseInt(d.consumption, 10).toLocaleString("en-US") : "-"}
            </td>
          ))}
        </tr>
        {showTemperature && (
          <tr>
            <th>{tBillComparisonTableAverageTemperature}</th>
            {tableData.map((d) => (
              <td key={`average-temperature-${getCellKey(d)}`}>
                {d && d.averageTemperature ? `${d.averageTemperature}°F` : "-"}
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </StyledBillComparisonTable>
  );
};
