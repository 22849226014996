import { IdType, UUIDType } from "@common/types/apiTypes";

export const prospectUrls = {
  contactLogs: {
    create: (id: IdType) => prospectUrls.contactLogs.list(id),
    list: (id: IdType) => `/api/prospects/${id}/contact-logs/`,
  },
  create: () => "/api/prospects/",
  creditScoreEvaluation: () => "/api/prospects/credit_score_evaluation/",
  getReferralCode: (id: IdType, code: string) =>
    `/api/prospects/${id}/referral-code/${code}/`,
  list: () => "/api/prospects/",
  retrieve: (id: IdType | UUIDType) => `/api/prospects/${id}/`,
  trackEnteredSignup: (id: IdType) =>
    `/api/prospects/${id}/track_entered_signup/`,
  trackServiceUnavailable: (id: IdType) =>
    `/api/prospects/${id}/track_service_unavailable/`,
  update: (id: IdType) => prospectUrls.retrieve(id),
  validatePhoneNumber: () => "/api/prospects/validate-phone-number",
};
