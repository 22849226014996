import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhModalDialog } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey, purple } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const SwitchPrintToPaperlessModalModal = styled(RhModal)`
  ${RhModalDialog} {
    padding: 0;
    width: 450px;
  }
`;

export const SwitchPrintToPaperlessModalTitle = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: left;
    text-wrap: balance;
  }
`;

export const SwitchPrintToPaperlessModalSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
    text-wrap: balance;
  }
`;

export const SwitchPrintToPaperlessTitleContainer = styled.div`
  align-items: center;
  background-color: ${purple["50"]};
  display: grid;
  gap: ${rhSpacingPx(2)};
  grid-template-columns: 1fr min-content;
  min-height: 170px;
  padding: ${rhSpacingPx(4)};
  position: relative;
  width: 100%;

  & > svg {
    align-self: flex-end;
    height: 130px;
    justify-self: flex-end;
    width: 130px;
  }
`;

export const SwitchPrintToPaperlessModalPaperButton = styled.button`
  background: none;
  border: none;
  color: ${grey[500]};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
`;

export const SwitchPrintToPaperlessModalBackdropCircularProgress = styled(
  RhCircularProgress
)`
  background: rgb(255 255 255 / 75%);
  height: 100%;
  position: absolute;
`;

export const SwitchPrintToPaperlessContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  padding: ${rhSpacingPx(4)};
`;
