import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import IMask, { InputMask } from "imask";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

interface MonthlyUsageInputProps {
  initialValue: number;
  onChange: (value: number | null) => void;
}

type InputMaskType = InputMask<{
  blocks: {
    UP_TO_FOUR_DIGITS: {
      mask: string;
    };
  };
  lazy: false;
  mask: string;
  placeholderChar: string;
}>;

export const MonthlyUsageInput = ({
  onChange,
  initialValue,
}: MonthlyUsageInputProps) => {
  const { t } = useRhIntl();
  const input = useRef<HTMLElement | null>(null);

  const [value, setValue] = useState<string>(
    initialValue ? initialValue.toString() : ""
  );
  const [mask, setMask] = useState<InputMaskType>();

  useEffect(() => {
    if (input.current) {
      const monthlyUsageMask = IMask(
        input.current.querySelector("input") as HTMLElement,
        {
          blocks: {
            UP_TO_FOUR_DIGITS: {
              mask: "[0000]",
            },
          },
          lazy: false,
          mask: `UP_TO_FOUR_DIGITS ${KILOWATT_HOURS_ABBREV}`,

          placeholderChar: " ",
        }
      );

      monthlyUsageMask.value = value;
      setMask(monthlyUsageMask);
      setValue(monthlyUsageMask.value);
    }
  }, [value]);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev?.currentTarget?.value ?? "";

    if (mask) {
      mask.value = inputValue;
      mask.updateValue();
      setValue(mask.value);
      const unmaskedValue = parseInt(mask.unmaskedValue, 10);

      onChange(unmaskedValue || null);
    }
  };

  const placeholder = t("MonthlyUsageInput.placeholder");
  const label = t("MonthlyUsageInput.howMuchDoYouUse");

  return (
    <RhTextInput
      color="primary"
      name="monthlyUsage"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      inputMode="numeric"
      ref={input}
      autoFocus
    >
      {label}
    </RhTextInput>
  );
};
