import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as Magnifier } from "@design-system/icons/Magnifier.svg";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { productAddOnsOptInPath } from "@portal/routes/routePaths";
import { MyAccountEvents } from "@portal/services/segment.service";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledInnerCard = styled(RhInnerCard)`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: ${rhSpacingPx(2)};
`;

const OptInDescription = styled(RhTypography).attrs({
  fontWeight: FontWeight.Semibold,
  variant: "subtitle2",
})`
  &.MuiTypography-root {
    margin-left: ${rhSpacingPx(1)};
  }
`;

const NextStepIcon = styled(RightChevron)`
  margin-left: auto;
  min-width: ${rhSpacingPx(3)};
`;

const OptInIcon = styled(Magnifier)`
  margin-right: ${rhSpacingPx(1)};
  min-width: ${rhSpacingPx(3)};
`;

export const MyAccountProductAddOnsOptIn = () => {
  const { t } = useRhIntl();
  const navigate = useNavigate();
  const track = useTrackMyAccountEvents();

  const optInDescriptionText = t(
    "MyAccountProductAddOnsOptIn.OptInForProductAddOnsDescription"
  );

  return (
    <StyledInnerCard
      onClick={() => {
        track({
          event: MyAccountEvents.viewProductAddonsClick,
          label: "Clicked Check our protection plans",
        });

        navigate(productAddOnsOptInPath());
      }}
    >
      <OptInIcon />
      <OptInDescription>{optInDescriptionText}</OptInDescription>
      <NextStepIcon />
    </StyledInnerCard>
  );
};
