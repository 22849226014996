import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { theme } from "@design-system/theme/theme.styled";
import styled from "styled-components";

const PLAN_NAME_MAX_WIDTH = "340px";

export const PlanFlex = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-start;

  @media screen and (min-width: 32rem) {
    gap: 2rem;
  }
`;

export const PlanListWrapper = styled.div`
  width: 100%;
`;

export const PlanListHeader = styled(PlanFlex)`
  border-bottom: 1px solid ${theme.palette.grey[100]};
  font-size: 0.7rem;
  padding-bottom: 0.5rem;
  text-align: left;
  width: 100%;

  @media screen and (min-width: 32rem) {
    font-size: 1rem;
  }
`;

export const PlanList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  max-height: 6rem;
  min-height: 6rem;
  overflow-y: auto;
  width: 100%;

  &.plansLoading {
    justify-content: center;

    > * {
      margin: auto;
    }
  }

  @media screen and (min-height: 35rem) {
    max-height: 10rem;
  }

  @media screen and (min-height: 44rem) {
    max-height: 16rem;
  }
`;

export const Plan = styled(PlanFlex)`
  border-top: 1px solid ${theme.palette.grey[100]};
  padding: 0.75rem 0;
  width: 100%;

  &:first-child {
    border-top: 0;
  }
`;

export const PlanNameWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-basis: 150px;
  flex-flow: column nowrap;
  gap: 0.1rem;
  justify-content: flex-start;
`;

export const PlanName = styled.h3`
  font-size: 0.8rem;
  font-weight: ${FontWeight.Regular};
  line-height: 1.2;
  margin: 0;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 36rem) {
    font-size: 1.1rem;
    max-width: ${PLAN_NAME_MAX_WIDTH};
  }
`;

export const PlanUpdatedOn = styled.p`
  color: ${theme.palette.grey[400]};
  font-size: 0.65rem;
  margin: 0;

  @media screen and (min-width: 32rem) {
    font-size: 0.85rem;
  }
`;

export const ProviderLogoCell = styled.div`
  display: block;
  width: 50px;

  svg {
    height: auto;
    width: 100%;
  }

  @media screen and (min-width: 32rem) {
    width: 75px;
  }
`;

export const EFLCell = styled.div`
  display: none;
  justify-content: flex-start;
  padding-right: 2rem;
  width: 3rem;

  svg {
    transform: translateX(5px);
  }

  /* To match us making the planName a link at 600px */
  @media screen and (min-width: 600px) {
    display: flex;
  }

  @media screen and (min-width: ${ModalWidths.md}) {
    padding-right: 0;
  }
`;

export const PriceCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 4rem;
`;

export const PlanPrice = styled.p`
  font-size: 1rem;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const PlanPricePerKwh = styled.p`
  font-size: 0.6rem;
  margin: 0;

  @media screen and (min-width: 32rem) {
    font-size: 0.8rem;
  }
`;

interface RhythmSelectedPlanWrapperProps {
  highlight: boolean;
}

export const RhythmSelectedPlanWrapper = styled.div<RhythmSelectedPlanWrapperProps>`
  align-items: center;
  border: ${({ highlight }) =>
    highlight ? `2px solid ${theme.palette.secondary.main}` : 0};
  border-radius: 6px;
  display: flex;
  gap: 1rem;
  padding: ${({ highlight }) => (highlight ? "0.5rem 1rem" : 0)};
  width: 100%;

  > svg {
    display: none;

    @media screen and (min-width: 36em) {
      display: block;
    }
  }
`;

export const RhythmPlanNameWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 0.1rem;
  justify-content: flex-start;
`;

export const RhythmLearnMoreButton = styled.button`
  background: 0;
  border: 0;
  color: ${theme.palette.primary.main};
  display: inline-block;
  font-size: 0.8rem;
  padding: 0;
  text-align: left;
  width: auto;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const RhythmPlanSelectButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  justify-content: center;
`;

export const RhythmLogoCell = styled(ProviderLogoCell)`
  display: none;

  @media screen and (min-width: 24rem) {
    display: block;
  }
`;

export const RhythmPriceContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.25rem;

  @media screen and (min-width: 36em) {
    flex-flow: column nowrap;
    gap: 0;
  }
`;

export const NoPlansFoundText = styled.p`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.8rem;
  gap: 1rem;
  justify-content: center;
  margin: auto;
  max-width: 40ch;
  padding-top: 0.75rem;
  text-align: center;

  > span {
    flex: 1;
  }

  @media screen and (min-width: 36em) {
    flex-flow: row nowrap;
    font-size: 1rem;
    justify-content: flex-start;
    text-align: left;
  }
`;
