import { borders } from "@design-system/constants/borders";
import { trueBlack } from "@design-system/theme/palette.colors";
import styled, { css } from "styled-components";

interface CartItemsProps {
  $isMobileCart?: boolean;
}

export const CartContainer = styled.div`
  color: ${trueBlack};
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

export const CartItems = styled.div<CartItemsProps>`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 2rem;
  overflow-y: auto;
  padding: 2rem;

  ${({ $isMobileCart }) =>
    $isMobileCart &&
    css`
      max-height: 16rem;
      overflow-y: auto;

      @media screen and (min-height: 40em) {
        max-height: 24rem;
      }
    `}
`;

export const CartButtonContainer = styled.div`
  align-items: flex-end;
  border-top: ${borders[100]};
  display: flex;
  justify-content: center;
  min-height: 110px;
  padding: 2rem;
`;
