import { UUIDType } from "@common/types/apiTypes";
import { RhError } from "@common/types/errorTypes";
import { ProspectType } from "@common/types/prospectTypes";
import { prospectApi } from "@portal/api/prospectApi";
import { prospectQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProspectQuery = (prospectUuid: UUIDType | undefined) => {
  const query = useQuery<ProspectType, RhError>({
    enabled: prospectUuid !== undefined,
    queryFn: () => prospectApi.retrieve(prospectUuid || ""),
    queryKey: prospectQueryKeys.retrieve(prospectUuid || ""),
  });

  return query;
};
