import { PriceScheduleBreakdown } from "@common/components/PriceScheduleBreakdown/PriceScheduleBreakdown";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { priceScheduleBreakdownTranslations } from "@portal/components/PortalPriceScheduleBreakdown/PortalPriceScheduleBreakdown.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

type PortalPriceScheduleBreakdownProps = {
  id?: string;
  offerSnapshot: OfferSnapshot;
  title?: string;
};

export const PortalPriceScheduleBreakdown = ({
  title,
  offerSnapshot,
  id,
}: PortalPriceScheduleBreakdownProps) => {
  const { translate } = useTranslations();

  const {
    tPriceScheduleBreakdownBuybackRate,
    tPriceScheduleBreakdownChargeRate,
    tPriceScheduleBreakdownEnergyChargeRate,
    tPriceScheduleBreakdownEnergyChargeRateDescription,
    tPriceScheduleBreakdownEnergyCharge,
    tPriceScheduleBreakdownEnergyChargeDescription,
    tPriceScheduleBreakdownSolarBuybackCreditSchedule,
    tPriceScheduleBreakdownEnergySchedule,
  } = translate(priceScheduleBreakdownTranslations);

  return (
    <PriceScheduleBreakdown
      id={id}
      title={title}
      offerSnapshot={offerSnapshot}
      labels={{
        buybackRateLabel: tPriceScheduleBreakdownBuybackRate,
        chargeRateLabel: tPriceScheduleBreakdownChargeRate,
        energyCharge: tPriceScheduleBreakdownEnergyCharge,
        energyChargeDescription: tPriceScheduleBreakdownEnergyChargeDescription,
        energyChargeRate: tPriceScheduleBreakdownEnergyChargeRate,
        energyChargeRateDescription:
          tPriceScheduleBreakdownEnergyChargeRateDescription,
        energyScheduleLabel: tPriceScheduleBreakdownEnergySchedule,
        solarBuybackCreditSchedule:
          tPriceScheduleBreakdownSolarBuybackCreditSchedule,
      }}
    />
  );
};
