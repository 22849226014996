import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@portal/components/OfferSnapshotCard/OfferSnapshotCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";

export const OfferSnapshotCardSkeleton = () => (
  <Card style={{ height: "305px" }}>
    <Skeleton />

    <CardHeader>
      <Skeleton $height="0.5rem" as="span" />
    </CardHeader>

    <CardContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
    </CardContent>

    <CardFooter>
      <Skeleton $rounded $height="2rem" />
    </CardFooter>
  </Card>
);
