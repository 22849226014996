import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { RepPriceComparisonModal } from "@portal/components/RepPriceComparisonModal/RepPriceComparisonModal";
import { CompetitorPlan } from "@portal/types/competitorTypes";
import React from "react";

interface RepPriceComparisonChartDialogsProps {
  closeModal: () => void;
  competitorPlans: CompetitorPlan[];
  modalOpen: boolean;
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  offerSnapshot: OfferSnapshot;
  selectButtonProps: {
    onClick: () => void;
    text: string;
  };
}

export const RepPriceComparisonChartDialogs = ({
  closeModal,
  competitorPlans,
  modalOpen,
  moreButtonProps,
  offerSnapshot,
  selectButtonProps,
}: RepPriceComparisonChartDialogsProps) => {
  const handleCloseModal = () => {
    document.body.classList.remove(CSSClasses.OverflowHidden);
    closeModal();
  };

  return (
    <>
      {modalOpen && (
        <RepPriceComparisonModal
          offerSnapshot={offerSnapshot}
          competitorPlans={competitorPlans}
          selectButtonProps={selectButtonProps}
          moreButtonProps={moreButtonProps}
          close={handleCloseModal}
        />
      )}
    </>
  );
};
