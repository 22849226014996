import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { PricingQueryKeys } from "@portal/constants/querykeys.constants";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@portal/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseOfferSnapshotQueryProps {
  offerSnapshotId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useOfferSnapshotQuery = (props: UseOfferSnapshotQueryProps) => {
  const { offerSnapshotId, queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => api.pricing.offerSnapshots.retrieve(offerSnapshotId),
    queryKey: PricingQueryKeys.offerSnapshot.retrieve(offerSnapshotId),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });

  return query;
};
