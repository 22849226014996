import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledBillComparisonCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const StyledBillComparisonDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${grey[100]};
    margin-top: ${rhSpacingPx(1)};
  }
`;

export const StyledBillComparisonChartContainer = styled.div`
  height: 200px;
  margin-top: ${rhSpacingPx(5)};
  width: 100%;
`;

export const StyledBillComparisonTableContainer = styled.div`
  margin-top: ${rhSpacingPx(0.5)};
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
