import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { IconButton } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    &:active {
      background-color: ${darkPurple["100"]};
      color: ${darkPurple.main};
    }

    &:hover,
    &:focus {
      background-color: ${darkPurple["50"]};
      color: ${darkPurple.main};
    }
    color: ${grey[600]};
    font-size: 2.2rem;

    padding: 12px;
  }
`;
