import { darkPurple, trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import { theme } from "@design-system/theme/theme.styled";
import { IconButton } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${darkPurple[500]};
  }

  &:hover {
    background-color: initial;
  }
`;

export const StyledIconWrapper = styled.div`
  width: ${rhSpacingPx(RH_HEADER_HEIGHT)};
`;

export const StyledList = styled.div`
  background-color: ${trueWhite};
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  font-size: 18px;
  height: 100%;
  list-style: none;
  margin-top: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  padding-bottom: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

interface StyledListItemProps {
  $dividedLastItem: boolean;
}

export const StyledListItem = styled.div<StyledListItemProps>`
  color: ${theme.palette.text.primary};
  font-size: 18px;
  height: ${rhSpacingPx(3)};
  margin: ${rhSpacingPx(2)} 0;
  padding: 0 ${rhSpacingPx(2)};
  white-space: nowrap;
  &:last-child {
    font-size: ${({ $dividedLastItem }) =>
      $dividedLastItem ? "14px" : "inherit"};
  }
`;

export const StyledFullWidthDividerWrapper = styled.li`
  margin-top: ${rhSpacingPx(2)};
  width: 100%;
`;
