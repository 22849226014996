import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { SnippetOptions } from "@fullstory/browser";
import { OktaAuthOptions } from "@okta/okta-auth-js";
import { allEnvApplicationSettings } from "@portal/settings/env";

interface GoogleTagManagerConfigObject {
  gtmId: string;
}

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const APP_BASE_URL = envApplicationSettings.appBaseUrl;
export const AXIOS_BASE_URL = envApplicationSettings.axiosBaseUrl;
export const AXIOS_TIMEOUT_MS: number = envApplicationSettings.axiosTimeoutMs;
export const CDN_URL = envApplicationSettings.cdnUrl;

export const OKTA_IDENTITY_PROVIDER_ID =
  envApplicationSettings.oktaIdentityProviderId;

export const OKTA_CONFIG = Object.freeze<OktaAuthOptions>({
  clientId: envApplicationSettings.oktaClientId,
  issuer: envApplicationSettings.oktaIssuer,
  pkce: true,
  redirectUri: envApplicationSettings.oktaRedirectUri,
  scopes: ["openid", "profile", "email", "groups"],
});

export const ZUORA_URL = envApplicationSettings.zuoraBaseUrl;
export const ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID =
  envApplicationSettings.zuoraCreditCardPaymentPageId;
export const ZUORA_ADD_BANK_ACCOUNT_PAGE_ID =
  envApplicationSettings.zuoraAddBankAccountPageId;

export const MAPBOX_TOKEN = envApplicationSettings.mapboxToken;
export const MAPBOX_API_URL = "https://api.mapbox.com";
export const MAPBOX_GEOCODING_API = `${MAPBOX_API_URL}/geocoding/v5/mapbox.places`;
export const MAPBOX_STATIC_API = `${MAPBOX_API_URL}/styles/v1/mlcamilli/ckhao6zpm2ff41aqho05d6j9b/static`;

export const GoogleTagManagerConfig: GoogleTagManagerConfigObject = {
  gtmId: envApplicationSettings.googleTagManagerId,
};

export const FullStoryConfig: SnippetOptions | null =
  envApplicationSettings.fullStoryOrgId
    ? Object.freeze({
        orgId: envApplicationSettings.fullStoryOrgId,
      })
    : null;

export const SENTRY_CONFIG = Object.freeze({
  dsn: envApplicationSettings.sentryDSN,
  orgSlug: envApplicationSettings.sentryOrgSlug,
  release: envApplicationSettings.sentryRelease,
  tracesSampleRate: envApplicationSettings.sentryTracesSampleRate,
});

export const FAVICON_PATH = envApplicationSettings.faviconPath;

export const PURECLOUD_CHAT_CONFIG = Object.freeze({
  orgId: envApplicationSettings.purecloudOrgId,
  orgName: envApplicationSettings.purecloudOrgName,
  queueName: envApplicationSettings.purecloudQueueName,
});

export const RHAPSODY_BASE_URL = envApplicationSettings.rhapsodyBaseUrl;

export const FINGERPRINT_JS_URL = envApplicationSettings.fingerPrintJsUrl;
export const FINGERPRINT_JS_API_KEY =
  envApplicationSettings.fingerPrintJsApiKey;

export const COOKIE_DOMAIN = envApplicationSettings.cookieDomain;
