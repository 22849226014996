import { RhButton } from "@design-system/components/RhButton/RhButton";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const OneClickRenewalSummaryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(4)};
  justify-content: center;
`;

export const StyledTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(1)};
  }

  > h1 {
    font-size: 32px;
  }
`;

export const StyledBackButtonContainer = styled(RhButton)`
  &.MuiButton-root {
    color: ${grey[300]};

    svg {
      height: 16px;
      margin-right: 10px;
      width: 8px;
    }
  }
`;
