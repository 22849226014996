import { FeatureFlagClientProvider } from "@common/context/featureFlagClient.context";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import {
  AppContainer,
  PortalContent,
  StyledAnnouncement,
} from "@portal/App.styled";
import { GlobalLogic } from "@portal/components/GlobalLogic/GlobalLogic";
import { GoogleAnalyticsTracking } from "@portal/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking";
import { Loader } from "@portal/components/Loader/Loader";
import { LoggedOutHeaderHoc } from "@portal/components/LoggedOutHeaderHoc/LoggedOutHeaderHoc";
import { PortalDevTools } from "@portal/components/PortalDevTools/PortalDevTools";
import { PortalFooter } from "@portal/components/PortalFooter/PortalFooter";
import { useDynamicIntl } from "@portal/hooks/useDynamicIntl";
import { useFeatureFlagUserId } from "@portal/hooks/useFeatureFlagUserId/useFeatureFlagUserId";
import { useFullStoryUserId } from "@portal/hooks/useFullStoryUserId.hook";
import { useIsExcludedUtmMedium } from "@portal/hooks/useIsExcludedUtmMedium";
import { usePageAnalytics } from "@portal/hooks/usePageAnalytics";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import { PortalRoutes } from "@portal/routes/PortalRoutes";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import { FAVICON_PATH } from "@portal/settings/config";
import { ErrorBoundary } from "@sentry/react";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RawIntlProvider } from "react-intl";

const FallBackComponent = () => <Error500Page />;

export const App = () => {
  const intl = useDynamicIntl();
  const { content, bannerRef } = useRhAnnouncement();
  const isExcludedUtmMedium = useIsExcludedUtmMedium();
  const featureFlagUserId = useFeatureFlagUserId();

  usePageAnalytics();

  useFullStoryUserId();

  // Identify user in tracking analytics with our rh_ user id
  useEffect(() => {
    if (featureFlagUserId) {
      track({
        action: ActionType.userIdentified,
        category: "portal",
        event: EventType.identify,
        label: "User Identified",
        value: featureFlagUserId,
      });
    }
  }, [featureFlagUserId]);

  const siteTitle = intl.formatMessage({
    id: "navigation.title.rhythm",
  });

  // We need to figure out how much extra padding to put to the top of the app to accomodate the announcement banner, if one is present. Since this is styled with MUI, we have to normalize the RH_HEADER_HEIGHT from a theme.spacing multiple to a pixel value.
  const AnnouncementBannerHeight = bannerRef?.current
    ? bannerRef.current.clientHeight
    : 0;

  return (
    <FeatureFlagClientProvider
      featureFlagUserId={featureFlagUserId}
      sendEvents={!isExcludedUtmMedium}
    >
      <RawIntlProvider value={intl}>
        <HelmetProvider>
          <GlobalLogic />
          <GoogleAnalyticsTracking />
          <Loader />
          {content && <StyledAnnouncement />}
          <Helmet>
            <title>{siteTitle}</title>
            <link rel="icon" type="image/x-icon" href={FAVICON_PATH} />
          </Helmet>
          <AppContainer $announcementBannerHeight={AnnouncementBannerHeight}>
            <LoggedOutHeaderHoc />
            <PortalContent>
              <ErrorBoundary fallback={FallBackComponent}>
                <PortalRoutes />
              </ErrorBoundary>
            </PortalContent>
            <PortalFooter />
          </AppContainer>
        </HelmetProvider>
      </RawIntlProvider>
      <PortalDevTools />
    </FeatureFlagClientProvider>
  );
};
