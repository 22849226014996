import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import {
  AcquisitionCampaignUrls,
  GuestPayUrls,
  ProductContractUrls,
  ProductUrls,
  QuickPayUrls,
} from "@portal/api/urls";
import {
  GuestPayAccountSummaryType,
  GuestPayCreatePaymentResponseType,
} from "@portal/types/guestPayTypes";
import type {
  ClaimOptionType,
  CreateProductContractResponse,
  ProductContractClaimOptionsRequestType,
  ProductContractClaimRequestType,
  ProductContractType,
} from "@portal/types/productContractTypes";
import {
  ProductContractCancelRequestType,
  ProductContractStatusFilter,
} from "@portal/types/productContractTypes";
import { ProductTierType } from "@portal/types/productTypes";
import {
  QuickPayAccountBalanceType,
  QuickPayCreatePaymentRequestType,
  QuickPayCreatePaymentResponseType,
  QuickPayPaymentMethodType,
} from "@portal/types/quickPayTypes";

interface ProductContractListQueryParams {
  contractIds?: UUIDType[];
  status: ProductContractStatusFilter;
}

export const portalApi = {
  acquisitionCampaign: {
    retrieve: (slug: string) =>
      ajax.get<{
        phone: string;
        slug: string;
      }>(AcquisitionCampaignUrls.retrieveBySlug(slug)),
  },
  guestPay: {
    accountSummaryByToken: (
      token: string
    ): Promise<GuestPayAccountSummaryType> =>
      ajax.get(GuestPayUrls.accountSummaryByToken(token)),
    accountSummaryLookup: (bodyData: {
      accountNumber: string;
      zipCode: string;
    }): Promise<GuestPayAccountSummaryType> =>
      ajax.post(GuestPayUrls.accountSummaryLookup(), bodyData),
    createPayment: ({
      amount,
      token,
    }: {
      amount: string;
      token: string;
    }): Promise<GuestPayCreatePaymentResponseType> =>
      ajax.post(GuestPayUrls.createPayment(token), {
        amount,
      }),
    createPaymentMethod: ({
      token,
      paymentMethodId,
    }: {
      paymentMethodId: string;
      token: string;
    }): Promise<null> =>
      ajax.post(GuestPayUrls.createPaymentMethod(token), {
        paymentMethodId,
      }),

    deletePaymentMethod: ({ token }: { token: string }): Promise<null> =>
      ajax.destroy(GuestPayUrls.deletePaymentMethod(token)),
    getPaymentMethod: (token: string): Promise<PaymentMethodType> =>
      ajax.get(GuestPayUrls.getPaymentMethod(token)),
  },
  premise: {
    productContract: {
      cancel: ({
        premiseId,
        contractId,
        endDate,
      }: ProductContractCancelRequestType): Promise<null> =>
        ajax.patch(ProductContractUrls.cancel(premiseId), {
          contractId,
          endDate,
        }),
      claim: ({
        premiseId,
        contractId,
        claimFile,
      }: ProductContractClaimRequestType): Promise<null> => {
        const formData = new FormData();

        formData.append("contract_id", contractId);
        formData.append("file", claimFile);
        return ajax.postFile(ProductContractUrls.claims(premiseId), formData);
      },
      claims: {
        createClaim: ({
          premiseId,
          contractId,
          claimFile,
        }: ProductContractClaimRequestType): Promise<null> => {
          const formData = new FormData();

          formData.append("contract_id", contractId);
          formData.append("file", claimFile);
          return ajax.postFile(ProductContractUrls.claims(premiseId), formData);
        },
        listOptions: ({
          premiseId,
          productContractId,
        }: ProductContractClaimOptionsRequestType): Promise<
          ClaimOptionType[]
        > =>
          ajax.get(
            ProductContractUrls.claimOptions(premiseId, productContractId)
          ),
      },
      create: ({
        premiseId,
        priceId,
      }: {
        premiseId: IdType;
        priceId: UUIDType;
      }): Promise<CreateProductContractResponse> =>
        ajax.post<CreateProductContractResponse>(
          ProductContractUrls.create(premiseId),
          { priceId }
        ),
      list: (premiseId: IdType, params: ProductContractListQueryParams) => {
        const { status = ProductContractStatusFilter.ACTIVE } = params;

        return ajax.get<PaginationEndpointResponseType<ProductContractType>>(
          ProductContractUrls.list(premiseId),
          {
            params: {
              ...params,
              status,
            },
          }
        );
      },
    },
  },
  products: {
    tiers: {
      list: () => ajax.get<ProductTierType[]>(ProductUrls.list()),
    },
  },
  quickPay: {
    addPaymentMethod: ({
      isOneTimePayment,
      token,
      paymentMethodId,
      setDefaultPaymentMethod,
    }: {
      isOneTimePayment?: boolean;
      paymentMethodId: string;
      setDefaultPaymentMethod?: boolean;
      token: string;
    }): Promise<null> =>
      ajax.put(QuickPayUrls.addPaymentMethod(token), {
        isOneTimePayment,
        paymentMethodId,
        setDefaultPaymentMethod,
      }),
    createPayment: ({
      token,
      paymentMethodId,
      amount,
      isOneTimePayment,
    }: QuickPayCreatePaymentRequestType): Promise<QuickPayCreatePaymentResponseType> =>
      ajax.post(QuickPayUrls.createPayment(token), {
        amount,
        isOneTimePayment,
        paymentMethodId,
      }),
    getAccountBalance: (token: UUIDType) =>
      ajax.get<QuickPayAccountBalanceType>(
        QuickPayUrls.getAccountBalance(token)
      ),
    getPaymentMethod: (token: UUIDType, paymentMethodId: string) =>
      ajax.get<PaymentMethodType>(
        QuickPayUrls.getPaymentMethod(token, paymentMethodId)
      ),
    getPaymentMethods: (token: UUIDType) =>
      ajax.get<QuickPayPaymentMethodType>(
        QuickPayUrls.getPaymentMethods(token)
      ),
    resend: (token: UUIDType): Promise<null> =>
      ajax.put(QuickPayUrls.resend(token), {}),
  },
};
