import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { OfferSnapshotFAQs } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQs";
import styled from "styled-components";

export const LearnMoreContainer = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(4)};
  padding-bottom: ${rhSpacingPx(8)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 2.5fr 1fr;

    footer {
      grid-column: 1 / -1;
    }
  }
`;

export const StyledFAQs = styled(OfferSnapshotFAQs)`
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-column: 1 / -1;
  }
`;

export const OfferSnapshotLearnMoreHeader = styled.header`
  display: flex;
  flex-flow: column wrap;
  gap: 0.5rem;
  grid-column: 1 / -1;
`;

export const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-column: 2;
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const ReasonsToBelieveTitle = styled(RhTypography)`
  &.MuiTypography-root {
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      display: none;
    }
  }
`;

export const LearnMoreFooter = styled.footer`
  margin-top: ${rhSpacingPx(3)};
`;
