import { api } from "@common/api/api";
import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { AddressFormType } from "@common/types/customerTypes";
import { AREA_NOT_SERVICED, RhApiError } from "@common/types/errorTypes";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { AppSource } from "@portal/enums/QueryParamValues.enum";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { availabilityErrorPaths } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage.constants";
import {
  REFERRAL_CODE_QUERY_PARAM,
  SignUpSteps,
  signUpBasePath,
  signUpStepPath,
} from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { customerPreferencesLanguageUpdated } from "@portal/slices/customerPreferencesSlice";
import {
  AcquisitionData,
  SignUpStateType,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const hasAcquisitionParam = (data: AcquisitionData): boolean => {
  const acquisitionValues: (string | null)[] = Object.values(data);

  return acquisitionValues.some((acq) => Boolean(acq));
};

export interface SignUpQueryParams {
  acquisition: AcquisitionData;
  appSource: AppSource | null;
  featureFlagUserId: string | null;
  languagePreference: SupportedPortalLanguage | null;
  rcid: string | null;
  referralCode: string | null;
  serviceAddress: Partial<AddressFormType>;
  termMonthsTab: string | null;
}

export const getSignUpQueryParams = (search: string): SignUpQueryParams => {
  const query = new URLSearchParams(search);

  const acquisition: AcquisitionData = {
    acquisitionCampaign: query.get("rh_campaign"),
    acquisitionContent: query.get("rh_content"),
    acquisitionMedium: query.get("rh_medium"),
    acquisitionSource: query.get("rh_source"),
    acquisitionTfn: null,
  };

  return {
    acquisition,
    appSource: (query.get("appSource") as AppSource) ?? null,
    featureFlagUserId: query.get("featureFlagUserId"),
    languagePreference:
      (query.get("locale") as SupportedPortalLanguage) ?? null,
    rcid: query.get("rcid"),
    referralCode: query.get(REFERRAL_CODE_QUERY_PARAM),
    serviceAddress: {
      addressLine1: query.get("streetLine") ?? undefined,
      city: query.get("city") ?? undefined,
      state: query.get("state") ?? undefined,
      unitNumber: query.get("secondary") ?? undefined,
      zipCode: query.get("zipcode") ?? undefined,
    },
    termMonthsTab: query.get("term_months_tab"),
  };
};

export const useSignUpQueryParams = () => {
  const { signUpClickNextStepHandler, currentStep } = useSignUpFlow();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const { campaignSlug, serviceAddress: storedServiceAddress } =
    useSelector(selectSignUpState);
  const {
    appSource,
    rcid,
    languagePreference,
    referralCode,
    serviceAddress,
    acquisition,
    featureFlagUserId,
    termMonthsTab,
  } = getSignUpQueryParams(search);

  useEffect(() => {
    const inSignUpFlow = SignUpSteps.includes(currentStep);
    const signUpState: Partial<SignUpStateType> = {};

    if (appSource) {
      signUpState.appSource = appSource;
    }

    if (featureFlagUserId) {
      signUpState.featureFlagUserId = featureFlagUserId;
    }

    if (rcid) {
      signUpState.campaignSlug = rcid;
    } else if (!campaignSlug) {
      signUpState.campaignSlug = CampaignSlugs.Default;
    }

    if (referralCode) {
      signUpState.referralFromFriendCode = referralCode;
    }

    if (hasAcquisitionParam(acquisition)) {
      signUpState.acquisition = acquisition;
    }

    if (languagePreference) {
      signUpState.languagePreference = languagePreference;

      dispatch(customerPreferencesLanguageUpdated(languagePreference));
    }

    if (termMonthsTab) {
      signUpState.selectedTermMonthsTab = termMonthsTab;
    }

    if (inSignUpFlow && !isEmpty(signUpState)) {
      dispatch(
        setSignUpInfo({
          ...signUpState,
        })
      );
    }
  }, [dispatch]);

  const [{ requestMonitor }, { setPending, setSuccess }] =
    useAjaxState<boolean>(!serviceAddress.zipCode);

  useEffect(() => {
    const templateServiceAddress: AddressFormType = {
      addressLine1: "",
      city: "",
      state: "",
      unitNumber: "",
      zipCode: "",
    };

    const actualServiceAddress: AddressFormType = merge(
      {},
      templateServiceAddress,
      storedServiceAddress,
      serviceAddress
    );

    // if a customer is directed to the /sign-up/plans path with a zipcode param from the marketing site
    // we allow them to enter the sign up flow without going through the availability step
    if (pathname.includes(signUpBasePath())) {
      if (
        pathname.includes(signUpStepPath("plans")) &&
        actualServiceAddress.zipCode
      ) {
        setPending();
        dispatch(setSignUpInfo({ visitedSteps: ["availability", "plans"] }));

        api.pricing.offerSnapshots
          .availability(actualServiceAddress)
          .then(({ dunsNumber }) => {
            signUpClickNextStepHandler({
              nextStep: "plans",
              signUpData: { dunsNumber, serviceAddress: actualServiceAddress },
              track: false,
            });

            track({
              action: ActionType.marketingAddressFormSubmitted,
              category: CategoryType.Availability,
              event: EventType.enrollmentClick,
              label: LabelType.ActiveServiceArea,
              value: actualServiceAddress.zipCode,
            });

            setSuccess(true);
          })
          .catch((error: RhApiError) => {
            const errorCode = error?.data.errorCode;

            if (errorCode && availabilityErrorPaths[errorCode]) {
              if (errorCode === AREA_NOT_SERVICED) {
                track({
                  action: ActionType.marketingAddressFormSubmitted,
                  category: CategoryType.Availability,
                  event: EventType.enrollmentClick,
                  label: LabelType.InvalidServiceArea,
                  value: actualServiceAddress.zipCode,
                });
              }

              signUpClickNextStepHandler({
                nextStep: availabilityErrorPaths[errorCode],
                signUpData: { serviceAddress: actualServiceAddress },
                track: true,
              });
            }

            setSuccess(true);
          });
      } else {
        setSuccess(true);

        dispatch(
          setSignUpInfo({
            serviceAddress: actualServiceAddress,
            visitedSteps: ["availability"],
          })
        );
      }
    }
  }, []);

  return requestMonitor;
};
