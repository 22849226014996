import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { QuickPayQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseQuickPayFetchPaymentMethodQueryProps {
  enabled: boolean;
  paymentMethodId: string;
  token: UUIDType;
}

export const useQuickPayFetchPaymentMethod = ({
  enabled,
  token,
  paymentMethodId,
}: UseQuickPayFetchPaymentMethodQueryProps) => {
  const query = useQuery({
    enabled,
    queryFn: () => portalApi.quickPay.getPaymentMethod(token, paymentMethodId),
    queryKey: QuickPayQueryKeys.paymentMethod(token, paymentMethodId),
    select: (data) => new PaymentMethod(data),
  });

  return query;
};
