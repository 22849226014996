import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Paper } from "@mui/material";
import styled from "styled-components";

export const CustomerHomeSectionInfoMessageContainer = styled(Paper).attrs({
  elevation: 0,
})`
  &.MuiPaper-root {
    background-color: ${grey[50]};
    height: 100%;
    padding: ${rhSpacingPx(8)} ${rhSpacingPx(3)};
    text-align: center;
  }
`;
