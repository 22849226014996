import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { repPriceComparisonSelectPlanCardTranslations } from "@portal/components/RepPriceComparisonSelectPlanCard/RepPriceComparisonSelectPlanCard.en.i18n";
import {
  OfferSnapshotTitle,
  RepPriceComparisonSelectPlanCardContainer,
  StyledPerKWHSection,
} from "@portal/components/RepPriceComparisonSelectPlanCard/RepPriceComparisonSelectPlanCard.style";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { FC } from "react";

interface RepPriceComparisonSelectPlanCardProps {
  moreButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
  offerSnapshot: OfferSnapshot;
  selectButtonProps: {
    onClick: React.MouseEventHandler;
    text: string;
  };
}

export const RepPriceComparisonSelectPlanCard: FC<
  RepPriceComparisonSelectPlanCardProps
> = ({ offerSnapshot, moreButtonProps, selectButtonProps }) => {
  const { translate } = useTranslations();
  const { title, id, solarEligible, energyRate, price2000Kwh } = offerSnapshot;

  const { tRepPriceComparisonSelectPlanCardPerKwh } = translate(
    repPriceComparisonSelectPlanCardTranslations
  );

  return (
    <RhCard>
      <RepPriceComparisonSelectPlanCardContainer>
        <OfferSnapshotTitle>{title}</OfferSnapshotTitle>
        <RhTypography variant="body2" fontWeight="Bold" color="textSecondary">
          {solarEligible
            ? formatDollarsToCents(energyRate())
            : formatDollarsToCents(price2000Kwh)}
          <StyledPerKWHSection>
            {tRepPriceComparisonSelectPlanCardPerKwh}
          </StyledPerKWHSection>
        </RhTypography>
        <RhButton
          data-tracking-click={{ event: "Prospect opening learn more modal" }}
          color="primary"
          size="small"
          variant="text"
          onClick={moreButtonProps.onClick}
        >
          {moreButtonProps.text}
        </RhButton>
        <RhButton
          data-tracking-click={{ event: "Prospect selecting a plan" }}
          color="primary"
          size="small"
          onClick={selectButtonProps.onClick}
          id={`comparePlans-selectButton-${id}`}
        >
          {selectButtonProps.text}
        </RhButton>
      </RepPriceComparisonSelectPlanCardContainer>
    </RhCard>
  );
};
