import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { PortalPageLayout } from "@portal/components/PortalPageLayout/PortalPageLayout";
import { RenewalPageLayout } from "@portal/components/RenewalPageLayout/RenewalPageLayout";
import { useOfferSnapshotQuery } from "@portal/hooks/queries/useOfferSnapshot.query";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremiseRenewalOfferSnapshotsQuery } from "@portal/hooks/queries/usePremiseRenewalOfferSnapshots";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { Error404Page } from "@portal/pages/Error404Page/Error404Page";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import { renewalsPageOfferSnapshotAtom } from "@portal/pages/RenewalsPage/RenewalsPage.atoms";
import { renewalsPageTranslations } from "@portal/pages/RenewalsPage/RenewalsPage.en.i18n";
import { homePath, renewalsSummaryPath } from "@portal/routes/routePaths";
import { useSetAtom } from "jotai";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const RenewalsPage = () => {
  const { premise, requestMonitor: premiseRequestMonitor } = usePremise();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    enabled: premise !== null,
    premiseId: premise?.id || "",
  });
  const { translate, translateJsx } = useTranslations();
  const { tRenewalsPageTranslationsTitle, tRenewalsPageTranslationsExplore } =
    translate(renewalsPageTranslations);
  const setOfferSnapshot = useSetAtom(renewalsPageOfferSnapshotAtom);
  const navigate = useNavigate();

  const { tRenewalsPageTranslationsChoose } = translateJsx({
    tRenewalsPageTranslationsChoose: {
      address: premise?.serviceAddress?.deliveryLine1,
    },
  });

  const renewalOfferSnapshotsQuery = usePremiseRenewalOfferSnapshotsQuery({
    enabled: Boolean(premise?.id),
    premiseId: premise?.id ?? "",
  });

  const currentOrderOfferSnapshot = useOfferSnapshotQuery({
    offerSnapshotId: premise?.currentOrder?.offersnapshotId ?? "",
  });

  const renewalNotAvailable =
    premiseRequestMonitor.didSucceed && !premise?.inRenewalWindow;

  if (renewalNotAvailable) {
    return <Navigate to={homePath()} />;
  }

  if (
    renewalOfferSnapshotsQuery.isPending ||
    accountSummaryQuery.isPending ||
    currentOrderOfferSnapshot.isPending
  ) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (
    renewalOfferSnapshotsQuery.isError ||
    accountSummaryQuery.isError ||
    currentOrderOfferSnapshot.isError
  ) {
    return (
      <PortalPageLayout>
        <Error500Page />
      </PortalPageLayout>
    );
  }

  // This should be hit while the `useLoader` hook is fetching premise data
  if (!premise || !premise.currentOrder) {
    return <PortalPageLayout>{null}</PortalPageLayout>;
  }

  const handleSelectOfferSnapshot = (offerSnapshot: OfferSnapshot) => {
    setOfferSnapshot(offerSnapshot);
    navigate(renewalsSummaryPath());
  };

  const {
    myAccountOfferSnapshots,
    oneClickRenewalOfferSnapshot,
    potentialSavingsOneClickOffer,
  } = renewalOfferSnapshotsQuery.data;

  if (myAccountOfferSnapshots.length === 0 && !oneClickRenewalOfferSnapshot) {
    return (
      <PortalPageLayout>
        <Error404Page />
      </PortalPageLayout>
    );
  }

  return (
    <RenewalPageLayout
      currentOrder={premise.currentOrder}
      currentOrderOfferSnapshot={currentOrderOfferSnapshot.data}
      exploreOtherOffersTitle={tRenewalsPageTranslationsExplore}
      handleSelectOfferSnapshot={handleSelectOfferSnapshot}
      otherOfferSnapshots={myAccountOfferSnapshots}
      premiseId={premise.id}
      renewalOfferSnapshot={oneClickRenewalOfferSnapshot}
      subtitle={tRenewalsPageTranslationsChoose}
      title={tRenewalsPageTranslationsTitle}
      estimatedSavings={potentialSavingsOneClickOffer}
    />
  );
};
