import { EdiActions } from "@common/constants/edi.constants";
import { CustomerStatus } from "@common/enums/customer.enum";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { EDIMessageType } from "@common/types/ediTypes";
import {
  AllSupportedPortalLanguages,
  SupportedPortalLanguage,
} from "@common/types/languageTypes";
import { MeterType } from "@common/types/meterTypes";
import { OrderType } from "@common/types/orderTypes";
import { PremiseListItemType, PremiseType } from "@common/types/premiseTypes";

export const MOVE_IN = "MOVE_IN";
export const SWITCH = "SWITCH";

export const AllEnrollmentTypes = [MOVE_IN, SWITCH] as const;

export type EnrollmentType = (typeof AllEnrollmentTypes)[number];

export type CustomerActivationStatusType = "PROVISIONED" | "ACTIVE";

export interface CustomerType {
  activationToken: string;
  activePremise: PremiseType;
  activePremiseAddress: AddressType;
  activePremiseId: IdType;
  autopay: boolean;
  averagedBilling: boolean;
  averagedBillingMonthlyCharge?: number;
  billingAccountId: string;
  billingAccountNumber: string;
  billingPaymentMethodId: string;
  confirmedStartDate?: string;
  dateOfBirth: string;
  ediMessages: EDIMessageType[];
  email: string;
  enrollmentStatus: string;
  enrollmentType: EnrollmentType;
  esiIds?: string[];
  etfAmount?: string;
  etfCreditAmountPoints: number;
  etfCreditStatus?: string;
  featureFlagUserId: string | null;
  firstName: string;
  fullName: string;
  hasPlacedOrder: boolean;
  id: IdType;
  invoiceByPrint: boolean;
  ironhideId?: IdType;
  isEmployee: boolean;
  languagePreference: SupportedPortalLanguage;
  lastName: string;
  lifeSupport: boolean;
  mailingAddress?: AddressFormType;
  meter: MeterType;
  oktaId: string;
  orders: OrderType[];
  phone: string;
  pickedBillingDueDay: number | null;
  premiseIds: IdType[];
  premises: PremiseListItemType[];
  prospectId?: IdType | null;
  referralToFriendCode: string;
  secondaryPhone: string | null;
  serviceEndDate: string;
  serviceStartDate: string;
  source: CustomerSourceType;
  status: CustomerStatus;
  uuid: UUIDType;
  zendeskUrl?: string;
}
export type CustomerUpdatePostDataType = Partial<CustomerType>;
export type CustomerListItemType = Pick<
  CustomerType,
  | "id"
  | "fullName"
  | "firstName"
  | "lastName"
  | "email"
  | "enrollmentStatus"
  | "enrollmentType"
  | "confirmedStartDate"
>;
export interface CustomerAuthenticationPostDataType {
  oktaId: string;
}

export type LanguagePreferenceType =
  (typeof AllSupportedPortalLanguages)[number];

export interface AddressFormType {
  addressLine1: string;
  city: string;
  state: string;
  unitNumber?: string;
  zipCode: string;
}

export interface AddressType {
  city: string;
  deliveryLine1: string;
  lastLine: string;
  state: string;
  zipCode: string;
}

export interface CustomerBillingPreferences {
  applyEtfPoints?: boolean;
  averagedBilling: boolean;
  etfAmount?: string;
  pickedBillingDueDay: number | null;
}

type EdiActionType = keyof Record<EdiActions, null>;

export type EDIServiceOrderActionTypes = EdiActionType;

export interface EDIMessagePostDataType {
  actionName: EdiActionType;
}

export type OktaIdTokenType = {
  authorizeUrl: string;
  claims: { email: string; groups: string[]; sub: string };
  clientId: string;
  expiresAt: number;
  idToken: string;
  issuer: string;
  scopes: string[];
};

// these strings match up with the SOURCE_CHOICES defined on the customer backend model
export enum CustomerSourceType {
  IRONHIDE = "IRONHIDE",
  RHYTHM = "RHYTHM",
}
