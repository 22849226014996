export const deviceDemandResponseActivityTableTranslations = {
  tDeviceDemandResponseActivityTableApproved: "Approved",
  tDeviceDemandResponseActivityTableCancelled: "Cancelled",
  tDeviceDemandResponseActivityTableDraft: "Pending Device Confirmation",
  tDeviceDemandResponseActivityTableDuration: "Duration",
  tDeviceDemandResponseActivityTableFinished: "Finished",
  tDeviceDemandResponseActivityTableOptedOut: "Opted Out",
  tDeviceDemandResponseActivityTablePhaseChanged: "Phase Changed",
  tDeviceDemandResponseActivityTableReady: "Ready",
  tDeviceDemandResponseActivityTableScheduled: "Scheduled",
  tDeviceDemandResponseActivityTableSent: "Sent",
  tDeviceDemandResponseActivityTableStartTime: "Start Time",
  tDeviceDemandResponseActivityTableStarted: "Started",
  tDeviceDemandResponseActivityTableStatus: "Status",
  tDeviceDemandResponseActivityTableStatusAndDate: "Date and Status",
  tDeviceDemandResponseActivityTableThermostatAdjustments:
    "Thermostat Adjustments",
};
