import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { TableCell, TableCellProps, TableRow } from "@mui/material";
import styled from "styled-components";

interface StyledTableCellProps extends TableCellProps {
  selected?: boolean;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  &.MuiTableCell-root {
    background-color: ${(props) =>
      props.selected ? muiTheme.palette.primary.main : trueWhite};
    border-radius: ${(props) => (props.selected ? "50%" : "0")};
    color: ${(props) => (props.selected ? trueWhite : grey[500])};
    font-size: 18px;
    padding: 9px 0;

    &:hover {
      background-color: ${(props) =>
        props.selected ? muiTheme.palette.primary.main : darkPurple[100]};
      border-radius: 50%;
      cursor: pointer;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    & td {
      border: none;
      height: ${rhSpacingPx(5.5)};

      @media (min-width: ${RhythmBreakpoints.SM}px) {
        height: ${rhSpacingPx(7)};
      }
    }

    &:nth-child(2n + 1) {
      background-color: ${trueWhite};
    }
  }
`;
