import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  CartSelectedPlanContainer,
  CartSelectedPlanTitle,
} from "@portal/components/CartSelectedPlan/CartSelectedPlan.style";
import { LegalDocuments } from "@portal/components/LegalDocuments/LegalDocuments";
import { OfferCharges } from "@portal/components/OfferCharges/OfferCharges";
import { OfferFeatures } from "@portal/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { signUpStepPath } from "@portal/routes/routePaths";
import { AnalyticsEvent } from "@portal/services/segment.service";
import React from "react";
import { NavLink } from "react-router-dom";

interface CartSelectedPlanProps {
  closeCart: () => void;
  isMobileCart?: boolean;
  offerSnapshot: OfferSnapshot;
}

export const CartSelectedPlan = ({
  offerSnapshot,
  isMobileCart = false,
  closeCart,
}: CartSelectedPlanProps) => {
  const { t } = useRhIntl();
  const { trackEvent } = useSignUpFlow();
  const trackDetailsEvent = (event: Partial<AnalyticsEvent>) => {
    trackEvent({ ...event, category: "onboarding.cart" });
  };

  return (
    <CartSelectedPlanContainer>
      <CartSelectedPlanTitle>
        <RhTypography
          variant={isMobileCart ? "h3" : "h2"}
          fontWeight={FontWeight.Regular}
        >
          {offerSnapshot.title}
        </RhTypography>
        <NavLink to={signUpStepPath("plans")} onClick={closeCart}>
          {t("CartSelectedPlan.changePlanText")}
        </NavLink>
      </CartSelectedPlanTitle>
      <div>
        <OfferRateAndEstimate offerSnapshot={offerSnapshot} inDialog />
      </div>
      <OfferFeatures offerSnapshot={offerSnapshot} />
      <OfferCharges offerSnapshot={offerSnapshot} />
      <div>
        <LegalDocuments
          trackEvent={trackDetailsEvent}
          offerSnapshot={offerSnapshot}
          flexDirection="column"
          inCart
        />
      </div>
    </CartSelectedPlanContainer>
  );
};
