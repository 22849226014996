import { RhButton } from "@design-system/components/RhButton/RhButton";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { grey } from "@design-system/theme/palette.colors";
import styled from "styled-components";

export const StyledTabletDownButton = styled(RhButton)`
  &.MuiButton-root {
    color: ${grey[600]};
    font-weight: ${FontWeight.Regular};
  }
`;
