import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { MiniOfferCard } from "@portal/components/OfferMiniChooser/MiniOfferCard";
import { RhOffersGrid } from "@portal/components/RhOffersGrid/RhOffersGrid";
import React from "react";

interface OfferMiniChooserProps {
  offerSnapshots: OfferSnapshotType[];
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshotType) => void;
}

export const OfferMiniChooser = ({
  offerSnapshots,
  onSelectOfferSnapshot: onSelect,
}: OfferMiniChooserProps) => {
  return (
    <RhOffersGrid offerCount={offerSnapshots.length} noPadding>
      {offerSnapshots.map((offerSnapshot) => (
        <MiniOfferCard
          key={`mini-offerSnapshot-card-${offerSnapshot.id}`}
          offerSnapshot={offerSnapshot}
          onSelect={onSelect}
        />
      ))}
    </RhOffersGrid>
  );
};
