import { borders } from "@design-system/constants/borders";
import { ReactComponent as UpArrow } from "@design-system/icons/UpArrow.svg";
import { trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledHeader = styled.header`
  border-bottom: ${borders[100]};
  margin-bottom: ${rhSpacingPx(2.5)};
  padding-bottom: ${rhSpacingPx(2.5)};
`;

export const MyAccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MyAccountNavLinksContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;

export const MyAccountSectionContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  max-width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex: 0 0 66.6%;
    max-width: 66.6%;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

export const StyledBackToTopContainer = styled.div`
  align-self: center;
  background-color: ${trueWhite};
  border-top: ${borders[100]};
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 2;
`;

export const StyledUpArrow = styled(UpArrow)`
  margin: 0 8px 5px 0;
`;
