import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { UUIDType } from "@common/types/apiTypes";
import { RhError } from "@common/types/errorTypes";
import { QuickPayExpiredToken } from "@portal/components/QuickPayExpiredToken/QuickPayExpiredToken";
import { Error404Page } from "@portal/pages/Error404Page/Error404Page";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import React from "react";

interface QuickPayErrorProps {
  error: RhError;
  token: UUIDType;
}

export const QuickPayError = (props: QuickPayErrorProps) => {
  const { error, token } = props;

  // Token does not exist
  if (error.status === HttpStatusCodes.HTTP_404_NOT_FOUND) {
    return <Error404Page />;
  }

  // Token is expired
  if (error.status === HttpStatusCodes.HTTP_410_GONE) {
    return <QuickPayExpiredToken token={token} />;
  }

  // Backend service not available
  if (error.status === HttpStatusCodes.HTTP_503_SERVICE_UNAVAILABLE) {
    return <Error500Page />;
  }

  // Generic 500 page
  return <Error500Page />;
};
