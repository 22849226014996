import { IdType, UUIDType } from "@common/types/apiTypes";

export const ProductContractUrls = {
  base: (premiseId: IdType) =>
    `/api/portal/premises/${premiseId}/product-contracts`,
  cancel: (premiseId: IdType) =>
    `${ProductContractUrls.base(premiseId)}/cancel`,
  claimOptions: (premiseId: IdType, productContractId: UUIDType) =>
    `${ProductContractUrls.base(premiseId)}/${productContractId}/claim-options`,
  claims: (premiseId: IdType) =>
    `${ProductContractUrls.base(premiseId)}/claims`,
  create: (premiseId: IdType) => `${ProductContractUrls.base(premiseId)}`,
  list: (premiseId: IdType) => ProductContractUrls.base(premiseId),
};

export const ProductUrls = {
  BASE: "/api/portal/product-tiers/" as const,
  list: () => ProductUrls.BASE,
};

export const QuickPayUrls = {
  BASE: "/api/portal/quick-pay" as const,
  addPaymentMethod: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/update-payment-method`,
  createPayment: (token: UUIDType) => `${QuickPayUrls.BASE}/${token}/payments`,
  getAccountBalance: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/get-account-balance`,
  getPaymentMethod: (token: UUIDType, paymentMethodId: string) =>
    `${QuickPayUrls.BASE}/${token}/payment-method/${paymentMethodId}`,
  getPaymentMethods: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/get-payment-methods`,
  resend: (token: UUIDType) => `${QuickPayUrls.BASE}/${token}/new-payment-link`,
};

export const GuestPayUrls = {
  BASE: "/api/portal/guest-pay" as const,
  accountSummaryByToken: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/account-summary`,
  accountSummaryLookup: () => `${GuestPayUrls.BASE}/account-summary`,
  createPayment: (token: string) => `${GuestPayUrls.BASE}/${token}/payments`,
  createPaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
  deletePaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
  getPaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
};

export const AcquisitionCampaignUrls = {
  retrieveBySlug: (slug: string) =>
    `/api/portal/acquisition-campaigns/${slug}/`,
};
