import { VALID_CURRENCY_AMOUNT } from "@common/constants/regex.constant";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  PortalRadioButton,
  PortalRadioLabel,
} from "@portal/components/PortalRadioButton/PortalRadioButton";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { GuestAndQuickPayFormValues } from "@portal/types/GuestAndQuickPayTypes";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

const CustomAmount = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  width: min-content;

  & > label {
    width: max-content;
  }
`;

const PaymentAmountContainer = styled.fieldset`
  align-items: start;
  border: unset;
  border-bottom: 1px solid ${grey["100"]};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  padding: unset;
  padding-bottom: ${rhSpacingPx(3)};

  ${PortalRadioLabel} {
    height: 22px;
  }
`;

const useGuestAndQuickPayPaymentAmountFieldsTranslations = () => {
  const { t } = useRhIntl();

  return {
    tCustomAmount: t("GuestAndQuickPayPaymentAmountFields.customAmount"),
    tInvalidDollarAmount: t(
      "GuestAndQuickPayPaymentAmountFields.invalidDollarAmount"
    ),
    tPastDueAmount: t("GuestAndQuickPayPaymentAmountFields.pastDueAmount"),
    tPayCustomAmount: t("GuestAndQuickPayPaymentAmountFields.payCustomAmount"),
    tPaymentAmount: t("GuestAndQuickPayPaymentAmountFields.paymentAmount"),
    tTotalAmount: t("GuestAndQuickPayPaymentAmountFields.totalAmount"),
  };
};

export const GuestAndQuickPayPaymentAmountFields = () => {
  const {
    tCustomAmount,
    tInvalidDollarAmount,
    tPastDueAmount,
    tPayCustomAmount,
    tPaymentAmount,
    tTotalAmount,
  } = useGuestAndQuickPayPaymentAmountFieldsTranslations();
  const { control, register, setValue } =
    useFormContext<GuestAndQuickPayFormValues>();

  const paymentType = useWatch({ control, name: "paymentType" });

  const clearCustomAmount = () => {
    setValue("customAmount", "");
  };

  return (
    <PaymentAmountContainer aria-label={tPaymentAmount}>
      <PortalRadioLabel>
        <PortalRadioButton
          type="radio"
          value="total"
          onClick={clearCustomAmount}
          {...register("paymentType")}
        />
        {tTotalAmount}
      </PortalRadioLabel>

      <PortalRadioLabel>
        <PortalRadioButton
          type="radio"
          value="pastDue"
          onClick={clearCustomAmount}
          {...register("paymentType")}
        />
        {tPastDueAmount}
      </PortalRadioLabel>

      <CustomAmount>
        <PortalRadioLabel>
          <PortalRadioButton
            type="radio"
            value="custom"
            {...register("paymentType")}
          />
          {tPayCustomAmount}
        </PortalRadioLabel>

        {paymentType === "custom" && (
          <Controller
            name="customAmount"
            rules={{
              pattern: {
                message: tInvalidDollarAmount,
                value: VALID_CURRENCY_AMOUNT,
              },
            }}
            render={({ field: { onChange, onBlur, name, ref } }) => (
              <RhTextInput
                placeholder="$1.00"
                name={name}
                aria-label={tCustomAmount}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
        )}
      </CustomAmount>
    </PaymentAmountContainer>
  );
};
