import { RhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { darkPurple } from "@design-system/theme/palette.colors";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const StyledOfferFilterTabs = styled(RhRoundTabs)`
  &.MuiTabs-root {
    & button {
      color: ${darkPurple.main};
      font-family: ${fontFamily};
      font-size: 16px;
      font-weight: ${FontWeight.Regular};
      letter-spacing: normal;
      text-transform: none;
      &.selected {
        background-color: transparent;
        font-weight: ${FontWeight.Semibold};
      }
    }
  }
`;
