export const balanceDetailsTranslations = {
  tBalanceDetailsBalanceDue: "Your balance is due {date}",
  tBalanceDetailsDisconnectionNotice:
    "To avoid possible service interruption, settle your balance by <date></date>",
  tBalanceDetailsNextBillExplainer: "Next bill of {amount} is due {date}",
  tBalanceDetailsNoBalanceDue:
    "Your balance is current. No payment due at this time.",
  tBalanceDetailsPastDue: "You have a past due balance",
  tBalanceDetailsPaymentExtension:
    "You currently have a Payment Extension set up on this account.",
  tBalanceDetailsPaymentExtensionDueDate:
    "Your Extension Due Date is {date} and your Extended Balance is shown below.",
  tBalanceDetailsSeeWhatFactoredIntoMyBill: "See what factored into my bill.",
};
