// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum CampaignSlugs {
  AddPremise = "IBTM",
  ContractSwap = "IBTM",
  Default = "default",
  InboundTelemarketing = "IBTM",
  InboundTelemarketingRenewal = "renewal-ibtm",
  OneClickRenewal = "renewal-oneclick",
  PowerToChoose = "power-to-choose",
  Renewal = "renewal-myaccount",
  SolarBuyback = "solar-buyback",
  SolarBuybackOneClickRenewal = "renewal-oneclick-rooftopSBB",
  SolarBuybackRenewal = "renewal-myaccount-TexasSolar",
  TransferOfService = "IBTM",
  Winback = "winback",
}
