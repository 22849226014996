import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsTimeOfUseTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTimeOfUse/OfferSnapshotFAQsTimeOfUse.en.i18n";
import {
  MultiAnswerContainer,
  TimeOfUseList,
} from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTimeOfUse/OfferSnapshotFAQsTimeOfUse.styled";
import { OfferSnapshotFAQsTitleWrapper } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

export const OfferSnapshotFAQsTimeOfUse = () => {
  const { translate } = useTranslations();
  const {
    tOfferSnapshotFAQsTimeOfUsWhoBenefitsAnswer,
    tOfferSnapshotFAQsTimeOfUsWhoBenefitsQuestion,
    tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyAnswer,
    tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyQuestion,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOffPeak,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOnPeak,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerSeasonalPeak,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerUltraOffPeak,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksQuestion,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenToReduceUsage,
    tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenYouCanGoNuts,
    tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanAnswer,
    tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanQuestion,
    tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownAnswer,
    tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownQuestion,
    tOfferSnapshotFAQsTimeOfUseTitle,
  } = translate(offerSnapshotFAQsTimeOfUseTranslations);

  return (
    <OfferSnapshotFAQsTitleWrapper title={tOfferSnapshotFAQsTimeOfUseTitle}>
      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsTimeOfUseWhatArePeaksQuestion}</summary>
        <MultiAnswerContainer>
          <RhTypography fontWeight="Bold">
            {tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenToReduceUsage}
          </RhTypography>
          <TimeOfUseList>
            <li>
              <RhTypography>
                {tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOnPeak}
              </RhTypography>
            </li>
            <li>
              <RhTypography>
                {tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerSeasonalPeak}
              </RhTypography>
            </li>
          </TimeOfUseList>
          <RhTypography fontWeight="Bold">
            {tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenYouCanGoNuts}
          </RhTypography>
          <TimeOfUseList>
            <li>
              <RhTypography>
                {tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOffPeak}
              </RhTypography>
            </li>
            <li>
              <RhTypography>
                {tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerUltraOffPeak}
              </RhTypography>
            </li>
          </TimeOfUseList>
        </MultiAnswerContainer>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyQuestion}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsTimeOfUsWhoBenefitsQuestion}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsTimeOfUsWhoBenefitsAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownQuestion}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownAnswer}
        </RhTypography>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
