import { LONG_FRIENDLY_DATE_FORMAT } from "@common/constants/date.constant";
import { ReactComponent as Share } from "@design-system/icons/Share.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { competitorPlanComparisonPlansModalTranslations } from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.en.i18n";
import {
  EFLCell,
  PlanName,
  PlanNameWrapper,
  PlanPrice,
  PlanPricePerKwh,
  PlanRow,
  PlanUpdatedOn,
  PriceCell,
  ProviderLogoCell,
} from "@portal/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal.styled";
import { renderLogo } from "@portal/components/RepPriceComparisonChart/renderLogo";
import { useTranslations } from "@portal/hooks/useTranslations";
import { CompetitorPlan } from "@portal/types/competitorTypes";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";
import { useMediaQuery } from "usehooks-ts";

const EFLLink = ({
  plan,
  children,
}: PropsWithChildren<{ plan: CompetitorPlan }>) => {
  const { eflUrl, planName } = plan;

  return (
    <a
      href={eflUrl}
      target="_blank"
      rel="noreferrer"
      data-tracking-click={{
        competitorPlanId: plan.id,
        event: "Competitor EFL link clicked",
      }}
      title={`EFL for ${planName}`}
      aria-label={`EFL for ${planName}`}
    >
      {children}
    </a>
  );
};

interface CompetitorPlanRowProps {
  plan: CompetitorPlan;
}
export const CompetitorPlanRow = (props: CompetitorPlanRowProps) => {
  const { plan } = props;
  const isMobile = useMediaQuery(`(max-width: ${RhythmBreakpoints.XS}px)`);

  const { translate, translateJsx } = useTranslations();

  const { tCompetitorPlanComparisonPlansModalPerKwh } = translate(
    competitorPlanComparisonPlansModalTranslations
  );

  const { tCompetitorPlanComparisonPlansModalLastUpdated } = translateJsx({
    tCompetitorPlanComparisonPlansModalLastUpdated: {
      date: dayjs(plan.parsedDatetime).format(LONG_FRIENDLY_DATE_FORMAT),
    },
  });

  return (
    <PlanRow>
      <ProviderLogoCell>{renderLogo(plan.competitorName)}</ProviderLogoCell>
      <PlanNameWrapper>
        {isMobile ? (
          <EFLLink plan={plan}>
            <PlanName>{plan.planName}</PlanName>
          </EFLLink>
        ) : (
          <PlanName>{plan.planName}</PlanName>
        )}
        <PlanUpdatedOn>
          {tCompetitorPlanComparisonPlansModalLastUpdated}
        </PlanUpdatedOn>
      </PlanNameWrapper>
      <PriceCell>
        <PlanPrice>{Number(plan.price2000Kwh).toFixed(1)}¢</PlanPrice>
        <PlanPricePerKwh>
          {tCompetitorPlanComparisonPlansModalPerKwh}
        </PlanPricePerKwh>
      </PriceCell>
      <EFLCell>
        <EFLLink plan={plan}>
          <Share aria-hidden />
        </EFLLink>
      </EFLCell>
    </PlanRow>
  );
};
