import { api } from "@common/api/api";
import { ediApi } from "@common/api/ediApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { UtilityType } from "@common/types/ediTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRadioCardInput } from "@design-system/components/RhRadioCardInput/RhRadioCardInput";
import { Box, RadioGroup } from "@mui/material";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpChooseUtilityPageTranslations } from "@portal/pages/SignUpChooseUtilityPage/SignUpChooseUtilityPage.en.i18n";
import { SignUpPremiseFormValues } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { signUpStepPath } from "@portal/routes/routePaths";
import {
  selectSignUpPremiseFormValues,
  selectSignUpState,
} from "@portal/selectors/signUpSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { Show500Page } from "@portal/utils/errors";
import React, { useEffect } from "react";
import { Form, useField } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SignUpChooseUtilityFormValues {
  dunsNumber: string;
}

interface UtilityChoiceFieldProps {
  label: string;
  value: string;
}

export const UtilityChoiceField = ({
  label,
  value,
}: UtilityChoiceFieldProps) => {
  const { input } = useField<string>("dunsNumber", {
    type: "radio",
    value,
  });

  return (
    <RhRadioCardInput
      inputId={`utility-choice-${value}`}
      label={label}
      {...input}
    />
  );
};

const signUpChooseUtilityValidator =
  generateValidationErrorCollector<SignUpChooseUtilityFormValues>({
    dunsNumber: [isRequired],
  });

export const SignUpChooseUtilityPage = () => {
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const navigate = useNavigate();
  const { serviceAddress } = useSelector<
    PortalStoreState,
    SignUpPremiseFormValues
  >(selectSignUpPremiseFormValues);

  const { translate } = useTranslations();
  const {
    tSignUpChooseUtilityPageNotSure,
    tSignUpChooseUtilityPagePleaseSelectAUtility,
    tSignUpChooseUtilityPageSubmitCta,
  } = translate(signUpChooseUtilityPageTranslations);

  const initialValues = {
    dunsNumber: undefined,
  };
  const { campaignSlug } = useSelector(selectSignUpState);

  const onSubmit = ({ dunsNumber }: SignUpChooseUtilityFormValues) => {
    return api.pricing.offerSnapshots
      .search(dunsNumber, campaignSlug ?? "")
      .then(() => {
        signUpClickNextStepHandler({
          nextStep: "plans",
          signUpData: { dunsNumber },
          track: true,
        });
      })
      .catch(() => {
        signUpClickNextStepHandler({
          nextStep: "updates",
          signUpData: { dunsNumber },
          track: true,
        });
      });
  };

  const [availableUtilities, { setSuccess, setPending, setFailure }] =
    useAjaxState<UtilityType[]>();

  useEffect(() => {
    setPending();
    ediApi.utilities
      .search({ zipCode: serviceAddress.zipCode })
      .then(setSuccess)
      .catch((err) => {
        setFailure(err);
      });
  }, [serviceAddress.zipCode]);

  if (availableUtilities.requestMonitor.isPending) {
    return <RhCircularProgress />;
  }
  if (availableUtilities.requestMonitor.didFail) {
    throw new Show500Page(
      `Could not fetch available utilities: ${availableUtilities.error?.data?.errorCode}`
    );
  }

  return (
    <SignUpPageLayout
      handleBackButtonClick={() => {
        navigate(signUpStepPath("availability"));
      }}
    >
      <LoggedOutPageHeader
        headerText={tSignUpChooseUtilityPagePleaseSelectAUtility}
        subHeaderText={tSignUpChooseUtilityPageNotSure}
      />

      <Form<SignUpChooseUtilityFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={signUpChooseUtilityValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpChooseUtilityPageSubmitCta}
          >
            <Box marginTop={6} marginBottom={5}>
              <RadioGroup>
                {(availableUtilities.data ?? []).map(({ dunsNumber, name }) => (
                  <UtilityChoiceField
                    key={`utility-${dunsNumber}`}
                    label={name}
                    value={dunsNumber}
                  />
                ))}
              </RadioGroup>
            </Box>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
