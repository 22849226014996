import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { DisclaimerContainer } from "@portal/components/SignUpOfferDisclaimer/SignUpOfferDisclaimer.styled";
import React, { CSSProperties } from "react";

interface SignUpOfferDisclaimerProps {
  disclaimer: string;
  textAlign?: CSSProperties["textAlign"];
}

export const SignUpOfferDisclaimer = ({
  disclaimer,
  textAlign = "center",
}: SignUpOfferDisclaimerProps) => {
  return (
    <DisclaimerContainer textAlign={textAlign}>
      <RhTypography
        variant="body1"
        color="textSecondary"
        style={{ fontSize: "0.75rem" }}
      >
        {disclaimer}
      </RhTypography>
    </DisclaimerContainer>
  );
};
