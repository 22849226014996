import { documentHistoryTableTranslations } from "@portal/components/DocumentHistoryTable/DocumentHistoryTable.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import { DocumentType } from "@portal/types/documentHistoryTypes";

export const useDocumentTypeNames = () => {
  const { translate } = useTranslations();

  const {
    tDocumentHistoryTableContractExpiration,
    tDocumentHistoryTableDeferredPayment,
    tDocumentHistoryTableDemandLetter,
    tDocumentHistoryTableDisconnectionNotice,
    tDocumentHistoryTableWelcomeKit,
  } = translate(documentHistoryTableTranslations);

  return {
    [DocumentType.ContractExpiration]: tDocumentHistoryTableContractExpiration,
    [DocumentType.DeferredPayment]: tDocumentHistoryTableDeferredPayment,
    [DocumentType.DemandLetter]: tDocumentHistoryTableDemandLetter,
    [DocumentType.DisconnectionNotice]:
      tDocumentHistoryTableDisconnectionNotice,
    [DocumentType.WelcomeKit]: tDocumentHistoryTableWelcomeKit,
  };
};
