import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledAreaNotServiceableModal = styled.div`
  padding: 2rem;
`;

export const AreaNotServiceableInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
`;

export const AreaNotServiceableModalTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin: auto;
    max-width: 350px;
  }
`;

export const AreaNotServiceableButton = styled(RhButton)`
  &.MuiButton-root {
    margin-top: ${rhSpacingPx(3)};
    pointer-events: auto;
  }
`;

export const AreaNotServiceableSubtitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ${rhSpacingPx(2.5)} 0;
`;

export const AreaNotServiceableModalSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey[500]};
  }
`;

export const OrSeparator = styled.span`
  color: ${grey["300"]};
  font-size: 14px;
  margin: 1rem auto;
`;
