import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { ReactComponent as CopyIcon } from "@design-system/icons/CopyIcon.svg";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Input } from "@mui/material";
import styled from "styled-components";

interface ContainerProps {
  $dense: boolean;
}

export const StyledCopyReferralContainer = styled(RhFlexBox)<ContainerProps>`
  display: flex;
  ${({ $dense }) =>
    $dense
      ? `
        align-items: center;
        flex-direction: column;
      `
      : `
        flex-direction: row;
        justify-content: space-between;
       
        @media (max-width: ${RhythmBreakpoints.SM}px) {
            align-items: center;
            flex-direction: column;
        }
      `}
`;

interface InputProps {
  $dense: boolean;
  $hideUrl: boolean;
}

export const StyledCopyReferralInput = styled(Input)<InputProps>`
  border: 1px solid ${grey[200]};
  border-radius: 4px;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.04);
  color: ${darkPurple.main};
  font-family: ${fontFamily};
  padding: 0.25rem 0.75rem 0.15rem;

  & .MuiInput-input {
    font-family: ${fontFamily};
    font-size: 16px;
    margin-bottom: ${rhSpacingPx(0.4)};
    margin-top: ${rhSpacingPx(0.4)};
  }

  ${({ $dense }) =>
    $dense
      ? `
      margin-right: 0;
      width: 100%;
      `
      : `
      margin-right: ${rhSpacingPx(2)};
      width: 100%;
       
        @media (max-width: ${RhythmBreakpoints.SM}px) {
            margin-right: 0;
            width: 100%;
        }
      `}
  ${({ $hideUrl }) =>
    $hideUrl
      ? `
      height: 0;
      opacity: 0;
      user-select: none;
      width: 0;
      `
      : ""}
`;

interface ButtonProps {
  $dense: boolean;
}

export const StyledCopyReferralButton = styled(RhButton)<ButtonProps>`
  &.MuiButton-root {
    display: flex;
    ${({ $dense }) =>
      $dense
        ? `
      margin: ${rhSpacingPx(2)} 0 0 0;
      width: 100%;
      `
        : `
      margin-left: auto;
      width: 200px;
       
        @media (max-width: ${RhythmBreakpoints.SM}px) {
            margin: ${rhSpacingPx(2)} 0 0 0;
            width: 100%;
        }
      `}
  }
`;

export const StyledCopyReferralCopyIcon = styled(CopyIcon)`
  margin-right: ${rhSpacingPx(1)};
`;
