import { portalApi } from "@portal/api/api";
import { QuickPayQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useResendQuickPayToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: portalApi.quickPay.resend,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: QuickPayQueryKeys.BASE,
      });
    },
  });
};
