import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhModalDialog } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { darkPurple, grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledRenewalReminderModal = styled(RhModal)`
  ${RhModalDialog} {
    max-width: 465px;
    padding: 0;
  }
`;

export const RenewalReminderTitle = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
    text-wrap: balance;
  }
`;

export const RenewalReminderPurpleText = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${darkPurple["500"]};
    text-align: center;
  }
`;

export const RenewalReminderWhyRhythm = styled(RenewalReminderPurpleText)``;

export const RenewalReminderTitleContainer = styled.div`
  background-color: ${darkPurple["50"]};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(2)};
  text-align: center;
  text-wrap: balance;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    gap: ${rhSpacingPx(2)};
    min-height: 110px;
    padding: ${rhSpacingPx(4)};
    position: relative;
    width: 100%;
  }
`;

export const RenewalReminderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    gap: ${rhSpacingPx(2)};
    padding: ${rhSpacingPx(4)};
  }
`;

export const RenewalReminderPaperButton = styled.button`
  background: none;
  border: none;
  color: ${grey[500]};
  cursor: pointer;

  margin: ${rhSpacingPx(1)} 0;
  padding: 0;
  text-decoration: underline;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-top: 0;
  }
`;

export const RenewalReminderReasonsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};

  & > li {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${rhSpacingPx(3)};

    & > svg {
      height: 80px;
      width: 80px;
    }

    & > span {
      flex-basis: 75%;
    }
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    gap: ${rhSpacingPx(2)};
    margin: ${rhSpacingPx(2)} 0 ${rhSpacingPx(3)} 0;
  }
`;
