import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalPlacement } from "@design-system/components/RhModal/RhModal.styled";
import { cartDialogTranslations } from "@portal/components/CartDialog/CartDialog.en.i18n";
import {
  CartButtonContainer,
  CartContainer,
  CartItems,
} from "@portal/components/CartDialog/CartDialog.style";
import { CartSelectedPlan } from "@portal/components/CartSelectedPlan/CartSelectedPlan";
import { CartSelectedProductTiers } from "@portal/components/CartSelectedProductTiers/CartSelectedProductTiers";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ProductTier } from "@portal/models/ProductTier.model";
import React from "react";

export interface CartDialogProps {
  closeCart: () => void;
  dialogPlacement: ModalPlacement;
  hideCartCloseButton?: boolean;
  hideModalCloseButton?: boolean;
  isMobileCart?: boolean;
  offerSnapshot: OfferSnapshot | null;
  selectedProductTiers?: ProductTier[];
  showPortalFooter?: boolean;
}

export const CartDialog = ({
  closeCart,
  dialogPlacement,
  hideCartCloseButton = false,
  hideModalCloseButton,
  isMobileCart = false,
  offerSnapshot,
  selectedProductTiers = [],
  showPortalFooter = false,
}: CartDialogProps) => {
  const { translate } = useTranslations();

  const { tCartDialogCloseButtonText } = translate(cartDialogTranslations);

  return (
    <RhModal
      ariaCloseLabel="Close Cart"
      ariaLabel="Cart"
      handleClose={closeCart}
      hideCloseButton={hideModalCloseButton}
      modalId="rhCart"
      noPadding
      placement={dialogPlacement}
      showPortalFooter={showPortalFooter}
      showPortalHeader
    >
      <CartContainer>
        <CartItems $isMobileCart={isMobileCart}>
          {offerSnapshot && (
            <CartSelectedPlan
              closeCart={closeCart}
              isMobileCart={isMobileCart}
              offerSnapshot={offerSnapshot}
            />
          )}
          {selectedProductTiers.length > 0 && (
            <CartSelectedProductTiers
              closeCart={closeCart}
              selectedProductTiers={selectedProductTiers}
            />
          )}
        </CartItems>
        {!hideCartCloseButton && (
          <CartButtonContainer>
            <RhButton
              data-tracking-click={{ event: "Prospect closed shopping cart" }}
              color="primary"
              onClick={closeCart}
            >
              {tCartDialogCloseButtonText}
            </RhButton>
          </CartButtonContainer>
        )}
      </CartContainer>
    </RhModal>
  );
};
