import {
  LearnMoreContainer,
  LearnMoreFooter,
  LeftColumnContainer,
  OfferSnapshotLearnMoreHeader,
  SideBar,
} from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import React from "react";
import styled from "styled-components";

const ExtendedSkeleton = styled(Skeleton)`
  margin: 16px 0;
`;

export const OfferSnapshotLearnMoreSkeleton = () => {
  return (
    <LearnMoreContainer>
      <OfferSnapshotLearnMoreHeader>
        <Skeleton />
        <Skeleton />
      </OfferSnapshotLearnMoreHeader>

      <SideBar>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SideBar>

      {/* Add content here for left column before FAQs */}
      <LeftColumnContainer>
        <ExtendedSkeleton />
        <Skeleton />
        <Skeleton />
        <ExtendedSkeleton />
        <ExtendedSkeleton />
        <Skeleton />
        <Skeleton />
      </LeftColumnContainer>
      <Skeleton />
      <Skeleton />

      <Skeleton />
      <Skeleton />
      <LearnMoreFooter>
        <Skeleton />
        <ExtendedSkeleton />
        <Skeleton />
      </LearnMoreFooter>
    </LearnMoreContainer>
  );
};
