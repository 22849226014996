import { api } from "@common/api/api";
import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidZipCode } from "@common/forms/validators";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { AddressFormType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { getSignUpQueryParams } from "@portal/hooks/useSignUpQueryParams";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  availabilityErrorPaths,
  serviceUnavailableErrorPaths,
} from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage.constants";
import { signUpAvailabilityPageTranslations } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage.en.i18n";
import {
  selectSignUpAvailabilityFormValues,
  selectSignUpState,
} from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const signUpAvailabilityFormValidator =
  generateValidationErrorCollector<SignUpAvailabilityFormValues>({
    zipCode: [isRequired, isValidZipCode],
  });

export interface SignUpAvailabilityFormValues {
  zipCode: AddressFormType["zipCode"];
}

export const SignUpAvailabilityPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { campaignSlug } = useSelector(selectSignUpState);
  const flash = useRhFlash();
  const { featureFlagClient } = useFeatureFlagClient();
  const { search } = useLocation();

  const { translate } = useTranslations();

  const {
    tSignUpAvailabilityPageErrorFindingAvailability,
    tSignUpAvailabilityPageFindOut,
    tSignUpAvailabilityPageSubmitCTA,
    tSignUpAvailabilityPageZipCode,
    tSignUpAvailabilityPageZipCodePlaceholder,
  } = translate(signUpAvailabilityPageTranslations);

  const { rcid } = getSignUpQueryParams(search);

  const trackZipCodeFocus = () =>
    trackEvent({
      action: ActionType.focus,
      label: "zipCodeEntry",
    });

  const currentValues = useSelector<
    PortalStoreState,
    SignUpAvailabilityFormValues
  >(selectSignUpAvailabilityFormValues);

  const onSubmit = ({ zipCode }: SignUpAvailabilityFormValues) => {
    const serviceAddress = {
      addressLine1: "",
      city: "",
      state: "",
      unitNumber: "",
      zipCode: zipCode ?? "",
    };

    return api.pricing.offerSnapshots
      .availability({ zipCode })
      .then(({ dunsNumber }) => {
        signUpClickNextStepHandler({
          nextStep: "plans",
          signUpData: {
            areaNotCovered: false,
            campaignSlug: campaignSlug ?? rcid ?? CampaignSlugs.Default,
            dunsNumber,
            serviceAddress,
          },
          track: true,
        });

        track({
          action: ActionType.portalZipCodeSubmitted,
          category: CategoryType.Availability,
          event: EventType.enrollmentClick,
          label: LabelType.ActiveServiceArea,
          value: serviceAddress.zipCode,
          zipCode: serviceAddress.zipCode,
        });

        featureFlagClient.trackMetric(
          FeatureFlagMetric.PortalProspectZipCodeSubmitted
        );
      })
      .catch((error: RhApiError) => {
        const errorCode = error?.data.errorCode;

        if (errorCode && availabilityErrorPaths[errorCode]) {
          const areaNotCovered = Object.keys(
            serviceUnavailableErrorPaths
          ).includes(errorCode);

          if (areaNotCovered) {
            track({
              action: ActionType.portalZipCodeSubmitted,
              category: CategoryType.Availability,
              event: EventType.enrollmentClick,
              label: LabelType.InvalidServiceArea,
              value: serviceAddress.zipCode,
            });
          }

          signUpClickNextStepHandler({
            nextStep: availabilityErrorPaths[errorCode],
            signUpData: {
              areaNotCovered,
              serviceAddress,
            },
            track: true,
          });
        } else if (error) {
          flash.error(tSignUpAvailabilityPageErrorFindingAvailability);
        }

        return {
          [FORM_ERROR]: [tSignUpAvailabilityPageErrorFindingAvailability],
        };
      });
  };

  return (
    <SignUpPageLayout hideBackButton>
      <LoggedOutPageHeader headerText={tSignUpAvailabilityPageFindOut} />
      <Form<SignUpAvailabilityFormValues>
        initialValues={currentValues}
        onSubmit={onSubmit}
        validate={signUpAvailabilityFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpAvailabilityPageSubmitCTA}
          >
            <LoggedOutFieldsLayout>
              <RhTextField
                name="zipCode"
                autoComplete="postal-code"
                inputMode="numeric"
                placeholder={tSignUpAvailabilityPageZipCodePlaceholder}
                type="number"
                InputProps={{ onFocus: trackZipCodeFocus }}
              >
                {tSignUpAvailabilityPageZipCode}
              </RhTextField>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
