import { EnvTypes } from "@common/enums/envTypes.enum";
import { rhWindow } from "@common/utils/rhWindow";

export function getEnv(): EnvTypes {
  if (import.meta.env.VITE_CONTRACT_API_ENV) {
    return import.meta.env.VITE_CONTRACT_API_ENV as EnvTypes;
  }

  const { host } = rhWindow.location;

  if (host.endsWith("staging.gotrhythm.com")) {
    return EnvTypes.staging;
  }
  // Handle production after other environments or else test and staging will return as Production.
  if (host.endsWith(".gotrhythm.com")) {
    return EnvTypes.production;
  }
  // Run local frontend with the staging backend: `VITE_LOCAL_STAGING=true && nx serve <app>`
  if (import.meta.env.VITE_LOCAL_STAGING === "true") {
    return EnvTypes.localStaging;
  }

  return EnvTypes.local;
}

export function isLocal(): boolean {
  return getEnv() === EnvTypes.local || getEnv() === EnvTypes.localStaging;
}

export function isStaging(): boolean {
  return getEnv() === EnvTypes.staging;
}

export function isProduction(): boolean {
  return getEnv() === EnvTypes.production;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnvApplicationSettings<T = any>(
  allEnvApplicationSettings: Record<EnvTypes, T>
): T {
  const env = getEnv();

  return allEnvApplicationSettings[env];
}
