import { DUE_DATE_FORMAT } from "@common/constants/date.constant";
import { PremiseStatus } from "@common/enums/premise.enum";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { useModalState } from "@common/hooks/useModalState";
import {
  DefaultGlobalAnnouncement,
  GlobalAnnouncementTypes,
} from "@common/types/globalAnnouncementTypes";
import {
  SupportedPortalLanguage,
  spanishLanguageCode,
} from "@common/types/languageTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { globalAnnouncementTranslations } from "@portal/components/GlobalAnnouncement/GlobalAnnouncement.en.i18n";
import { NewContractModal } from "@portal/components/NewContractModal/NewContractModal";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import dayjs from "dayjs";
import parse from "html-react-parser";
import React, { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FutureOrderContainer = styled.div`
  text-wrap: balance;
`;

export const GlobalAnnouncement = () => {
  const { translate, translateJsx } = useTranslations();

  const { tGlobalAnnouncementInactivePremise } = translate(
    globalAnnouncementTranslations
  );

  const { announceNotice, announceInfo, announceError } = useRhAnnouncement();
  const { premise } = usePremise();
  const {
    isOpen: isNewContractModalOpen,
    open: openNewContractModal,
    close: closeNewContractModal,
  } = useModalState(false);
  const { featureFlagClient } = useFeatureFlagClient();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const { portalGlobalAnnouncement } = featureFlagClient.getFlags([
    ["portalGlobalAnnouncement", {}],
  ]);

  useEffect(() => {
    if (premise?.futureOrder && !premise?.futureOrder?.isMonthToMonth) {
      const orderStartDate = dayjs(premise.futureOrder.startDate).format(
        DUE_DATE_FORMAT
      );

      const { tGlobalAnnouncementFutureOrder } = translateJsx({
        tGlobalAnnouncementFutureOrder: {
          link: (message: ReactNode[]) => (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();

                openNewContractModal();
              }}
            >
              {message}
            </a>
          ),
          orderStartDate,
        },
      });

      announceNotice(
        <FutureOrderContainer>
          {tGlobalAnnouncementFutureOrder}
        </FutureOrderContainer>,
        true
      );
    } else if (premise?.status === PremiseStatus.INACTIVE) {
      announceError(tGlobalAnnouncementInactivePremise);
    } else if (
      portalGlobalAnnouncement &&
      Object.keys(portalGlobalAnnouncement.value).length
    ) {
      const announcement =
        portalGlobalAnnouncement.value as DefaultGlobalAnnouncement;

      let text = announcement.textEn;

      if (locale === spanishLanguageCode) {
        text = announcement.textEs;
      }

      const announcementType = (
        content: JSX.Element | React.ReactNode,
        dismissible?: boolean | undefined
      ) => {
        switch (announcement.type) {
          case GlobalAnnouncementTypes.Error:
            return announceError(content, dismissible);
          case GlobalAnnouncementTypes.Info:
            return announceInfo(content, dismissible);
          default:
            return announceNotice(content, dismissible);
        }
      };

      const notExpired = announcement.expires
        ? dayjs().isBefore(dayjs(announcement.expires))
        : true;

      if (notExpired) {
        announcementType(
          <div>{parse(text)}</div>,
          announcement?.dismissible ?? false
        );
      }
    }
  }, [
    announceError,
    announceNotice,
    announceInfo,
    premise?.futureOrder,
    premise?.status,
    portalGlobalAnnouncement.value,
    locale,
  ]);

  return isNewContractModalOpen ? (
    <NewContractModal onClose={closeNewContractModal} />
  ) : null;
};
