import { formatCurrency } from "@common/utils/dataFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { red } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { googleAnalyticsDataAtom } from "@portal/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { GuestPayForm } from "@portal/components/GuestPayForm/GuestPayForm";
import { useGuestPayAccountBalanceByToken } from "@portal/hooks/queries/useGuestPayAccountBalanceByToken";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { guestPayBasePath } from "@portal/routes/routePaths";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

interface GuestPayAccountSummaryProps {}

const StyledGuestPayAccountSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2.5)};
`;

const StyledTitle = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
  }
`;

const StyledSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
  }
`;

const BalanceRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const PastDueBalance = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${red.main};
    font-size: 0.8rem;
  }
`;

const PastDueTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 0.8rem;
  }
`;

const AmountDueTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 1.25em;
  }
`;

const AmountDueBalance = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 1.75em;
    font-weight: bold;
  }
`;

export const GuestPayAccountSummary = (props: GuestPayAccountSummaryProps) => {
  const { t } = useRhIntl();
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);

  const tTitle = t("GuestPayAccountSummary.title");
  const tSubTitle = t("GuestPayAccountSummary.subTitle");
  const tAmountDueLabel = t("GuestPayAccountSummary.amountDueLabel");
  const tPastDueLabel = t("GuestPayAccountSummary.pastDueLabel");
  const tUnableToCollect = t("GuestPayAccountSummary.unableToCollect");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paramToken = searchParams.get("token");

  const token = paramToken ?? "";

  const accountSummaryQuery = useGuestPayAccountBalanceByToken({ token });

  useEffect(() => {
    if (!paramToken || accountSummaryQuery.isError) {
      navigate(guestPayBasePath());
    }
  }, [navigate, paramToken, accountSummaryQuery.isError]);

  useEffect(() => {
    if (accountSummaryQuery.data) {
      setGoogleAnalyticsDataAtom({
        customerUuid: accountSummaryQuery.data.customerUuid,
        premiseUuid: accountSummaryQuery.data.premiseUuid,
        type: "tokenAuth",
      });
    }
  }, [accountSummaryQuery.data, setGoogleAnalyticsDataAtom]);

  if (accountSummaryQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (accountSummaryQuery.isError) {
    return null;
  }

  const summary = accountSummaryQuery.data;

  return (
    <StyledGuestPayAccountSummary>
      <StyledTitle variant="h1">{tTitle}</StyledTitle>
      <StyledSubtitle variant="subtitle2">{tSubTitle}</StyledSubtitle>
      <RhDivider light />
      <BalanceRow>
        <AmountDueTitle>{tAmountDueLabel}:</AmountDueTitle>
        <AmountDueBalance variant="body1">
          {formatCurrency(summary.totalBalance)}
        </AmountDueBalance>
      </BalanceRow>
      <RhDivider light />
      <BalanceRow>
        <PastDueTitle variant="body1">{tPastDueLabel}:</PastDueTitle>
        <PastDueBalance>
          {formatCurrency(summary.pastDueBalance)}
        </PastDueBalance>
      </BalanceRow>
      <RhDivider light />
      {summary.isNoCheckOrAch && summary.isNoCreditOrDebitCard ? (
        <RhTypography variant="body1">{tUnableToCollect}</RhTypography>
      ) : (
        <GuestPayForm
          token={token}
          totalBalance={summary.totalBalance}
          pastDueBalance={summary.pastDueBalance}
          isNoCheckOrAch={summary.isNoCheckOrAch}
          isNoCreditOrDebitCard={summary.isNoCreditOrDebitCard}
        />
      )}
    </StyledGuestPayAccountSummary>
  );
};
