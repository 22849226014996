import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { offerSnapshotsUrls } from "@portal/api/offerSnapshotsUrls";
import { OfferSnapshotsSearchOptions } from "@portal/types/offerSnapshotTypes";

export const offerSnapshotsApi = {
  list: (
    params: OfferSnapshotsSearchOptions
  ): Promise<PaginationEndpointResponseType<OfferSnapshotType>> => {
    return ajax.get(offerSnapshotsUrls.list(), {
      params,
    });
  },
};
