import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { MARKETING_RHYTHM_URL } from "@portal/constants/urls.constant";
import { AppSource } from "@portal/enums/QueryParamValues.enum";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpPlansPageHeaderTranslations } from "@portal/pages/SignUpPlansPage/SignUpPlansPageHeader/SignUpPlansPageHeader.en.i18n";
import {
  SignUpPlansPageHeaderContainer,
  StyledHeader,
} from "@portal/pages/SignUpPlansPage/SignUpPlansPageHeader/SignUpPlansPageHeader.styled";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";

export const SignUpPlansPageHeader = () => {
  const { appSource } = useSelector(selectSignUpState);

  const comesFromEnergyTexas = appSource === AppSource.MarketingEnergyTexas;

  const { translate, translateJsx } = useTranslations();

  const {
    tSignUpPlansPageHeaderEnergyTexasDisclaimer,
    tSignUpPlansPageHeaderTitle,
  } = translate(signUpPlansPageHeaderTranslations);
  const { tSignUpPlansPageHeaderSubtitle } = translateJsx({
    tSignUpPlansPageHeaderSubtitle: {
      link: (text: string) => (
        <a
          href={`${MARKETING_RHYTHM_URL}/about`}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ),
    },
  });

  return (
    <SignUpPlansPageHeaderContainer>
      <StyledHeader>
        {comesFromEnergyTexas ? (
          <RhTypography color="textSecondary">
            {tSignUpPlansPageHeaderEnergyTexasDisclaimer}
          </RhTypography>
        ) : null}
        <RhTypography variant="h1">{tSignUpPlansPageHeaderTitle}</RhTypography>
        <RhTypography variant="subtitle1">
          {tSignUpPlansPageHeaderSubtitle}
        </RhTypography>
      </StyledHeader>
    </SignUpPlansPageHeaderContainer>
  );
};
