import { darkPurple } from "@design-system/theme/palette.colors";
import styled, { css } from "styled-components";

const cssVars = css`
  --purple: ${darkPurple["500"]};
  --lightPurple: ${darkPurple["300"]};
`;

export const RhStyledLinkButton = styled.button`
  ${cssVars}
  background: transparent;
  border: 0;
  color: var(--purple);
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: var(--lightPurple);
    text-decoration: underline;
  }
`;
