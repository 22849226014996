import {
  LoaderContainer,
  LoaderWrapper,
} from "@portal/components/Loader/Loader.styled";
import { useLoader } from "@portal/components/Loader/useLoader";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import bike from "@portal/images/bike-loader.lottie.json";
import Lottie from "lottie-react";
import React from "react";

export const Loader = () => {
  const { t } = useRhIntl();
  const { isLoading } = useLoader();

  const title = t("Loader.bike");

  if (!isLoading) {
    return null;
  }

  return (
    <LoaderContainer role="progressbar">
      <LoaderWrapper>
        <Lottie
          title={title}
          animationData={bike}
          autoplay
          loop
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        />
      </LoaderWrapper>
    </LoaderContainer>
  );
};
