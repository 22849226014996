import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { paymentExtensionUnableToCollectTranslations } from "@portal/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect.en.i18n";
import {
  StyledPaymentExtensionUnableToCollectContainer,
  StyledRhythmPaymentDetails,
} from "@portal/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect.styled";
import {
  RHYTHM_ADDRESS,
  RHYTHM_LLC,
  RHYTHM_PO_BOX,
} from "@portal/constants/misc.constant";
import { useTranslations } from "@portal/hooks/useTranslations";
import { homePath } from "@portal/routes/routePaths";
import React from "react";

export const PaymentExtensionUnableToCollect = () => {
  const { translate } = useTranslations();
  const {
    tPaymentExtensionUnableToCollectAccountBlocked,
    tPaymentExtensionUnableToCollectBackHome,
    tPaymentExtensionUnableToCollectMakePayment,
    tPaymentExtensionUnableToCollectPaymentExtension,
  } = translate(paymentExtensionUnableToCollectTranslations);

  return (
    <StyledPaymentExtensionUnableToCollectContainer>
      <RhTypography variant="h1">
        {tPaymentExtensionUnableToCollectPaymentExtension}
      </RhTypography>
      <RhTypography>
        {tPaymentExtensionUnableToCollectAccountBlocked}
      </RhTypography>
      <StyledRhythmPaymentDetails>
        <RhTypography>
          {tPaymentExtensionUnableToCollectMakePayment}
        </RhTypography>
        <RhTypography>{RHYTHM_LLC}</RhTypography>
        <RhTypography>{RHYTHM_PO_BOX}</RhTypography>
        <RhTypography>{RHYTHM_ADDRESS}</RhTypography>
      </StyledRhythmPaymentDetails>
      <RhLinkButton
        data-tracking-click={{ event: "Customer going to home" }}
        href={homePath()}
      >
        {tPaymentExtensionUnableToCollectBackHome}
      </RhLinkButton>
    </StyledPaymentExtensionUnableToCollectContainer>
  );
};
