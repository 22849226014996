import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { boxShadows } from "@design-system/constants/boxShadows";
import { grey } from "@design-system/theme/palette.colors";
import { theme } from "@design-system/theme/theme.styled";
import styled from "styled-components";

const promoColor = theme.palette.success[500];
const borderHighlight = `1px solid ${theme.palette.primary.main}`;
const cardPadding = "1.25rem";

interface StyledCardElementProps {
  $desktop?: boolean;
}

interface OfferCardStylesProps {
  $calloutBackgroundColor: string | null | undefined;
  $hasCallout: boolean;
  $hasPromo: boolean;
}

export const StyledCard = styled.div<OfferCardStylesProps>`
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.white};
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  padding: ${cardPadding};
  transition: border 100ms;
  width: 100%;
  z-index: 1;

  &:hover {
    border: ${({ $hasPromo, $hasCallout, $calloutBackgroundColor }) => {
      let borderHover = borderHighlight;

      if ($hasPromo) {
        borderHover = `1px solid ${promoColor}`;
      } else if ($hasCallout) {
        borderHover = `1px solid ${$calloutBackgroundColor}`;
      }

      return borderHover;
    }};
  }
`;

export const StyledCardActions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${cardPadding};

  & > button {
    margin-bottom: theme.spacing(1);
  }
`;

export const StyledCardHeader = styled.div<StyledCardElementProps>`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 0.25rem;
  padding-bottom: ${({ $desktop }) => ($desktop ? cardPadding : 0)};
`;

export const StyledCardDescription = styled(RhTypography)`
  &.MuiTypography-root {
    line-height: 1.2;
  }
`;

interface StyledCardCalloutProps {
  $calloutBackgroundColor: string;
}

export const StyledCardCallout = styled.div<StyledCardCalloutProps>`
  align-items: center;
  background-color: ${({ $calloutBackgroundColor }) => {
    return $calloutBackgroundColor ?? promoColor;
  }};
  color: ${theme.palette.white};
  display: flex;
  justify-content: "flex-start";
  line-height: 1;
  margin-bottom: ${cardPadding};
  margin-left: -${cardPadding};
  margin-right: -${cardPadding};
  margin-top: -${cardPadding};
  padding: 0.25rem ${cardPadding};
  text-align: left;
`;

export const StyledCompareButton = styled.button<StyledCardElementProps>`
  align-items: center;
  background: none;
  border: none;
  color: ${theme.palette.primary.main};
  display: flex;
  flex: 1;
  flex-direction: ${({ $desktop }) => ($desktop ? "row" : "column")};
  font-family: inherit;
  font-size: ${({ $desktop }) => ($desktop ? "14px" : "10px")};
  gap: ${({ $desktop }) => ($desktop ? "0.5rem" : "0.25rem")};
  justify-content: center;
  line-height: 1.1;
  margin: ${({ $desktop }) => ($desktop ? 0 : "0 auto")};
  max-width: ${({ $desktop }) => ($desktop ? "100%" : "10ch")};
  padding: 0;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledOfferFeatures = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 0 0.25rem;
`;

export const StyledRateInfo = styled.div`
  align-items: "center";
  display: flex;
  flex: 1;
`;

export const StyledRateInfoChild = styled.div`
  & > p {
    line-height: 1.2;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  border-right: ${borders[100]};
  flex: 1;
  line-height: 1.1;
  padding: 0.5rem 0.75rem 1rem;
`;

export const StyledRateInfoCalculateButton = styled.button<StyledCardElementProps>`
  align-items: ${({ $desktop }) => ($desktop ? "flex-start" : "center")};
  background-color: transparent;
  border: 0;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${({ $desktop }) => ($desktop ? "flex-start" : "center")};
  line-height: 1.1;
  margin: ${({ $desktop }) => ($desktop ? 0 : "0 auto")};
  max-width: 10ch;
  padding: 0;
  padding-top: ${({ $desktop }) => ($desktop ? "0.25rem" : 0)};
  text-align: ${({ $desktop }) => ($desktop ? "left" : "center")};

  &:hover {
    text-decoration: none !important;
  }
`;

export const StyledRateCalculateText = styled(RhTypography)`
  &.MuiTypography-root {
    line-height: 1.2;
    margin-top: 4px;
  }
`;

export const StyledPhoneWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;

  & > svg {
    height: 20px;
    margin-right: 0.5rem;
    width: 20px;
  }
`;

export const StyledPhoneLink = styled.a`
  white-space: nowrap;

  &:hover {
    color: ${theme.palette.primary.main} !important;
  }
`;

export const StyledButtonGroup = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: space-evenly;

  @media screen and (min-width: 320px) {
    flex-flow: row nowrap;
  }
`;

export const StyledSeparator = styled.div`
  background: ${grey[200]};
  height: 1px;
  width: 100%;

  @media screen and (min-width: 320px) {
    height: 100%;
    width: 1px;
  }
`;
