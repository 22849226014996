import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { LOGGED_OUT_FIELD_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import styled from "styled-components";

export const StyledCreatePasswordPageCaption = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${grey[400]};
    display: block;
    margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
  }
`;
