import { portalApi } from "@portal/api/api";
import { useMutation } from "@tanstack/react-query";

export const useGuestPayAccountSummaryLookup = () => {
  const mutation = useMutation({
    mutationFn: portalApi.guestPay.accountSummaryLookup,
  });

  return mutation;
};
