import { UUIDType } from "@common/types/apiTypes";

export const SignUpProductUrls = {
  BASE: "/api/portal/product-tiers/" as const,
  list: () => SignUpProductUrls.BASE,
};

export const SignUpOfferSnapshotUrls = {
  BASE: "/api/sign-up/offer-snapshots" as const,
  retrieve: (uuid: UUIDType) => `${SignUpOfferSnapshotUrls.BASE}/${uuid}/`,
};
