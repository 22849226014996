import { api } from "@common/api/api";
import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { AREA_NOT_SERVICED, RhApiError } from "@common/types/errorTypes";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { CostEstimator } from "@portal/components/CostEstimator/CostEstimator";
import {
  OfferFilterCategories,
  OfferFilterTabs,
} from "@portal/components/OfferFilterTabs/OfferFilterTabs";
import { OfferGrid } from "@portal/components/OfferGrid/OfferGrid";
import { RepPriceComparison } from "@portal/components/RepPriceComparison/RepPriceComparison";
import { RhythmRTBs } from "@portal/components/RhythmRTBs/RhythmRTBs";
import { SignUpOfferDisclaimer } from "@portal/components/SignUpOfferDisclaimer/SignUpOfferDisclaimer";
import { RepPriceComparisonChartProvider } from "@portal/context/repPriceComparisonChart.context";
import { useHideCostEstimator } from "@portal/hooks/useHideCostEstimator";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useSignUpOfferSnapshots } from "@portal/hooks/useSignUpOfferSnapshots";
import { useTranslations } from "@portal/hooks/useTranslations";
import { availabilityErrorPaths } from "@portal/pages/SignUpAvailabilityPage/SignUpAvailabilityPage.constants";
import { signUpOfferPageTranslations } from "@portal/pages/SignUpOfferPage/SignUpOfferPage.en.i18n";
import {
  StyleRepPriceRef,
  StyledOfferPageSectionContainer,
  StyledOffersLoading,
} from "@portal/pages/SignUpOfferPage/SignUpOfferPage.styled";
import {
  OfferPageScrollToSections,
  useOfferPageScrollTo,
} from "@portal/pages/SignUpOfferPage/useOfferPageScrollTo";
import {
  calculateOfferSnapshotsToDisplay,
  calculateTermMonthsFilter,
} from "@portal/pages/SignUpOfferPage/utils";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { SignUpStateType, setSignUpInfo } from "@portal/slices/signUpSlice";
import { Show500Page } from "@portal/utils/errors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const OffersLoading = () => (
  <StyledOffersLoading>
    <RhCircularProgress />
  </StyledOffersLoading>
);

export const SignUpOfferPageContent = ({ isMobile }: { isMobile: boolean }) => {
  const {
    data: offerSnapshots,
    requestMonitor,
    error,
  } = useSignUpOfferSnapshots();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const dispatch = useDispatch();
  const {
    acquisition,
    campaignSlug,
    dunsNumber,
    estimatedMonthlyUsage,
    selectedTermMonthsTab,
    serviceAddress,
  } = useSelector(selectSignUpState);
  const hideCostEstimator = useHideCostEstimator(offerSnapshots);
  const { featureFlagClient } = useFeatureFlagClient();

  const { ref: repPriceComparisonRef } = useOfferPageScrollTo();

  const { translate } = useTranslations();

  const {
    tSignUpOfferPageRadioCampaignDisclaimer,
    tSignUpOfferPageEvGoCampaignDisclaimer,
  } = translate(signUpOfferPageTranslations);

  const { portalRepPriceComparison } = featureFlagClient.getFlags([
    ["portalRepPriceComparison", false],
  ]);

  // Moving the record visitor up higher (out of where we use it, so it is only called one time on the page)
  portalRepPriceComparison.recordVisitor();

  const uniqueOfferSnapshotTermMonths = new Set(
    offerSnapshots?.map((offerSnapshot) => offerSnapshot.termMonths)
  );
  const sortedTermMonths = Array.from(uniqueOfferSnapshotTermMonths).sort();

  const termMonthsFilter = calculateTermMonthsFilter(
    selectedTermMonthsTab,
    uniqueOfferSnapshotTermMonths
  );

  useEffect(() => {
    if (!dunsNumber && serviceAddress) {
      api.pricing.offerSnapshots
        .availability(serviceAddress)
        .then(({ dunsNumber: fetchedDunsNumber }) => {
          signUpClickNextStepHandler({
            nextStep: "plans",
            signUpData: { dunsNumber: fetchedDunsNumber, serviceAddress },
            track: false,
          });

          track({
            action: ActionType.marketingAddressFormSubmitted,
            category: CategoryType.Availability,
            event: EventType.enrollmentClick,
            label: LabelType.ActiveServiceArea,
            value: serviceAddress.zipCode,
          });
        })
        .catch((availabilityError: RhApiError) => {
          const errorCode = availabilityError?.data.errorCode;

          if (errorCode && availabilityErrorPaths[errorCode]) {
            if (errorCode === AREA_NOT_SERVICED) {
              track({
                action: ActionType.marketingAddressFormSubmitted,
                category: CategoryType.Availability,
                event: EventType.enrollmentClick,
                label: LabelType.InvalidServiceArea,
                value: serviceAddress.zipCode,
              });
            }

            signUpClickNextStepHandler({
              nextStep: availabilityErrorPaths[errorCode],
              signUpData: { serviceAddress },
              track: true,
            });
          }
        });
    }
  }, [dunsNumber, serviceAddress, signUpClickNextStepHandler]);

  useEffect(() => {
    if (selectedTermMonthsTab === null && offerSnapshots?.length) {
      const hasFeaturedOfferSnapshots = offerSnapshots.some(
        (offerSnapshot) => new OfferSnapshot(offerSnapshot).isFeatured
      );

      if (hasFeaturedOfferSnapshots) {
        dispatch(
          setSignUpInfo({
            selectedTermMonthsTab: OfferFilterCategories.Featured,
          })
        );
      }
    }
  }, [offerSnapshots, selectedTermMonthsTab, dispatch]);

  const displayTermLengthToggle =
    !campaignSlug || campaignSlug === CampaignSlugs.Default;

  const disableRepPriceComparisonForSolarBuyback =
    campaignSlug && campaignSlug.includes(CampaignSlugs.SolarBuyback);

  const displayedOfferSnapshots = calculateOfferSnapshotsToDisplay(
    offerSnapshots,
    termMonthsFilter,
    displayTermLengthToggle
  );

  const selectOfferSnapshot = (offerSnapshot: OfferSnapshotType) => {
    const finalOfferSnapshot = new OfferSnapshot(offerSnapshot);

    const signUpData: Partial<SignUpStateType> = {
      acquisition,
      autopay: !!finalOfferSnapshot.isAutoPayPaperlessDiscountOffer,
      campaignSlug,
      estimatedMonthlyUsage,
      invoiceByPrint: !finalOfferSnapshot.isAutoPayPaperlessDiscountOffer,
      offerSnapshotUuid: finalOfferSnapshot.uuid,
      offersnapshotId: finalOfferSnapshot.id,
    };

    signUpClickNextStepHandler({
      nextStep: "name",
      signUpData,
      track: true,
    });

    featureFlagClient.trackMetric(FeatureFlagMetric.PortalProspectSelectedPlan);
  };

  const offerSnapshotModels: OfferSnapshot[] =
    offerSnapshots?.map((offerSnapshot) => new OfferSnapshot(offerSnapshot)) ??
    [];

  const radioCampaign = campaignSlug && campaignSlug === "digital-promo-50";
  const evGoCampaign = campaignSlug && campaignSlug === "electric-vehicle";

  if (requestMonitor.didFail) {
    throw new Show500Page(`Could not fetch offers: ${error?.data?.errorCode}`);
  }

  if (requestMonitor.isPending) {
    return <OffersLoading />;
  }

  if (requestMonitor.isWaiting || !termMonthsFilter || !dunsNumber) {
    return <OffersLoading />;
  }

  return (
    <RepPriceComparisonChartProvider offerSnapshots={offerSnapshotModels}>
      {displayTermLengthToggle && sortedTermMonths && (
        <OfferFilterTabs
          offerTermMonths={sortedTermMonths}
          isMobile={isMobile}
          currentValue={termMonthsFilter}
          hasFeatured={offerSnapshots?.some(
            (offerSnapshot) => new OfferSnapshot(offerSnapshot).isFeatured
          )}
        />
      )}

      {displayedOfferSnapshots ? (
        <OfferGrid
          offerSnapshots={displayedOfferSnapshots}
          onSelectOfferSnapshot={selectOfferSnapshot}
        />
      ) : (
        <OffersLoading />
      )}

      {radioCampaign && (
        <SignUpOfferDisclaimer
          disclaimer={tSignUpOfferPageRadioCampaignDisclaimer}
        />
      )}
      {evGoCampaign && (
        <SignUpOfferDisclaimer
          disclaimer={tSignUpOfferPageEvGoCampaignDisclaimer}
        />
      )}

      <StyledOfferPageSectionContainer $isMobile={isMobile}>
        <RhythmRTBs />
      </StyledOfferPageSectionContainer>

      {portalRepPriceComparison.value &&
        !disableRepPriceComparisonForSolarBuyback && (
          <StyleRepPriceRef
            ref={repPriceComparisonRef}
            id={OfferPageScrollToSections.RepPriceComparisonChart}
          >
            <StyledOfferPageSectionContainer $isMobile={isMobile}>
              <RepPriceComparison
                offerSnapshots={offerSnapshotModels}
                onSelectOfferSnapshot={(offerSnapshot: OfferSnapshot) =>
                  selectOfferSnapshot(offerSnapshot.toType())
                }
              />
            </StyledOfferPageSectionContainer>
          </StyleRepPriceRef>
        )}

      {!hideCostEstimator && displayedOfferSnapshots && (
        <CostEstimator
          offerSnapshots={displayedOfferSnapshots}
          onSelectOfferSnapshot={selectOfferSnapshot}
        />
      )}
    </RepPriceComparisonChartProvider>
  );
};
