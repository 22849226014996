import { ChevronRight } from "@mui/icons-material";
import { myAccountTableTranslations } from "@portal/components/MyAccountTable/MyAccountTable.en.i18n";
import {
  StyledMyAccountTable,
  StyledPageButton,
  StyledPrevNextButton,
  StyledTableCell,
  StyledTableHeader,
  StyledTablePaginationContainer,
  StyledTableRow,
} from "@portal/components/MyAccountTable/MyAccountTable.styled";
import { MyAccountTableProps } from "@portal/components/MyAccountTable/MyAccountTableTypes";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  PaginationOptions,
  PaginationTableState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useMemo } from "react";

export const MyAccountTable = <T,>(props: MyAccountTableProps<T>) => {
  const { columns, data, pagination, rowLabel, tableState, columnVisibility } =
    props;

  const { translate, translateJsx } = useTranslations();

  const { tMyAccountTableNextPage, tMyAccountTablePreviousPage } = translate(
    myAccountTableTranslations
  );

  const initialState = {
    pagination: {
      pageIndex: 0,
      pageSize: 8,
    },
  };

  const getPaginationProps = () => {
    if (!pagination) {
      return {};
    }

    const { pageCount, setPagination } = props;

    return {
      manualPagination: true,
      onPaginationChange: setPagination,
      pageCount,
    } satisfies PaginationOptions;
  };

  const getPaginationState = () => {
    if (!pagination) {
      return {};
    }

    const { pageIndex, pageSize } = props;

    return {
      pagination: {
        pageIndex,
        pageSize,
      },
    } satisfies PaginationTableState;
  };

  const table = useReactTable({
    columns: useMemo(() => columns, [columns]),
    data: useMemo(() => data, [data]),
    getCoreRowModel: getCoreRowModel(),
    // Allows for both controlled and uncontrolled pagination, useful for when
    // endpoints don't support pagination
    ...(!pagination && { getPaginationRowModel: getPaginationRowModel() }),
    ...(!pagination && initialState),
    ...(pagination && getPaginationProps()),
    ...(columnVisibility && columnVisibility),

    state: {
      ...(!pagination && tableState),
      columnVisibility,
      ...(pagination && getPaginationState()),
    },
  });

  const { tMyAccountTablePageOf } = translateJsx({
    tMyAccountTablePageOf: {
      current: table.getState().pagination.pageIndex + 1,
      total: table.getPageCount(),
    },
  });

  return (
    <>
      <StyledMyAccountTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledTableHeader key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </StyledTableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const tableRowLabel = rowLabel
              ? `${rowLabel}-${row.id}`
              : `row-${row.id}`;

            return (
              <StyledTableRow key={row.id} aria-label={tableRowLabel}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <StyledTableCell
                      key={cell.id}
                      $isDownloadCell={cell.column.columnDef.id === "download"}
                      $noPadding={cell.column.columnDef.meta?.classList?.includes(
                        "noPadding"
                      )}
                      $tableCellCenter={cell.column.columnDef.meta?.classList?.includes(
                        "tableCellCenter"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </tbody>
      </StyledMyAccountTable>
      {pagination && table.getPageCount() !== 0 ? (
        <StyledTablePaginationContainer aria-label="Page Navigation">
          <StyledPrevNextButton
            aria-label={tMyAccountTablePreviousPage}
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            <ChevronRight
              width="10px"
              style={{ transform: "rotate(180deg)" }}
              role="presentation"
              aria-hidden
            />
          </StyledPrevNextButton>

          <div>
            {data.length !== 0 ? (
              <strong>{tMyAccountTablePageOf}</strong>
            ) : null}
          </div>

          <StyledPrevNextButton
            aria-label={tMyAccountTableNextPage}
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            <ChevronRight width="10px" role="presentation" aria-hidden />
          </StyledPrevNextButton>
        </StyledTablePaginationContainer>
      ) : null}
      {!pagination && table.getPageOptions().length > 1 ? (
        <StyledTablePaginationContainer>
          <StyledPrevNextButton
            aria-label={tMyAccountTablePreviousPage}
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            <ChevronRight
              width="10px"
              style={{ transform: "rotate(180deg)" }}
              role="presentation"
              aria-hidden
            />
          </StyledPrevNextButton>
          {table.getPageOptions().map((page) => (
            <StyledPageButton
              key={page}
              type="button"
              className={classNames({
                active: table.getState().pagination.pageIndex === page,
              })}
              onClick={() => table.setPageIndex(page)}
            >
              {page + 1}
            </StyledPageButton>
          ))}
          <StyledPrevNextButton
            aria-label={tMyAccountTableNextPage}
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            <ChevronRight width="10px" role="presentation" aria-hidden />
          </StyledPrevNextButton>
        </StyledTablePaginationContainer>
      ) : null}
    </>
  );
};
