import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as CheckIcon } from "@design-system/icons/CheckIcon.svg";
import {
  darkPurple,
  green,
  grey,
  red,
} from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { DeviceStatus } from "@portal/types/devicesTypes";
import styled, { css } from "styled-components";

export const SelectDeviceContainer = styled.div<{
  $isAlreadyConnected?: boolean;
  $isEnrolled?: boolean;
  $isSelected?: boolean;
}>`
  align-items: flex-start;
  border: 1px solid ${grey[100]};
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  padding: 1rem;
  position: relative;
  width: 100%;

  > * {
    flex: 1;
  }

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: row nowrap;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${darkPurple.main};
    `}

  ${({ $isEnrolled }) =>
    $isEnrolled &&
    css`
      background-color: ${grey[50]};
    `}

  ${({ $isAlreadyConnected }) =>
    $isAlreadyConnected &&
    css`
      background-color: ${red[50]};
      border-color: ${red.dark};
    `}
`;

export const SelectDeviceInfoColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;

  > * {
    flex: 1;
  }

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: column nowrap;
  }
`;

export const SelectDeviceAddressContainer = styled.div`
  display: flex;
  flex: 2;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
  width: 100%;
`;

export const SelectDeviceStatus = styled.span<{ $status: DeviceStatus }>`
  color: ${({ $status }) =>
    $status === DeviceStatus.Online ? green.main : red.main};
  font-weight: ${FontWeight.Semibold};
  text-transform: capitalize;
`;

export const EnrolledDeviceAddressContainer = styled.div`
  display: flex;
  flex: 2;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 100%;
`;

export const ConnectionStatus = styled.span`
  align-items: center;
  display: inline-flex;
  gap: ${rhSpacingPx(0.5)};
`;

export const StyledEnrolledCheckIcon = styled(CheckIcon)`
  color: ${green.main};
`;

export const AlreadyConnectedText = styled(RhTypography).attrs({
  variant: "body2",
})`
  color: ${red.main};
`;
