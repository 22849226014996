import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { enrollReferralCodeAnnouncementTranslations } from "@portal/components/EnrollReferralCodeAnnouncement/EnrollReferralCodeAnnouncement.en.i18n";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { useProspectReferralCodeQuery } from "@portal/hooks/queries/useProspectReferralCode.query";
import { useTranslations } from "@portal/hooks/useTranslations";
import { useEffect } from "react";

export const EnrollReferralCodeAnnouncement = () => {
  const { translate } = useTranslations();
  const { uuid: prospectUuid, referralCode } = useProspectFromContext();
  const { announceNotice } = useRhAnnouncement();
  const prospectReferralCodeQuery = useProspectReferralCodeQuery(
    prospectUuid,
    referralCode
  );

  useEffect(() => {
    if (prospectReferralCodeQuery.data) {
      const { tEnrollReferralCodeAnnouncementReferralCodeApplied } = translate(
        enrollReferralCodeAnnouncementTranslations
      );

      announceNotice(tEnrollReferralCodeAnnouncementReferralCodeApplied);
    }
  }, [announceNotice, prospectReferralCodeQuery.data, translate]);

  return null;
};
