import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { RhApiError } from "@common/types/errorTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as EyeIcon } from "@design-system/icons/EyeIcon.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface SignInFormValues {
  email: string;
  password: string;
}

const EmailInput = styled(RhTextInput)`
  &.MuiFormControl-root {
    margin-bottom: ${rhSpacingPx(3)};
  }
`;
const PasswordInput = styled(RhTextInput)`
  &.MuiFormControl-root {
    margin-bottom: ${rhSpacingPx(4)};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

const SignInFormButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

const useSignInFormTranslations = () => {
  const { t } = useRhIntl();

  return {
    tEmail: t("SignInForm.email"),
    tHidePassword: t("SignInForm.hidePassword"),
    tInvalidEmailOrPassword: t("SignInForm.error.invalidEmailOrPassword"),
    tInvalidInputEmail: t("SignInForm.error.invalidInputEmail"),
    tInvalidInputPassword: t("SignInForm.error.invalidInputPassword"),
    tLoggingInCustomer: t("SignInForm.loggingInCustomer"),
    tPassword: t("SignInForm.password"),
    tShowPassword: t("SignInForm.showPassword"),
    tSubmitCta: t("SignInForm.submitCTA"),
  };
};

export const SignInForm = () => {
  const flash = useRhFlash();
  const {
    tEmail,
    tHidePassword,
    tInvalidEmailOrPassword,
    tInvalidInputEmail,
    tInvalidInputPassword,
    tLoggingInCustomer,
    tShowPassword,
    tPassword,
    tSubmitCta,
  } = useSignInFormTranslations();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, dirtyFields, errors },
  } = useForm<SignInFormValues>();

  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [inputPasswordType, setInputPasswordType] = useState<
    "text" | "password"
  >("password");

  const toggleViewPassword = () => {
    setInputPasswordType((currentState) => {
      return currentState === "password" ? "text" : "password";
    });
  };

  const onSubmit = handleSubmit(({ email, password }: SignInFormValues) => {
    if (errors.email || errors.password) {
      return;
    }

    setIsSigningIn(true);

    return portalAuthClient
      .signIn(email, password)
      .catch((_error: RhApiError) => {
        setIsSigningIn(false);
        flash.error(tInvalidEmailOrPassword);
      });
  });

  const emailProps = register("email", {
    pattern: {
      message: tInvalidInputEmail,
      value: EMAIL_VALIDATION_REGEX,
    },
    required: true,
  });

  const passwordProps = register("password", {
    required: tInvalidInputPassword,
  });

  return (
    <form onSubmit={onSubmit}>
      <EmailInput
        errorMessage={errors.email?.message}
        error={Boolean(errors.email)}
        placeholder=""
        color="primary"
        autoFocus
        {...emailProps}
      >
        {tEmail}
      </EmailInput>

      <PasswordInput
        errorMessage={errors.password?.message}
        error={Boolean(errors.password)}
        placeholder=""
        color="primary"
        type={inputPasswordType}
        {...passwordProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={toggleViewPassword}
                aria-label={
                  inputPasswordType === "text" ? tHidePassword : tShowPassword
                }
              >
                <EyeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      >
        {tPassword}
      </PasswordInput>

      <SignInFormButton
        data-tracking-click={{ event: "Customer attempting to sign in" }}
        type="submit"
        disabled={
          !dirtyFields.email ||
          !dirtyFields.password ||
          isSubmitting ||
          isSigningIn
        }
        color="primary"
      >
        {isSigningIn ? (
          <>
            <StyledCircularProgress color="inherit" size={14} />
            <RhTypography color="inherit" variant="inherit">
              {tLoggingInCustomer}
            </RhTypography>
          </>
        ) : (
          tSubmitCta
        )}
      </SignInFormButton>
    </form>
  );
};
