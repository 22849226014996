import { IdType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { switchPrintToPaperlessModalTranslations } from "@portal/components/SwitchPrintToPaperlessModal/SwitchPrintToPaperlessModal.en.i18n";
import {
  SwitchPrintToPaperlessContentContainer,
  SwitchPrintToPaperlessModalBackdropCircularProgress,
  SwitchPrintToPaperlessModalModal,
  SwitchPrintToPaperlessModalPaperButton,
  SwitchPrintToPaperlessModalSubtitle,
  SwitchPrintToPaperlessModalTitle,
  SwitchPrintToPaperlessTitleContainer,
} from "@portal/components/SwitchPrintToPaperlessModal/SwitchPrintToPaperlessModal.styled";
import { usePremiseCommunicationPreferencesMutation } from "@portal/hooks/mutations/usePremiseCommunicationPreferences";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as WomanUnplug } from "@portal/images/WomanUnplug.svg";
import { premiseRefetch } from "@portal/slices/premiseSlice";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";

interface SwitchPrintToPaperlessModalProps {
  handleClose(): void;
  premiseId: IdType;
  showRewardsIncentive: boolean;
}

export const SwitchPrintToPaperlessModal = (
  props: SwitchPrintToPaperlessModalProps
) => {
  const { premiseId, handleClose, showRewardsIncentive } = props;
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const premiseCommunicationPreferenceMutation =
    usePremiseCommunicationPreferencesMutation();

  const { setCookie } = usePortalCookies();

  const { translate } = useTranslations();
  const {
    tSwitchPrintToPaperlessModalIncentiveSubTitle,
    tSwitchPrintToPaperlessModalIncentiveTitle,
    tSwitchPrintToPaperlessModalNoIncentiveSubTitle,
    tSwitchPrintToPaperlessModalNoIncentiveTitle,
    tSwitchPrintToPaperlessModalLegalText,
    tSwitchPrintToPaperlessModalRemindMeLater,
    tSwitchPrintToPaperlessModalSwitch,
    tSwitchPrintToPaperlessModalUpdateFailure,
    tSwitchPrintToPaperlessModalUpdateToPaperlessSuccess,
    tSwitchPrintToPaperlessModalClose,
    tSwitchPrintToPaperlessModalLabel,
  } = translate(switchPrintToPaperlessModalTranslations);

  const titleText = showRewardsIncentive
    ? tSwitchPrintToPaperlessModalIncentiveTitle
    : tSwitchPrintToPaperlessModalNoIncentiveTitle;

  const subTitleText = showRewardsIncentive
    ? tSwitchPrintToPaperlessModalIncentiveSubTitle
    : tSwitchPrintToPaperlessModalNoIncentiveSubTitle;

  const handleUpdateCommunicationPreferencesToPaperless = () => {
    premiseCommunicationPreferenceMutation.mutate(
      { paperless: true, premiseId },
      {
        onError: () => {
          flash.error(tSwitchPrintToPaperlessModalUpdateFailure);
        },
        onSuccess: () => {
          dispatch(premiseRefetch());

          flash.success(tSwitchPrintToPaperlessModalUpdateToPaperlessSuccess);
          handleOnClose();
        },
      }
    );
  };

  const handleOnClose = () => {
    const sixtyDaysFromNow = dayjs().add(60, "day").toDate();

    setCookie("rhSwitchPrintToPaperlessModal", "hide", {
      expires: sixtyDaysFromNow,
    });
    handleClose();
  };

  const isSendingRequest = premiseCommunicationPreferenceMutation.isPending;

  return (
    <SwitchPrintToPaperlessModalModal
      ariaLabel={tSwitchPrintToPaperlessModalLabel}
      ariaCloseLabel={tSwitchPrintToPaperlessModalClose}
      handleClose={handleOnClose}
      noPadding
      hideCloseButton
    >
      <SwitchPrintToPaperlessTitleContainer>
        <SwitchPrintToPaperlessModalTitle
          variant="h1"
          fontWeight={FontWeight.Bold}
        >
          {titleText}
        </SwitchPrintToPaperlessModalTitle>

        <WomanUnplug />
      </SwitchPrintToPaperlessTitleContainer>
      <SwitchPrintToPaperlessContentContainer>
        <SwitchPrintToPaperlessModalSubtitle variant="subtitle1">
          {subTitleText}
        </SwitchPrintToPaperlessModalSubtitle>
        <RhTypography variant="body2" color="textSecondary">
          {tSwitchPrintToPaperlessModalLegalText}
        </RhTypography>
        <RhButton
          data-tracking-click={{
            event: "Customer switching from paper to paperless",
          }}
          fullWidth
          onClick={handleUpdateCommunicationPreferencesToPaperless}
        >
          {tSwitchPrintToPaperlessModalSwitch}
        </RhButton>
        <SwitchPrintToPaperlessModalPaperButton onClick={handleOnClose}>
          {tSwitchPrintToPaperlessModalRemindMeLater}
        </SwitchPrintToPaperlessModalPaperButton>
      </SwitchPrintToPaperlessContentContainer>
      {isSendingRequest ? (
        <SwitchPrintToPaperlessModalBackdropCircularProgress />
      ) : null}
    </SwitchPrintToPaperlessModalModal>
  );
};
