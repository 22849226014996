import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { AddBankAccount } from "@portal/components/AddBankAccount/AddBankAccount";
import { AddPaymentCard } from "@portal/components/AddPaymentCard/AddPaymentCard";
import { useGuestPayAddPaymentMethod } from "@portal/hooks/mutations/useGuestPayAddPaymentMethod";
import { useGuestPayPaymentMethod } from "@portal/hooks/queries/useGuestPayPaymentMethod";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React from "react";
import styled from "styled-components";

const useGuestPayAddOrGetPaymentMethodTranslation = () => {
  const { t } = useRhIntl();

  return {
    tAddPaymentMethodError: t(
      "GuestPayAddOrGetPaymentMethod.addPaymentMethodError"
    ),
    tPaymentAdded: t("GuestPayAddOrGetPaymentMethod.paymentAdded"),
  };
};

interface GuestPayAddOrGetPaymentMethodProps {
  isNoCheckOrAch: boolean;
  isNoCreditOrDebitCard: boolean;
  token: string;
}

export const GuestPayAddOrGetPaymentMethod = (
  props: GuestPayAddOrGetPaymentMethodProps
) => {
  const { token, isNoCheckOrAch, isNoCreditOrDebitCard } = props;
  const flash = useRhFlash();

  const addPaymentMethodMutation = useGuestPayAddPaymentMethod();
  const guestPayPaymentMethodQuery = useGuestPayPaymentMethod({
    // Once we add a card, we never want to enable this query instance,
    // as it would mean that once a payment method is there and the BE
    // fails for whatever reason, the add payment button would show up again
    enabled: !addPaymentMethodMutation.isSuccess,
    // We don't want to refetch on error as this is supposed to fail when there are no cards
    retry: 0,
    token,
  });

  const { tAddPaymentMethodError, tPaymentAdded } =
    useGuestPayAddOrGetPaymentMethodTranslation();

  const handleAddCardSuccess = (data: ZuoraAddPaymentResponseType) => {
    addPaymentMethodMutation.mutate(
      {
        paymentMethodId: data.refId,
        token,
      },
      {
        onError: () => {
          flash.error(tAddPaymentMethodError);
        },
        onSuccess: () => {
          flash.success(tPaymentAdded);
        },
      }
    );
  };

  if (guestPayPaymentMethodQuery.isError) {
    return (
      <StyledCardWrapper>
        {!isNoCreditOrDebitCard ? (
          <AddPaymentCard
            onCardAdded={handleAddCardSuccess}
            showFlashSuccess={false}
          />
        ) : null}
        {!isNoCheckOrAch ? (
          <AddBankAccount
            onBankAccountAdded={handleAddCardSuccess}
            showFlashSuccess={false}
          />
        ) : null}
      </StyledCardWrapper>
    );
  }

  if (guestPayPaymentMethodQuery.isPending) {
    return <RhCircularProgress />;
  }

  return null;
};

const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  margin: ${rhSpacingPx(1.5)} 0;
`;
