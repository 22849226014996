import { prospectApi } from "@common/api/prospectApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@mui/material";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { useTranslations } from "@portal/hooks/useTranslations";
import { signUpNoCoveragePageTranslations } from "@portal/pages/SignUpNoCoveragePage/SignUpNoCoveragePage.en.i18n";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SignUpNoCoverageFormValues {
  email: string;
}

const signUpNoCoverageFormValidator =
  generateValidationErrorCollector<SignUpNoCoverageFormValues>({
    email: [isRequired, isValidEmail],
  });

export const SignUpNoCoveragePage = () => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const dispatch = useDispatch();
  const prospectId = useSelector(selectProspectId);

  const { translate } = useTranslations();

  const {
    tSignUpNoCoveragePageYoureOnTheList,
    tSignUpNoCoveragePageWellLetYouKnow,
    tSignUpNoCoveragePageBackToSite,
    tSignUpNoCoveragePageEmail,
    tSignUpNoCoveragePageSubmitCTA,
    tSignUpNoCoveragePageRhythmHasntMadeIt,
    tSignUpNoCoveragePageButWellLetYouKnow,
  } = translate(signUpNoCoveragePageTranslations);

  if (showConfirmation) {
    return (
      <SignUpPageLayout>
        <LoggedOutPageHeader
          headerText={tSignUpNoCoveragePageYoureOnTheList}
          subHeaderText={tSignUpNoCoveragePageWellLetYouKnow}
        />
        <Box mt={LOGGED_OUT_CONTAINER_SPACING} width={LOGGED_OUT_FORM_WIDTH}>
          <RhButton
            data-tracking-click={{
              event:
                "customer navigating away from enrollment flow as we have no coverage in their address",
            }}
            fullWidth
            color="primary"
            onClick={() => navigate("/")}
          >
            {tSignUpNoCoveragePageBackToSite}
          </RhButton>
        </Box>
      </SignUpPageLayout>
    );
  }
  const onSubmit = ({ email }: SignUpNoCoverageFormValues) => {
    dispatch(setSignUpInfo({ email }));
    setShowConfirmation(true);
    return prospectApi.trackServiceUnavailable({ email, id: prospectId });
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpNoCoveragePageRhythmHasntMadeIt}
        subHeaderText={tSignUpNoCoveragePageButWellLetYouKnow}
      />
      <Form<SignUpNoCoverageFormValues>
        onSubmit={onSubmit}
        validate={signUpNoCoverageFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpNoCoveragePageSubmitCTA}
          >
            <LoggedOutFieldsLayout>
              <RhTextField name="email" autoComplete="email">
                {tSignUpNoCoveragePageEmail}
              </RhTextField>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
