import { findClosest } from "@common/utils/arrayUtils";
import { formatKwh } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  SliderOnChangeEvent,
  SliderOnChangeFunction,
  SliderOnChangeValueType,
} from "@design-system/types/domEventTypes";
import {
  DescriptionContainer,
  DescriptionImage,
  MonthlyUsageSliderContainer,
  StyledUsageSliderUnorderedList,
} from "@portal/components/MonthlyUsageSlider/MonthlyUsageSlider.styled";
import { MonthlyUsageSliderInput } from "@portal/components/MonthlyUsageSlider/MonthlyUsageSliderInput";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { TranslationKey, useRhIntl } from "@portal/hooks/useRhIntl";
import { ReactComponent as House1000 } from "@portal/images/House1000.svg";
import { ReactComponent as House1500 } from "@portal/images/House1500.svg";
import { ReactComponent as House2000 } from "@portal/images/House2000.svg";
import { ReactComponent as House2500 } from "@portal/images/House2500.svg";
import { ReactComponent as House3000 } from "@portal/images/House3000.svg";
import { ReactComponent as House3500 } from "@portal/images/House3500.svg";
import { ReactComponent as House500 } from "@portal/images/House500.svg";
import React, { useEffect, useState } from "react";

type DescriptionRecord = {
  Icon: JSX.Element;
  descriptor: TranslationKey;
  preference: TranslationKey;
};

interface MonthlyUsageSliderProps {
  initialValue: number;
  onChange: SliderOnChangeFunction;
}

export const monthlyUsageSliderConfig: Record<string, DescriptionRecord> = {
  1000: {
    Icon: <House1000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-1000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-1000",
  },
  1500: {
    Icon: <House1500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-1500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-1500",
  },
  2000: {
    Icon: <House2000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-2000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-2000",
  },
  2500: {
    Icon: <House2500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-2500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-2500",
  },
  3000: {
    Icon: <House3000 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-3000",
    preference: "MonthlyUsageSlider.homePrefDescriptor-3000",
  },
  3500: {
    Icon: <House3500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-3500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-3500",
  },
  500: {
    Icon: <House500 title="House" />,
    descriptor: "MonthlyUsageSlider.homeDescriptor-500",
    preference: "MonthlyUsageSlider.homePrefDescriptor-500",
  },
};

export const monthlyUsageSliderMarks = Object.keys(monthlyUsageSliderConfig);
export const monthlyUsageSliderMarksValues = monthlyUsageSliderMarks.map((x) =>
  parseInt(x, 10)
);

export const MonthlyUsageSlider = ({
  onChange,
  initialValue,
}: MonthlyUsageSliderProps) => {
  const { t } = useRhIntl();

  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] =
    useState<number>(initialValue);

  const handleChange: SliderOnChangeFunction = (
    event: SliderOnChangeEvent,
    value: SliderOnChangeValueType
  ) => {
    if (!Array.isArray(value)) {
      setEstimatedMonthlyUsage(value);
      onChange(event, value);
    }
  };

  useEffect(() => {
    if (!Number.isFinite(initialValue)) {
      setEstimatedMonthlyUsage(DEFAULT_AVERAGE_MONTHLY_USAGE);
    }
  }, [initialValue]);

  const renderDescription = () => {
    if (!(estimatedMonthlyUsage in monthlyUsageSliderConfig)) {
      setEstimatedMonthlyUsage(
        findClosest(monthlyUsageSliderMarksValues, estimatedMonthlyUsage)
      );
      return null;
    } else {
      const { Icon, descriptor, preference } =
        monthlyUsageSliderConfig[estimatedMonthlyUsage.toString()];

      return (
        <DescriptionContainer>
          <DescriptionImage>{Icon}</DescriptionImage>
          <div>
            <RhTypography variant="subtitle2" fontWeight="Bold">
              {formatKwh(estimatedMonthlyUsage, 0)}
            </RhTypography>
            <StyledUsageSliderUnorderedList>
              <li>
                <RhTypography variant="body1">{t(descriptor)}</RhTypography>
              </li>
              <li>
                <RhTypography variant="body1">{t(preference)}</RhTypography>
              </li>
            </StyledUsageSliderUnorderedList>
          </div>
        </DescriptionContainer>
      );
    }
  };

  if (!estimatedMonthlyUsage) {
    return null;
  }
  return (
    <MonthlyUsageSliderContainer>
      {renderDescription()}
      <MonthlyUsageSliderInput
        initialValue={estimatedMonthlyUsage}
        onChange={handleChange}
      />
    </MonthlyUsageSliderContainer>
  );
};
