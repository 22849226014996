import { LONG_FRIENDLY_DATE_FORMAT } from "@common/constants/date.constant";
import { ReactComponent as Share } from "@design-system/icons/Share.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { renderLogo } from "@portal/components/RepPriceComparisonChart/renderLogo";
import {
  EFLCell,
  Plan,
  PlanName,
  PlanNameWrapper,
  PlanPrice,
  PlanPricePerKwh,
  PlanUpdatedOn,
  PriceCell,
  ProviderLogoCell,
} from "@portal/components/RepPriceComparisonPlanList/RepPriceComparisonPlanList.styled";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import {
  ActionType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { CompetitorPlan } from "@portal/types/competitorTypes";
import dayjs from "dayjs";
import React, { FC, PropsWithChildren } from "react";

interface RepPriceComparisonPlanListRowProps {
  plan: CompetitorPlan;
}

const EFLLink: FC<PropsWithChildren<{ plan: CompetitorPlan }>> = ({
  plan,
  children,
}) => {
  const { eflUrl, competitorName, planName, price2000Kwh } = plan;

  const trackEvent = () =>
    track({
      action: ActionType.click,
      category: "onboarding.plans.repPriceEFL",
      competitor: competitorName,
      efl: eflUrl,
      event: EventType.enrollmentClick,
      label: LabelType.ClickedEFL,
      planName,
      price: Number(price2000Kwh),
    });

  return (
    <a
      href={eflUrl}
      target="_blank"
      rel="noreferrer"
      onClick={trackEvent}
      title={`EFL for ${planName}`}
    >
      {children}
    </a>
  );
};

export const RepPriceComparisonPlanListRow: FC<
  RepPriceComparisonPlanListRowProps
> = ({ plan }) => {
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  return (
    <Plan>
      <ProviderLogoCell>{renderLogo(plan.competitorName)}</ProviderLogoCell>
      <PlanNameWrapper>
        {isMobile ? (
          <EFLLink plan={plan}>
            <PlanName>{plan.planName}</PlanName>
          </EFLLink>
        ) : (
          <PlanName>{plan.planName}</PlanName>
        )}
        <PlanUpdatedOn>
          {t("RepPriceComparisonPlanList.lastUpdated")}{" "}
          {dayjs(plan.parsedDatetime).format(LONG_FRIENDLY_DATE_FORMAT)}
        </PlanUpdatedOn>
      </PlanNameWrapper>
      <PriceCell>
        <PlanPrice>{Number(plan.price2000Kwh).toFixed(1)}¢</PlanPrice>
        <PlanPricePerKwh>
          {t("RepPriceComparisonPlanList.perKwh")}
        </PlanPricePerKwh>
      </PriceCell>
      <EFLCell>
        <EFLLink plan={plan}>
          <Share />
        </EFLLink>
      </EFLCell>
    </Plan>
  );
};
